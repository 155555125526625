import { CrudService } from "../../../services";
import { GEDUrls } from './contract.constants'

export function createGEDFile(data) {
    return CrudService.Post(GEDUrls.CREATE_GEDFILE_URL, data);
}
export function fetchGEDFiles(queryParams) {
    return CrudService.Get(GEDUrls.FETCH_GEDFILES_URL, {}, queryParams);
}
export function fetchGEDFilesPost(queryParams) {
    return CrudService.Post(GEDUrls.FETCH_GEDFILES_URL, queryParams);
}
export function fetchGEDFile(fileID) {
    return CrudService.Get(GEDUrls.FETCH_GEDFILE_URL + fileID);
}
export function deleteGEDFile(files) {
    return CrudService.Delete(GEDUrls.DELETE_GEDFILES_URL, files);
}
export function editGEDFile(fileID) {
    return CrudService.Get(GEDUrls.EDIT_GEDFILE_URL + fileID);
}
export function updateGEDFile(file) {
    return CrudService.Patch(GEDUrls.UPDATE_GEDFILE_URL, file);
}
