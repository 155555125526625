import { createSlice } from "@reduxjs/toolkit";
const queryString = require('query-string');
const initialState = {
    contactErrors: null,
    listContacts: {},
    contact:null,
    currentQuery:""
};

export const contactSlice = createSlice({
    name: "contact",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { errors } = action.payload
            state.contactErrors = errors;
        },
        contactCreated: (state) => {
            state.contactErrors = state.contactErrors === false ? null : false
        },
        resetState:(state, action) => {
            action.payload && action.payload.map(elem=>{
                state[elem.name]=elem.value
            })
        },
        contactsFetched: (state, action) => {
            const { listContacts,currentQuery } = action.payload
            state.contactErrors = null
            state.currentQuery='?'+queryString.stringify(currentQuery)
            state.listContacts = listContacts
        },
        contactFetched:(state, action) => {
            const { Contact } = action.payload
            state.contactErrors = null
            state.contact=Contact
        },
        contactsDeleted:(state, action) => {
            const { asyncData,contacts } = action.payload
            if (asyncData) {
                let prevList = state.listContacts
                prevList.data = prevList.data.filter(contact => contacts.indexOf(contact.id_contact) === -1)
                state.listContacts = prevList
            }
            state.contactErrors = state.contactErrors === false ? null : false
        }
    }
});