import { CrudService } from "../../../services";
import { notificationsUrls } from './notifications.constants'
export function fetchNotifications() {
    return CrudService.Get(notificationsUrls.GET_NOTIFICATIONS_URL);
}
export function readNotification(idNotification) {
    return CrudService.Patch(notificationsUrls.READ_NOTIFICATION_URL + idNotification);
}
export function resetNotification() {
    return CrudService.Get(notificationsUrls.RESET_NOTIFICATION_URL);
}