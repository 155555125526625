import { createSlice } from "@reduxjs/toolkit";

const initialState = {
};

export const commonSlice = createSlice({
    name: "common",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { errors, errorType } = action.payload
            state[errorType] = errors;
        }
    }
});