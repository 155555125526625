import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CostumTable, SageTable } from '../../Commons'
class ContactsList extends Component {
    render() {
        return (
            <>
                <SageTable
                    columns={[
                        { title: "Statut", dataIndex: "status", fixed: 'left', ellipsis: true, render: status => status ? "Active" : "in Active" },
                        { title: "Civilité", dataIndex: "genre", ellipsis: true },
                        { title: "Nom", dataIndex: "nom", fixed: 'left', ellipsis: true, },
                        { title: "Prénom", dataIndex: "prenom", ellipsis: true, },
                        { title: "Email", dataIndex: "email", ellipsis: true, },
                        { title: "Téléphone", dataIndex: "telephone", ellipsis: true, },
                        { title: "Mobile", dataIndex: "mobile", ellipsis: true, },
                        { title: "Adresse", dataIndex: "address", ellipsis: true, fixed: 'right' },
                    ]}
                    style={{ height: 580 }}
                    data={this.props.contacts}
                    IterableProps={{
                        emptyText: 'Aucun contact associé á cette société',
                        rowKey: "id_contact",
                        pagination: false
                    }}
                />
                <br />
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { function_person } } = state.enums
    return { function_person }
}
const connectedClass = connect(mapState, {})(ContactsList)
export { connectedClass as ContactsList }