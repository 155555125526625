import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class ConstructeurInstallUVE extends Component {
    render() {
        return (
            <CommonComponent keyName="constructeurInstallation" titleSetting={global._trans('sites', 'installConstruUve')} constructeurInstallation={this.props.constructeurInstallation} />
        )
    }
}
function mapState(state) {
    const { listEnums: { constructeurInstallation } } = state.enums
    return { constructeurInstallation }
}
const connectedClass = connect(mapState, null)(ConstructeurInstallUVE)
export { connectedClass as ConstructeurInstallUVE }