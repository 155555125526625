import { CrudService } from "../../../services";
import { userSimpleUrls } from './userSimple.constants'
export function updateUserSimple(user) {
    return CrudService.Patch(userSimpleUrls.UPDATE_USER_SIMPLE_URL, user);
}
export function fetchUsersSimple(queryParams) {
    return CrudService.Get(userSimpleUrls.FETCH_USERS_SIMPLE_URL,{},queryParams);
}
export function fetchUsersSimplePost(queryParams) {
    return CrudService.Post(userSimpleUrls.FETCH_USERS_SIMPLE_URL, queryParams);
}
