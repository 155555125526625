import React from 'react'
import { Checkbox } from 'antd';
import { ListGroup } from "react-bootstrap";
export const ColumnsUVE = (props) => {
    return (
        <div className="row mx-0">
            <ListGroup.Item>
                <Checkbox name="nombreFours" checked={props.checkedColumns.nombreFours} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'nombreFours')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="capacite" checked={props.checkedColumns.capacite} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'capacite')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="nombreChaudiere" checked={props.checkedColumns.nombreChaudiere} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'nombreChaudiere')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="capaciteMaxAnu" checked={props.checkedColumns.capaciteMaxAnu} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'capaciteMaxAnu')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="videFour" checked={props.checkedColumns.videFour} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'videFour')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="debitEau" checked={props.checkedColumns.debitEau} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'debitEau')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="installationComplementair" checked={props.checkedColumns.installationComplementair} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'installationComplementair')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="voiTraiFemuee" checked={props.checkedColumns.voiTraiFemuee} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'voiTraiFemuee')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="traitementNOX" checked={props.checkedColumns.traitementNOX} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'traitementNOX')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="miseEnService" checked={props.checkedColumns.miseEnService} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'miseEnService')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="typeFoursChaudiere" checked={props.checkedColumns.typeFoursChaudiere} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'typeFoursChaudiere')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="reseauChaleur" checked={props.checkedColumns.reseauChaleur} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'reseauChaleur')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="typeTerboalternateur" checked={props.checkedColumns.typeTerboalternateur} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'typeTerboalternateur')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="reactif" checked={props.checkedColumns.reactif} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'reactif')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="equipeProcessTF" checked={props.checkedColumns.equipeProcessTF} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'equipeProcessTF')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="typeDechetRecus" checked={props.checkedColumns.typeDechetRecus} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'typeDechetRecus')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="traitementFumee" checked={props.checkedColumns.traitementFumee} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'traitementFumee')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="tonnageReglementaireAp" checked={props.checkedColumns.tonnageReglementaireAp} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'tonnageReglementaireAp')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="constructeurInstallation" checked={props.checkedColumns.constructeurInstallation} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'constructeurInstallation')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="performenceEnergetique" checked={props.checkedColumns.performenceEnergetique} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'performenceEnergetique')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="performenceEnergetiqueDate" checked={props.checkedColumns.performenceEnergetiqueDate} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'performenceEnergetiqueDate')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="performenceEnergetiqueR1" checked={props.checkedColumns.performenceEnergetiqueR1} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'performenceEnergetiqueR1')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="performenceEnergetiqueR1Date" checked={props.checkedColumns.performenceEnergetiqueR1Date} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'performenceEnergetiqueR1Date')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="cycleVapeur" checked={props.checkedColumns.cycleVapeur} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'cycleVapeur')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="terboalternateur" checked={props.checkedColumns.terboalternateur} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'terboalternateur')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="venteProduction" checked={props.checkedColumns.venteProduction} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'venteProduction')}</Checkbox>
            </ListGroup.Item>
        </div>
    )
}