import { adminsSlice } from "./admin.reducer";
import * as adminRequests from "./admin.crud";
const { actions } = adminsSlice;
export const createAdmin = AdminToCreate => dispatch => {
    return adminRequests
        .createAdmin(AdminToCreate)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.adminCreated());
            } else {
                dispatch(actions.catchError({ errors: "erreur inconue" }));
            }
        })
        .catch(({message,errors}) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: "erreur inconue" }));
            }
        });
};
export const updateAdmin = AdminToUpdate => dispatch => {
    return adminRequests
        .updateAdmin(AdminToUpdate)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.adminUpdated());
            } else {
                dispatch(actions.catchError({ errors: "erreur inconue" }));
            }
        })
        .catch(({message,errors}) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: "erreur inconue" }));
            }
        });
};
export const fetchAdmins = QueryParams => dispatch => {
    return adminRequests
        .fetchAdmins(QueryParams)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.adminsFetched({ listAdmins: data }));
            } else {
                dispatch(actions.catchError({ errors: 'Fetch again' }));
            }
        })
        .catch(({}) => {
            dispatch(actions.catchError({ errors: "fetch again" }));
        });
}
export const deleteAdmins = AdminsToDelete => dispatch => {
    return adminRequests
        .deleteAdmins(AdminsToDelete)
        .then(({ok,data,admins}) => {
            if (ok) {
                dispatch(actions.adminsDeleted({ asyncData: data === "async", admins: admins  }));
            } else {
                dispatch(actions.catchError({ errors: "No action" }));
            }
        })
        .catch(({}) => {
            dispatch(actions.catchError({ errors: "fetch again" }));
        });
}
