import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class TypeInstalation extends Component {
    render() {
        return (
            <CommonComponent keyName="typeInstallation" titleSetting={global._trans('settings', 'typeInstall')} typeInstallation={this.props.typeInstallation} />
        )
    }
}
function mapState(state) {
    const { listEnums: { typeInstallation } } = state.enums
    return { typeInstallation }
}
const connectedClass = connect(mapState, null)(TypeInstalation)
export { connectedClass as TypeInstalation }