import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { CreateGestionaire } from './CreateGestionaire'
import { ListGestionaires } from './ListGestionaires'
import { ViewGestionaire } from './ViewGestionaire'
class GestionairePage extends Component {
    render() {
        return (
            <Switch>
                <Route
                    path="/manager/view/:idGestionaire"
                    component={ViewGestionaire}
                />
                <Route
                    path="/manager/new"
                    component={CreateGestionaire}
                />
                <Route
                    path="/manager"
                    component={ListGestionaires}
                />
            </Switch>
        )
    }
}
export { GestionairePage }