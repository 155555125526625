import React from 'react'
import { Checkbox } from 'antd';
import { ListGroup, Button } from "react-bootstrap";
export const Contracts = ({ checkedColumns, handleChange, columns }) => {
    return (
        <div className="row mx-0">
            {!!columns && columns.length > 0 && columns.map((column, index) => {
                return (
                    <>
                        <Button size='sm' key={index} className={`${!!checkedColumns && checkedColumns[column] ? 'bg-btn-checked' : 'bg-btn-Unchecked'} mr-3 pr-5 mb-3`}>
                            <Checkbox name={column} checked={!!checkedColumns && checkedColumns[column]}
                                className='check-partage'
                                onChange={(e) => handleChange(column, e.target.checked)} >{global._trans("shares", column)}</Checkbox>
                        </Button>
                    </>
                )
            }
                // <ListGroup.Item>
                //     <Checkbox checked={!!checkedColumns && checkedColumns[column]} 
                // onChange={(e) => handleChange(column, e.target.checked)} style={{ lineHeight: '32px' }}>{global._trans("shares", column)}</Checkbox>
                // </ListGroup.Item>
            )}
        </div>
    )
}