import React, { Component } from 'react'
import { connect } from "react-redux";
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { fetchContract } from '../../../modules/Contract/contract.actions'
import { BasicInfo, ListClients } from './utils'
import { EntityHistory } from '../Commons/EntityHistory';

class ViewContract extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            contract: {},
            tab: 'basic',
            disabled: true
        }
        props.fetchContract(props.match.params.idContract)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            this.setState({
                loading: false,
                contract: nextProps.contrat ? nextProps.contrat : {}
            })
        }
    }
    getTabBody() {
        switch (this.state.tab) {
            case "basic": return <BasicInfo Contract={this.state.contract} disabled={this.state.disabled} />
            case "communes": return <ListClients clients={this.state.contract.communes} />
            case "history": return <EntityHistory entity={this.state.contract} />
        }
    }
    handleTab(tab) {
        this.setState({
            tab: tab
        })
    }
    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div className="card-header border-0 pt-5 pb-0 px-6">
                    <h3 className="card-title align-items-start flex-column mb-0">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('contracts', 'titleView')} </span>
                    </h3>
                </div>
                <div className="card-body py-4">
                    <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('basic')} className={`nav-link ${this.state.tab === "basic" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'basicInfo')}</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link onClick={() => this.handleTab('communes')} className={`nav-link ${this.state.tab === "communes" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'communes')}</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('history')} className={`nav-link ${this.state.tab === "communes" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'history')}</Link>
                        </li>
                    </ul>
                    <div className="pt-5">{this.getTabBody()}</div>
                    <div className="d-flex justify-content-end">
                        <div className="card-toolbar mx-4">
                            <Link to="/contrat" className="btn btn-secondary" >{global._trans('common', 'goBack')}</Link>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}
function mapState(state) {
    const { contrat } = state.contract
    return { contrat }
}
const connectedClass = connect(mapState, { fetchContract })(ViewContract)
export { connectedClass as ViewContract }