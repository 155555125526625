import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { SageTable } from './SageTable'
import { HeaderTab } from './HeaderTab'
import { fetchEpicsGet, fetchSyndicatsGet, fetchCommunesGet } from "../../../modules/Collectivities/collectivitie.actions";
import { fetchSitesGet } from "../../../modules/Sites/sites.actions";
import { fetchSocietesGet } from "../../../modules/Societes/societe.actions";
import debounce from 'lodash.debounce'

class EntitySelector extends Component {
    entities = {
        'sites': {
            key: 'id_site',
            label: 'Sites',
            fetch: this.props.fetchSitesGet,
            searchField: 'name'
        },
        'epics': {
            key: 'id_epic',
            label: 'EPCIs',
            fetch: this.props.fetchEpicsGet,
            searchField: 'nomEpic'
        },
        'syndicats': {
            key: 'id_syndicat',
            label: 'Syndicats',
            fetch: this.props.fetchSyndicatsGet,
            searchField: 'nomCourt'
        },
        'communes': {
            key: 'id_commune',
            label: 'Communes',
            fetch: this.props.fetchCommunesGet,
            searchField: 'nomCommune'
        },
        'societe_exploitants': {
            key: 'id_societe_exploitant',
            label: 'Societés',
            fetch: this.props.fetchSocietesGet,
            searchField: 'groupe'
        }
    };
    constructor(props) {
        super(props);
        this.state = {
            currentTab: props.defaultCurrent ? props.defaultCurrent : 'sites',
            selectedRow: Object.keys(this.entities).reduce((acc, key, index) => ({ ...acc, [key]: [] }), {}),
            searchValue: Object.keys(this.entities).reduce((acc, key, index) => ({ ...acc, [key]: '' }), {}),
            listsData: Object.keys(this.entities).reduce((acc, key, index) => ({ ...acc, [key]: [] }), {}),
            loading: Object.keys(this.entities).reduce((acc, key, index) => ({ ...acc, [key]: false }), {})
        }
        this.searchBuffer = 0;
        this.columes = {
            'sites':
                [{ title: "ID", dataIndex: "id_site", fixed: 'left', ellipsis: true, },
                { title: "Dénomination légale", dataIndex: "denomination", ellipsis: true, },
                { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
                { title: "Siren", dataIndex: "serin", ellipsis: true, }],

            'epics': [{ title: "ID", dataIndex: "id_epic", fixed: 'left', ellipsis: true, },
            { title: global._trans('clientsEpic', 'epicName'), dataIndex: "nomEpic", ellipsis: true, },
            { title: "Siren", dataIndex: "serin", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, }],

            'syndicats': [{ title: "ID", dataIndex: "id_syndicat", fixed: 'left', ellipsis: true, },
            { title: "Nom Court", dataIndex: "nomCourt", ellipsis: true, },
            { title: "Siren", dataIndex: "serin", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, }],

            'communes': [
                { title: "ID", dataIndex: "id_commune", fixed: 'left', ellipsis: true, },
                { title: "Nom Commune", dataIndex: "nomCommune", ellipsis: true, },
                { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
                { title: "Nombre habitants", dataIndex: "nombreHabitant", ellipsis: true, }],

            'societe_exploitants': [
                { title: "ID", dataIndex: "id_societe_exploitant", fixed: 'left', ellipsis: true, },
                { title: "Groupe", dataIndex: "groupe", fixed: 'left', ellipsis: true, render: (groupe) => Array.isArray(groupe) ? groupe.map((g) => g.value_enum).join(',') : groupe },
                { title: "Dénomination", dataIndex: "denomination", ellipsis: true, },
                { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
                { title: "Nombre salarié", dataIndex: "nombreSalarie", ellipsis: true, }]
        }

        this.search = '';
        this.keysTable = {
            'syndicats': 'id_syndicat',
            'epics': 'id_epic',
            'communes': 'id_commune',
            'societe_exploitants': 'id_societe_exploitant',
            'sites': 'id_site',
        }
        this.typeClient = [
            { typePersonMoral: 'Syndicat', name: 'Nom Court', dataIndex: 'nomCourt' },
            { typePersonMoral: 'Epic', name: 'Nom EPIC', dataIndex: 'nomEpic' },
            { typePersonMoral: 'Commune', name: 'Nom Commune', dataIndex: 'nomCommune' },
            { typePersonMoral: 'Societe', name: 'Groupe', dataIndex: 'groupe' }
        ]
        this.handleSwitchTabs = this.handleSwitchTabs.bind(this)
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.validSelect = this.validSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this);

        this.getNoneBlockedEntities = this.getNoneBlockedEntities.bind(this);

        this.debouncedHandleSearch = debounce(this.handleSearch, 2000);
        this.handleSearchInputChanged = this.handleSearchInputChanged.bind(this);
    }
    getNoneBlockedEntities() {
        return Object.keys(this.entities).filter((entity) => !this.props.blocked.includes(entity)).reduce((acc, entity, index) => ({ ...acc, [entity]: this.entities[entity] }), {});
    }
    componentDidMount() {
        let entities = this.getNoneBlockedEntities();
        for (var entity in entities) {
            entities[entity].fetch({ pageSize: 15 });
        }
    }
    componentWillReceiveProps(nextProps) {

    }
    handleSwitchTabs(keyItem) {
        if (this.props.blocked && this.props.blocked.indexOf(keyItem) !== -1) {
            return
        }
        console.log({ keyItem })
        this.setState({
            currentTab: keyItem
        })
    }
    handleSelectRow(selectedRowKeys) {
        console.log(selectedRowKeys);
        let rowsPrev = this.state.selectedRow
        rowsPrev[this.state.currentTab].rows = selectedRowKeys
        this.setState({
            selectedRow: rowsPrev
        })
    }
    handleSearchInputChanged(value) {
        this.setState((state) => ({
            searchValue: { ...state.searchValue, [this.state.currentTab]: value }
        }))
        this.handleSearch(value)
    }
    async handleSearch(value) {


        this.entities[this.state.currentTab].fetch({
            pageSize: 15,
            denomination: value,
            address: value,
            [this.entities[this.state.currentTab].searchField]: value
        });
    }
    validSelect() {
        let elem = this.props[this.state.currentTab].data.find(obj => obj[this.keysTable[this.state.currentTab]] == this.state.selectedRow[this.state.currentTab].rows[0])
        this.props.validSelection({ elem, type: this.state.currentTab });
    }
    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} aria-labelledby="example-modal-sizes-title-lg">
                <HeaderTab items={Object.keys(this.entities).map((key) => ({ key, label: this.entities[key].label }))} switchItem={(index) => this.handleSwitchTabs(Object.keys(this.entities)[index])} active={Object.keys(this.entities).indexOf(this.state.currentTab)} />
                <div className="px-5 pb-5 pt-2">
                    <Form.Control type="text" value={this.state.searchValue[this.state.currentTab]} name="searchvalue" placeholder="Chercher par nom ou adresse" onChange={(e) => this.handleSearchInputChanged(e.target.value)} />
                    <SageTable
                        columns={this.columes[this.state.currentTab]}
                        data={this.props[this.state.currentTab].data}
                        style={{ height: 550 }}
                        IterableProps={{
                            rowSelection: {
                                selectedRowKeys: this.state.selectedRow[this.state.currentTab].rows,
                                onChange: this.handleSelectRow,
                                type: 'radio'
                            },
                            emptyText: global._trans('common', 'emptyData'),
                            loading: this.state['loading' + this.state.currentTab],
                            rowKey: this.keysTable[this.state.currentTab],
                            pagination: false
                        }}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="primary" onClick={this.validSelect}>{global._trans('common', 'validSelect')}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
function mapState(state) {
    const { listEpics, listCommunes, listSyndicats } = state.collectivitie;
    const { listSites } = state.sites;
    const { listSocietes } = state.societe;
    return { epics: listEpics, communes: listCommunes, syndicats: listSyndicats, societe_exploitants: listSocietes, sites: listSites }
}
const connectedClass = connect(mapState, { fetchEpicsGet, fetchSyndicatsGet, fetchCommunesGet, fetchSocietesGet, fetchSitesGet })(EntitySelector)
export { connectedClass as EntitySelector }