import React from "react";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { UserProfileDropdown } from '../extras/dropdowns/UserProfileDropdown';

export function Topbar() {
  return (
    <div className="topbar">
      <UserNotificationsDropdown />
      <UserProfileDropdown />
    </div>
  );
}
