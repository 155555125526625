import React, { Component } from 'react';
import { Form, Col } from "react-bootstrap";
import { Select, DatePicker } from 'antd';
class DataTechISDND extends Component {
    render() {
        const { stateTech } = this.props
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Capacité nominale</Form.Label>
                        <Form.Control type="number" placeholder="Capacité nominale" value={stateTech.capaciteNominale} name="capaciteNominale" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Capacité restante</Form.Label>
                        <Form.Control type="number" placeholder="Capacité restante" value={stateTech.capaciteRestante} name="capaciteRestante" disabled={true} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Capacité réglementaire</Form.Label>
                        <Form.Control type="number" placeholder="Capacité réglementaire" value={stateTech.capaciteReglementaire} name="capaciteReglementaire" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Projet d'extension ?</Form.Label>
                        <Select
                            value={stateTech.projetExtension}
                            className="inputSizeSelect"
                            style={{ width: '100%' }}
                            placeholder="Projet d'extension ?"
                            disabled={true}
                        >
                            <Select.Option value={true}>Oui</Select.Option>
                            <Select.Option value={false}>Non</Select.Option>
                        </Select>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Date d'extension</Form.Label>
                        <Form.Control type="text" placeholder="Date d'extension" value={stateTech.dateExtension} name="dateExtension" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Date d'ouverture</Form.Label>
                        <Form.Control type="text" placeholder="Date d'ouverture" value={stateTech.dateOuverture} name="dateOuverture" disabled={true} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Date de fermeture</Form.Label>
                        <Form.Control type="text" placeholder="Date de fermeture" value={stateTech.dateFermeture} name="dateFermeture" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Date de fermeture prévisionnelle</Form.Label>
                        <Form.Control type="text" placeholder="Date de fermeture prévisionnelle" value={stateTech.dateFermeturePrev} name="dateFermeturePrev" disabled={true} />
                    </Form.Group>
                </Form.Row>
            </>
        )
    }
}
export { DataTechISDND }