import React, { Component } from 'react';
import { Modal } from 'antd'
import { sessionsUserPrem, deleteSessionUser } from '../../../../../../modules/User/user.actions'
import { SageTable, InlineBottunSvg } from '../../../../Commons'
import { CreateUpdateUserSimple } from '../../Component'
class UsersAccorder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            deleting: false,
            users: null,
            userSimple: false,
            userInQue: null
        }
        this.fetchData = this.fetchData.bind(this)
        this.asyncUsers = this.asyncUsers.bind(this)
        this.handleDeleteUser = this.handleDeleteUser.bind(this)
        this.handleModelUserSimple = this.handleModelUserSimple.bind(this)
        this.fetchData()
    }
    handleDeleteUser(idUser, indexData) {
        Modal.confirm({
            title: global._trans('users', 'deleteUserSimple'),
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('users', 'bodyDeleteSimple'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    deleting: true
                })
                deleteSessionUser({ session: idUser })
                    .then(({ ok, data }) => {
                        if (ok && data) {
                            let prevData = this.state.users
                            prevData.splice(indexData, 1)
                            this.setState({
                                deleting: false,
                                users: prevData
                            })
                        } else {
                            this.setState({
                                deleting: false
                            })
                        }
                    })
                    .catch(({ message, errors }) => {
                        this.setState({
                            deleting: false
                        })
                    })
            }
        });
    }
    fetchData() {
        sessionsUserPrem(this.props.idUser)
            .then(({ ok, data }) => {
                if (ok && data) {
                    this.setState({
                        loading: false,
                        users: data
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false
                })
            })
    }
    asyncUsers() {
        this.setState({
            userSimple: false,
            userInQue: null,
            loading: true
        })
        this.fetchData()
    }
    handleEditUser(indexData) {
        this.setState({
            userSimple: true,
            userInQue: this.state.users[indexData]
        })
    }
    handleModelUserSimple() {
        let prevState = this.state.userSimple
        this.setState({
            userSimple: !prevState,
            userInQue: null
        })
    }
    render() {
        return (
            <>
                <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">{global._trans("users", "usersCreated")}</h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">{global._trans("users", "bodySessions")}</span>
                    </div>
                </div>
                <div className="form">
                    <div className="card-body">
                        <SageTable
                            columns={[
                                { title: global._trans('common', 'firstName'), dataIndex: "nom" },
                                { title: global._trans('common', 'lastName'), dataIndex: "prenom" },
                                { title: global._trans('common', 'userName'), dataIndex: "username" },
                                { title: global._trans('common', 'initPassword'), dataIndex: "init_password", render: init_password => init_password ? init_password : <strong>MdP modifié</strong> },
                                { title: global._trans('common', 'email'), dataIndex: "email" },
                                { title: global._trans('common', 'phone'), dataIndex: "phone", sorter: true },
                                {
                                    title: global._trans('common', 'action'), dataIndex: "id_user", fixed: 'right', render: (id_user, key) => <InlineBottunSvg
                                        bottuns={[
                                            { iconPath: "/media/svg/icons/Costum/Edit.svg", type: "primary", action: () => this.handleEditUser(key) },
                                            { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.handleDeleteUser(id_user, key) }
                                        ]}
                                    />
                                }
                            ]}
                            style={{ height: 580 }}
                            data={this.state.users}
                            IterableProps={{
                                emptyText: global._trans("users", "emptyUserSession"),
                                loading: this.state.loading || this.state.deleting,
                                rowKey: "id_site",
                            }}
                        />
                    </div>
                </div>
                {this.state.userSimple && <CreateUpdateUserSimple asyncUsers={this.asyncUsers} initUser={this.state.userInQue} handleModel={this.handleModelUserSimple} />}
            </>
        )
    }
}
export { UsersAccorder }