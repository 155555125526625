import { commonSlice } from "./common.reducer";
import * as commonRequests from "./common.crud";
const { actions } = commonSlice;
export const uploadImage = FormData => {
    return commonRequests.uploadImage(FormData)     
};
export const fetchDepartements = (value,query) => {
    return commonRequests.fetchDepartements(value,query)     
};
export const fetchRegions = (value,query) => {
    return commonRequests.fetchRegions(value,query)     
};
export const fetchHistory = (query) => {
    return commonRequests.fetchHistory(query)     
};
export const importData = dataImport => {
    return commonRequests.importData(dataImport)
}
export const exportData = dataImport => {
    return commonRequests.exportData(dataImport)
}
export const getTableViews = query => {
    return commonRequests.getTableViews(query)
}
export const createTableView = data => {
    return commonRequests.createTableView(data)
}
export const updateTableView = data => {
    return commonRequests.updateTableView(data)
}
export const deleteTableView = data => {
    return commonRequests.deleteTableView(data)
}
export const selectTableView = data => {
    return commonRequests.selectTableView(data)
}