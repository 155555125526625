import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import useSound from 'use-sound';
import { useHistory } from "react-router-dom";
import { Empty } from 'antd';
import { fetchNotifications, readNotification, resetNotification } from '../../../../../app/modules/Notifications/notifications.actions'
var socketConnected = false
export function UserNotificationsDropdown() {
  const [playNotif] = useSound('/notification_sound.mp3',
    { volume: 1 }
  );
  const { user, authToken } = useSelector((state) => state.auth, shallowEqual);
  const { notifications, count } = useSelector((state) => state.notifications, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchNotifications)
  }, []);
  if (!socketConnected) {
    socketConnected = true
    window.Pusher = require('pusher-js');
    var pusher = new window.Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: 'mt1'
    });
    var channel = pusher.subscribe(user.user_channel);
    channel.bind('notification', function(data) {
        // console.log(JSON.stringify(data));
        dispatch(fetchNotifications)
        document.getElementById("notif_sound_effect").click()
    });
    // window.Echo.channel(user.user_channel)
    // window.Echo.channel("test")
    //   // .listen('UserNotification', (e) => {
    //   .listen('test', (e) => {
    //     if (e && e.notification && e.notification.async) {
    //       document.getElementById("notif_sound_effect").click()
    //       dispatch(fetchNotifications)
    //     }
    //   })
  }
  const playNotifFun = () => {
    playNotif();
  }
  const handleSeeNotif = (key) => {
    let notifSelect = notifications[key]
    if (notifSelect.data?.action) {
      dispatch(readNotification(notifSelect.id))
      window.open(notifSelect.data?.action)
    }
  }
  return (
    <>
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="kt_quick_notifications_toggle"
        >
          <div
            className="btn btn-icon btn-clean position-relative btn-lg mr-1"
            id="kt_quick_notifications_toggle"
          >
            <span className="">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Notification.svg")} /> */}
              <img src="/media/notification.png" alt="notification logo" width='45' style={{marginRight: '10px'}} />
            </span>
            {count !== 0 ?
              <div className="label label-danger position-absolute text-white" style={{ fontWeight: 'bold', top: 0, right: 0 }}>
                <span style={{ marginTop: 1 }}>{count}</span>
              </div>
              : null}
            <button onClick={() => {
              playNotifFun()
            }} id="notif_sound_effect" className="d-none">sound</button>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu p-0 set-t-10 dropdown-menu-right dropdown-menu-anim-up border" style={{ width: 450 }}>
          <div className="d-flex flex-column rounded-top">
            <div className="d-flex justify-content-between align-items-center px-8 py-2 border-bottom bg-dark rounded-top">
              <h4 className="d-flex rounded-top m-0 py-5 text-white">
                <span>Mes notifications</span>
              </h4>
              {count !== 0 ?
                <button className="btn btn-danger font-weight-bolder font-size-sm px-5" onClick={() => {
                  dispatch(resetNotification());
                }}>
                  OK
                </button>
                : null}
            </div>
            <div style={{ maxHeight: 380, overflowY: 'auto' }}>
              {notifications.map((notif, key) => {
                return <div key={key} onClick={() => handleSeeNotif(key)} className="py-5 px-6 bg-hover-light border-bottom d-flex align-items-center">
                  <span className="">
                    <SVG src={toAbsoluteUrl(notif.data?.logo)} />
                  </span>
                  <div className="ml-3" style={{ width: "90%" }}>
                    <h6 className="m-0 text-truncate">{global._trans("notification", notif.data?.title)}</h6>
                    <span className="text-muted pt-1 text-truncate d-block">{/*global._trans("notification", notif.data?.description)*/global._toFormatDate(notif.created_at)}</span>
                  </div>
                </div>
              })}
              {!count && <div className="py-15">
                <Empty description={global._trans("notification", "emptyState")} />
              </div>}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
