
import React from "react";
import ReactDOM from "react-dom";
import { persistor, store } from "./config";
import App from "./app/app";
import "./index.scss";
import "./App.css";
import "./_sage/_assets/plugins/keenthemes-icons/font/ki.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./_sage/layout";
import { ConfigProvider } from 'antd';
import { CrudService } from './services'
import frFR from 'antd/lib/locale/fr_FR';
import { Translate,toDateFormat,toStringSpace } from './services'
require('dotenv').config()
const { PUBLIC_URL } = process.env;
global._trans = Translate;
global._toFormatDate=toDateFormat
global._toStringSpace=toStringSpace
CrudService.setAuthHeader(store)
ReactDOM.render(
  <MetronicLayoutProvider>
    <MetronicSubheaderProvider>
      <MetronicSplashScreenProvider>
        <ConfigProvider locale={frFR}>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </ConfigProvider>
      </MetronicSplashScreenProvider>
    </MetronicSubheaderProvider>
  </MetronicLayoutProvider>
  ,
  document.getElementById("root")
);

