import React, { Component } from 'react';
import { Form, Col } from "react-bootstrap";
import { Select, DatePicker } from 'antd';
import moment from 'moment';
class DataTechISDND extends Component {
    render() {
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'capaciteNominale')}</Form.Label>
                        <Form.Control type="number" placeholder={global._trans('sites', 'capaciteNominale')} value={this.props.stateTech.capaciteNominale} name="capaciteNominale" onChange={this.props.handleEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'capaciteRestante')}</Form.Label>
                        <Form.Control type="number" placeholder={global._trans('sites', 'capaciteRestante')} value={this.props.stateTech.capaciteRestante} name="capaciteRestante" onChange={this.props.handleEvent} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'capaciteReglementaire')}</Form.Label>
                        <Form.Control type="number" placeholder={global._trans('sites', 'capaciteReglementaire')} value={this.props.stateTech.capaciteReglementaire} name="capaciteReglementaire" onChange={this.props.handleEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'projetExtension')}</Form.Label>
                        <Select
                            defaultValue={this.props.stateTech.projetExtension}
                            className="inputSizeSelect"
                            style={{ width: '100%' }}
                            placeholder={global._trans('sites', 'projetExtension')}
                            onChange={(selectedValue) => this.props.handleName("projetExtension", selectedValue)}
                        >
                            <Select.Option value={true}>Oui</Select.Option>
                            <Select.Option value={false}>Non</Select.Option>
                        </Select>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'dateExtension')}</Form.Label>
                        <DatePicker format="DD/MM/YYYY" value={moment(this.props.stateTech.dateExtension, "DD/MM/YYYY")} onChange={(date, dateString) => this.props.handleName("dateExtension", dateString)} className="form-control" placeholder={global._trans('sites', 'dateExtension')} style={{ width: '100%' }} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'dateOuverture')}</Form.Label>
                        <DatePicker format="DD/MM/YYYY" value={moment(this.props.stateTech.dateOuverture, "DD/MM/YYYY")} onChange={(date, dateString) => this.props.handleName("dateOuverture", dateString)} className="form-control" placeholder={global._trans('sites', 'dateOuverture')} style={{ width: '100%' }} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'dateFermeture')}</Form.Label>
                        <DatePicker format="DD/MM/YYYY" value={moment(this.props.stateTech.dateFermeture, "DD/MM/YYYY")} onChange={(date, dateString) => this.props.handleName("dateFermeture", dateString)} className="form-control" placeholder={global._trans('sites', 'dateFermeture')} style={{ width: '100%' }} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'dateFermeturePrev')}</Form.Label>
                        <DatePicker format="DD/MM/YYYY" value={moment(this.props.stateTech.dateFermeturePrev, "DD/MM/YYYY")} onChange={(date, dateString) => this.props.handleName("dateFermeturePrev", dateString)} className="form-control" placeholder={global._trans('sites', 'dateFermeturePrev')} style={{ width: '100%' }} />
                    </Form.Group>
                </Form.Row>
            </>
        )
    }
}
export { DataTechISDND }