import { Spin, Select } from 'antd';
import { Form, Col, Card, ListGroup, Button } from "react-bootstrap";

import React, { Component } from 'react'
import { connect } from "react-redux";
import { fetchGEDFile } from '../../../modules/GED/contract.actions';
import { SageTable } from '../Commons';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
import SVG from "react-inlinesvg";
// import { fetchContract } from '../../../modules/Contract/contract.actions'
// import { BasicInfo, ListClients } from './utils'
// import { EntityHistory } from '../Commons/EntityHistory';

class ViewGEDFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            contract: {},
            tab: 'basic',
            disabled: true
        }
    }

    componentDidMount() {
        return this.props.fetchGEDFile(this.props.match.params.idGEDFile);
    }

    render() {
        let { file, loading } = this.props;
        return (
            <Spin spinning={loading}>
                <div className="card-header border-0 pt-5 pb-0 px-6">
                    <h3 className="card-title align-items-start flex-column mb-0">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('ged', 'titleView')} </span>
                    </h3>
                </div>
                <div className="card-body py-4">
                    <div className="pt-5">
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('ged', 'name')}</Form.Label>
                                    <Form.Control type="text" name="date" value={!!file ? file.name : ''} placeholder={global._trans('clientsSyndicat', 'Date')} disabled={true} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans("ged", "fileCategory")}*</Form.Label>
                                    <Select
                                        disabled={true}
                                        className="inputSizeSelect"
                                        value={!!file ? file.category.id_enemuration : ''}
                                        placeholder={global._trans("ged", "fileCategory")}
                                        style={{ width: '100%' }}
                                        options={this.props.enums.fileCategory}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('ged', 'Date')}*</Form.Label>
                                    <Form.Control type="text" name="date" value={!!file ? file.date : ''} disabled={true} placeholder={global._trans('clientsSyndicat', 'Date')} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans("ged", "shareable")}*</Form.Label>
                                    <Select
                                        showSearch
                                        showArrow
                                        className="inputSizeSelect"
                                        value={!!file ? file.shareable : false}
                                        disabled={true}
                                        placeholder={global._trans("ged", "shareable")}
                                        style={{ width: '100%' }}
                                        options={[{ value: false, label: global._trans("ged", "non-shareable") }, { value: true, label: global._trans("ged", "shareable") }]}
                                    // onSelect={(SelectedValue) => this.handleName("shareable", SelectedValue)}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <SageTable
                                columns={[
                                    { title: global._trans('ged', 'type'), dataIndex: "type" },
                                    // { title: "Ville", dataIndex: "city" },
                                    {
                                        title: global._trans('ged', 'entity'), fixed: 'left', render: (data, key, entity) => {
                                            if (!entity) return;
                                            switch (entity.type) {
                                                case 'sites':
                                                    return entity.denomination;
                                                case 'epics':
                                                    return entity.nomEpic;
                                                case 'syndicats':
                                                    return entity.nomCourt;
                                                case 'communes':
                                                    return entity.nomCommune;
                                                case 'societe_exploitants':
                                                    return entity.denomination;
                                                default:
                                                    return null;
                                            }
                                        }
                                    },
                                    {
                                        title: global._trans('ged', 'shareable'), render: (data, key, file) => {
                                            return !!file && file.shareable ? global._trans('ged', 'shareable') : global._trans('ged', 'non-shareable');
                                        }
                                    },
                                ]}
                                data={!!file ? [{ ...file.entity, type: file.type, shareable: file.shareable }] : []}
                                style={{ height: 100 }}
                                IterableProps={{
                                    emptyText: global._trans('admins', 'No attached entities'),
                                    loading: loading,
                                }}
                            />
                        </Form>
                        <div className="card-toolbar">

                        </div>

                        {!!file && <a className="btn btn-primary font-weight-bolder font-size-sm" href={file.path} target='_blank'>
                            {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/View.svg")} className="h-50 align-self-center"></SVG> */}
                            {global._trans('ged', 'previewFile')}
                        </a>}
                    </div>
                    <div className="d-flex justify-content-end">
                        <div className="card-toolbar mx-4">
                            <Link to={`/ged`} className="btn btn-secondary" >{global._trans('common', 'retoure')}</Link>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}
function mapState(state) {
    const { file, loading } = state.ged;
    const { listEnums } = state.enums;
    return { file, loading, enums: listEnums };
}
const connectedClass = connect(mapState, { fetchGEDFile })(ViewGEDFile)
export { connectedClass as ViewGEDFile }