import React, { Component } from 'react'
import { connect } from "react-redux";
import { Select, DatePicker } from 'antd';
import { Form, Col, Card, ListGroup, Button } from "react-bootstrap";
import { Link } from 'react-router-dom'
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import locale from 'antd/es/date-picker/locale/fr_FR';
class BasicInfo extends Component {
    render() {
        let contract = this.props.Contract;
        console.log({ contract });
        return (
            <Form>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('contracts', 'startDate')}*</Form.Label>
                        <Form.Control type="text" name="dateDebut" value={this.props.Contract.dateDebut} disabled={this.props.disabled} placeholder={global._trans('contracts', 'startDate')} onChange={this.handleChangeEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('contracts', 'endDate')}*</Form.Label>
                        <Form.Control type="text" name="dateFin" value={this.props.Contract.dateFin} disabled={this.props.disabled} placeholder={global._trans('contracts', 'endDate')} onChange={this.handleChangeEvent} />
                    </Form.Group>
                </Form.Row>
                {/*<Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('contracts', 'autherActivContract')}</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('contracts', 'selectActiviteContract')}
                            style={{ width: '100%' }}
                            optionFilterProp="label"
                            disabled={this.props.disabled}
                            value={parseInt(this.props.Contract.autreActivite)}
                            loading={!this.props.contract}
                            options={this.props.contract}
                            onChange={(selectedRow) => this.handleName('autreActivite', selectedRow)}
                        />
                    </Form.Group>
                </Form.Row>*/}
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans('contracts', 'siteContract')}</Form.Label>
                        {this.props.Contract.site && <Card>
                            <ListGroup variant="flush">

                                <ListGroup.Item><strong>{global._trans('sites', 'denomenation')} : </strong>{'  ' + this.props.Contract.site.denomination}</ListGroup.Item>
                                <ListGroup.Item><strong>{global._trans('sites', 'categorieSite')} : </strong>{'  ' + this.props.Contract.site.categorieSite}</ListGroup.Item>
                                <ListGroup.Item><strong>{global._trans('sites', 'modeGestion')}: </strong>{'  ' + this.props.Contract.modeGestion}</ListGroup.Item>

                                <ListGroup.Item><strong>{global._trans('common', 'city')}: </strong>{'  ' + this.props.Contract.site.city}</ListGroup.Item>
                                <ListGroup.Item>
                                    <Link to={"/sites/view/" + this.props.Contract.site.id_site}><strong>{global._trans('contracts', 'goSite')}</strong></Link>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    {this.props.Contract.client && <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans('client', 'maitre')}</Form.Label>
                        <Card>
                            <ListGroup variant="flush">
                                <ListGroup.Item><strong>{this.props.Contract.client.name} ({global._trans('client', this.props.Contract.client.typePersonMoral.toLowerCase())}) : </strong>{'  ' + this.props.Contract.client[this.props.Contract.client.dataIndex]}</ListGroup.Item>
                                <ListGroup.Item><strong>{global._trans('common', 'city')}: </strong>{'  ' + this.props.Contract.client.city}</ListGroup.Item>
                                <ListGroup.Item>
                                    <Link to={{ 'Syndicat': `/client/communities/syndicat/view/${this.props.Contract.client.id_person}`, 'Epic': `/client/communities/epic/view/${this.props.Contract.client.id_person}`, 'Commune': `/client/communities/communes/view/${this.props.Contract.client.id_person}`, 'Societe': `/client/companies/view/${this.props.Contract.client.id_person}` }[this.props.Contract.client.typePersonMoral]}><strong>{global._trans('common', 'viewClient')}</strong></Link>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Form.Group>}
                    {this.props.Contract.contractant && <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans('contracts', 'contractant')}</Form.Label>
                        <Card>
                            <ListGroup variant="flush">
                                <ListGroup.Item><strong>{this.props.Contract.contractant.name} ({global._trans('client', this.props.Contract.contractant.typePersonMoral.toLowerCase())}) : </strong>{'  ' + this.props.Contract.contractant[this.props.Contract.contractant.dataIndex]}</ListGroup.Item>
                                <ListGroup.Item><strong>{global._trans('common', 'city')}: </strong>{'  ' + this.props.Contract.contractant.city}</ListGroup.Item>
                                <ListGroup.Item>
                                    <Link to={{ 'Syndicat': `/client/communities/syndicat/view/${this.props.Contract.contractant.id_person}`, 'Epic': `/client/communities/epic/view/${this.props.Contract.contractant.id_person}`, 'Commune': `/client/communities/communes/view/${this.props.Contract.contractant.id_person}`, 'Societe': `/client/companies/view/${this.props.Contract.contractant.id_person}` }[this.props.Contract.contractant.typePersonMoral]}><strong>{global._trans('contracts', 'goContract')}</strong></Link>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Form.Group>}
                </Form.Row>
                {/* <Form.Row>
                    {this.props.Contract.communes && Array.isArray(this.props.Contract.communes) && this.props.Contract.communes.length > 0 && this.props.Contract.communes.map((commune) => (
                        <Form.Group as={Col} className='col-md-6'>
                            <Form.Label className="d-block">{`${global._trans('client', 'maitre')}`}</Form.Label>
                            <Card>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><strong>{global._trans('sites', 'client')} : </strong>{'  ' + commune.typePersonMoral}</ListGroup.Item>
                                    <ListGroup.Item><strong>{global._trans('sites', 'denomenation')}: </strong>{'  ' + commune[commune.dataIndex]}</ListGroup.Item>
                                    <ListGroup.Item><strong>{global._trans('common', 'city')}: </strong>{'  ' + commune.city}</ListGroup.Item>
                                    <ListGroup.Item><Link to={{ 'Syndicat': `/client/communities/syndicat/view/${commune.id_person}`, 'Epic': `/client/communities/epic/view/${commune.id_person}`, 'Commune': `/client/communities/communes/view/${commune.id_person}`, 'Societe': `/client/companies/view/${commune.id_person}` }[commune.typePersonMoral]}><strong>{global._trans('common', 'viewClient')}</strong></Link></ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Form.Group>
                    ))}
                </Form.Row> */}
            </Form>
        )
    }
}
function mapState(state) {
    const { listEnums: { contract } } = state.enums
    return { contract }
}
const connectedClass = connect(mapState, null)(BasicInfo)
export { connectedClass as BasicInfo }
