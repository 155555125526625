import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mapErrors: null,
    listSites: {}
};

export const MapSlice = createSlice({
    name: "mapsites",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.mapErrors = action.payload.errors;
        },
    }
});