import { createSlice } from "@reduxjs/toolkit";

const initialEnumsState = {
    listEnums: {
        nature_juridique: [],
        mode_gestion: [],
        competence_dechet: [],
        amobe: [],
        codeape: [],
        function_person: [],
        contract: [],
        typeDechetRecus: [],
        traitementFumee: [],
        installationComplementair: [],
        voiTraiFemuee: [],
        traitementNOX: [],
        equipeProcessTF: [],
        reactif: [],
        typeTerboalternateur: [],
        constructeurInstallation: [],
        extension: [],
        typeInstallation: [],
        typeDechetAccepter: [],
        typeFours: [],
        typeFoursChaudiere: [],
        typeChaudiere: [],
        technologie: [],
        valorisationEnergitique: [],
        autreActivite: [],
        groupeList:[],
        fileCategory:[],
        // new enums created for lines and valorisations
        reactifNOX: [],
        constructeurChaudiere: [],
        typeEquipement: [],
        marqueEquipement: [],
        RCUIndustirel: [],
        clients: [],
        agregateurElectrique: [],
    },
    departements: [],
    regions: [],
    errors: null,
    requestFailed: null,
    enumsLoaded: false
};

export const enumSlice = createSlice({
    name: "enums",
    initialState: initialEnumsState,
    reducers: {
        catchError: (state, action) => {
            state.errors = action.payload.errors;
        },
        enumCreated: (state, action) => {
            const { newEnum, typeEnum, enemuration } = action.payload
            let prevEnums = state.listEnums
            if (Array.isArray(prevEnums[typeEnum])) {
                if (enemuration) {
                    prevEnums[typeEnum] = prevEnums[typeEnum].map(enm => {
                        if (enm.value !== enemuration) {
                            return enm
                        } else {
                            return newEnum
                        }
                    })
                } else {
                    prevEnums[typeEnum].unshift(newEnum)
                }

            }
            state.errors = null
            state.listEnums = prevEnums
        },
        enumsFetched: (state, action) => {
            const { enumsList } = action.payload
            state.errors = null
            state.listEnums = { ...state.listEnums, ...enumsList }
            state.enumsLoaded = true
        },
        departmentsFetched: (state, action) => {
            const { listDeps } = action.payload
            state.errors = null
            state.departements = listDeps
        },
        regionsFetched: (state, action) => {
            const { listRegs } = action.payload
            state.errors = null
            state.regions = listRegs
        },
        enumDeleted: (state, action) => {
            const { enumuration, typeEnum } = action.payload
            if (enumuration) {
                let prevEnums = state.listEnums
                if (Array.isArray(prevEnums[typeEnum])) {
                    prevEnums[typeEnum] = prevEnums[typeEnum].filter(enm => enm.value !== enumuration)
                }
                state.listEnums = prevEnums
            }
            state.errors = null
        },
    }
});