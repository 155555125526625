import React, { Component } from 'react';
import { sitesGestionaire, deleteSitesGestionaire } from '../../../../modules/Gestionaires/gestionaire.actions'
import { InlineBottunSvg, SageTable } from '../../Commons'
import { Modal } from 'antd'
class SitesRatached extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            sites: null,
            page: 1,
            total: 0
        }
        this.handleTableChange = this.handleTableChange.bind(this)
    }
    componentDidMount(){
        this.handleFetch();
    }
    handleFetch(query) {
        sitesGestionaire(this.props.idGestionaire, query)
            .then(res => {
                if (!!res && !!res.data && res.ok) {
                    this.setState({
                        loading: false,
                        sites: res.data.data,
                        total: res.data.total,
                        page: res.data.current_page
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false
                })
            })
    }
    removeItemSite(idSite) {
        Modal.confirm({
            width: 500,
            title: global._trans('managers', 'detachSite') + " #" + idSite,
            okButtonProps: { type: "primary", primary: true },
            content: global._trans('managers', 'bodyDetach'),
            okText: global._trans('common', 'confirm'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loading: true
                })
                deleteSitesGestionaire({ gestionnaire: this.props.idGestionaire, sites: [idSite] })
                    .then(res => {
                        if (res && res.data && res.data.ok && res.data.data === "async") {
                            this.handleFetch({ page: this.state.page })
                        } else {
                            this.setState({
                                loading: false
                            })
                        }
                    })
                    .catch(err => {
                        this.setState({
                            loading: false
                        })
                    })
            }
        });
    }
    handleTableChange = (pagination, filters) => {
        if (pagination.current !== this.state.page) {
            this.setState({
                loading: true
            })
            this.handleFetch({ page: pagination.current })
        }
    }
    render() {
        return (
            <div className="flex-row-fluid ml-lg-8">
                <div className="card card-custom card-stretch">
                    <div className="card-header py-3">
                        <div className="card-title align-items-start flex-column">
                            <h3 className="card-label font-weight-bolder text-dark">{global._trans("managers", "ratachedSite")}</h3>
                            <span className="text-muted font-weight-bold font-size-sm mt-1">{global._trans("managers", "bodyRattach")}</span>
                        </div>
                    </div>
                    <div className="form">
                        <div className="card-body">
                            <SageTable
                                columns={[
                                    { title: "Dénomination", dataIndex: "denomination", fixed: 'left', ellipsis: true, },
                                    { title: "Catégorie site", dataIndex: "categorieSite", ellipsis: true, },
                                    { title: "Mode de gestion", dataIndex: "modeGestion", ellipsis: true, },
                                    { title: "Périodicité de relance", dataIndex: "perdiocitRelance", ellipsis: true, },
                                    { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
                                    { title: "Telephone Standrad", dataIndex: "telephoneStandrad", ellipsis: true, },
                                    { title: "Annee création", dataIndex: "anneeCreation", ellipsis: true, },
                                    {
                                        title: "Actions", dataIndex: "id_site", fixed: 'right', render: id_site => <InlineBottunSvg
                                            bottuns={[
                                                { iconPath: "/media/svg/icons/Costum/RemoveItem.svg", type: "default", action: () => this.removeItemSite(id_site) },
                                                { iconPath: "/media/svg/icons/Costum/View.svg", type: "primary", action: "/sites/view/" + id_site }
                                            ]}
                                        />
                                    }
                                ]}
                                style={{ height: 610 }}
                                data={this.state.sites}
                                IterableProps={{
                                    emptyText: global._trans("managers", "emptyTableAttach"),
                                    loading: this.state.loading,
                                    rowKey: "id_site",
                                    pagination: { total: this.state.total, pageSize: 20, current: this.state.page, showSizeChanger: false },
                                    onChange: this.handleTableChange
                                }}

                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export { SitesRatached }