import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    contractErrors: null,
    listContracts: {},
    contrat: null
};

export const contractSlice = createSlice({
    name: "contract",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { errors } = action.payload
            state.contractErrors = errors;
        },
        contractCreated: (state) => {
            state.contractErrors = state.contractErrors === false ? null : false
        },
        contractsFetched: (state, action) => {
            const { listContracts } = action.payload
            state.contactErrors = null
            state.listContracts = listContracts
        },
        contractFetched: (state, action) => {
            const { Contract } = action.payload
            state.contactErrors = null
            state.contrat = Contract
        },
        contractsDeleted: (state, action) => {
            const { asyncData, contracts } = action.payload
            if (asyncData) {
                let prevList = state.listContracts
                prevList.data = prevList.data.filter(contract => contracts.indexOf(contract.id_contrat) === -1)
                state.listContracts = prevList
            }
            state.contractErrors = state.contractErrors === false ? null : false
        }
    }
});