export * from './NotFound'
export * from './PrivatePages/Sites'
export * from './PrivatePages/MapSites'
export * from './PrivatePages/Settings'
export * from './PrivatePages/Collectivities'
export * from './PrivatePages/Societies'
export * from './PrivatePages/Contacts'
export * from './PrivatePages/Gestionaires'
export * from './PrivatePages/Admins'
export * from './PrivatePages/Contracts'
export * from './PrivatePages/Users'
export * from './PrivatePages/Share'
export * from './PrivatePages/GED'

export * from './AuthPages/Login'
export * from './AuthPages/OTP'
export * from './LoadingApp'
export * from './AuthPages/Logout'
export * from './AuthPages/ForgetPassword'
export * from './PrivatePages/Dashboard'