import React, { Component } from 'react'
import { DataTechUVE } from './DataTechUVE'
import { DataTechTRI } from './DataTechTRI'
import { DataTechTMB } from './DataTechTMB'
import { DataTechISDND } from './DataTechISDND'
class InfoTechnique extends Component {
    getBody() {
        if (this.props.tech && this.props.tech.data_tech) {
            switch (this.props.tech.typesite) {
                case "TRI": return <DataTechTRI stateTech={this.props.tech.data_tech} />
                case "TMB": return <DataTechTMB stateTech={this.props.tech.data_tech} />
                case "UVE": return <DataTechUVE stateTech={this.props.tech.data_tech} />
                case "ISDND": return <DataTechISDND stateTech={this.props.tech.data_tech} />
            }
        }
    }
    render() {
        return (
            <div>{this.getBody()}</div>
        )
    }
}
export { InfoTechnique }