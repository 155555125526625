import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form } from "react-bootstrap";
import { Steps, Spin } from 'antd';
import { GeneralInfoSite, DataTechISDND, DataTechTMB, DataTechTRI, DataTechUVE, SucessScreen } from './CreateSitePages'
import { createSite, editSite, updateSite } from '../../../modules/Sites/sites.actions'
import { uploadImage } from '../../../modules/Common/common.actions'
import { CostumAlert } from '../Commons'
import { Link } from 'react-router-dom'
import moment from 'moment';
import axios from 'axios';

let line = () => ({
    capacite: '',
    pci: '',
    debitVapeur: '',
    cycleVapeurPression: '',
    cycleVapeurTemp: '',
    voiTraiFemuee: '',
    typeFours: [],
    reactifDENOX: [],
    typeChaudiere: [],
    cycleVapeur: '',
    traitementFumee: [],
    installationComplementair: [],
    traitementNOX: [],
    equipeProcessTF: [],
    reactif: [],
    typeTerboalternateur: '',
    constructeurInstallation: '',
    constructeurChaudiere: '',
    commentTraitementFumee: '',
    miseEnService: '',
    revampingDate: '',
    arretDate: '',
    line_in_use: 'oui'
});
let valorisation = () => ({
    type: '',
    name: '',
    miseEnService: '',
    typeEquipement: [],
    marqueEquipement: [],
    puissanceInstallee: '',
    electriciteVendue: '',
    RCUIndustirel: [],
    client: [],
    chaleurVendue: '',
    puissanceElectrolyseur: '',
    H2Vendue: '',
});
class CreateSite extends Component {
    constructor(props) {
        super(props)
        let action = props.match.params.idSite === "new"
        let datetime = moment().format("DD/MM/YYYY")
        this.state = {
            siteInfo: {
                denomination: '',
                categorieSite: 'UVE',
                modeGestion: null,
                perdiocitRelance: moment().format("MM/YYYY"),
                anneeCreation: moment().format("YYYY"),
                siteIntrnet: '',
                telephoneStandrad: '',
                region_siege: null,
                departement_siege: null,
                adresse: '',
                latitude: '',
                langititude: '',
                sinoe: '',
                // client: null,
                // societe: null,
                gestionaire: null,
                photos: [],
                default_image_id: null,
                status: 'NOT_VALIDATED',
                city: '',
                country: 'France',
                postcode: '',
                comments: '',
                amobe: null,
                amobes: [],
            },
            UVE: {
                infos: {
                    typeDechetRecus: [],
                    tonnageReglementaireAp: '',
                    capacite: '',
                    videFour: '',

                    // traitements: {},
                    // nombreFours: '',
                    // nombreChaudiere: '',
                    // debitEau: '',
                    // miseEnService: datetime,
                    // typeFoursChaudiere: [],
                    // capaciteMaxAnu: '',
                    // reseauChaleur: true,
                    // rsCommentaire: '',
                    // performenceEnergetique: '',
                    // cycleVapeur: '',
                    // terboalternateur: '',
                    // venteProduction: '',
                    // traitementFumee: [],
                    installationComplementair: [],
                    // voiTraiFemuee: null,
                    // traitementNOX: [],
                    // equipeProcessTF: [],
                    // reactif: [],
                    // typeTerboalternateur: null,
                    // constructeurInstallation: null,
                },
                lines: [],
                valorisations: {
                    valorisationTypes: [],
                    agregateurElectrique: [],
                    performenceEnergetique: null,
                    performenceEnergetiqueDate: null,
                    performenceEnergetiqueR1: null,
                    performenceEnergetiqueR1Date: null,
                    electriciteVendue: null,
                    chaleurVendue: null,
                    H2Vendue: null,
                    informationComplementaire: null,
                    blocks: []
                },
            },
            TRI: {
                capaciteHoraire: '',
                capaciteNominale: '',
                capaciteReglementaire: '',
                dernierConstructeur: '',
                extension: null,
                dateExtension: datetime,
                miseEnService: datetime
            },
            TMB: {
                quantiteRefus: '',
                CSRProduit: '',
                envoiPreparation: '',
                tonnageAnnuel: '',
                capaciteNominal: '',
                dernierConstruct: '',
                typeInstallation: null,
                typeDechetAccepter: [],
                technologie: [],
                valorisationEnergitique: [],
                autreActivite: [],
            },
            ISDND: {
                capaciteNominale: '',
                capaciteRestante: '',
                capaciteReglementaire: '',
                projetExtension: true,
                dateExtension: datetime,
                dateOuverture: datetime,
                dateFermeture: datetime,
                dateFermeturePrev: datetime,
            },
            currentStep: 0,
            loadingCreate: false,
            loading: !action,
            showAlert: false,
            siteErrors: null
        }
        this.defaultDepartement = []
        this.defaultRegions = []
        this.isNewItem = action
        if (!action) {
            props.editSite(props.match.params.idSite)
        }
        axios.get("https://api.mapbox.com/geocoding/v5/mapbox.places/2.28394125,48.865844499999994.json?access_token=pk.eyJ1Ijoiemlub2toZWRyaSIsImEiOiJja200b3I4MmQwNnB2MnBqeGpnOGNyNDlrIn0.PxXjYV1tGyXbTMjx0KntDQ")
            .then(res => {

            })
            .catch(err => {

            })
        this.handleStep = this.handleStep.bind(this)
        this.handleSiteinfoChange = this.handleSiteinfoChange.bind(this)
        this.handleSiteinfoChangeName = this.handleSiteinfoChangeName.bind(this)
        this.handleTechInfoChange = this.handleTechInfoChange.bind(this)
        this.handleTechInfoChangeName = this.handleTechInfoChangeName.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this._suggestionSelect = this._suggestionSelect.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
        this.handleRemoveFile = this.handleRemoveFile.bind(this)


        // new lines logic
        this.handleUVEInfoChange = this.handleUVEInfoChange.bind(this)

        this.handleAddLine = this.handleAddLine.bind(this)
        this.handleSelectLine = this.handleSelectLine.bind(this)
        this.handleLineChange = this.handleLineChange.bind(this)
        this.handleDeleteLine = this.handleDeleteLine.bind(this)


        this.handleValorisationInfoChange = this.handleValorisationInfoChange.bind(this);
        this.handleAddValorisationBlock = this.handleAddValorisationBlock.bind(this)
        this.handleValorisationBlockChange = this.handleValorisationBlockChange.bind(this)
        this.handleValorisationBlockDelete = this.handleValorisationBlockDelete.bind(this);
    }


    handleUVEInfoChange(info, value) {
        return this.setState((state) => ({
            UVE: { ...state.UVE, infos: { ...state.UVE.infos, [info]: value } }
        }));
    }

    handleAddLine() {
        return this.setState((state) => {
            return {
                ...state,
                UVE: {
                    ...state.UVE,
                    lines: [
                        ...state.UVE.lines, line()
                    ]
                }
            }
        })
    }
    handleSelectLine() {
        return this.setState((state) => {
            return {
                ...state,
                UVE: {
                    ...state.UVE,
                    lines: [
                        ...state.UVE.lines
                    ]
                }
            }
        })
    }
    handleLineChange(lineIndex, name, value) {
        let lines = [...this.state.UVE.lines];
        lines.splice(lineIndex, 1, { ...this.state.UVE.lines[lineIndex], [name]: value });
        return this.setState((state) => ({
            ...state,
            UVE: {
                ...state.UVE, lines
            }
        }));
    }
    handleDeleteLine(lineIndex) {
        if (!window.confirm('Etes vous sur ?')) return false;
        let lines = [...this.state.UVE.lines];
        lines.splice(lineIndex, 1);
        return this.setState((state) => ({
            ...state,
            UVE: {
                ...state.UVE, lines
            }
        }));
    }
    handleAddValorisationBlock() {
        return this.setState((state) => {
            return {
                ...state,
                UVE: {
                    ...state.UVE,
                    valorisations: { ...state.UVE.valorisations, blocks: [...state.UVE.valorisations.blocks, valorisation()] }
                }
            }
        });
    }
    handleValorisationInfoChange(name, value) {
        return this.setState((state) => {
            return {
                ...state,
                UVE: {
                    ...state.UVE,
                    valorisations: { ...state.UVE.valorisations, [name]: value }
                }
            }
        });
    }
    handleValorisationBlockChange(valorisationBlockIndex, name, value) {
        let blocks = [...this.state.UVE.valorisations.blocks];
        blocks.splice(valorisationBlockIndex, 1, { ...this.state.UVE.valorisations.blocks[valorisationBlockIndex], [name]: value });
        return this.setState((state) => ({
            ...state,
            UVE: {
                ...state.UVE, valorisations: { ...state.UVE.valorisations, blocks }
            }
        }));
    }
    handleValorisationBlockDelete(valorisationBlockIndex) {
        if (!window.confirm('Etes vous sur ?')) return false;
        let blocks = [...this.state.UVE.valorisations.blocks];
        blocks.splice(valorisationBlockIndex, 1);
        return this.setState((state) => ({
            ...state,
            UVE: {
                ...state.UVE, valorisations: { ...state.UVE.valorisations, blocks }
            }
        }));
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.loadingCreate) {
            let errors = nextProps.siteErrors
            let currentIndex = this.state.currentStep
            if (errors) {
                if (errors.listErr) {
                    currentIndex = errors.lvl
                    errors = errors.listErr
                }
                this.setState({
                    loadingCreate: false,
                    siteErrors: errors,
                    showAlert: true,
                    currentStep: currentIndex
                })
            } else {
                this.setState({
                    loadingCreate: false,
                    showAlert: false,
                    siteErrors: false,
                    currentStep: 2
                })
            }
        }
        if (this.state.loading && (nextProps.site || nextProps.siteErrors)) {
            this.defaultDepartement = [nextProps.site.departement_siege]
            this.defaultRegions = [nextProps.site.region_siege]
            this.setState({
                ...nextProps.site,
                loading: false,
            })
        }
    }
    handleSiteinfoChange(e) {
        let { name, value } = e.target;

        return this.setState((prevState) => ({
            siteInfo: {
                ...prevState.siteInfo,
                [name]: value
            }
        }))
    }
    handleSiteinfoChangeName(name, value) {
        if (name == 'departement_siege') {
            let { departements } = this.props;
            let id_region = null;
            let selectedDepartement = Array.isArray(departements) ? departements.find((dept) => dept.id_departement == value) : null;
            if (selectedDepartement) id_region = selectedDepartement.region.id_region;
            return this.setState((prevState) => ({
                siteInfo: {
                    ...prevState.siteInfo,
                    'departement_siege': value,
                    'region_siege': id_region,
                }
            }))
        }
        return this.setState((prevState) => ({
            siteInfo: {
                ...prevState.siteInfo,
                [name]: value
            }
        }))

    }
    handleTechInfoChange(e) {
        const { name, value } = e.target
        this.setState(prevState => ({
            [this.state.siteInfo.categorieSite]: {
                ...prevState[this.state.siteInfo.categorieSite],
                [name]: value
            }
        }))
    }
    handleTechInfoChangeName(name, value) {
        this.setState(prevState => ({
            [this.state.siteInfo.categorieSite]: {
                ...prevState[this.state.siteInfo.categorieSite],
                [name]: value
            }
        }))
    }
    _suggestionSelect(result) {
        this.setState(prevState => ({
            siteInfo: {
                ...prevState.siteInfo,
                adresse: result.address,
                latitude: result.lat,
                langititude: result.lang,
                city: result.city,
                country: 'France',
                postcode: result.postcode
            }
        }))
    }
    getTechData() {
        switch (this.state.siteInfo.categorieSite) {
            case "UVE": return <DataTechUVE
                {...this.state.UVE}
                handleInfoChange={this.handleUVEInfoChange}
                onAddLine={this.handleAddLine}
                handleLineChange={this.handleLineChange}
                handleDeleteLine={this.handleDeleteLine}
                onSelectLine={this.handleSelectLine}

                onAddValorisationBlock={this.handleAddValorisationBlock}
                handleValorisationBlockChange={this.handleValorisationBlockChange}
                handleValorisationBlockDelete={this.handleValorisationBlockDelete}
                handleValorisationInfoChange={this.handleValorisationInfoChange}

                handleEvent={this.handleTechInfoChange}
                handleName={this.handleTechInfoChangeName} />
            case "TMB": return <DataTechTMB handleEvent={this.handleTechInfoChange} stateTech={this.state.TMB} handleName={this.handleTechInfoChangeName} />
            case "TRI": return <DataTechTRI handleEvent={this.handleTechInfoChange} stateTech={this.state.TRI} handleName={this.handleTechInfoChangeName} />
            case "ISDND": return <DataTechISDND handleEvent={this.handleTechInfoChange} stateTech={this.state.ISDND} handleName={this.handleTechInfoChangeName} />
        }
    }
    getFormInput() {
        switch (this.state.currentStep) {
            case 0: return <GeneralInfoSite modesGestion={this.props.mode_gestion} amobe={this.props.amobe} handleEvent={this.handleSiteinfoChange} defaultDepartement={this.defaultDepartement} defaultRegions={this.defaultRegions} onRemoveFile={this.handleRemoveFile} uploadFile={this.handleUpload} stateSite={this.state.siteInfo} handleName={this.handleSiteinfoChangeName} _suggestionSelect={this._suggestionSelect} />
            case 1: return this.getTechData()
            case 2: return <SucessScreen typeShow={this.isNewItem} />
        }
    }
    handleStep(number) {
        let prevState = this.state.currentStep
        if ((number === -1 && prevState > 0) || (number === 1 && prevState < 2)) {
            this.setState({
                currentStep: this.state.currentStep + number,
                loadingCreate: false
            })
        }
    }
    showErrors() {
        if (this.state.showAlert) {
            if (this.state.siteErrors) {
                return <CostumAlert type='danger' errors={this.state.siteErrors} />
            }
        }
    }
    handleRemoveFile(file) {
        let newImages = this.state.siteInfo.photos.filter(item => item.uid != file.uid)
        this.handleSiteinfoChangeName("photos", newImages)
        if (this.state.siteInfo.default_image_id && this.state.siteInfo.default_image_id == file.uid) {
            this.handleSiteinfoChangeName("default_image_id", null)
        }
    }
    handleUpload(file) {
        let data = new FormData()
        let prevImage = [...this.state.siteInfo.photos]
        let index = prevImage.length
        prevImage.push({
            uid: "-4",
            status: "Chargement..."
        })
        this.handleSiteinfoChangeName("photos", prevImage)
        data.append('file', file.file)
        uploadImage(data)
            .then(({ ok, image }) => {
                if (ok) {
                    prevImage[index] = image
                    this.handleSiteinfoChangeName("photos", prevImage)
                }
            })
            .catch(err => {

            })
    }
    handleSubmit(e) {
        e.preventDefault()
        var siteInfo = { ...this.state.siteInfo };
        // if (siteInfo.societe) {
        //     switch (siteInfo.societe.typePersonMoral) {
        //         case "Syndicat":
        //             siteInfo.typeExploitant = "Syndicat"
        //             siteInfo.societe = siteInfo.societe.id_syndicat
        //             break;
        //         case "Epic":
        //             siteInfo.typeExploitant = "Epic"
        //             siteInfo.societe = siteInfo.societe.id_epic
        //             break;
        //         case "Commune":
        //             siteInfo.typeExploitant = "Commune"
        //             siteInfo.societe = siteInfo.societe.id_commune
        //             break;
        //         case "Societe":
        //             siteInfo.typeExploitant = "Societe"
        //             siteInfo.societe = siteInfo.societe.id_societe_exploitant
        //             break;
        //     }
        // }
        // if (siteInfo.client) {
        //     siteInfo.client = siteInfo.client.id_collectivite
        // }
        if (siteInfo.gestionaire) {
            siteInfo.gestionaire = siteInfo.gestionaire.id_gestionnaire
        }
        let site = {
            siteInfo: siteInfo,
            [this.state.siteInfo.categorieSite]: this.state[this.state.siteInfo.categorieSite]
        }
        if (this.isNewItem) {
            this.props.createSite(site)
        } else {
            this.props.updateSite(site)
        }
        this.setState({
            loadingCreate: true,
            showAlert: false
        })
    }
    render() {
        return (
            <>
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{this.isNewItem ? global._trans('sites', 'titleCreate') : global._trans('sites', 'titleEdit')}</span>
                    </h3>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingCreate}>
                    <div className="separator separator-solid mt-2"></div>
                    <div className="mx-5 px-5 py-4">
                        <Steps current={this.state.currentStep}>
                            <Steps.Step title={global._trans('common', 'informationsGeneral')} />
                            <Steps.Step title={global._trans('common', 'informationsTechnique')} />
                            <Steps.Step title="Terminé" />
                        </Steps>
                    </div>
                    <div className="separator separator-solid"></div>
                    <div className="card-body py-4">
                        {this.showErrors()}
                        <div className="form">
                            <Form onSubmit={this.handleSubmit}>
                                {this.getFormInput()}
                                {this.state.currentStep < 2 && <div className="d-flex justify-content-between">
                                    {this.state.currentStep > 0 && <Button variant="primary" className="text-nowrap" onClick={() => this.handleStep(-1)}>Information site</Button>}
                                    <div className="d-flex justify-content-end w-100">
                                        <div className="card-toolbar mx-4">
                                            <Link to="/sites" className="btn btn-secondary" >Annuler</Link>
                                        </div>
                                        {this.state.currentStep === 0 && <Button variant="primary" onClick={() => this.handleStep(1)}>{global._trans('common', 'informationsTechnique')}</Button>}
                                        {this.state.currentStep === 1 && this.state.siteInfo.categorieSite && <Button variant="success" type='submit' disabled={this.state.loadingCreate}>{this.isNewItem ? global._trans('sites', 'textSubmit') : global._trans('common', 'saveChanges')}</Button>}
                                    </div>
                                </div>}
                            </Form>
                        </div>
                    </div>
                </Spin>
            </>
        )
    }
}
function mapState(state) {
    const { siteErrors, site } = state.sites
    const { listEnums: { mode_gestion, amobe }, departements, regions } = state.enums
    return { siteErrors, site, mode_gestion, amobe, departements: departements.data, regions: regions.data }
}
const connectedClass = connect(mapState, { createSite, editSite, updateSite })(CreateSite)
export { connectedClass as CreateSite }