import React, { Component } from 'react'
import { Image } from 'antd'
import { Form, Col, Card, ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { LoadHistory } from '../../../../Commons'
class BasicInfo extends Component {
    toolTipContent() {
        return <Popover id="popover-basic" style={{ maxWidth: 500 }}>
            <LoadHistory type="Commune" data={this.props.commune.id_commune} column="nombreHabitant" title={global._trans("common", "historyPopulation")} />
        </Popover>
    }
    render() {
        return (
            <Form>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'state')}</Form.Label>
                        <div className="d-flex align-items-center p-2" style={{border: "1px solid #E4E6EF", borderRadius: "0.42rem" ,fontSize: "1rem", padding: "0.65rem 1rem !important", height: "calc(1.5em + 1.3rem + 2px)"}}>
                            <i style={{border: "2px solid #ffffff", borderRadius: "100%"}} className={`state-badge symbol-badge symbol-badge-xl bg-${this.props.commune.state ? (this.props.commune.state == "A" ? "success" : "danger") : "dark"}`}></i><span class="state-bade-text">{this.props.commune.state ? (this.props.commune.state == "A" ? "Actif" : "Fermé") : "Indéfini"}</span>
                        </div>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsCommune', 'communeName')}</Form.Label>
                        <Form.Control type="text" name="nomCommune" disabled={this.props.disabled} value={this.props.commune.nomCommune} placeholder={global._trans('clientsCommune', 'communeName')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'usual_denomination')}</Form.Label>
                        <Form.Control type="text" name="usual_denomination" disabled={this.props.disabled} value={this.props.commune.usual_denomination} placeholder={global._trans('common', 'usual_denomination')} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsCommune', 'siret')}</Form.Label>
                        <Form.Control type="text" name="siret" disabled={this.props.disabled} value={this.props.commune.siret} placeholder={global._trans('clientsCommune', 'siret')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsCommune', 'serin')}*</Form.Label>
                        <Form.Control type="text" name="serin" disabled={this.props.disabled} value={this.props.commune.serin} placeholder={global._trans('clientsCommune', 'serin')} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'regionSiege')}</Form.Label>
                        <Form.Control type="text" name="nombreHabitant" disabled={this.props.disabled} value={this.props.commune.region_siege} placeholder={global._trans('common', 'regionSiege')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'departementSiege')}</Form.Label>
                        <Form.Control type="text" name="nomCommune" disabled={this.props.disabled} value={this.props.commune.departement_siege} placeholder={global._trans('common', 'departementSiege')} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'address')}*</Form.Label>
                        <Form.Control disabled={true} placeholder={global._trans('common', 'searchAdress')} name='adresse' value={this.props.commune.adresse} />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-0">
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans('common', 'city')}</Form.Label>
                                <Form.Control type='text' placeholder={global._trans('common', 'city')} disabled={this.props.disabled} name='city' value={this.props.commune.city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans('common', 'country')}</Form.Label>
                                <Form.Control type='text' placeholder={global._trans('common', 'country')} disabled={this.props.disabled} name='country' value={this.props.commune.country} />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'postcode')}</Form.Label>
                        <Form.Control type='text' placeholder={global._trans('common', 'postcode')} disabled={this.props.disabled} name='postcode' value={this.props.commune.postcode} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans('common', 'logoCompany')}</Form.Label>
                        <Image
                            width={110}
                            src={this.props.commune.logo}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsCommune', 'insee')}*</Form.Label>
                        <Form.Control type="text" name="insee" disabled={this.props.disabled} value={this.props.commune.insee} placeholder={global._trans('clientsCommune', 'insee')} />
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Row>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'population')}</Form.Label>
                                <Form.Control type="number" name="nombreHabitant" disabled={this.props.disabled} value={this.props.commune.nombreHabitant} placeholder={global._trans('common', 'population_year')} />
                            </Form.Group>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'population_year')}</Form.Label>
                                <Form.Control type="number" name="nombreHabitant_year" disabled={this.props.disabled} value={this.props.commune.nombreHabitant_year} placeholder={global._trans('common', 'nbPeopleAnnee')} />
                            </Form.Group>
                        </Form.Row>
                        <div className="d-flex justify-content-between align-items-center p-2">
                            <span><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.props.commune.date_enter)}</span>
                            <OverlayTrigger trigger="click" placement="top" overlay={this.toolTipContent()}>
                                <span role="button" id="tooltip_info" className="text-primary font-weight-bold">{global._trans('common', 'viewAll')}</span>
                            </OverlayTrigger>
                        </div>
                    </Form.Group>


                    {/* <Form.Group as={Col}> */}
                        {/* <Form.Label>{global._trans('common', 'nbPeople')}</Form.Label> */}
                        {/* <Form.Control type="text" name="nombreHabitant" disabled={this.props.disabled} value={global._toStringSpace(this.props.commune.nombreHabitant)} placeholder={global._trans('common', 'nbPeople')} /> */}
                        {/* <div className="d-flex justify-content-between align-items-center p-2"> */}
                            {/* <span><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.props.commune.date_enter)}</span> */}
                            {/* <OverlayTrigger trigger="click" placement="top" overlay={this.toolTipContent()}> */}
                                {/* <span role="button" id="tooltip_info" className="text-primary font-weight-bold">{global._trans('common', 'viewAll')}</span> */}
                            {/* </OverlayTrigger> */}
                        {/* </div> */}
                    {/* </Form.Group> */}
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsCommune', 'epicReatache')}</Form.Label>
                        {this.props.commune.epic && <Card>
                            <ListGroup variant="flush">
                                <ListGroup.Item><strong>{global._trans('clientsEpic', 'epicName')} : </strong>{'  ' + this.props.commune.epic.nomEpic}</ListGroup.Item>
                                <ListGroup.Item><strong>{global._trans('common', 'address')}: </strong>{'  ' + this.props.commune.epic.adresse}</ListGroup.Item>
                                <ListGroup.Item><Link to={"/client/communities/epic/view/" + this.props.commune.epic.id_epic}><strong>{global._trans('clientsCommune', 'viewEpic')}</strong></Link></ListGroup.Item>
                            </ListGroup>
                        </Card>}
                    </Form.Group>
                </Form.Row>
            </Form>
        )
    }
}
export { BasicInfo }