import { userSlice } from "./user.reducer";
import * as userRequests from "./user.crud";
const { actions } = userSlice;
export const createUserPrem = UserToCreate => dispatch => {
    return userRequests
        .createUserPrem(UserToCreate)
        .then(({ok}) => {
            if (ok) {
                dispatch(actions.userCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(({message,errors}) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        });
};
export const updateUserPrem = UserToUpdate => dispatch => {
    return userRequests
        .updateUserPrem(UserToUpdate)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.userPremFetched({ user: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(({message,errors}) => {
            if (message==="The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        });
};
export const fetchUsersPrem = QueryParams => dispatch => {
    return userRequests
        .fetchUsersPrem(QueryParams)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.usersPremFetched({ listUsers: data,currentQuery:QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const fetchUsersPremPost = QueryParams => dispatch => {
    return userRequests
        .fetchUsersPremPost(QueryParams)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.usersPremFetched({ listUsers: data,currentQuery:QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const fetchUserPrem = idUser => dispatch => {
    return userRequests
        .fetchUserPrem(idUser)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.userPremFetched({ user: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const deleteUsersPrem = Users => dispatch => {
    return userRequests
        .deleteUsersPrem(Users)
        .then(({ok,data,users}) => {
            if (ok) {
                dispatch(actions.usersPremDeleted({ asyncData: data === "async", users: users }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const sitesUserPrem = (idUser,queryParams) => {
    return userRequests.sitesUserPrem(idUser,queryParams)     
};
export const sessionsUserPrem = idUser => {
    return userRequests.sessionsUserPrem(idUser)     
};
export const deleteSessionUser = Session => {
    return userRequests.deleteSessionUser(Session)     
};
export const resetState = state => dispatch => {
    dispatch(actions.resetState(state));
};