import React, { Component } from 'react'
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom'
export const SucessScreen = (props) => {
    return (
        <Result
            status="success"
            title={global._trans("sites", props.typeShow ? "messageCreate" : "messageUpdate")}
            extra={[<Link to="/sites"><Button>{global._trans("sites", "goBackToSites")}</Button></Link>]}
        />
    )
}