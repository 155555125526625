import React, { Component } from 'react'
import { Form, Col, Card, ListGroup } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { Image, Select } from 'antd';
import { PhonePicker } from '../../Commons'
class BasicInfo extends Component {
    constructor(props) {
        super(props)
        this.typeClient = {
            syndicat: { type: 'Syndicat', name: 'Nom Court', Key: "id_syndicat", dataIndex: 'nomCourt', url: "/client/communities/syndicat/view/" },
            epic: { type: 'Epic', name: 'Nom EPIC', Key: "id_epic", dataIndex: 'nomEpic', url: "/client/communities/epic/view/" },
            commune: { type: 'Commune', name: 'Nom Commune', Key: "id_commune", dataIndex: 'nomCommune', url: "/client/communities/communes/view/" },
            societe: { type: 'Société', name: 'Groupe', Key: "id_societe_exploitant", dataIndex: 'groupe', url: "/client/companies/view/" }
        }
    }
    render() {
        const site = this.props.site
        const enums = this.props.enums
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'denomenation')}*</Form.Label>
                        <Form.Control type="text" placeholder={global._trans('sites', 'denomenation')} disabled={true} value={site.denomination} name="denomination" />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "categorieSite")}*</Form.Label>
                        <Form.Control type="text" placeholder={global._trans("sites", "categorieSite")} disabled={true} value={site.categorieSite} name="categorieSite" />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'sinoe')}*</Form.Label>
                        <Form.Control type="text" placeholder={global._trans('clientsEpic', 'sinoe')} disabled={true} value={site.sinoe} name="denomination" />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "modeGestion")}*</Form.Label>
                        <Form.Control type="text" placeholder={global._trans("sites", "modeGestion")} disabled={true} value={site.modeGestion} name="modeGestion" />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("common", "address")}*</Form.Label>
                        <Form.Control type="text" placeholder={global._trans("common", "address")} disabled={true} value={`${site.adresse}, ${site.postcode} - ${site.city}, ${site.country}`} name="adress" />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "createYear")}</Form.Label>
                        <Form.Control type="text" placeholder={global._trans("sites", "createYear")} disabled={true} value={site.anneeCreation} name="anneeCreation" />
                    </Form.Group>

                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'regionSiege')}</Form.Label>
                        <Form.Control type="text" name="region_siege" disabled={true} value={site.region_siege} placeholder={global._trans('common', 'regionSiege')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'departementSiege')}</Form.Label>
                        <Form.Control type="text" name="departement_siege" disabled={true} value={site.departement_siege} placeholder={global._trans('common', 'departementSiege')} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "photoSite")}</Form.Label>
                        <div>
                            <Image.PreviewGroup>
                                {site.photos && site.photos.map((photo, key) => {
                                    return <Image
                                        key={key}
                                        width={110}
                                        height={110}
                                        src={photo}
                                        style={(site.default_image && photo == site.default_image.url ? { border: '2px solid #1890ff' } : {})}
                                    />
                                })}
                            </Image.PreviewGroup>
                        </div>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "comments")}</Form.Label>
                        <Form.Control name="comments" placeholder={global._trans('sites', 'comments')} disabled={true} value={site.comments} as="textarea" rows="3" />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("common", "webSite")}</Form.Label>
                        <Form.Control placeholder={global._trans("common", "webSite")} value={site.siteIntrnet} name="siteIntrnet" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("common", "standrPhone")}</Form.Label>
                        <PhonePicker placeholder={global._trans("common", "standrPhone")} value={site.telephoneStandrad} disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsSyndicat', 'amobe')}</Form.Label>
                        <Select
                            disabled={true}
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            optionFilterProp="label"
                            placeholder={global._trans('clientsSyndicat', 'amobe')}
                            style={{ width: '100%', overflow: 'scroll' }}
                            value={site.amobes}
                            loading={!enums.amobe}
                            options={enums.amobe}
                            mode="multiple"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    {site.client && site.client.client && <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans("sites", "maitre_ouvrage")} <strong>({this.typeClient[site.client.typeCollectivite.toLowerCase()].type})</strong></Form.Label>
                        <Card>
                            <ListGroup variant="flush">

                                <ListGroup.Item><strong>{this.typeClient[site.client.typeCollectivite.toLowerCase()].name} : </strong>
                                    {site.client.typeCollectivite.toLowerCase() == 'societe' ?
                                        site.client.client[this.typeClient[site.client.typeCollectivite.toLowerCase()].dataIndex].map(({ value_enum }) => value_enum).join(', ') :
                                        site.client.client[this.typeClient[site.client.typeCollectivite.toLowerCase()].dataIndex]} </ListGroup.Item>

                                {site.client.typeCollectivite.toLowerCase() == 'societe' && <ListGroup.Item><strong>{global._trans("sites", "company_name")}</strong> : 
                                    {!!site.client.client && site.client.client.denomination}
                                </ListGroup.Item>}
                                <ListGroup.Item><strong>Ville: </strong>{'  ' + site.client.client.city}</ListGroup.Item>
                                <ListGroup.Item><Link to={this.typeClient[site.client.typeCollectivite.toLowerCase()].url + site.client.client[this.typeClient[site.client.typeCollectivite.toLowerCase()].Key]}><strong>{global._trans("common", "view" + site.client.typeCollectivite.toLowerCase())}</strong></Link></ListGroup.Item>
                           
                            </ListGroup>
                        </Card>
                    </Form.Group>}
                    {site.exploitant && site.exploitant.client && <Form.Group as={Col}>
                        <Form.Label className="d-block">Societe d'exploitation <strong>({this.typeClient[site.exploitant.typeExploitant.toLowerCase()].type})</strong></Form.Label>
                        <Card>
                            <ListGroup variant="flush">

                                <ListGroup.Item><strong>{this.typeClient[site.exploitant.typeExploitant.toLowerCase()].name} : </strong>
                                    {site.exploitant.typeExploitant.toLowerCase() == 'societe' ?
                                        site.exploitant.client[this.typeClient[site.exploitant.typeExploitant.toLowerCase()].dataIndex].map(({ value_enum }) => value_enum).join(', ') :
                                        site.exploitant.client[this.typeClient[site.exploitant.typeExploitant.toLowerCase()].dataIndex]} </ListGroup.Item>

                                {site.exploitant.typeExploitant.toLowerCase() == 'societe' && <ListGroup.Item><strong>{global._trans("sites", "company_name")}</strong> : 
                                    {!!site.exploitant.client && site.exploitant.client.denomination}
                                </ListGroup.Item>}
                                <ListGroup.Item><strong>Ville: </strong>{'  ' + site.exploitant.client.city}</ListGroup.Item>
                                <ListGroup.Item><Link to={this.typeClient[site.exploitant.typeExploitant.toLowerCase()].url + site.exploitant.client[this.typeClient[site.exploitant.typeExploitant.toLowerCase()].Key]}><strong>{global._trans("common", "view" + site.exploitant.typeExploitant.toLowerCase())}</strong></Link></ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Form.Group>}
                    {site.gestionnaire && <Form.Group as={Col}>
                        <Form.Label className="d-block">Employé en charge</Form.Label>
                        <Card>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <div className="row">
                                        <div className="col-xl-6"><strong>Nom : </strong>{'  ' + site.gestionnaire.nom}</div>
                                        <div className="col-xl-6"><strong>Prenom : </strong>{'  ' + site.gestionnaire.prenom}</div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item><strong>Email: </strong>{'  ' + site.gestionnaire.email}</ListGroup.Item>
                                <ListGroup.Item><strong>Statut: </strong>{site.gestionnaire.status ? ' Active' : ' inActive'}</ListGroup.Item>
                                <ListGroup.Item>
                                    <div className="row">
                                        <div className="col-xl-6"><strong>Telephone : </strong>{'  ' + site.gestionnaire.telephone}</div>
                                        <div className="col-xl-6"><strong>Mobile : </strong>{'  ' + site.gestionnaire.mobile}</div>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Form.Group>}
                </Form.Row>
            </>
        )
    }
}
export { BasicInfo }