import React, { Component } from 'react'
import { NatureJuridique, CompetenceDechet, Amobe, CodeApe, FunctionPerson, Contract, GroupeSo, ModesGestions, Effectifs } from './ClientComponent'
import { CommonComponentWrapper } from './SiteComponent'
import { Switch, Route, Redirect } from 'react-router-dom';
import { TimeLineHelper } from './utils/TimeLineHelper'
class ClientData extends Component {
    render() {
        return (
            <div className="card card-custom card-stretch">
                <div className="card-header py-3" style={{ minHeight: "0px" }}>
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">{global._trans('settings', 'clientSettingsTitle')}</h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">{global._trans('settings', 'subTitleClient')}</span>
                    </div>
                </div>
                <div className="card-body py-4">
                    <div className="row">
                        <div className="col-xl-3 border-right">
                            <div className="timeline timeline-6 mt-3">
                                <TimeLineHelper lines={[
                                    { path: "/settings/clients/contracts", name: global._trans('contracts', 'autherActivContract') },
                                    { path: "/settings/clients/amobe", name: global._trans('clientsSyndicat', 'amobe') },
                                    { path: "/settings/clients/fileCategory", name: global._trans('settings', 'fileCategory') },
                                    { path: "/settings/clients/codeape", name: "Code APE" },
                                    { path: "/settings/clients/competances", name: global._trans('common', 'compDechetDelgue') },
                                    { path: "/settings/clients/effectifs", name: global._trans('common', 'effectifs') },
                                    { path: "/settings/clients/funtions", name: global._trans('common', 'fonctionContact') },
                                    { path: "/settings/clients/groupes", name: global._trans('companies', 'groupe') },
                                    { path: "/settings/clients/modes", name: global._trans('companies', 'mode_gestion') },
                                    { path: "/settings/clients/natures", name: global._trans('common', 'natureJuridque') },
                                ]} />
                            </div>
                        </div>
                        <div className="col-xl-9">
                            <Switch>
                                <Redirect from="/settings/clients" exact={true} to="/settings/clients/contracts" />
                                <Route path="/settings/clients/natures" component={NatureJuridique} />
                                <Route path="/settings/clients/funtions" component={FunctionPerson} />
                                <Route path="/settings/clients/contracts" component={Contract} />
                                <Route path="/settings/clients/competances" component={CompetenceDechet} />
                                <Route path="/settings/clients/amobe" component={Amobe} />
                                <Route path="/settings/clients/codeape" component={CodeApe} />
                                <Route path="/settings/clients/groupes" component={GroupeSo} />
                                <Route path="/settings/clients/modes" component={ModesGestions} />
                                <Route path="/settings/clients/fileCategory" component={(props) => <CommonComponentWrapper {...props} keyName="fileCategory" title={global._trans('settings', 'fileCategory')} />} />
                                <Route path="/settings/clients/effectifs" component={Effectifs} />

                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export { ClientData }