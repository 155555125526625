import React, { Component } from 'react'
import { connect } from "react-redux";
import { Spin } from 'antd';

import { Link } from 'react-router-dom';
import { fetchCommune } from '../../../../../modules/Collectivities/collectivitie.actions'
import { BasicInfo } from './utils'
import { Contacts } from '../../Common'
import { EntityHistory } from '../../../Commons/EntityHistory';
import { EntityFiles } from '../../../Commons';

class ViewCommune extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            commune: {},
            tab: 'basic',
            disabled: true
        }
        props.fetchCommune(props.match.params.idCommune)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            this.setState({
                loading: false,
                commune: nextProps.Commune ? nextProps.Commune : {}
            })
        }
    }
    getTabBody() {
        switch (this.state.tab) {
            case "basic": return <BasicInfo commune={this.state.commune} disabled={this.state.disabled} />
            case "contacts": return <Contacts contacts={this.state.commune.contacts} />
            case "history": return <EntityHistory entity={this.state.commune} showStatus={true} />
            case "files": return <EntityFiles entity={this.state.commune} />
        }
    }
    handleTab(tab) {
        this.setState({
            tab: tab
        })
    }
    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div className="card-header border-0 pt-5 pb-0 px-6">
                    <h3 className="card-title align-items-start flex-column mb-0">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('clientsCommune', 'titleView')} <span className="text-primary">#{this.state.commune.nomCommune ? this.state.commune.nomCommune : '...'}</span></span>
                    </h3>
                </div>
                <div className="card-body py-4">
                    <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('basic')} className={`nav-link ${this.state.tab === "basic" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'basicInfo')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('contacts')} className={`nav-link ${this.state.tab === "contacts" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'contacts')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('history')} className={`nav-link ${this.state.tab === "history" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'history')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('files')} className={`nav-link ${this.state.tab === "files" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'files')}</Link>
                        </li>
                    </ul>
                    <div className="pt-5">{this.getTabBody()}</div>
                    <div className="d-flex justify-content-end">
                        <div className="card-toolbar mx-4">
                            <Link to={`/client/communities/communes${this.props.queryCommune}`} className="btn btn-secondary" >{global._trans('common', 'goBack')}</Link>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}
function mapState(state) {
    const { Commune, queryCommune } = state.collectivitie
    return { Commune, queryCommune }
}
const connectedClass = connect(mapState, { fetchCommune })(ViewCommune)
export { connectedClass as ViewCommune }