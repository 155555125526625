import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SageTable } from '../../../../Commons'
class SitesSyndicat extends Component {
    render() {
        return (
            <>
                <SageTable
                    columns={[
                        { title: global._trans('sites', 'categorieSite'), dataIndex: "categorieSite" },
                        { title: global._trans('sites', 'modeGestion'), dataIndex: "modeGestion" },
                        { title: global._trans('sites', 'denomenation'), dataIndex: "denomination" },
                        { title: global._trans('sites', 'createYear'), dataIndex: "anneeCreation" },
                        { title: global._trans('sites', 'restartPeriod'), dataIndex: "perdiocitRelance" },
                        { title: global._trans('common', 'standrPhone'), dataIndex: "telephoneStandrad" },
                        { title: global._trans('common', 'webSite'), dataIndex: "siteIntrnet" },
                        { title: global._trans('common', 'address'), dataIndex: "adresse" },

                    ]}
                    style={{ height: 540 }}
                    data={this.props.sites}
                    IterableProps={{
                        locale: {
                            emptyText: global._trans('clientsSyndicat', 'emptySitesAssociat')
                        },
                        rowKey: "id_contact",
                        pagination: false
                    }}
                />
                <br />
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { nature_juridique, region_siege, departement_siege, competence_dechet } } = state.enums
    return { nature_juridique, region_siege, departement_siege, competence_dechet }
}
const connectedClass = connect(mapState, {})(SitesSyndicat)
export { connectedClass as SitesSyndicat }