import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_sage/_helpers";
import * as Apis from '../../modules/Auth/auth.crud';
import { CostumAlert } from '../PrivatePages/Commons'
export const ForgetPassword = (props) => {
    const initState = {
        email: ""
    }
    const [state, setState] = useState(initState)
    const [loading, setLoading] = useState(false)
    const [showErrors, setErrors] = useState({})
    const dispatch = useDispatch()
    const onChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!loading) {
            if (state.email) {
                setLoading(true)
                setErrors({})
                Apis.sendEmailPassword({ ...state, type: 'adminApp' })
                    .then(({ message }) => {
                        if (message === "Email sent successfully") {
                            setErrors({ valid: true })
                        } else {
                            setErrors({ unkon: global._trans("common", "messageErrors") })
                        }
                    })
                    .catch(({ message, errors }) => {
                        if (message === "The given data was invalid.") {
                            setErrors(errors)
                        } else {
                            setErrors({ unkon: message })
                            // setErrors({ unkon: global._trans("common", "messageErrors") })
                        }

                    })
                    .finally(e => {
                        setLoading(false)
                    })
            }
        }
    }
    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
                    <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" style={{ backgroundImage: `url(${toAbsoluteUrl("/media/images/bg-5.jpg")})`, minWidth: 600 }}>
                        <div className="d-flex flex-row-fluid flex-column justify-content-between">
                            <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                                <img
                                    alt="Logo"
                                    className="max-h-70px"
                                    src={toAbsoluteUrl("/Sage.svg")}
                                />
                            </Link>
                            <div className="flex-column-fluid d-flex flex-column justify-content-center">
                                <h3 className="font-size-h1 mb-5 text-white">{global._trans("login", "welcome")}!</h3>
                                <p className="font-weight-lighter text-white opacity-80"></p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                            <div className="login-form login-signin" id="kt_login_signin_form" style={{ width: "400px" }}>
                                <div className="text-center mb-10">
                                    <h3 className="font-size-h1">{global._trans("auth", "resetPassword")}</h3>
                                    <p className="text-muted font-weight-bold">{global._trans("auth", "enterEmail")}</p>
                                    {showErrors.valid && <CostumAlert type='success' errors={global._trans("auth", "emailSend")} />}
                                    {showErrors.unkon && <CostumAlert type='danger' errors={showErrors.unkon} />}
                                </div>
                                <form className="form fv-plugins-bootstrap fv-plugins-framework" onSubmit={handleSubmit}>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label className="font-size-h6 font-weight-bolder text-dark">{global._trans("common", "email")}</label>
                                        <input placeholder={global._trans("common", "email")} onChange={onChange} type="email" value={state.password} className={`form-control ${showErrors.email && 'is-invalid'} form-control-solid h-auto py-5 px-6 `} name="email" />
                                        {showErrors.email &&
                                            <span class="invalid-feedback" role="alert">
                                                <strong>{showErrors.email}</strong>
                                            </span>}
                                    </div>
                                    <Link to="/login" className="text-sm font-weight-bold">{global._trans("auth", "login")}</Link>
                                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                                        <button id="kt_login_signin_submit" type="submit" className={`btn btn-primary font-weight-bold px-9 py-4 my-3`} disabled={loading}>
                                            <span>{loading ? "Chargement..." : "Envoyer le lien"}</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}