import React, { useState } from 'react'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../../_sage/_helpers';
export function CostumAlert({ type, errors }) {
    const [isError, setisError] = useState(true);
    let body = []
    let keysCount = 0
    if (typeof errors === 'object') {
        for (const [key, value] of Object.entries(errors)) {
            if (Array.isArray(value)) {
                value.map(item => {
                    body.push(<div key={keysCount} className="my-2" >{item}</div>)
                    keysCount++
                })
            } else {
                body.push(<div key={keysCount} className="my-2" >{value}</div>)
                keysCount++
            }
        }
    } else {
        body = [<div key={keysCount} className="my-2">{errors}</div>]
    }
    return (
        isError ?
            <div className={"alert alert-custom p-3 fade show mb-4 alert-light-" + type} role="alert" style={type != 'success' ? {backgroundColor: '#FFF6F8', borderRadius: '8px', border: '1px solid #F44336'} : {borderRadius: '8px'}}>
                <div className="alert-icon">
                    <span className={"svg-icon svg-icon-2x svg-icon-" + type} style={{position:'absolute', top: 15, left:15}}>
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Costum/" + type + ".svg")}
                        ></SVG>{" "}
                    </span>
                </div>
                <div className="alert-text font-weight-bold" style={{marginLeft: '30px'}}>{body}</div>
                <div className="alert-close" onClick={() => setisError(false)} style={{position:'absolute', top: 15, right:15}}>
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">
                            <i className="ki ki-close"></i>
                        </span>
                    </button>
                </div>
            </div> :
            <>
            </>
    )
}