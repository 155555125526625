import { contractSlice } from "./contract.reducer";
import * as contractRequests from "./contract.crud";
const { actions } = contractSlice;
export const createContract = ContractToCreate => dispatch => {
    return contractRequests
        .createContract(ContractToCreate)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.contractCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        });
};
export const updateContract = ContractToUpdate => dispatch => {
    return contractRequests
        .updateContract(ContractToUpdate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.contractCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        });
};
export const fetchContracts = QueryParams => dispatch => {
    return contractRequests
        .fetchContracts(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.contractsFetched({ listContracts: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const fetchContract = idContract => dispatch => {
    return contractRequests
        .fetchContract(idContract)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.contractFetched({ Contract: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const editContract = idContract => dispatch => {
    return contractRequests
        .editContract(idContract)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.contractFetched({ Contract: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const deleteContracts = Contracts => dispatch => {
    return contractRequests
        .deleteContracts(Contracts)
        .then(async ({ ok, data, contracts }) => {
            if (ok) {
                dispatch(actions.contractsDeleted({ asyncData: data === "async", contracts: contracts }));
                return true;
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
                return false;
            }
        })
        .catch(async (err) => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            return false;
        });
};