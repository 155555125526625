import React from 'react'
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Spin } from 'antd'
import { useSelector } from 'react-redux'
import { toAbsoluteUrl } from "../../../../../_sage/_helpers";
export const CardInfo = (props) => {
    const { gestionaire, currentQuery } = useSelector((state) => state.gestionaire)
    return (
        <Spin spinning={!gestionaire} wrapperClassName="position-sticky h-max-content top-0">
            <div className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
                <div className="card card-custom">
                    <div className="card-body pt-4">
                        <div className="d-flex align-items-center mt-2">
                            <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                <div className="symbol-label" style={{ backgroundImage: `url(${toAbsoluteUrl("/media/images/blank.png")})` }}></div>
                                <i className={`symbol-badge bg-${gestionaire && gestionaire.status ? "success" : "danger"}`}></i>
                            </div>
                            {gestionaire && <div>
                                <a className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                                    {gestionaire.nom} {gestionaire.prenom}
                                </a>
                                <div className="text-muted">{gestionaire.genre}</div>
                                <div className="text-muted">{gestionaire.status ? global._trans('contacts', 'active') : global._trans('contacts', 'inactive')}</div>
                            </div>}
                        </div>
                        <hr />
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded mt-2">
                            <div className="navi-item mb-2">
                                <NavLink to={`/manager/view/${props.idGestionaire}/info`} className="navi-link py-4" activeClassName="active">
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Compiling.svg")}></SVG>
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">{global._trans("common", "personalInfo")}</span>
                                </NavLink>
                            </div>
                            <div className="navi-item mb-2">
                                <NavLink to={`/manager/view/${props.idGestionaire}/attached`} className="navi-link py-4" activeClassName="active">
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Article.svg")}></SVG>
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">{global._trans("managers", "ratachedSite")}</span>
                                </NavLink>
                            </div>
                            <div className="navi-item">
                                <NavLink to={`/manager${currentQuery}`} exact={true} className="navi-link py-4" activeClassName="active">
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Back.svg")}></SVG>
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">{global._trans("common", "backToList")}</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}