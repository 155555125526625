import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class TraitementFumee extends Component {
    render() {
        return (
            <CommonComponent keyName="traitementFumee" titleSetting={global._trans('settings', 'traitmentFumme')} traitementFumee={this.props.traitementFumee} />
        )
    }
}
function mapState(state) {
    const { listEnums: { traitementFumee } } = state.enums
    return { traitementFumee }
}
const connectedClass = connect(mapState, null)(TraitementFumee)
export { connectedClass as TraitementFumee }