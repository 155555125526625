import React, { useState } from 'react'
import { Popover } from 'react-bootstrap'
import { SageTable } from './SageTable'
import { fetchHistory } from '../../../modules/Common/common.actions'
export const LoadHistory = (props) => {
    const [state, setState] = useState({ list: null, loading: true })
    const [called, setCalled] = useState(null)
    if (!called) {
        setCalled(true)
        fetchHistory({ type: props.type, data: props.data, column: props.column })
            .then(({ ok, list }) => {
                if (ok) {
                    setState({
                        loading: false,
                        list: list
                    })
                }
            })
            .catch(({ }) => {
                setState({
                    loading: false,
                    list: null
                })
            })
    }
    return (
        <>
            <Popover.Title as="h3">{props.title}</Popover.Title>
            <Popover.Content>
                <SageTable
                    columns={[
                        { title: global._trans('common', 'dateEnter'), dataIndex: "date_reference", render: date_reference => global._toFormatDate(date_reference) },
                        { title: global._trans('common', 'valueEnter'), dataIndex: "prev_value", render: prev_value => prev_value && prev_value.includes(":")? prev_value.split(':')[1].trim() : prev_value },
                        { title: global._trans('common', 'Année'), dataIndex: "prev_value", render: prev_value => prev_value && prev_value.includes(":")? prev_value.split(':')[0].trim() : "Pas d'année" },
                    ]}
                    style={{ maxWidth: 500 }}
                    data={state.list}
                    IterableProps={{
                        loading: state.loading
                    }}
                    history={true}
                />
            </Popover.Content>
        </>
    )
}