import React, { Component } from 'react';
import { Form, Col, Button } from "react-bootstrap";
import { Select, Spin, Radio, Switch } from 'antd'
import { connect } from 'react-redux';
import { CostumAlert, PhonePicker } from '../../Commons'
import { updateGestionaire } from '../../../../modules/Gestionaires/gestionaire.actions'
import PhoneInput from "react-phone-input-2";
import fr from 'react-phone-input-2/lang/fr.json'
class InfoPersonal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props.gestionaire,
            loading: !props.gestionaire,
            disableEdit: true,
            errors: null,
            loadingUpdate: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeByName = this.handleChangeByName.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading && (nextProps.gestionaire || nextProps.errorsGestionaire)) {
            this.setState({
                ...nextProps.gestionaire,
                loading: false
            })
        }
        if (this.state.loadingUpdate) {
            this.setState({
                loadingUpdate: false,
                errors: nextProps.errorsGestionaire,
                disableEdit: !nextProps.errorsGestionaire,
                showAlert: true
            })
        }
    }
    handleChange(e) {
        const { value, name } = e.target
        this.setState({
            [name]: value
        })
    }
    handleChangeByName(name, value) {
        this.setState({
            [name]: value
        })
    }
    handleEdit() {
        let prevState = this.state.disableEdit
        this.setState({
            ...(!prevState ? this.props.gestionaire : null),
            disableEdit: !prevState
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        let gestionnaire = {
            id_gestionnaire: this.state.id_gestionnaire,
            status: this.state.status,
            genre: this.state.genre,
            nom: this.state.nom,
            username: this.state.username,
            init_password: this.state.init_password,
            prenom: this.state.prenom,
            telephone: this.state.telephone,
            mobile: this.state.mobile,
            email: this.state.email,
            societe: this.state.societe,
        }
        this.setState({
            loadingUpdate: true,
            showAlert: false
        })
        this.props.updateGestionaire(gestionnaire)
    }
    showErrors() {
        if (this.state.showAlert) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                return <CostumAlert type='success' errors={global._trans("managers", "titleUpdateMessage")} />
            }
        }
    }
    render() {
        return (
            <Spin spinning={this.state.loading || this.state.loadingUpdate} wrapperClassName="flex-row-fluid ml-lg-8">
                <div className="card card-custom card-stretch">
                    <div className="card-header py-3">
                        <div className="card-title align-items-start flex-column">
                            <h3 className="card-label font-weight-bolder text-dark">{global._trans("common", "personalInfo")}</h3>
                            <span className="text-muted font-weight-bold font-size-sm mt-1">{global._trans("managers", "bodyInfoManager")}</span>
                        </div>
                    </div>
                    <div className="form">
                        <div className="card-body">
                            {this.showErrors()}
                            <Form method="post" onSubmit={this.handleSubmit}>
                                <div className="mb-4 row">
                                    <div className="col-xl-6">
                                        <Form.Label>{global._trans('contacts', 'sexe')}*</Form.Label>
                                        <div className="d-flex align-items-center my-1">
                                            <Radio.Group value={this.state.genre} disabled={this.state.disableEdit} name="genre" onChange={this.handleChange}>
                                                <Radio.Button value="MME">MME</Radio.Button>
                                                <Radio.Button value="MR">MR</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <Form.Label>{global._trans('contacts', 'status')}*</Form.Label>
                                        <div className="d-flex align-items-center my-3">
                                            <strong>{global._trans('contacts', 'inactive')}</strong>
                                            <Switch checked={this.state.status} disabled={this.state.disableEdit} className="mx-3" onChange={(value) => this.handleChangeByName("status", value)} />
                                            <strong>{global._trans('contacts', 'active')}</strong>
                                        </div>
                                    </div>
                                </div>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'firstName')}</Form.Label>
                                        <Form.Control type="text" name="nom" className="form-control-lg form-control-solid" disabled={this.state.disableEdit} value={this.state.nom} placeholder={global._trans('common', 'firstName')} onChange={this.handleChange} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'lastName')}</Form.Label>
                                        <Form.Control type="text" name="prenom" value={this.state.prenom} placeholder={global._trans('common', 'lastName')} className="form-control-lg form-control-solid" disabled={this.state.disableEdit} onChange={this.handleChange} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'userName')}</Form.Label>
                                        <Form.Control type="text" name="username" className="form-control-lg form-control-solid" disabled={this.state.disableEdit} value={this.state.username} placeholder={global._trans('common', 'userName')} onChange={this.handleChange} />
                                    </Form.Group>
                                    {this.state.init_password !== null && <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'initPassword')}</Form.Label>
                                        <Form.Control type="text" name="init_password" value={this.state.init_password} placeholder={global._trans('common', 'initPassword')} className="form-control-lg form-control-solid" disabled={this.state.disableEdit} onChange={this.handleChange} />
                                    </Form.Group>}
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'mobile')}*</Form.Label>
                                        <PhoneInput
                                            specialLabel={false}
                                            inputClass='w-100 form-control-lg form-control-solid'
                                            inputStyle={{ fontSize: "1.08rem" }}
                                            enableSearch={true}
                                            disableSearchIcon={true}
                                            searchStyle={{ width: "90%", padding: "10px 15px" }}
                                            searchPlaceholder={global._trans("common", "searchCode")}
                                            placeholder={global._trans('common', 'mobile')}
                                            country={"fr"}
                                            localization={fr}
                                            value={this.state.mobile}
                                            disabled={this.state.disableEdit}
                                            onChange={(value) => this.handleChangeByName("mobile", value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'phone')}</Form.Label>
                                        <PhonePicker value={this.state.telephone} isLg={true} disabled={this.state.disableEdit} onChange={(value) => this.handleChangeByName("telephone", value)} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'email')}</Form.Label>
                                        <Form.Control type="email" name="email" value={this.state.email} placeholder={global._trans('common', 'email')} className="form-control-lg form-control-solid" disabled={this.state.disableEdit} onChange={this.handleChange} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('managers', 'contractList')}</Form.Label>
                                        <Select
                                            showSearch
                                            showArrow
                                            className="inputSizeSelect-lg inputSizeSelect-solid"
                                            placeholder={global._trans('managers', 'selectContract')}
                                            style={{ width: '100%' }}
                                            optionFilterProp="label"
                                            value={this.state.societe}
                                            disabled={this.state.disableEdit}
                                            options={[{ label: "SAGE ENGINEERING", value: "Sage_engineering" }, { label: "SAGE EXPERT", value: "Sage_expert" }, { label: "SAGE INDUSTRY", value: "Sage_industry" }]}
                                            onChange={(selectedValue) => this.handleChangeByName("Societe", selectedValue)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <div className="d-flex justify-content-end mt-4">
                                    {this.state.disableEdit ?
                                        <Button variant="success" type='button' onClick={this.handleEdit}>{global._trans('managers', 'titleEdit')}</Button> :
                                        <>
                                            <Button variant="default" type='button' className="mr-2" onClick={this.handleEdit}>{global._trans('common', 'cancelBtn')}</Button>
                                            <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{global._trans('common', 'saveChanges')}</Button>
                                        </>}

                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}
function mapState(state) {
    const { listEnums: { contract } } = state.enums
    const { errorsGestionaire, gestionaire } = state.gestionaire
    return { contract, errorsGestionaire, gestionaire }
}
const connectedClass = connect(mapState, { updateGestionaire })(InfoPersonal)
export { connectedClass as InfoPersonal }