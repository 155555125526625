export const commonUrls = {
    UPLOAD_IMAGE_URL: 'move/file',
    FETCH_DEPARTMENT_URL: 'departement/list',
    FETCH_REGION_URL: 'region/list',
    FETCH_HISTORY_URL:'history/fetch',

    IMPORT_DATA_URL: "data/import",
    EXPORT_DATA_URL: "data/export",

    GET_TABLE_VIEWS_URL: "table-view",
    CREATE_TABLE_VIEW_URL: "table-view/create",
    UPDATE_TABLE_VIEW_URL: "table-view/update",
    DELETE_TABLE_VIEW_URL: "table-view/delete",
    SELECT_TABLE_VIEW_URL: "table-view/select",
}