import React, { Component } from 'react'
import { connect } from "react-redux";
import { Form, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { Image } from 'antd';
import { LoadHistory, PhonePicker } from '../../../../Commons'
class BasicInfo extends Component {
    toolTipContent(column, title) {
        return <Popover id="popover-basic" style={{ maxWidth: 500 }}>
            <LoadHistory type="Epic" data={this.props.epic.id_epic} column={column} title={global._trans("common", title)} />
        </Popover>
    }
    render() {
        return (
            <Form>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'state')}</Form.Label>
                        <div className="d-flex align-items-center p-2" style={{border: "1px solid #E4E6EF", borderRadius: "0.42rem" ,fontSize: "1rem", padding: "0.65rem 1rem !important", height: "calc(1.5em + 1.3rem + 2px)"}}>
                            <i style={{border: "2px solid #ffffff", borderRadius: "100%"}} className={`state-badge symbol-badge symbol-badge-xl bg-${this.props.epic.state ? (this.props.epic.state == "A" ? "success" : "danger") : "dark"}`}></i><span class="state-bade-text">{this.props.epic.state ? (this.props.epic.state == "A" ? "Actif" : "Fermé") : "Indéfini"}</span>
                        </div>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'epicName')}</Form.Label>
                        <Form.Control type="text" name="nomEpic" disabled={this.props.disabled} value={this.props.epic.nomEpic} placeholder={global._trans('clientsEpic', 'epicName')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsSyndicat', 'nameSyndicat')}</Form.Label>
                        <Form.Control type="text" name="nom_court" disabled={this.props.disabled} value={this.props.epic.nom_court} placeholder={global._trans('clientsSyndicat', 'nameSyndicat')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'usual_denomination')}</Form.Label>
                        <Form.Control type="text" name="usual_denomination" disabled={this.props.disabled} value={this.props.epic.usual_denomination} placeholder={global._trans('common', 'usual_denomination')} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'siret')}</Form.Label>
                        <Form.Control type="text" name="siret" disabled={this.props.disabled} value={this.props.epic.siret} placeholder={global._trans('clientsEpic', 'siret')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'serin')}</Form.Label>
                        <Form.Control type="text" name="serin" disabled={this.props.disabled} value={this.props.epic.serin} placeholder={global._trans('clientsEpic', 'serin')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'sinoe')}</Form.Label>
                        <Form.Control type="text" name="sinoe" disabled={this.props.disabled} value={this.props.epic.sinoe} placeholder={global._trans('clientsEpic', 'sinoe')} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'address')}</Form.Label>
                        <Form.Control disabled={true} placeholder={global._trans('common', 'searchAdress')} name='adresse' value={this.props.epic.adresse} />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-0">
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans('common', 'city')}*</Form.Label>
                                <Form.Control disabled={true} type='text' placeholder={global._trans('common', 'city')} name='city' value={this.props.epic.city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans('common', 'country')}*</Form.Label>
                                <Form.Control disabled={true} type='text' placeholder={global._trans('common', 'country')} name='country' value={this.props.epic.country} />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>

                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'postcode')}*</Form.Label>
                        <Form.Control type='text' disabled={this.props.disabled} placeholder={global._trans('common', 'postcode')} name='postcode' value={this.props.epic.postcode} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'webSite')}</Form.Label>
                        <Form.Control type='text' placeholder={global._trans('common', 'webSite')} disabled={this.props.disabled} value={this.props.epic.siteInternet} name='siteInternet' />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'regionSiege')}</Form.Label>
                        <Form.Control type="text" name="region_siege" disabled={this.props.disabled} value={this.props.epic.region_siege} placeholder={global._trans('common', 'regionSiege')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'departementSiege')}</Form.Label>
                        <Form.Control type="text" name="departement_siege" disabled={this.props.disabled} value={this.props.epic.departement_siege} placeholder={global._trans('common', 'departementSiege')} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'natureJuridque')}</Form.Label>
                        <Form.Control type="text" name="nature_juridique" disabled={this.props.disabled} value={this.props.epic.nature_juridique} placeholder={global._trans('clientsEpic', 'natureJuridque')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans('common', 'logoCompany')}</Form.Label>
                        <Image
                            width={110}
                            src={this.props.epic.logo}
                        />
                    </Form.Group>
                </Form.Row>
                <div className="separator separator-solid mb-4"></div>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'standrPhone')}</Form.Label>
                        <PhonePicker name="telephoneStandard" value={this.props.epic.telephoneStandard} disabled={this.props.disabled} placeholder={global._trans('common', 'standrPhone')} />
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Row>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'population')}</Form.Label>
                                <Form.Control type="number" name="nombreHabitant" disabled={this.props.disabled} value={this.props.epic.nombreHabitant} placeholder={global._trans('common', 'population')} />
                            </Form.Group>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'population_year')}</Form.Label>
                                <Form.Control type="number" name="nombreHabitant_year" disabled={this.props.disabled} value={this.props.epic.nombreHabitant_year} placeholder={global._trans('common', 'population_year')} />
                            </Form.Group>
                        </Form.Row>
                        <div className="d-flex justify-content-between align-items-center p-2">
                            <span><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.props.epic.date_enter)}</span>
                            <OverlayTrigger trigger="click" placement="top" overlay={this.toolTipContent("nombreHabitant", "historyHabitant")}>
                                <span role="button" id="tooltip_info" className="text-primary font-weight-bold">{global._trans('common', 'viewAll')}</span>
                            </OverlayTrigger>
                        </div>
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Row>
                            <Form.Group as={Col}  className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'effectifs')}</Form.Label>
                                <Form.Control type='text' disabled={this.props.disabled} placeholder={global._trans('common', 'effectifs')} value={this.props.epic.effectifs} name="effectifs" />
                            </Form.Group>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'effectifs_year')}</Form.Label>
                                <Form.Control type="number" name="effectifs_year" disabled={this.props.disabled} value={this.props.epic.effectifs_year} placeholder={global._trans('common', 'effectifs_year')} />
                            </Form.Group>
                        </Form.Row>
                        <div className="d-flex justify-content-end align-items-center p-2">
                            <OverlayTrigger trigger="click" placement="top" overlay={this.toolTipContent("effectifs", "historyEffectif")}>
                                <span role="button" id="tooltip_info" className="text-primary font-weight-bold">{global._trans('common', 'viewAll')}</span>
                            </OverlayTrigger>
                        </div>
                    </Form.Group>

                </Form.Row>
            </Form>
        )
    }
}
function mapState(state) {
    const { listEnums: { competence_dechet } } = state.enums
    return { competence_dechet }
}
const connectedClass = connect(mapState, {})(BasicInfo)
export { connectedClass as BasicInfo }
