import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import './ViewsList.css';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_sage/_helpers";
import { Form, Col, ListGroup, Spinner } from 'react-bootstrap';
import { Checkbox } from 'antd';

class ViewsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view_id: null,
            view_name: "",
            view_name_invalid: false,
            view_columns: []
        }
    }
    handleColumnChecked = (e) => {
        let { name, checked } = e.target;
            
        if (checked) {
            this.setState(prevState => ({
                view_columns: [...prevState.view_columns, name],
            }));
        } else {
            this.setState(prevState => ({
                view_columns: prevState.view_columns.filter(i => i !== name),
            }));
        }
    }
    handleNameChange = (e) => {
        let { value } = e.target;
        this.setState({
            view_name: value
        })
    }
    handleNewView = () => {
        this.setState({
            view_id: null,
            view_name: "",
            view_name_invalid: false,
            view_columns: []
        })
        this.props.onSwitch("create")
    }
    handleEditView = (id) => {
        const view = this.props.views.find((view) => view.id === id)
        this.setState({
            view_id: view.id,
            view_name: view.name,
            view_name_invalid: false,
            view_columns: view.state.filter((obj) => !obj.hide).map((obj) => obj.colId)
        })
        this.props.onSwitch("edit")
    }
    handleCreateView = () => {
        if (this.state.view_name.trim() == "") {
            this.setState({
                view_name_invalid: true
            })
        } else {
            this.props.onCreate({view_name: this.state.view_name, view_columns: this.state.view_columns})
        }
    }
    handleUpdateView = () => {
        if (this.state.view_name.trim() == "") {
            this.setState({
                view_name_invalid: true
            })
        } else {
            this.props.onUpdate({view_id: this.state.view_id, view_name: this.state.view_name, view_columns: this.state.view_columns, view_selected: this.state.selected_view_id === this.state.view_id})
        }
    }
    handleViewChange = (id) => {
        this.setState({
            selected_view_id: id
        })
        this.props.onChange(id)
    }
    handleOnClose = () => {
        if (this.state.selected_view_id !== null) this.props.onClose(this.state.selected_view_id)
        else this.props.onClose()
    }
    render() {
        return (
            <Modal size="md" centered backdrop="static" show={this.props.isOpen}>
                {this.props.loading && <div className="position-absolute h-100 w-100 d-flex justify-content-center flex-column align-items-center" style={{ top: 0, left: 0, zIndex: 15, background: '#ffffff9e' }}>
                    <Spinner animation="border" variant="primary" />
                </div>}
                {this.props.action == "list" && <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between px-5 py-4">
                        <span className="card-label font-size-h4 font-weight-bolder text-dark">{global._trans('common', 'Views management')}</span>
                    </div>
                    <div className="card-body py-4">
                        <div className="mb-3">
                            <div className="view-list">
                                <div key={0}>
                                    <input type="radio" name="selected" id={`view-row-0`} onChange={() => this.handleViewChange(0)} defaultChecked={!this.props.views.find((view) => view.selected)} />
                                    <label htmlFor={`view-row-0`}>{global._trans('common', 'Main')}</label>
                                </div>
                                {this.props.views && this.props.views.map((view) => (
                                    <div key={view.id}>
                                        <input type="radio" name="selected" id={`view-row-${view.id}`} defaultChecked={view.selected} onChange={() => this.handleViewChange(view.id)} />
                                        <label htmlFor={`view-row-${view.id}`}>{view.name}</label>
                                        <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"} onClick={() => this.handleEditView(view.id)}>
                                            <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Edit.svg")}></SVG></span>
                                        </button>
                                        <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-danger"} onClick={() => this.props.onDelete(view.id, view.name)}>
                                            <span className={"svg-icon svg-icon-md svg-icon-danger"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG></span>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end py-3 px-4 border-top">
                        <button className="btn btn-light font-weight-bolder font-size-sm mr-2" onClick={this.handleOnClose}>
                            {global._trans('common', 'Close')}
                        </button>
                        <button className={"btn btn-success font-weight-bolder font-size-sm mr-2"} onClick={this.handleNewView}>
                            {global._trans('common', 'Create a view')}
                        </button>
                    </div>
                </div>}
                {this.props.action == "create" && <div className="card">
                    <div className="card-header d-flex align-items-center px-5 py-4">
                        <span className="card-label font-size-h4 font-weight-bolder text-dark">{global._trans('common', 'Create a view')}</span>
                    </div>
                    <div className="card-body py-4">
                        <div className="mb-3">
                            
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'View name')}</Form.Label>
                                    <Form.Control type="text" isInvalid={this.state.view_name_invalid} placeholder={global._trans('common', 'View name')} name="view_name" value={this.state.view_name} onChange={this.handleNameChange}/>
                                    {this.state.view_name_invalid && (
                                        <Form.Control.Feedback type="invalid">
                                            {global._trans('common', 'Please enter a view name.')}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Form.Row>
                            <Form.Label>{global._trans('common', 'View columns')}</Form.Label>
                            <div className="d-flex flex-row align-items-center flex-wrap">
                                {this.props.columns && this.props.columns.length > 0 ?
                                    this.props.columns.map((col, index) => 
                                        <Checkbox key={index} name={col.colId} onChange={this.handleColumnChecked} style={{ margin: 0, fontSize: "12px", padding: "5px 10px", border: "1px solid #EBEDF3" }}>
                                            {col.colDef.headerName ? col.colDef.headerName : col.colId}
                                        </Checkbox>
                                    )
                                : global._trans('common', "No columns available.")}
                            </div>

                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between py-3 px-4 border-top">
                        <button className="btn btn-light-primary font-weight-bolder font-size-sm " onClick={() => this.props.onSwitch("list")}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Back.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'backToList')}
                        </button>
                        <div className="d-flex align-items-center">
                            <button className="btn btn-light font-weight-bolder font-size-sm mr-2" onClick={this.handleOnClose}>
                                {global._trans('common', 'Close')}
                            </button>
                            <button className={"btn btn-success font-weight-bolder font-size-sm mr-2"} onClick={this.handleCreateView}>
                                {global._trans('common', 'Create')}
                            </button>
                        </div>
                    </div>
                </div>}
                {this.props.action == "edit" && <div className="card">
                    <div className="card-header d-flex align-items-center px-5 py-4">
                        <span className="card-label font-size-h4 font-weight-bolder text-dark">{global._trans('common', 'Edit a view')}</span>
                    </div>
                    <div className="card-body py-4">
                        <div className="mb-3">
                           
                           <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'View name')}</Form.Label>
                                    <Form.Control type="text" isInvalid={this.state.view_name_invalid}  placeholder={global._trans('common', 'View name')} name="view_name" value={this.state.view_name} onChange={this.handleNameChange}/>
                                    {this.state.view_name_invalid && (
                                        <Form.Control.Feedback type="invalid">
                                            {global._trans('common', 'Please enter a view name.')}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Form.Row>
                            <Form.Label>{global._trans('common', 'View columns')}</Form.Label>
                            <div className="d-flex flex-row align-items-center flex-wrap">
                                {this.props.columns && this.props.columns.length > 0 ?
                                    this.props.columns.map((col, index) => 
                                        <Checkbox key={index} name={col.colId} defaultChecked={this.state.view_columns.includes(col.colId)} onChange={this.handleColumnChecked} style={{ margin: 0, fontSize: "12px", padding: "5px 10px", border: "1px solid #EBEDF3" }}>
                                            {col.colDef.headerName ? col.colDef.headerName : col.colId}
                                        </Checkbox>
                                    )
                                : global._trans('common', "No columns available.")}
                            </div>

                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between py-3 px-4 border-top">
                        <button className="btn btn-light-primary font-weight-bolder font-size-sm " onClick={() => this.props.onSwitch("list")}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Back.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'backToList')}
                        </button>
                        <div className="d-flex align-items-center">
                            <button className="btn btn-light font-weight-bolder font-size-sm mr-2" onClick={this.handleOnClose}>
                                {global._trans('common', 'Close')}
                            </button>
                            <button className={"btn btn-success font-weight-bolder font-size-sm mr-2"} onClick={this.handleUpdateView}>
                                {global._trans('common', 'Save')}
                            </button>
                        </div>
                    </div>
                </div>}
            </Modal>
        )
    }
}

export { ViewsList }