import React, { Component } from 'react'
import { connect } from "react-redux";
import { SageTable } from '../../../../Commons'

class ListEpics extends Component {
    constructor(props){
        super(props);
    }
    getTextValue(idEnum, objetSearch) {
        console.log(objetSearch);
        let text = objetSearch.find(obj => obj.value === idEnum);
        if(!!text) return text.label;
        return idEnum;
    }
    mergeData(data, enums) {
        return data.map(epic => ({
            ...epic, region_siege: this.getTextValue(epic.region_siege, enums.regions), nature_juridique: this.getTextValue(epic.nature_juridique, enums.nature_juridique), departement_siege: this.getTextValue(epic.departement_siege, enums.departements)
        }))
    }
    render() {
        return (
            <SageTable
                columns={[
                    { title: "ID", dataIndex: "id_epic", sorter: true },
                    { title: global._trans('clientsEpic', 'epicName'), dataIndex: "nomEpic", sorter: true },
                    { title: global._trans('common', 'natureJuridque'), dataIndex: "nature_juridique", sorter: true },
                    { title: global._trans('common', 'regionSiege'), dataIndex: "region_siege", sorter: true },
                    { title: global._trans('common', 'departementSiege'), dataIndex: "departement_siege", sorter: true },
                    { title: global._trans('clientsEpic', 'serin'), dataIndex: "serin", sorter: true },
                    { title: global._trans('common', 'address'), dataIndex: "adresse", sorter: true },
                    { title: global._trans('common', 'population'), dataIndex: "nombreHabitant", sorter: true }
                ]}
                data={this.props.epics}
                style={{ height: 550 }}
                IterableProps={{
                    locale: {
                        emptyText: global._trans('clientsSyndicat', 'emptySitesAssociat')
                    },
                    rowKey: "id_epic",
                    pagination: false
                }}
            />
        )
    }
}

function mapState(state) {
    const { listEnums: { nature_juridique, competence_dechet }, regions, departements } = state.enums
    return {enums: { nature_juridique, competence_dechet, regions, departements}}
}
const connectedClass = connect(mapState, { })(ListEpics)
export { connectedClass as EpicsSyndicat }