import { createSlice } from "@reduxjs/toolkit";
const queryString = require('query-string');
const initialState = {
    errorsGestionaire: null,
    listGestionaires: {},
    gestionaire:null,
    currentQuery:""
};

export const gestionaireSlice = createSlice({
    name: "gestionaire",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { errors } = action.payload
            state.errorsGestionaire = errors;
        },
        gestionaireCreated: (state) => {
            state.errorsGestionaire = state.errorsGestionaire === false ? null : false
        },
        resetState:(state, action) => {
            action.payload && action.payload.map(elem=>{
                state[elem.name]=elem.value
            })
        },
        gestionairesFetched: (state, action) => {
            const { listGestionaires,currentQuery } = action.payload
            state.errorsGestionaire = null
            state.listGestionaires = listGestionaires
            state.currentQuery='?'+queryString.stringify(currentQuery)
        },
        gestionaireFetched: (state, action) => {
            const { Gestionaire } = action.payload
            state.errorsGestionaire = null
            state.gestionaire = Gestionaire
        },
        gestionaireDeleted: (state, action) => {
            const { asyncData, gestionnaires } = action.payload
            if (asyncData) {
                let prevList = state.listGestionaires
                prevList.data = prevList.data.filter(gestionnaire => gestionnaires.indexOf(gestionnaire.id_gestionnaire) === -1)
                state.listGestionaires = prevList
            }
            state.errorsGestionaire = state.errorsGestionaire === false ? null : false
        },
    }
});