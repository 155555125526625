import React, { forwardRef } from 'react'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
import { Form, Dropdown } from "react-bootstrap";
import { Switch } from 'antd'
const FilterPicker = forwardRef((props, ref) => {
    return (
        <div ref={ref} onClick={e => {
            e.preventDefault();
            props.onClick(e);
        }} className="btn btn-md btn-default btn-icon">
            <span className="svg-icon svg-icon-default svg-icon-lg">
                <span className="svg-icon-default svg-icon-2x">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Filter.svg")}></SVG>
                </span>
            </span>
        </div>
    )
})
export const FilterSearch = (props) => {
    return (
        <Dropdown className="dropdown-inline" drop="down" alignLeft>
            <Dropdown.Toggle as={FilterPicker} id="dropdown-toggle-quick-actions-subheader" />
            <Dropdown.Menu className="dropdown-menu p-0 mt-1 dropdown-menu-lg dropdown-menu-right">
                <ul className="navi navi-hover">
                    <li className="navi-header font-weight-bold py-4">
                        <span className="font-size-lg">{global._trans("common", "fieldSearch")}</span>
                    </li>
                    <li className="navi-separator opacity-70"></li>
                    <Form onSubmit={props.handleSubmit} id={props.idForm} className={`px-4 mt-3`}>
                        <div className="overflow-auto" style={{ maxHeight: 350 }}>{props.children}</div>
                        <div className="d-flex justify-content-between align-items-center py-3">
                            <div className="d-flex align-items-center">
                                <strong>{global._trans('common', 'orFilter')}</strong>
                                <Switch checked={props.isAndType} className="mx-3" onChange={props.handleType} />
                                <strong>{global._trans('common', 'andFilter')}</strong>
                            </div>
                            <button className="btn btn-default font-weight-bolder font-size-sm">{global._trans("common", "search")}</button>
                        </div>
                    </Form>
                </ul>
            </Dropdown.Menu>
        </Dropdown>
    )
}