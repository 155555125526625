import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class FunctionPerson extends Component {
    render() {
        return (
            <CommonComponent keyName="function_person" titleSetting={global._trans('common', 'fonctionContact')} function_person={this.props.function_person} />
        )
    }
}
function mapState(state) {
    const { listEnums: { function_person } } = state.enums
    return { function_person }
}
const connectedClass = connect(mapState, null)(FunctionPerson)
export { connectedClass as FunctionPerson }