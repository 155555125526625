import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class GroupeSo extends Component {
    render() {
        return (
            <CommonComponent keyName="groupeList" titleSetting={global._trans('companies', 'groupe')} groupeList={this.props.groupeList} />
        )
    }
}
function mapState(state) {
    const { listEnums: { groupeList } } = state.enums
    return { groupeList }
}
const connectedClass = connect(mapState, null)(GroupeSo)
export { connectedClass as GroupeSo }