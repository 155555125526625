import { CrudService } from "../../../services";
import { userUrls } from './user.constants'
export function createUserPrem(user) {
    return CrudService.Post(userUrls.CREATE_USER_PREM_URL, user);
}
export function fetchUsersPrem(queryParams) {
    return CrudService.Get(userUrls.FETCH_USERS_PREM_URL, {}, queryParams);
}
export function fetchUsersPremPost(queryParams) {
    return CrudService.Post(userUrls.FETCH_USERS_PREM_URL, queryParams);
}
export function fetchUserPrem(idUser) {
    return CrudService.Get(userUrls.FETCH_USER_PREM_URL+idUser);
}
export function sitesUserPrem(idUser,queryParams) {
    return CrudService.Get(userUrls.SITES_USER_PREM_URL+idUser,{},queryParams);
}
export function sessionsUserPrem(idUser) {
    return CrudService.Get(userUrls.SESSIONS_USER_PREM_URL+idUser);
}
export function updateUserPrem(user) {
    return CrudService.Patch(userUrls.UPDATE_USER_PREM_URL,user);
}
export function deleteUsersPrem(users) {
    return CrudService.Delete(userUrls.DELETE_USERS_PREM_URL, users);
}
export function deleteSessionUser(session) {
    return CrudService.Delete(userUrls.DELETE_USERS_SESSION_URL, session);
}
