import React, { Component } from 'react'
import { connect } from "react-redux";
import { Form } from 'react-bootstrap'
import { NavLink, } from 'react-router-dom'
import { Modal, Spin } from 'antd'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_sage/_helpers";
import { FilterSearch, InlineBottunSvg, SageTable, ImportData } from '../../../Commons'
import { fetchCommunes, deleteCommunes, syncCommunesInseeAPI } from "../../../../../modules/Collectivities/collectivitie.actions";
import { FiltersCommune } from './utils'
import { AgGrid } from '../../../Commons';

import { message, notification } from 'antd';
import { exportData } from "../../../../../modules/Common/common.actions";
const queryString = require('query-string');
class ListCommunes extends Component {
    constructor(props) {
        super(props)
        // let asyncLoad = props.queryCommune !== props.history.location.search || !props.queryCommune
        this.state = {
            first_load: true,
            view_save: 0,
            loading: true,
            total: 0,
            page: 1,
            page_size: 20,
            list: [],
            columnDefs: [
                {headerName: "#", filter: "agNumberColumnFilter", field: 'id_commune'},
                {type: 'stateColumn'},
                {headerName: global._trans('clientsCommune', 'communeName'), field: 'nomCommune'},
                {headerName: global._trans('common', 'usual_denomination'), field: 'usual_denomination'},
                {headerName: global._trans('clientsEpic', 'serin'), field: 'serin'},
                {headerName: global._trans('common', 'siret'), field: 'siret'},
                {headerName: global._trans('clientsCommune', 'insee'), field: 'insee'},
                {headerName: global._trans('clientsCommune', 'epicReatache'), field: 'epic.nomEpic'},
                {headerName: global._trans('common', 'departementSiege'), field: 'departement_siege.name_departement'},
                {headerName: global._trans('common', 'regionSiege'), field: 'region_siege.name_region'},
                {headerName: global._trans('common', 'address'), field: 'adresse'},
                {headerName: global._trans('common', 'postcode'), field: 'postcode'},
                {headerName: global._trans('common', 'city'), field: 'city'},
                {headerName: global._trans('common', 'country'), field: 'country'},
                {headerName: global._trans('common', 'nbPeople'), field: 'nombreHabitant'},
                {headerName: global._trans('common', 'population_year'), field: 'nombreHabitant_year'},
                {headerName: global._trans('common', 'status'), field: 'status', type: "statusColumn"},
                {headerName: "", minWidth: 150, colId: "Actions", field: 'id_commune', sortable: false, filter: false, cellRendererFramework: params => {
                    return <div className="d-flex">
                        <a href={'/client/communities/communes/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Edit.svg")}></SVG></span>
                            </button>
                        </a>
                        <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-danger"} onClick={() => this.handleDeleteCommune(params.value)}>
                            <span className={"svg-icon svg-icon-md svg-icon-danger"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG></span>
                        </button>
                        <a href={'/client/communities/communes/view/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"} to={'/contrat/view/' + params.value}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/View.svg")}></SVG></span>
                            </button>
                        </a>
                    </div>
                }}
            ],


            // selectedRows: [],
            // listCommunes: (props.history.location.search && props.listCommunes.data) || [],
            // total: props.listCommunes.total,
            // filters: {
            //     page: 1,
            //     pageSize: 20,
            //     source: 'communes',
            //     ...queryString.parse(props.history.location.search)
            // },
            // loadCommunes: asyncLoad,
            loadingDelete: false,
            // showImport: false
        }
        // this.toReset = ['form_search_list', 'form_search_input']
        // this.filterTab = {}
        // this.deleteSelected = this.deleteSelected.bind(this)
        // this.handleSelectRow = this.handleSelectRow.bind(this)
        // this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
        // this.handleChangeSearch = this.handleChangeSearch.bind(this)
        // this.handleTableChange = this.handleTableChange.bind(this)
        // this.handleResetSearch = this.handleResetSearch.bind(this)
        // this.handleSorter = this.handleSorter.bind(this)
        // this.handleResetSearch = this.handleResetSearch.bind(this)
        // if (asyncLoad) {
        //     props.fetchCommunes(this.state.filters)
        // }
        props.fetchCommunes({pagination: {page: this.state.page, page_size: this.state.page_size}, first_load: true})

    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.listCommunes && Array.isArray(nextProps.listCommunes.data)) {
                this.setState({

                    list: nextProps.listCommunes.data,
                    total: nextProps.listCommunes.total,
                    page: nextProps.listCommunes.current_page,
                    page_size: nextProps.listCommunes.per_page,

                    // listCommunes: nextProps.listCommunes.data,
                    // loadCommunes: false,
                    // filters: {
                    //     ...this.state.filters,
                    //     page: nextProps.listCommunes.current_page,
                    //     pageSize: nextProps.listCommunes.per_page
                    // },
                    // total: nextProps.listCommunes.total,
                    // selectedRows: []
                    loading: false,
                }, () => {
                    this.childComponentRef.onLoadingDone(this.state.first_load)
                    if (this.state.first_load) this.setState({first_load: false})
                })
            }
        }
        if (this.state.loadingDelete) {
            this.setState({
                loadingDelete: false,
                listCommunes: nextProps.listCommunes.data,
                selectedRows: []
            })

            if (nextProps.CommuneErrors) {
                message.error(global._trans('settings', 'cantDelete'));
            }
        }
    }
    deleteSelected() {
        Modal.confirm({
            title: global._trans('common', 'deleteAllTitle'),
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('sites', 'deleteBody'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadingDelete: true
                })
                this.props.deleteCommunes({ communes: this.state.selectedRows })
            }
        });
    }
    handleTypeFilter(checked) {
        this.setState({
            filters: {
                ...this.state.filters,
                typeFilter: checked ? "inter" : null
            }
        })
    }
    handleSelectRow(selectedRows) {
        this.setState({
            selectedRows: selectedRows
        })
    }
    handleDeleteCommune(idCommune) {
        Modal.confirm({
            title: global._trans('clientsCommune', 'titleDeleteCommune') + " #" + idCommune,
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('clientsCommune', 'bodyDeleteCommune'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.props.deleteCommunes({ communes: [idCommune] })
                this.setState({
                    loadingDelete: true
                })
            }
        });
    }
    // handleTableChange = (pagination, filters) => {
    //     if (pagination.current !== this.state.cuurentPage || pagination.pageSize !== this.state.pageSize) {
    //         let prevfilter = this.state.filters
    //         prevfilter.page = pagination.current
    //         prevfilter.pageSize = pagination.pageSize
    //         this.handleFetchData(prevfilter)
    //     }
    // }
    handleSorter(sorter) {
        let prevfilter = this.state.filters
        prevfilter.sort = sorter.value
        prevfilter.sorter = sorter.current
        this.handleFetchData(prevfilter)
    }
    handleFetchData(filter) {
        this.props.history.push({
            search: queryString.stringify(filter)
        })
        this.props.fetchCommunes(filter)
        this.setState({
            loadCommunes: true
        })
    }
    handleSubmitSearch(e) {
        e.preventDefault()
        this.state.filters = {
            ...this.state.filters,
            ...this.filterTab
        }
        this.handleFetchData(this.state.filters)
    }
    handleChangeSearch(e) {
        const { name, value } = e.target
        this.filterTab[name] = value
    }
    handleResetSearch() {
        let resteData = {
            page: this.state.filters.page,
            pageSize: this.state.filters.pageSize,
            source: this.state.filters.source,
        }
        this.state.filters = resteData
        this.filterTab = {}
        this.toReset.map(item => {
            let formReset = document.getElementById(item)
            if (formReset) formReset.reset();
        })
        this.handleFetchData(resteData)
    }
    showReset() {
        const keys = Object.entries(this.state.filters).filter(item => item[1] && (item[0] !== 'typeFilter'));
        return keys.length > 3
    }
    handleImportExcel = (value) => {
        this.setState({
            showImport: value
        })
    }
    handleExportExcel = (nameImport) => {
        let columns = {}
        this.childComponentRef.state.columns.filter(item => item.visible && item.colId != "Actions").forEach(item => {
            columns[item.colId] = item.colDef.headerName
        });
        exportData({...nameImport, columns, filter: this.childComponentRef.gridApi.getFilterModel()})
            .then(({ message, ok }) => {
                if (ok && message == "Import Started") {
                    notification.info({
                        message: global._trans('common', 'titleImported'),
                        description: global._trans('common', 'bodyImportedMessage'),
                        placement: 'bottomLeft',
                        // duration: 0,
                        style: {
                            width: 500,
                            padding: '15px 20px',
                            borderRadius: 8
                        },
                    })
                    // onHide()
                }
            })
            .catch((e) => {
                console.log('🚀 => e', e)

            })
            .finally((f) => {
                console.log('🚀 => f', f)
                // setLoading(false)
            })
    }

    handleTableChange = (filter, sort, pagination) => {
        this.setState({
            loading: true,
            list: []
        }, () => this.props.fetchCommunes({sort, filter, pagination}));
    }
    handleSaveView = () => {
        this.setState({
            view_save: 2
        }, () => {
            this.childComponentRef.saveCurrentView()
        })
    }
    handleViewSaved = (value) => {
        this.setState({
            view_save: value
        })
    }
    render() {
        return (
            <>
                <div className="card-header header-center py-1">
                    <h3 className="card-title">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('clientsCommune', 'titleList')} ({this.state.total} élément trouvé)</span>
                    </h3>
                    <div className="card-toolbar">
                    {this.state.view_save > 0 && <button title={"Enregistrer l'état de la vue (filtre, ordre, disposition et taille des colonnes)"} disabled={this.state.view_save == 2} className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.handleSaveView()}>
                            <span className="svg-icon svg-icon-md" style={{margin: 0}}>
                                <SVG hidden={this.state.view_save != 1} src={toAbsoluteUrl("/media/svg/icons/Costum/Save.svg")} className="h-50 align-self-center"></SVG>
                                <SVG hidden={this.state.view_save != 2} src={toAbsoluteUrl("/media/svg/icons/Costum/Loading.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                        </button>}
                        <button className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.childComponentRef.viewsList(true)}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Columns.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Views management')}
                        </button>
                        <button className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => this.handleImportExcel('communes')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'importData')}
                        </button>
                        {/* <button className="btn btn-light-success font-weight-bolder font-size-sm mr-2" onClick={() => this.props.syncCommunesInseeAPI({ action: 'sync_all' })}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Compiling.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'SyncAPI')}
                        </button> */}
                        {/* <button className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => this.handleImportExcel('CommunesMAJ')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Import MAJ')}
                        </button> */}
                        <button className="btn btn-light-primary font-weight-bolder font-size-sm mx-3" onClick={() => this.handleExportExcel({ type: 'communes' })}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            Exporter
                        </button>
                        <NavLink to="/client/communities/communes/new" className="btn btn-primary font-weight-bolder font-size-sm">
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('clientsCommune', 'createCommune')}
                        </NavLink>
                    </div>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingDelete}>
                <div className="card-body py-4">
                    {/* <div className="d-flex align-items-center">
                        {this.state.selectedRows.length > 0 && <button type="button" className="btn btn-light-danger font-weight-bolder font-size-sm d-flex align-items-center mr-3" onClick={this.deleteSelected}>
                            <span className="svg-icon svg-icon-md svg-icon-light-danger">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG>
                            </span>
                            <span>{`${global._trans("common", "deleteSelected")} ${this.state.selectedRows.length} ${global._trans("common", "element")}`}</span>
                        </button>}
                        <div className="d-flex align-items-center">
                            <FilterSearch handleSubmit={this.handleSubmitSearch} idForm="form_search_list" isAndType={this.state.filters.typeFilter === "inter"} handleType={this.handleTypeFilter.bind(this)}>
                                <FiltersCommune filters={this.state.filters} handleChange={this.handleChangeSearch} />
                            </FilterSearch>
                            <Form id="form_search_input" className="d-flex" onSubmit={this.handleSubmitSearch}>
                                <Form.Control type="text" style={{ width: 350 }} className="mx-2" placeholder={global._trans('common', 'searchData')} defaultValue={this.state.filters.search} name="search" onChange={this.handleChangeSearch}></Form.Control>
                                {this.showReset() && <button type="button" onClick={this.handleResetSearch} className="btn btn-light font-weight-bolder mr-2 font-size-sm">{global._trans("common", "reset")}</button>}
                                <button className="btn btn-light-primary font-weight-bolder font-size-sm">{global._trans("common", "search")}</button>
                            </Form>
                        </div>
                    </div> */}
                    {/* <div className="separator separator-solid my-3"></div> */}
                    {/* <SageTable
                        columns={[
                            {
                                title: global._trans('common', 'state'), dataIndex: "state", sorter: false, render: state => <div className="symbol align-self-start align-self-xxl-center">
                                    <i className={`state-badge symbol-badge symbol-badge-xl bg-${state ? (state == "A" ? "success" : "danger") : "dark"}`}></i><span class="state-bade-text">{state ? (state == "A" ? "Actif" : "Fermé") : "Indéfini"}</span>
                                </div>
                            },
                            { title: "ID", dataIndex: "id_commune", sorter: true },
                            { title: global._trans('clientsCommune', 'communeName'), dataIndex: "nomCommune", sorter: true },
                            { title: global._trans('common', 'address'), dataIndex: "adresse", sorter: true },
                            { title: global._trans('common', 'nbPeople'), dataIndex: "nombreHabitant", sorter: true },
                            { title: global._trans('clientsCommune', 'insee'), dataIndex: "insee", sorter: true },
                            { title: global._trans('clientsEpic', 'serin'), dataIndex: "serin", sorter: true },
                            {
                                title: '', dataIndex: "id_commune", render: (id_commune, index, commune) => <InlineBottunSvg
                                    bottuns={[
                                        { iconPath: "/media/svg/icons/Costum/Edit.svg", type: "primary", action: '/client/communities/communes/' + id_commune },
                                        { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.handleDeleteCommune(id_commune) },
                                        { iconPath: "/media/svg/icons/Costum/View.svg", type: "primary", action: '/client/communities/communes/view/' + id_commune }
                                    ]}
                                />
                            }
                        ]}
                        data={this.state.listCommunes}
                        style={{ height: 550 }}
                        callBackSort={this.handleSorter}
                        defaultSorter={this.state.filters}
                        IterableProps={{
                            rowSelection: {
                                onChange: this.handleSelectRow,
                            },
                            locale: {
                                emptyText: global._trans('clientsCommune', 'emptyList')
                            },
                            loading: this.state.loadCommunes || this.state.loadingDelete,
                            rowKey: "id_commune",
                            pagination: { total: this.state.total, pageSize: this.state.filters.pageSize, current: parseInt(this.state.filters.page), showSizeChanger: true },
                            onChange: this.handleTableChange
                        }}
                    /> */}
                    <AgGrid
                        rows={this.state.list}
                        columns={this.state.columnDefs}
                        pagination={{total: this.state.total, pageSize: this.state.page_size, current: this.state.page}}
                        onChange={this.handleTableChange}
                        loading={this.state.loading}
                        ref={ref => this.childComponentRef = ref}
                        views_key={"communes"}
                        onViewSaved={this.handleViewSaved}
                    />
                </div>
                </Spin>
                <ImportData show={this.state.showImport} title={global._trans('common', 'titelImport')} sourceFile={this.state.showImport} onHide={() => this.handleImportExcel(false)} />
            </>
        )
    }
}
function mapState(state) {
    const { listCommunes, CommuneErrors, queryCommune } = state.collectivitie
    return { listCommunes, CommuneErrors, queryCommune }
}
const connectedClass = connect(mapState, { fetchCommunes, deleteCommunes, syncCommunesInseeAPI })(ListCommunes)
export { connectedClass as ListCommunes }