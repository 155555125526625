import React, { Component } from 'react'
import { connect } from "react-redux";
import { message, Upload, Tooltip, Button } from 'antd';
import * as actions from "../../../../../modules/Settings/settings.actions";
import { EditableTable, InlineBottunSvg } from '../../../Commons'
import { uploadImage } from '../../../../../modules/Common/common.actions'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_sage/_helpers";
class CommonComponent extends Component {
    constructor(props) {
        super(props)
        this.keyEnum = props.keyName;
        this.state = {
            rows: props[this.keyEnum],
            count: props[this.keyEnum][0] ? props[this.keyEnum][0].value + 1 : 0,
            loadingList: !props.enumsLoaded,
            loadingCreate: false,
            loadingDelete: false
        }

        this.handleAdd = this.handleAdd.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loadingList && Array.isArray(nextProps[this.keyEnum])) {
            this.setState({
                rows: nextProps[this.keyEnum],
                count: nextProps[this.keyEnum][0] ? nextProps[this.keyEnum][0].value + 1 : 0,
                loadingList: false
            })
        }
        if (this.state.loadingCreate) {
            console.log(nextProps)
            this.setState({
                rows: nextProps[this.keyEnum],
                count: this.state.count + 1,
                loadingCreate: false
            })
        }
        if (this.state.loadingDelete) {
            if (nextProps.errors && nextProps.errors.action) {
                message.error(global._trans('settings', 'cantDelete'));
            }
            this.setState({
                rows: nextProps[this.keyEnum],
                count: this.state.count - 1,
                loadingDelete: false
            })
        }
    }
    handleSave(row) {
        let newEnum = { key: this.keyEnum, value: row.label, enemuration: row.value, code: row.code }
        this.props.CreateEnum(newEnum)
        this.setState({
            loadingCreate: true
        });
    }
    handleAdd() {
        const { count, rows } = this.state;
        const newData = {
            value: null,
            label: "",
        };
        this.setState({
            rows: [newData, ...rows],
            count: count + 1,
        });
    }
    handleDelete(key) {
        this.props.DeleteEnum({ enumuration: key, typeEnum: this.keyEnum })
        this.setState({
            loadingDelete: true
        })
    }
    handleUpload(file, row) {
        this.setState({
            loadingCreate: true
        });
        let data = new FormData()
        data.append('file', file.file)
        uploadImage(data)
            .then(({ ok, image }) => {
                if (ok) {
                    this.handleSave({...row, code: image.uid})
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    handleDeleteLogo(row) {
        this.handleSave({...row, code: null})
    }
    render() {
        const { rows } = this.state
        var cols = [{ title: this.props.titleSetting, dataIndex: 'label', editable: true, }]

        if (this.keyEnum === 'nature_juridique' || this.keyEnum === 'effectifs') {
            cols = [...cols, { title: 'Code', dataIndex: 'code', editable: true, }]
        }
        
        if (this.keyEnum === 'groupeList') {
            cols = [...cols, {title: 'Logo', dataIndex: "logo",  render: (logo, row) => (
                <Upload
                    listType="picture"
                    fileList={logo}
                    onRemove={() => this.handleDeleteLogo(row)}
                    customRequest={(info) => this.handleUpload(info, row)}>
                        {(!logo || logo.length === 0) && <Button className="d-flex align-items-center" icon={<SVG src={toAbsoluteUrl('/media/svg/icons/Costum/Upload.svg')}></SVG>}>
                        <span className="ml-2">{global._trans("common", "selectFile")}</span>
                    </Button>}
                </Upload>
            )},
            {
                title: 'Action', dataIndex: 'value', render: (value, row) => this.keyEnum != 'competence_dechet' && <InlineBottunSvg
                bottuns={[{ iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.handleDelete(value) }]}
            />,
            width: 80
            }]
        } else {
            cols = [...cols, 
                {
                    title: 'Action', dataIndex: 'value', render: value => this.keyEnum != 'competence_dechet' && <InlineBottunSvg
                        bottuns={[{ iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.handleDelete(value) }]}
                    />,
                    width: 80
                }
            ]
        }
        return (
            <div className="">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="font-weight-bold">{this.props.titleSetting}</h5>
                    <div className="card-toolbar">
                        {!['competence_dechet', 'fileCategory'].includes(this.keyEnum) && <InlineBottunSvg
                            bottuns={[{ iconPath: "/media/svg/icons/Costum/Add.svg", type: "primary", action: this.handleAdd }]}
                        />}
                        <br />
                    </div>
                </div>
                <div>
                    {['competence_dechet', 'fileCategory'].includes(this.keyEnum) && <small>Veuillez contacter l'administrateur pour ajouter des éléments à ce tableau.</small>}
                    {
                        <EditableTable
                            dataSource={rows}
                            columns={cols}
                            IterableProps={{
                                pagination: false,
                                scroll: {
                                    y: 585,
                                },
                                locale: {
                                    emptyText: global._trans('settings', 'emptyListChoix')
                                },
                                loading: this.state.loadingList || this.state.loadingCreate || this.state.loadingDelete,
                                rowKey: "value"
                            }}
                            handleSave={this.handleSave}
                        />
                    }
                </div>
            </div>
        )
    }
}
function mapState(state) {
    const { errors, requestFailed, enumsLoaded } = state.enums
    return { errors, requestFailed, enumsLoaded }
}
const connectedClass = connect(mapState, actions)(CommonComponent)
export { connectedClass as CommonComponent }