import React, { Component } from 'react'
import { connect } from "react-redux";
import { Switch, Route, Redirect } from 'react-router-dom'
import { fetchUserPrem, resetState } from '../../../../../modules/User/user.actions'
import { CardInfo, PersonalInfo, SharedSites, UsersAccorder } from './utils'
import { Spin } from 'antd'
class ViewUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            user: null
        }
        props.fetchUserPrem(props.match.params.idUser)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading && (nextProps.userPrem || nextProps.usersErrors)) {
            this.setState({
                loading: false,
                user: nextProps.userPrem
            })
        }
        if (this.state.user !== nextProps.userPrem) {
            this.setState({
                user: nextProps.userPrem
            })
        }
    }
    componentWillUnmount() {
        this.props.resetState([{ name: "userPrem", value: null }])
    }
    render() {
        return (
            <div className="d-flex flex-row">
                <Spin spinning={this.state.loading} wrapperClassName="position-sticky h-max-content top-130">
                    <CardInfo user={this.state.user} query={this.props.currentQuery} idUser={this.props.match.params.idUser} />
                </Spin>
                <Spin spinning={this.state.loading} wrapperClassName="flex-row-fluid ml-lg-8">
                    <div className="card card-custom card-stretch">
                        <Switch>
                            <Redirect
                                from="/users/premium/view/:idUser"
                                exact={true}
                                to="/users/premium/view/:idUser/info"
                            />
                            <Route path="/users/premium/view/:idUser/info" component={PersonalInfo} />
                            <Route path="/users/premium/view/:idUser/shared-sites" >
                                {<SharedSites idUser={this.props.match.params.idUser} />}
                            </Route>
                            <Route path="/users/premium/view/:idUser/user-access" >
                                {<UsersAccorder idUser={this.props.match.params.idUser} />}
                            </Route>
                        </Switch>
                    </div>
                </Spin>
            </div>
        )
    }
}
function mapState(state) {
    const { usersErrors, userPrem, currentQuery } = state.users
    return { usersErrors, userPrem, currentQuery }
}
const connectedClass = connect(mapState, { fetchUserPrem, resetState })(ViewUser)
export { connectedClass as ViewUser }