import { CrudService } from "../../../services";
import { SettingsUrls } from './settings.constants'
export function createEnum(Enum) {
    return CrudService.Post(SettingsUrls.CREATE_URL, Enum);
}
export function fetchEnums() {
    return CrudService.Get(SettingsUrls.FETCH_URL);
}
export function deleteEnum(Enum) {
    return CrudService.Delete(SettingsUrls.DELETE_URL, Enum);
}
export function fetchDepartements(QueryParams) {
    return CrudService.Get(SettingsUrls.FETCH_DEPARTEMENT_URL,{},QueryParams);
}
export function handleDepartement(Departement) {
    return CrudService.Post(SettingsUrls.ADD_DEPARTEMENT_URL,Departement);
}
export function deleteDepartement(idDepartement) {
    return CrudService.Delete(SettingsUrls.DELETE_DEPARTEMENT_URL,{idDep:idDepartement});
}
export function fetchRegions(QueryParams) {
    return CrudService.Get(SettingsUrls.FETCH_REGION_URL,{},QueryParams);
}
export function handleRegion(Region) {
    return CrudService.Post(SettingsUrls.ADD_REGION_URL,Region);
}
export function deleteRegion(idRegion) {
    return CrudService.Delete(SettingsUrls.DELETE_REGION_URL,{idReg:idRegion});
}
