import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import * as Apis from '../modules/Auth/auth.crud';
import { actions } from '../modules/Auth/auth.reducer';
export const LoadingApp = () => {
    const [called, setCalled] = useState(false)
    const dispatch = useDispatch()
    if (!called) {
        Apis.getUserByToken()
            .then(({ user, ok }) => {
                setCalled(true)
                if (ok) {
                    dispatch(actions.fulfillUser(user))
                } else {
                    dispatch(actions.login(null))
                }
            })
            .catch(err => {
                setCalled(true)
                dispatch(actions.login(null))
            })
    }

    return (
        <div className="position-fixed h-100 w-100 d-flex justify-content-center flex-column align-items-center">
            <Spinner animation="border" variant="primary" />
            <strong className="font-size-h4 pt-4">Chargement...</strong>
        </div>
    )
}