import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ListSyndicats } from './ListSyndicats'
import { CreateSyndicat } from './CreateSyndicat'
import { ViewSyndicat } from './ViewSyndicat'
class SyndicatPage extends Component {
    render() {
        return (
            <Switch>
                <Route
                    path="/client/communities/syndicat/view/:idsyndicat"
                    component={ViewSyndicat}
                />
                <Route
                    path="/client/communities/syndicat/:idCreate"
                    component={CreateSyndicat}
                />
                <Route
                    path="/client/communities/syndicat"
                    component={ListSyndicats}
                />

            </Switch>
        )
    }
}
export { SyndicatPage }