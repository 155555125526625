import React, { Component } from 'react'
import { connect } from "react-redux";
import { Switch, Route, Redirect } from 'react-router-dom'
import { fetchGestionaire, resetState } from '../../../modules/Gestionaires/gestionaire.actions'
import { CardInfo, InfoPersonal, SitesRatached } from './utils'
class ViewGestionaire extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            gestionaire: null
        }
        props.fetchGestionaire(props.match.params.idGestionaire)
    }
    componentWillUnmount() {
        this.props.resetState([{ name: "gestionaire", value: null }])
    }
    render() {
        return (
            <div className="d-flex flex-row">
                <CardInfo idGestionaire={this.props.match.params.idGestionaire} />
                <Switch>
                    <Redirect
                        from="/manager/view/:idGestionaire"
                        exact={true}
                        to="/manager/view/:idGestionaire/info"
                    />
                    <Route path="/manager/view/:idGestionaire/info" component={InfoPersonal} />
                    <Route path="/manager/view/:idGestionaire/attached" >
                        <SitesRatached idGestionaire={this.props.match.params.idGestionaire} />
                    </Route>
                </Switch>
            </div>
        )
    }
}
const connectedClass = connect(null, { fetchGestionaire, resetState })(ViewGestionaire)
export { connectedClass as ViewGestionaire }