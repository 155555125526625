import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { SageTable } from './SageTable'
import { HeaderTab } from './HeaderTab'
import { fetchEpics, fetchSyndicats, fetchCommunes } from "../../../modules/Collectivities/collectivitie.actions";
import { fetchSocietes } from "../../../modules/Societes/societe.actions";
class PersonesSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTab: this.props.currentTab ? this.props.currentTab : 0,
            selectedRow: [
                { rows: [], nameList: 'listSyndicats' },
                { rows: [], nameList: 'listEpics' },
                { rows: [], nameList: 'listCommunes' },
                { rows: [], nameList: 'listSocietes' }

            ],
            searchValue: [
                { nmFnc: 'fetchSyndicats', name: 'nomCourt', search: '' },
                { nmFnc: 'fetchEpics', name: 'nomEpic', search: '' },
                { nmFnc: 'fetchCommunes', name: 'nomCommune', search: '' },
                { nmFnc: 'fetchSocietes', name: 'groupe', search: '' }],
            listsData: [
                {},
                {},
                {},
                {}
            ],
            loading0: true,
            loading1: true,
            loading2: true,
            loading3: true
        }
        this.columes = [
            [{ title: "Nom Court", dataIndex: "nomCourt", fixed: 'left', ellipsis: true, },
            { title: "Dénomination légale", dataIndex: "denominationLegale", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
            { title: "Siren", dataIndex: "serin", ellipsis: true, }],

            [{ title: "ID", dataIndex: "id_epic", fixed: 'left', ellipsis: true, },
            { title: "Nom EPIC", dataIndex: "nomEpic", ellipsis: true, },
            { title: "Siren", dataIndex: "serin", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, }],

            [{ title: "ID", dataIndex: "id_commune", fixed: 'left', ellipsis: true, },
            { title: "Nom Commune", dataIndex: "nomCommune", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
            { title: "Nombre habitants", dataIndex: "nombreHabitant", ellipsis: true, }],

            [{ title: "Groupe", dataIndex: "groupe", fixed: 'left', ellipsis: true, },
            { title: "Dénomination", dataIndex: "denomination", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
            { title: "Nombre salarié", dataIndex: "nombreSalarie", ellipsis: true, }]
        ]
        this.keysTable = [
            'id_syndicat',
            'id_epic',
            'id_commune',
            'id_societe_exploitant'
        ]
        this.typeClient = [
            { typePersonMoral: 'Syndicat', name: 'Nom Court', dataIndex: 'nomCourt' },
            { typePersonMoral: 'Epic', name: 'Nom EPIC', dataIndex: 'nomEpic' },
            { typePersonMoral: 'Commune', name: 'Nom Commune', dataIndex: 'nomCommune' },
            { typePersonMoral: 'Societe', name: 'Groupe', dataIndex: 'groupe' }
        ]
        props.fetchEpics({ pageSize: 100 })
        props.fetchCommunes({ pageSize: 100 })
        props.fetchSyndicats({ pageSize: 100 })
        props.fetchSocietes({ pageSize: 100 })
        this.handleSwitchTabs = this.handleSwitchTabs.bind(this)
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.validSelect = this.validSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading0 && nextProps.listSyndicats.data) {
            let newData = this.state.listsData
            newData[0] = nextProps.listSyndicats
            this.setState({
                loading0: false,
                listsData: newData
            })
        }
        if (this.state.loading1 && nextProps.listEpics.data) {
            let newData = this.state.listsData
            newData[1] = nextProps.listEpics
            this.setState({
                loading1: false,
                listsData: newData
            })
        }
        if (this.state.loading2 && nextProps.listCommunes.data) {
            let newData = this.state.listsData
            newData[2] = nextProps.listCommunes
            this.setState({
                loading2: false,
                listsData: newData
            })
        }
        if (this.state.loading3 && nextProps.listSocietes.data) {
            let newData = this.state.listsData
            newData[3] = nextProps.listSocietes
            this.setState({
                loading3: false,
                listsData: newData
            })
        }
    }
    handleSwitchTabs(keyItem) {
        if (this.props.blocked && this.props.blocked.indexOf(keyItem) !== -1) {
            return
        }
        this.setState({
            currentTab: keyItem
        })
    }
    handleSelectRow(selectedRowKeys) {
        let rowsPrev = this.state.selectedRow
        rowsPrev[this.state.currentTab].rows = selectedRowKeys
        this.setState({
            selectedRow: rowsPrev
        })
    }
    handleSearch(e) {
        let prevSearchs = this.state.searchValue
        prevSearchs[this.state.currentTab].search = e.target.value;
        console.log('Yo la street', [prevSearchs[this.state.currentTab].name], e.target.value)
        this.props[prevSearchs[this.state.currentTab].nmFnc]({ pageSize: 100, address: e.target.value, [prevSearchs[this.state.currentTab].name]: e.target.value })
        this.setState({
            searchValue: prevSearchs,
            ['loading' + this.state.currentTab]: true
        })
    }
    validSelect() {
        let elem = {}
        let dataReturn = []
        this.state.selectedRow.map((rows, key) => {
            rows.rows.map(row => {
                elem = this.props[this.state.selectedRow[key].nameList].data.find(obj => obj[this.keysTable[key]] == row)
                dataReturn.push({ [this.typeClient[key].dataIndex]: elem[[this.typeClient[key].dataIndex]], [this.keysTable[key]]: row, id_collectivite: elem.id_collectivite, id_person: elem[this.keysTable[key]], adresse: elem.adresse, fonctionPerson: null, ...this.typeClient[key] })
            })
        })
        this.props.validSelection(dataReturn)
    }
    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} aria-labelledby="example-modal-sizes-title-lg">
                <HeaderTab items={[global._trans("client", "syndicat"), global._trans("client", "epic"), global._trans("client", "commune"), global._trans("client", "societe")]} switchItem={this.handleSwitchTabs} active={this.state.currentTab} />
                <div className="px-5 pb-5 pt-2">
                    <Form.Control type="text" name="searchvalue" value={this.state.searchValue[this.state.currentTab].search} placeholder="Chercher par nom ou adresse" onChange={this.handleSearch} />
                    <SageTable
                        columns={this.columes[this.state.currentTab]}
                        data={this.state.listsData[this.state.currentTab].data}
                        style={{ height: 550 }}
                        IterableProps={{
                            rowSelection: {
                                selectedRowKeys: this.state.selectedRow[this.state.currentTab].rows,
                                onChange: this.handleSelectRow,
                                type: 'radio'
                            },
                            emptyText: global._trans('common', 'emptyData'),
                            loading: this.state['loading' + this.state.currentTab],
                            rowKey: this.keysTable[this.state.currentTab],
                            pagination: true
                        }}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="primary" onClick={this.validSelect}>{global._trans('common', 'validSelect')}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
function mapState(state) {
    const { listEpics, listCommunes, listSyndicats } = state.collectivitie
    const { listSocietes } = state.societe
    return { listEpics, listCommunes, listSyndicats, listSocietes }
}
const connectedClass = connect(mapState, { fetchEpics, fetchSyndicats, fetchCommunes, fetchSocietes })(PersonesSelector)
export { connectedClass as PersonesSelector }