import React from 'react'
import { Checkbox } from 'antd';
import { ListGroup } from "react-bootstrap";
export const ColumnsISDND = (props) => {
    return (
        <div className="row mx-0">
            <ListGroup.Item>
                <Checkbox name="capaciteNominale" checked={props.checkedColumns.capaciteNominale} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'capaciteNominale')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="capaciteRestante" checked={props.checkedColumns.capaciteRestante} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'capaciteRestante')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="capaciteReglementaire" checked={props.checkedColumns.capaciteReglementaire} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'capaciteReglementaire')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="projetExtension" checked={props.checkedColumns.projetExtension} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'projetExtension')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="dateExtension" checked={props.checkedColumns.dateExtension} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'dateExtension')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="dateOuverture" checked={props.checkedColumns.dateOuverture} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'dateOuverture')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="dateFermeture" checked={props.checkedColumns.dateFermeture} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'dateFermeture')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="dateFermeturePrev" checked={props.checkedColumns.dateFermeturePrev} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'dateFermeturePrev')}</Checkbox>
            </ListGroup.Item>
        </div>
    )
}