import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Form, Col, Button, Card, ListGroup } from "react-bootstrap";
import { CostumAlert, PersoneSelector, PhonePicker } from '../../../../Commons';
import { Spin } from 'antd'
import { updateUserPrem } from '../../../../../../modules/User/user.actions'
class PersonalInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props.userPrem,
            loading: !props.userPrem,
            loadingUpdate: false,
            showSelect: false,
            alert: false,
            errors: null
        }
        this.handleChange = this.handleChange.bind(this)
        this.hideSelectClient = this.hideSelectClient.bind(this)
        this.setSelection = this.setSelection.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading && (nextProps.userPrem || nextProps.usersErrors)) {
            this.setState({
                ...nextProps.userPrem,
                loading: false
            })
        }
        if (this.state.loadingUpdate) {
            this.setState({
                loadingUpdate: false,
                errors: nextProps.usersErrors,
                alert: true
            })
        }
    }
    handleChange(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleChangeName(name, value) {
        this.setState({
            [name]: value
        })
    }
    hideSelectClient() {
        let prevShow = this.state.showSelect
        this.setState({
            showSelect: !prevShow
        })
    }
    setSelection(client) {
        this.setState({
            showSelect: false,
            client: client
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        if (!this.state.loadingUpdate) {
            let user = {
                id_user: this.state.id_user,
                nom: this.state.nom,
                prenom: this.state.prenom,
                email: this.state.email,
                nbAccess: this.state.nbAccess,
                init_password: this.state.init_password,
                username: this.state.username,
                phone: this.state.phone,
                client: this.state.client ? this.state.client : null
            }
            this.props.updateUserPrem(user)
            this.setState({
                loadingUpdate: true,
                alert: false
            })
        }
    }
    showErrors() {
        if (this.state.alert) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                return <CostumAlert type='success' errors="Utilisateur bien modfier" />
            }
        }
    }
    render() {
        return (
            <>
                <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">{global._trans("common", "personalInfo")}</h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">{global._trans("users", "bodyInfoUser")}</span>
                    </div>
                </div>
                <Spin spinning={this.state.loadingUpdate}>
                    <div className="form">
                        <div className="card-body">
                            {this.showErrors()}
                            <Form method="post" onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'firstName')}</Form.Label>
                                        <Form.Control type="text" name="nom" className="form-control-lg form-control-solid" onChange={this.handleChange} value={this.state.nom} placeholder={global._trans('common', 'firstName')} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'lastName')}</Form.Label>
                                        <Form.Control type="text" name="prenom" value={this.state.prenom} placeholder={global._trans('common', 'lastName')} className="form-control-lg form-control-solid" onChange={this.handleChange} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'userName')}</Form.Label>
                                        <Form.Control type="text" name="username" value={this.state.username} placeholder={global._trans('common', 'userName')} className="form-control-lg form-control-solid" onChange={this.handleChange} />
                                    </Form.Group>
                                    {this.state.init_password && <Form.Group as={Col}>
                                        <Form.Label>{global._trans('users', 'initPassword')}</Form.Label>
                                        <Form.Control type="text" name="init_password" value={this.state.init_password} placeholder={global._trans('common', 'initPassword')} className="form-control-lg form-control-solid" onChange={this.handleChange} />
                                    </Form.Group>}
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'email')}</Form.Label>
                                        <Form.Control type="email" name="email" value={this.state.email} placeholder={global._trans('common', 'email')} className="form-control-lg form-control-solid" onChange={this.handleChange} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('users', 'nbSession')}*</Form.Label>
                                        <Form.Control type="number" name="nbAccess" value={this.state.nbAccess} placeholder={global._trans('users', 'nbSession')} className="form-control-lg form-control-solid" onChange={this.handleChange} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'phone')}*</Form.Label>
                                        <PhonePicker value={this.state.phone} isLg={true} onChange={(value) => this.handleChangeName("phone", value)} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('users', 'moralPerson')}*</Form.Label>
                                        {this.state.client ? <Card>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item><strong>{this.state.client.name} : </strong>{'  ' + this.state.client[this.state.client.dataIndex]}</ListGroup.Item>
                                                <ListGroup.Item><strong>Adresse: </strong>{'  ' + this.state.client.adresse}</ListGroup.Item>
                                                <ListGroup.Item><Card.Link onClick={this.hideSelectClient}><strong>Changer le client rattacher</strong></Card.Link></ListGroup.Item>
                                            </ListGroup>
                                        </Card> : <Button variant="outline-secondary" size="lg" block onClick={this.hideSelectClient}>{global._trans('users', 'selectPerson')}</Button>}
                                    </Form.Group>
                                </Form.Row>
                                <div className="d-flex justify-content-end">
                                    <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{global._trans('common', 'saveChanges')}</Button>
                                </div>
                            </Form>
                        </div>
                        {this.state.showSelect && <PersoneSelector show={this.state.showSelect} validSelection={this.setSelection} onHide={this.hideSelectClient} />}
                    </div>
                </Spin>
            </>
        )
    }
}
function mapState(state) {
    const { usersErrors, userPrem } = state.users
    return { usersErrors, userPrem }
}
const connectedClass = connect(mapState, { updateUserPrem })(PersonalInfo)
export { connectedClass as PersonalInfo }