import React, { Component } from 'react';
import { Navbar, FormControl, Button, DropdownButton, Dropdown } from 'react-bootstrap'
import { RegionSearch, DepartementSearch } from '../Commons';
const queryString = require('query-string');
export class FilterPicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            catg: 'all',
            mode: 'all',
            reg: null,
            dep: null,
            ...queryString.parse(props.history.location.search)
        }
        this.handleStaticName = this.handleStaticName.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleResetSearch = this.handleResetSearch.bind(this)
    }
    handleFilterName(name, type) {
        this.setState({
            [name]: type
        })
    }
    handleStaticName(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleSearch(e) {
        e.preventDefault()
        this.props.history.push({
            search: queryString.stringify(this.state)
        })
        this.props.handleSearch(this.state)
    }
    handleResetSearch() {
        this.setState({
            catg: 'all',
            mode: 'all',
            reg: null,
            dep: null
        })
        this.props.history.push({
            search: queryString.stringify({})
        })
        this.props.handleSearch({})
    }
    render() {
        return (
            <form onSubmit={this.handleSearch}>
                <Navbar className="bg-white rounded py-4" style={{ zIndex: 401 }}>
                    <Navbar.Brand >{global._trans("sites", "categorieSite")}:</Navbar.Brand>
                    <DropdownButton id="dropdown-item-button" variant="white" className="border border-2 rounded" title={this.state.catg === "all" ? "Tout les catégorie" : this.state.catg}>
                        <Dropdown.Item onClick={() => this.handleFilterName('catg', 'all')}>Tout les catégorie</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleFilterName('catg', 'UVE')}>UVE</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleFilterName('catg', 'TRI')}>TRI</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleFilterName('catg', 'TMB')}>TMB</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleFilterName('catg', 'ISDND')}>ISDND</Dropdown.Item>
                    </DropdownButton>
                    <Navbar.Brand className="pl-3">{global._trans("sites", "modeGestion")}:</Navbar.Brand>
                    <DropdownButton id="dropdown-item-button" variant="white" className="border border-2 rounded" title={this.state.mode === "all" ? "Tout les modes" : this.state.mode}>
                        <Dropdown.Item onClick={() => this.handleFilterName('mode', 'all')}>Tout les modes</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleFilterName('mode', 'Gestion privée')}>Gestion privée</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleFilterName('mode', 'Prestation de service')}>Prestation de service</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleFilterName('mode', 'Regie')}>Regie</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleFilterName('mode', 'DSP')}>DSP</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleFilterName('mode', 'MPS')}>MPS</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleFilterName('mode', 'MGP')}>MGP</Dropdown.Item>
                    </DropdownButton>
                    <Navbar.Brand className="pl-3">Région:</Navbar.Brand>
                    <RegionSearch placeholder={global._trans('common', 'regionSiege')} onChange={(selected) => this.handleFilterName('reg', selected)} value={this.state.reg}/>
                    <Navbar.Brand className="pl-3">Département:</Navbar.Brand>
                    <DepartementSearch placeholder={global._trans('common', 'departementSiege')} onChange={(selected) => this.handleFilterName('dep', selected)} value={this.state.dep}/>
                    {this.props.history.location.search && <Button variant="light" className="ml-3" onClick={this.handleResetSearch}>{global._trans("common", "reset")}</Button>}
                    <Button type="submit" className="ml-3">{global._trans("common", "search")}</Button>
                </Navbar>
            </form>
        )
    }
}