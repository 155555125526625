import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ListAdmins } from './ListAdmins'
class AdminsPage extends Component {
    render() {
        return (
            <div className="card card-custom">
                <Switch>
                    <Route
                        path="/admin"
                        component={ListAdmins}
                    />
                </Switch>
            </div>
        )
    }
}
export { AdminsPage }