import React from 'react'
import { Checkbox } from 'antd';
import { ListGroup } from "react-bootstrap";
export const ColumnsTMB = (props) => {
    return (
        <div className="row mx-0">
            <ListGroup.Item>
                <Checkbox name="quantiteRefus" checked={props.checkedColumns.quantiteRefus} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'quantiteRefus')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="CSRProduit" checked={props.checkedColumns.CSRProduit} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'CSRProduit')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="envoiPreparation" checked={props.checkedColumns.envoiPreparation} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'envoiPreparation')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="tonnageAnnuel" checked={props.checkedColumns.tonnageAnnuel} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'tonnageAnnuel')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="capaciteNominal" checked={props.checkedColumns.capaciteNominal} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'capaciteNominal')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="dernierConstruct" checked={props.checkedColumns.dernierConstruct} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'dernierConstruct')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="typeInstallation" checked={props.checkedColumns.typeInstallation} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'typeInstallation')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="typeDechetAccepter" checked={props.checkedColumns.typeDechetAccepter} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'typeDechetAccepter')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="technologie" checked={props.checkedColumns.technologie} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'technologie')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="valorisationEnergitique" checked={props.checkedColumns.valorisationEnergitique} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'valorisationEnergitique')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="autreActivite" checked={props.checkedColumns.autreActivite} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'autreActivite')}</Checkbox>
            </ListGroup.Item>
        </div>
    )
}