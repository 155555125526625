import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { Form, Col, Card, ListGroup, Button } from "react-bootstrap";
import { notification, DatePicker, Spin, Checkbox } from 'antd';
import { CostumAlert, InlineBottunSvg, UserSelector } from '../Commons'
import { createShare } from "../../../modules/Shares/shares.actions";
import { ColumnsTMB, ColumnsTRI, ColumnsUVE, ColumnsISDND, GeneralColumns, DataSelector, InformationClient, SocieteInformation, GEDFiles, TechColumns, Contracts } from './utils'
import moment from 'moment'
const columns = require('../Commons/columns.json')

const TabsMenu = ({ items, onSelectItem, onAddItem, activeItemIndex, onDeleteItem, itemText, disabled }) => (<div className="mt-10 mb-10">
    <div className="header" style={{ background: 'transparent', height: '0' }}>
        <div className="header-menu header-menu-mobile header-menu-layout-default">
            <ul className='menu-nav'>
                {items.map((line, index) => <li onClick={() => onSelectItem(index)} className={`menu-item menu-item-rel mx-1 ${index == activeItemIndex ? 'menu-item-active' : ''}`}>
                    <a className={`menu-link`}>
                        <span className="menu-text">{itemText(line)}</span> &nbsp;&nbsp; {!disabled && <span style={{ color: 'red' }} onClick={() => onDeleteItem(index)}>X</span>}
                    </a>
                </li>)}
                <li className={`menu-item menu-item-rel mx-1`}>
                    <button className="btn btn-primary" type="button" onClick={onAddItem}>+</button>
                </li>
            </ul>
        </div>
    </div>
</div >);

class CreateShare extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateDebut: null,
            dateFin: null,
            userPrem: [],
            dataShare: [],
            showForSite: false,
            showForUser: false,
            columns: {},
            loading: false,
            loadingEdit: false,
            alerts: false,
            errors: null,
            columnsStructure: {
                UVE: ['nombreFours', 'capacite', 'nombreChaudiere', 'capaciteMaxAnu', 'videFour', 'debitEau', 'installationComplementair', 'voiTraiFemuee', 'traitementNOX', 'miseEnService', 'typeFoursChaudiere', 'reseauChaleur', 'typeTerboalternateur', 'reactif', 'equipeProcessTF', 'typeDechetRecus', 'traitementFumee', 'tonnageReglementaireAp', 'constructeurInstallation', 'performenceEnergetique', 'performenceEnergetiqueDate', 'performenceEnergetiqueR1', 'performenceEnergetiqueR1Date', 'cycleVapeur', 'terboalternateur', 'venteProduction'],
                TRI: ['capaciteHoraire', 'capaciteNominale', 'capaciteReglementaire', 'dernierConstructeur', 'dateExtension', 'extension', 'miseEnService'],
                TMB: ['quantiteRefus', 'CSRProduit', 'envoiPreparation', 'tonnageAnnuel', 'capaciteNominal', 'dernierConstruct', 'typeInstallation', 'typeDechetAccepter', 'technologie', 'valorisationEnergitique', 'autreActivite'],
                ISDND: ['capaciteNominale', 'capaciteRestante', 'capaciteReglementaire', 'projetExtension', 'dateExtension', 'dateOuverture', 'dateFermeture', 'dateFermeturePrev'],
                generalInfo: ['denomination', 'modeGestion', 'categorieSite', 'adresse', 'siteIntrnet', 'telephoneStandrad', 'anneeCreation', 'perdiocitRelance'],
                Client: ['client_nomEpic', 'client_nom_court', 'client_denomination', 'client_usual_denomination', 'client_serin', 'client_siteInternet', 'client_telephoneStandard', 'client_nature_juridique', 'client_nomCourt', 'client_denominationLegale', 'client_sinoe', 'client_amobe', 'client_nomCommune', 'client_insee', 'client_adresse', 'client_city', 'client_country', 'client_postcode', 'client_region_siege', 'client_departement_siege', 'client_nombreHabitant'],
                Company: ['company_groupe', 'company_denomination', 'company_usual_denomination', 'company_serin', 'company_sinoe', 'company_nature_juridique', 'company_codeape', 'company_siteInternet', 'company_telephoneStandard', 'company_effectifs', 'company_adresse', 'company_city', 'company_country', 'company_postcode', 'company_email']
            }
        }
        this.handleHideModal = this.handleHideModal.bind(this)
        this.setSelection = this.setSelection.bind(this)
        this.handleName = this.handleName.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.checkColumnType = this.checkColumnType.bind(this)
        this.handleMultColumn = this.handleMultColumn.bind(this)
        this.handleDeleteItem = this.handleDeleteItem.bind(this)
        this.selectSingleRow = this.selectSingleRow.bind(this)

        this.handleCheckboxChanged = this.handleCheckboxChanged.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            this.setState({
                loading: false,
                alerts: true,
                errors: nextProps.shareErrors
            })
        }
    }
    handleName(name, value) {
        this.setState({
            [name]: value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        if (this.state.userPrem && this.state.dataShare) {
            let share = {
                dateDebut: this.state.dateDebut,
                dateFin: this.state.dateFin,
                userPrem: this.state.userPrem.map(user => user.id_user),
                dataShare: this.state.dataShare.map(data => {
                    return {
                        dataShare: data[data.dataIndex],
                        typeShare: data.typeShare,
                        columns: this.state.dataShare[0].columns,
                        files: this.state.dataShare[0].files,
                        contracts: this.state.dataShare[0].contracts,
                    }
                })
            }
            // return console.log(share);
            this.setState({
                loading: true
            })
            this.props.createShare(share)
        }
    }
    getColumns(indexItem) {
        if (this.state.dataShare[indexItem] && this.state.dataShare[indexItem].categorieSite) {
            switch (this.state.dataShare[indexItem].categorieSite) {
                case "UVE": return <ColumnsUVE checkedColumns={this.state.dataShare[indexItem].columns} handleChange={(e) => this.handleMultColumn(e, null, null, indexItem)} />
                case "TRI": return <ColumnsTRI checkedColumns={this.state.dataShare[indexItem].columns} handleChange={(e) => this.handleMultColumn(e, null, null, indexItem)} />
                case "TMB": return <ColumnsTMB checkedColumns={this.state.dataShare[indexItem].columns} handleChange={(e) => this.handleMultColumn(e, null, null, indexItem)} />
                case "ISDND": return <ColumnsISDND checkedColumns={this.state.dataShare[indexItem].columns} handleChange={(e) => this.handleMultColumn(e, null, null, indexItem)} />
            }
        }
    }
    handleMultColumn(type, name, checked, indexItem) {
        if (this.state.dataShare[indexItem]) {
            let prevColumnItem = this.state.dataShare
            if (prevColumnItem[indexItem].typeShare === "Site") {
                const { name, checked } = type.target
                prevColumnItem[indexItem].columns[name] = checked
            } else {
                prevColumnItem[indexItem].columns[type][name] = checked
            }
            this.setState({
                columns: prevColumnItem
            })
        }
    }
    handleHideModal(modelType) {
        let prevState = this.state[modelType]
        this.setState({
            [modelType]: !prevState
        })
    }
    setSelection(rowsData, type) {
        // console.log({ rowsData, type })
        this.setState({
            [type[0]]: [...this.state[type[0]], ...rowsData.map((row) => ({ ...row, columns: { UVE: { infos: {}, lines: {}, valorisations: {} }, TRI: {}, TMB: {}, ISDND: {}, generalInfo: {}, Client: {}, Company: {} }, files: this.props.enums.fileCategory.reduce((acc, category, index) => ({ ...acc, [category]: false }), {}), contracts: columns.contracts.reduce((acc, key, index) => ({ ...acc, [key]: false }), {}) }))],
            [type[1]]: false,
            ...(type[0] === "dataShare" ? {
                columns: { UVE: { infos: {}, lines: {}, valorisations: {} }, TRI: {}, TMB: {}, ISDND: {}, generalInfo: {}, Client: {}, Company: {} }, files: this.props.enums.fileCategory.reduce((acc, category, index) => ({ ...acc, [category]: false }), {}), contracts: columns.contracts.reduce((acc, key, index) => ({ ...acc, [key]: false }), {})
            } : null)
        })
    }
    showErrors() {
        if (this.state.alerts) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                notification.success({
                    message: global._trans('shares', 'titleMessageCreate'),
                    description: global._trans('shares', 'bodyMessageCreate'),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}

                })
            }
            this.setState({
                userPrem: [],
                dataShare: null,
                dateDebut: null,
                dateFin: null,
                showForSite: false,
                showForUser: false,
                loading: false,
                dataShare: [],
                loadingEdit: false,
                alerts: false,
                errors: null
            })
        }
    }
    getDataShared(itemIndex) {
        switch (this.state.dataShare[itemIndex].typeShare) {
            case 'Site': return <>
                <ListGroup.Item>
                    <div className="d-flex align-items-center justify-content-between">
                        <span>{global._trans("shares", `yourChoice${this.state.dataShare[itemIndex].typeShare}`)} : <strong>{this.state.dataShare.map((site) => site.denomination).join(', ')}</strong></span>
                        <div className="d-flex align-items-center">
                            <InlineBottunSvg
                                bottuns={[
                                    { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.deleteItemShare(itemIndex, 'dataShare') }
                                ]}
                            />
                            <Button variant="primary" size="sm" className="ml-2" onClick={() => this.selectSingleField(itemIndex)}>Tout sélectionner</Button>
                        </div>

                    </div>
                </ListGroup.Item>
                <div className="d-flex">
                    {/* <ListGroup.Item><strong>{global._trans('sites', 'categorieSite')} : </strong>{'  ' + this.state.dataShare[itemIndex].categorieSite}</ListGroup.Item> */}
                    {/* <ListGroup.Item><strong>{global._trans('sites', 'modeGestion')}: </strong>{'  ' + this.state.dataShare[itemIndex].modeGestion}</ListGroup.Item> */}
                </div>
                {/* <ListGroup.Item><strong>{global._trans('common', 'address')}: </strong>{'  ' + this.state.dataShare[itemIndex].adresse}</ListGroup.Item> */}
            </>
            case 'Departement':
            case 'Region': return <>
                <ListGroup.Item>
                    <div className="d-flex align-items-center justify-content-between">
                        <span>{global._trans("shares", `yourChoice${this.state.dataShare[itemIndex].typeShare}`)} : <strong> {this.state.dataShare.map((item) => item.label).join(', ')}</strong> </span>
                        <div className="d-flex align-items-center">
                            <InlineBottunSvg
                                bottuns={[
                                    { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.deleteItemShare(itemIndex, 'dataShare') }
                                ]}
                            />
                            <Button variant="primary" size="sm" className="ml-2" onClick={() => this.selectSingleField(itemIndex)}>Tout sélectionner</Button>
                        </div>

                    </div>
                </ListGroup.Item>
            </>
        }
    }
    deleteItemShare = (index, type) => {
        if (window.confirm('Etes vous sur ?')) return this.setState({ dataShare: [] });
        // let listsData = this.state[type]
        // listsData.splice(index, 1);
        // this.setState({
        //     [type]: listsData
        // })
    }
    checkColumnType(e, indexItem) {
        const { name, checked } = e.target
        let prevClmn = this.state.dataShare
        prevClmn[indexItem].columns[name] = checked ? {} : null
        this.setState({
            dataShare: prevClmn
        })
    }
    selectAllFields = () => {

        var filtred_columns;

        if (this.state.dataShare[0].typeShare == "Site") {
            let categories = this.state.dataShare.map(site => (site.categorieSite))
            filtred_columns = Object.keys(columns).filter((column) => ['generalInfo', 'Client', 'Company'].concat(categories).includes(column)).reduce((acc, column, index) => ({
                ...acc,
                [column]: columns[column]
            }), {});
        } else {
            filtred_columns = Object.keys(columns).filter(column => column !== 'contracts').reduce((acc, column, index) => ({
                ...acc,
                [column]: columns[column]
            }), {});
        }

        let element = this.state.dataShare[0];

        let selected = Object.keys(element.files).reduce((acc, key, index) => acc && element.files[key], true);
        element.files = this.props.enums.fileCategory.reduce((acc, { code }, index) => ({ ...acc, [code]: !selected }), {});

        selected = Object.keys(element.contracts).reduce((acc, key, index) => acc && element.contracts[key], true);
        element.contracts = columns.contracts.reduce((acc, key, index) => ({ ...acc, [key]: !selected }), {});

        Object.keys(filtred_columns).forEach(column_key => {
            Object.keys(filtred_columns[column_key]).forEach(key => {
                let type = filtred_columns[column_key][key]
                if (typeof type == "string") {
                    element.columns[column_key][type] = !element.columns[column_key][type]
                }
                else if (typeof type == "object") {
                    Object.values(type).forEach(value => {
                        element.columns[column_key][key][value] = !element.columns[column_key][key][value]
                    })
                }
            })
        })

        let dataShare = this.state.dataShare;
        dataShare.splice(0, 1, element);
        return this.setState({
            dataShare
        });
    }
    selectSingleRow(row, secondLevelRow) {
        let dataShare = this.state.dataShare;
        let element = dataShare[0];
        element.columns = { ...element.columns };
        element.contracts = { ...element.contracts };
        element.files = { ...element.files };
        if (row == 'files') {
            // if all are true, deselect, otherwise, select all
            let selected = Object.keys(element.files).reduce((acc, key, index) => acc && element.files[key], true);
            element.files = this.props.enums.fileCategory.reduce((acc, { code }, index) => ({ ...acc, [code]: !selected }), {});
        }
        else if (row == 'contracts') {
            let selected = Object.keys(element.contracts).reduce((acc, key, index) => acc && element.contracts[key], true);
            element.contracts = columns.contracts.reduce((acc, key, index) => ({ ...acc, [key]: !selected }), {});
        }
        else if (columns[row]) {
            if (!!secondLevelRow && columns[row][secondLevelRow]) {
                columns[row][secondLevelRow].forEach(type => {
                    element.columns[row][secondLevelRow][type] = !element.columns[row][secondLevelRow][type]
                })
            } else {
                columns[row].forEach(type => {
                    element.columns[row][type] = !element.columns[row][type]
                })
            }
        }
        dataShare.splice(0, 1, element);
        return this.setState({
            dataShare
        });

    }
    selectSingleField = (indexItem) => {
        let prevState = this.state.dataShare
        let element = prevState[indexItem]
        if (element) {
            if (element.typeShare === "Site") {
                if (this.state.columnsStructure[element.categorieSite]) {
                    this.state.columnsStructure[element.categorieSite].forEach(type => {
                        element.columns[type] = !element.columns[type]
                    })
                }
                this.state.columnsStructure.generalInfo.forEach(type => {
                    element.columns[type] = !element.columns[type]
                })
                this.state.columnsStructure.Client.forEach(type => {
                    element.columns[type] = !element.columns[type]
                })
                this.state.columnsStructure.Company.forEach(type => {
                    element.columns[type] = !element.columns[type]
                })

                this.props.enums.fileCategory.map(({ code }) => code).forEach((code) => {
                    element.files[code] = !element.files[code];
                });

            } else {
                for (let [key, value] of Object.entries(this.state.columnsStructure)) {
                    if (!element.columns[key]) element.columns[key] = {}
                    value.forEach(type => {
                        element.columns[key][type] = !element.columns[key][type]
                    })
                }
                this.props.enums.fileCategory.map(({ code }) => code).forEach((code) => {
                    element.files[code] = !element.files[code];
                });
            }
            this.setState({
                dataShare: prevState
            })
        }
    }
    handleDeleteItem(lineIndex) {
        if (!window.confirm('Etes vous sur ?')) return false;
        let lines = [...this.state.dataShare];
        lines.splice(lineIndex, 1);
        return this.setState((state) => ({ dataShare: lines }));
    }
    itemText(item) {
        switch (item.typeShare) {
            case 'Site':
                return 'Site: ' + item.denomination;
            case 'Region':
                return 'Region: ' + item.label;
            case 'Departement':
                return 'Departement: ' + item.label;
            default:
                return 'ITEM';
        }
    }
    handleChangeFiles(index, key) {
        let site = { ...this.state.dataShare[index] };
        site.files[key] = !site.files[key];
        let dataShare = [...this.state.dataShare];
        dataShare.splice(index, 1, site);
        return this.setState({
            dataShare
        })
    }

    handleChangeContracts(index, key) {
        let site = { ...this.state.dataShare[index] };
        site.contracts[key] = !site.contracts[key];
        let dataShare = [...this.state.dataShare];
        dataShare.splice(index, 1, site);
        return this.setState({
            dataShare
        })
    }

    handleCheckboxChanged(index, column, key, value) {
        // console.log('=> ', { index, column, key, value });
        let prevState = [...this.state.dataShare];
        let element = { ...prevState[index] };
        element.columns[column] = { ...element.columns[column], [key]: value };
        return this.setState({
            dataShare: prevState
        })
    }
    handleSecondLevelCheckboxChanged(index, column, secondLevelCol, key, value) {
        // console.log({ index, column, secondLevelCol, key, value });
        let prevState = [...this.state.dataShare];
        let element = { ...prevState[index] };
        element.columns[column] = { ...element.columns[column], [secondLevelCol]: { ...element.columns[column][secondLevelCol], [key]: value } };
        return this.setState({
            dataShare: prevState
        })
    }
    render() {
        let techsToShow = [];
        let site = this.state.dataShare[0];
        let key = 0;
        return (
            <>
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('shares', 'createShare')}</span>
                    </h3>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingEdit}>
                    <div className="card-body py-4">
                        {this.showErrors()}
                        <Form method="post" onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('shares', 'startDate')}*</Form.Label>
                                    <DatePicker format="DD/MM/YYYY" value={this.state.dateDebut && moment(this.state.dateDebut, 'DD/MM/YYYY')} onChange={(date, dateString) => this.handleName("dateDebut", dateString)} className="form-control" placeholder={global._trans('shares', 'startDate')} style={{ width: '100%' }} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('shares', 'endDate')}*</Form.Label>
                                    <DatePicker format="DD/MM/YYYY" value={this.state.dateFin && moment(this.state.dateFin, 'DD/MM/YYYY')} disabledDate={(current) => current < moment(this.state.dateDebut, "DD/MM/YYYY").add(1, 'day')} onChange={(date, dateString) => this.handleName("dateFin", dateString)} className="form-control" placeholder={global._trans('shares', 'endDate')} style={{ width: '100%' }} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label className="d-block">{global._trans('shares', 'user')}</Form.Label>
                                    <Form.Row>
                                        {this.state.userPrem.map((user, key) => {
                                            return <Form.Group className="col-xl-4" key={key}>
                                                <Card>
                                                    <ListGroup variant="flush">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="d-flex">
                                                                <ListGroup.Item><strong>{global._trans('common', 'firstName')} : </strong>{'  ' + user.nom}</ListGroup.Item>
                                                                <ListGroup.Item><strong>{global._trans('common', 'lastName')} : </strong>{'  ' + user.prenom}</ListGroup.Item>
                                                            </div>
                                                            <InlineBottunSvg
                                                                bottuns={[
                                                                    { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.deleteItemShare(key, 'userPrem') }
                                                                ]}
                                                            />
                                                        </div>
                                                        <ListGroup.Item><strong>{global._trans('common', 'userName')}: </strong>{'  ' + user.username}</ListGroup.Item>
                                                        <ListGroup.Item><strong>{global._trans('common', 'email')}: </strong>{'  ' + user.email}</ListGroup.Item>
                                                    </ListGroup>
                                                </Card>
                                            </Form.Group>
                                        })}
                                        <Form.Group className="col-xl-4">
                                            <Button variant="light-primary" size="md" block onClick={() => this.handleHideModal("showForUser")}>{global._trans('shares', 'selectUser')}</Button>
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <div className='d-flex align-items-center justify-content-between mb-2'>
                                        <Form.Label className="d-block mb-0">{global._trans('shares', 'sitetoshare')}</Form.Label>
                                    </div>
                                    {this.state.dataShare && this.state.dataShare.length > 0 && <ul>
                                        {this.state.dataShare.map((line, index) => <li className={`menu-item menu-item-rel mx-1`}>
                                            <span className="menu-text">{this.itemText(line)}</span> &nbsp;&nbsp; <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.handleDeleteItem(index)}>X</span>
                                        </li>)}
                                    </ul>}
                                    {/* {!!this.state.dataShare && this.state.dataShare.length > 0 && <TabsMenu enums={{}} items={this.state.dataShare} onAddItem={() => this.handleHideModal("showForSite")} activeItemIndex={-1} onSelectItem={() => false} itemText={this.itemText} onDeleteItem={this.handleDeleteItem} />} */}
                                    {!!this.state.dataShare && this.state.dataShare.length > 0 &&
                                        <>
                                            <Button style={{backgroundColor: "#1057a0", borderColor: "#1057a0"}} size="sm" className="mt-5 mb-5" onClick={this.selectAllFields}>Tout Sélectionner/Désélectionner</Button>
                                            <TechColumns
                                                onSelectRow={(row, secondLevelRow) => this.selectSingleRow(row, secondLevelRow)}
                                                dataShare={this.state.dataShare}
                                                typeShare={this.state.dataShare[0].typeShare}
                                                columns={columns}
                                                onSecondLevelCheckboxChange={(column, secondLevelCol, key, value) => this.handleSecondLevelCheckboxChanged(0, column, secondLevelCol, key, value)}
                                                onCheckboxChange={(column, key, value) => this.handleCheckboxChanged(0, column, key, value)} />

                                            <div className='ColumnRow-Partage'>
                                                <strong className="d-block my-3">{global._trans('shares', 'GED')} :  <Button variant="primary" size="sm" className="ml-2" onClick={() => this.selectSingleRow('files')}>Tout sélectionner</Button> </strong>
                                                <GEDFiles handleChange={(key) => this.handleChangeFiles(0, key)} filesTypes={this.props.enums.fileCategory} checkedColumns={this.state.dataShare[0].files} />
                                            </div>
                                            <br />
                                            <hr />
                                            <br />
                                            <div className='ColumnRow-Partage'>
                                                <strong className="d-block my-3">{global._trans('shares', 'contracts')} :  <Button variant="primary" size="sm" className="ml-2" onClick={() => this.selectSingleRow('contracts')}>Tout sélectionner</Button> </strong>
                                                <Contracts handleChange={(key) => this.handleChangeContracts(0, key)} columns={columns.contracts} checkedColumns={this.state.dataShare[0].contracts} />
                                            </div>
                                            <br />
                                            <hr />
                                            <br />
                                        </>
                                    }
                                    <br />
                                    <Button variant="light-primary" size="md" onClick={() => this.handleHideModal("showForSite")}>{global._trans('shares', 'selectSiteToShare')}</Button>
                                </Form.Group>
                            </Form.Row>
                            <div className="d-flex justify-content-end mt-4">
                                <div className="card-toolbar mx-4">
                                    <Link to="/shares/site" className="btn btn-secondary" >{global._trans('common', 'cancelBtn')}</Link>
                                </div>
                                <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{global._trans('shares', 'submitShare')}</Button>
                            </div>
                        </Form>
                        {this.state.showForUser && <UserSelector show={this.state.showForUser} validSelection={(item) => this.setSelection(item, ["userPrem", "showForUser"])} typeSelect="checkbox" onHide={() => this.handleHideModal("showForUser")} />}
                        {this.state.showForSite && <DataSelector typeSelect="checkbox" checkColumns show={this.state.showForSite} validSelection={(items) => this.setSelection(items, ["dataShare", "showForSite"])} onHide={() => this.handleHideModal("showForSite")} />}
                    </div>
                </Spin>
            </>
        )
    }
}
function mapState(state) {
    const { shareErrors } = state.shares
    const { listEnums } = state.enums;
    return { shareErrors, enums: listEnums }
}

const connectedClass = connect(mapState, { createShare })(CreateShare)
export { connectedClass as CreateShare }