import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { CreateSite } from './CreateSite'
import { ListSites } from './ListSites'
import { ViewSite } from './ViewSite'
import { SitesDetails } from './SitesDetails'
class SitesPage extends Component {
    render() {
        return (
            <div className="card card-custom">
                <Switch>
                    <Route
                        path="/sites/view/:idSite"
                        component={ViewSite}
                    />
                    <Route
                        path="/sites/details/:idSite"
                        component={SitesDetails}
                    />
                    <Route
                        path="/sites/:idSite"
                        component={CreateSite}
                    />
                    
                    <Route
                        path="/sites"
                        component={ListSites}
                    />
                </Switch>
            </div>
        )
    }
}
export { SitesPage }