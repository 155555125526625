import React, { Component } from 'react'
import { connect } from "react-redux";
import { NavLink, } from 'react-router-dom'
import { Form } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
import { Modal, Spin } from 'antd'
import { FilterSearch, InlineBottunSvg, SageTable } from '../Commons'
import { FiltersContacts } from './utils'
import { fetchContacts, deleteContacts } from "../../../modules/Contacts/contact.actions";
import { ImportData } from '../Commons';
import { exportData } from "../../../modules/Common/common.actions";
import { notification } from 'antd';
import { AgGrid } from '../Commons';

const queryString = require('query-string');
class ListContacts extends Component {
    constructor(props) {
        super(props)
        // let asyncLoad = props.currentQuery !== props.history.location.search || !props.currentQuery
        this.state = {
            first_load: true,
            view_save: 0,
            loading: true,
            total: 0,
            page: 1,
            page_size: 20,
            list: [],
            columnDefs: [
                {headerName: "#", filter: "agNumberColumnFilter", field: 'id_contact'},
                {headerName: global._trans('contacts', 'status'), field: 'status', 
                    filter: "multiChoiceFilter", filterParams: { suppressFilter: true, choices: {true: "Actif(ve)", false: "Inactif(ve)"} },
                    cellRendererFramework: params => {
                        return <div className="symbol align-self-start align-self-xxl-center">
                        <div className="state-bade-text">{params.value ? global._trans('contacts', 'active') : global._trans('contacts', 'inactive')}</div>
                        <i className={`state-badge symbol-badge bg-${params.value ? "success" : "danger"}`}></i>
                    </div>
                    }
                },

                {headerName: global._trans('contacts', 'sexe'), field: 'genre', filter: "multiChoiceFilter", filterParams: { suppressFilter: true, choices: {"MR": "MR", "MME": "MME"} },},
                {headerName: global._trans('common', 'firstName'), field: 'nom'},
                {headerName: global._trans('common', 'lastName'), field: 'prenom'},
                {headerName: global._trans('common', 'email'), field: 'email'},
                {headerName: global._trans('common', 'phone'), field: 'telephone'},
                {headerName: global._trans('common', 'mobile'), field: 'mobile'},
                {headerName: global._trans('common', 'address'), field: 'address'},
                {headerName: global._trans('common', 'postcode'), field: 'postcode', filter: "agNumberColumnFilter"},
                {headerName: global._trans('common', 'city'), field: 'city'},
                {headerName: global._trans('common', 'country'), field: 'country'},
               

                {headerName: "", minWidth: 150, colId: "Actions", field: 'id_contact', sortable: false, filter: false, cellRendererFramework: params => {
                    return <div className="d-flex">
                        <a href={'/contact/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Edit.svg")}></SVG></span>
                            </button>
                        </a>
                        <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-danger"} onClick={() => this.handleDeleteContact(params.value)}>
                            <span className={"svg-icon svg-icon-md svg-icon-danger"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG></span>
                        </button>
                        <a href={'/contact/view/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"} to={'/contrat/view/' + params.value}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/View.svg")}></SVG></span>
                            </button>
                        </a>
                    </div>
                }}
            ],

            // importData: false,
            // selectedRows: [],
            // loadContacts: asyncLoad,
            loadingDelete: false,
            // listContacts: props.listContacts.data,
            // filters: {
            //     page: 1,
            //     pageSize: 20,
            //     source: 'contacts',
            //     ...queryString.parse(props.history.location.search)
            // },
            // total: props.listContacts.total,
        }
        // this.toReset = ['form_search_list', 'form_search_input']
        // this.filterTab = {}
        // this.handleSorter = this.handleSorter.bind(this)
        // this.handleSelectRow = this.handleSelectRow.bind(this)
        // this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
        // this.handleChangeSearch = this.handleChangeSearch.bind(this)
        // this.handleTableChange = this.handleTableChange.bind(this)
        // this.deleteSelected = this.deleteSelected.bind(this)
        // this.handleResetSearch = this.handleResetSearch.bind(this)
        // if (asyncLoad) {
        //     props.fetchContacts(this.state.filters)
        // }
        props.fetchContacts({pagination: {page: this.state.page, page_size: this.state.page_size}, first_load: true})
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.listContacts && Array.isArray(nextProps.listContacts.data)) {
                this.setState({
                    list: nextProps.listContacts.data,
                    total: nextProps.listContacts.total,
                    page: nextProps.listContacts.current_page,
                    page_size: nextProps.listContacts.per_page,

                    // listContacts: nextProps.listContacts.data,
                    // loadContacts: false,
                    // filters: {
                    //     ...this.state.filters,
                    //     page: nextProps.listContacts.current_page,
                    //     pageSize: nextProps.listContacts.per_page
                    // },
                    // total: nextProps.listContacts.total
                    loading: false,
                }, () => {
                    this.childComponentRef.onLoadingDone(this.state.first_load)
                    if (this.state.first_load) this.setState({first_load: false})
                })
            }
        }
        if (this.state.loadingDelete) {
            this.setState({
                listContacts: nextProps.listContacts.data,
                loadingDelete: false,
                selectedRows: []
            })
        }
    }
    handleTypeFilter(checked) {
        this.setState({
            filters: {
                ...this.state.filters,
                typeFilter: checked ? "inter" : null
            }
        })
    }
    handleSelectRow(selectedRows) {
        this.setState({
            selectedRows: selectedRows
        })
    }
    deleteSelected() {
        Modal.confirm({
            title: global._trans('common', 'deleteAllTitle'),
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('sites', 'deleteBody'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadingDelete: true
                })
                this.props.deleteContacts({ contacts: this.state.selectedRows })
            }
        });
    }
    handleDeleteContact(idContact) {
        Modal.confirm({
            title: global._trans('contacts', 'titleDeleteContact') + " #" + idContact,
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('contacts', 'bodyDeleteContact'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadingDelete: true
                })
                this.props.deleteContacts({ contacts: [idContact] })
            }
        });
    }
    // handleTableChange = (pagination, filters) => {
    //     if (pagination.current !== this.state.cuurentPage || pagination.pageSize !== this.state.pageSize) {
    //         let prevfilter = this.state.filters
    //         prevfilter.page = pagination.current
    //         prevfilter.pageSize = pagination.pageSize
    //         this.handleFetchData(prevfilter)
    //     }
    // }
    handleSorter(sorter) {
        let prevfilter = this.state.filters
        prevfilter.sort = sorter.value
        prevfilter.sorter = sorter.current
        this.handleFetchData(prevfilter)
    }
    handleFetchData(filter) {
        this.props.history.push({
            search: queryString.stringify(filter)
        })
        this.props.fetchContacts(filter)
        this.setState({
            loadContacts: true
        })
    }
    handleSubmitSearch(e) {
        e.preventDefault()
        this.state.filters = {
            ...this.state.filters,
            ...this.filterTab
        }
        this.handleFetchData(this.state.filters)
    }
    handleChangeSearch(e) {
        const { name, value } = e.target
        this.filterTab[name] = value
    }
    handleResetSearch() {
        let resteData = {
            page: this.state.filters.page,
            pageSize: this.state.filters.pageSize,
            source: this.state.filters.source,
        }
        this.state.filters = resteData
        this.filterTab = {}
        this.toReset.map(item => {
            let formReset = document.getElementById(item)
            if (formReset) formReset.reset();
        })
        this.handleFetchData(resteData)
    }
    showReset() {
        const keys = Object.entries(this.state.filters).filter(item => item[1] && (item[0] !== 'typeFilter'));
        return keys.length > 3
    }
    handleImportExcel = (show) => {
        this.setState({
            importData: show
        })
    }
    handleExportExcel = (nameImport) => {
        let columns = {}
        this.childComponentRef.state.columns.filter(item => item.visible && item.colId != "Actions").forEach(item => {
            columns[item.colId] = item.colDef.headerName
        });
        exportData({...nameImport, columns, filter: this.childComponentRef.gridApi.getFilterModel()})
        .then(({ message, ok }) => {
            if (ok && message == "Import Started") {
                notification.info({
                    message: global._trans('common', 'titleImported'),
                    description: global._trans('common', 'bodyImportedMessage'),
                    placement: 'bottomLeft',
                    // duration: 0,
                    style: {
                        width: 500,
                        padding: '15px 20px',
                        borderRadius: 8
                    },
                })
                // onHide()
            }
        })
        .catch((e) => {
        console.log('🚀 => e', e)

        })
        .finally((f) => {
            console.log('🚀 => f', f)
            // setLoading(false)
        })
    }
    handleTableChange = (filter, sort, pagination) => {
        this.setState({
            loading: true,
            list: []
        }, () => this.props.fetchContacts({sort, filter, pagination}));
    }
    handleSaveView = () => {
        this.setState({
            view_save: 2
        }, () => {
            this.childComponentRef.saveCurrentView()
        })
    }
    handleViewSaved = (value) => {
        this.setState({
            view_save: value
        })
    }
    render() {
        return (
            <>
                <div className="card-header header-center py-1">
                    <h3 className="card-title">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('contacts', 'titleList')} ({this.state.total} élément trouvé)</span>
                    </h3>
                    <div className="card-toolbar">
                    {this.state.view_save > 0 && <button title={"Enregistrer l'état de la vue (filtre, ordre, disposition et taille des colonnes)"} disabled={this.state.view_save == 2} className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.handleSaveView()}>
                            <span className="svg-icon svg-icon-md" style={{margin: 0}}>
                                <SVG hidden={this.state.view_save != 1} src={toAbsoluteUrl("/media/svg/icons/Costum/Save.svg")} className="h-50 align-self-center"></SVG>
                                <SVG hidden={this.state.view_save != 2} src={toAbsoluteUrl("/media/svg/icons/Costum/Loading.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                        </button>}
                        <button className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.childComponentRef.viewsList(true)}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Columns.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Views management')}
                        </button>
                        <button className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => this.handleImportExcel('contacts')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'importData')}
                        </button>
                        {/* <button className="btn btn-light-success font-weight-bolder font-size-sm " onClick={() => this.handleImportExcel('ContactsMAJ')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Import MAJ')}
                        </button> */}
                        <button className="btn btn-light-primary font-weight-bolder font-size-sm mx-3" onClick={() => this.handleExportExcel({ type:'contacts'})}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            Exporter
                        </button>
                        <NavLink to="/contact/new" className="btn btn-primary font-weight-bolder font-size-sm">
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('contacts', 'createContact')}
                        </NavLink>
                    </div>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingDelete}>
                    <div className="card-body py-4">
                        {/* <div className="d-flex align-items-center">
                            {this.state.selectedRows.length > 0 && <button type="button" className="btn btn-light-danger font-weight-bolder font-size-sm d-flex align-items-center mr-3" onClick={this.deleteSelected}>
                                <span className="svg-icon svg-icon-md svg-icon-light-danger">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG>
                                </span>
                                <span>{`${global._trans("common", "deleteSelected")} ${this.state.selectedRows.length} ${global._trans("common", "element")}`}</span>
                            </button>}
                            <div className="d-flex align-items-center">
                                <FilterSearch handleSubmit={this.handleSubmitSearch} idForm="form_search_list" isAndType={this.state.filters.typeFilter === "inter"} handleType={this.handleTypeFilter.bind(this)}>
                                    <FiltersContacts filters={this.state.filters} handleChange={this.handleChangeSearch} />
                                </FilterSearch>
                                <Form id="form_search_input" className="d-flex" onSubmit={this.handleSubmitSearch}>
                                    <Form.Control type="text" style={{ width: 350 }} className="mx-2" placeholder={global._trans('common', 'searchData')} defaultValue={this.state.filters.search} name="search" onChange={this.handleChangeSearch}></Form.Control>
                                    {this.showReset() && <button type="button" onClick={this.handleResetSearch} className="btn btn-light font-weight-bolder mr-2 font-size-sm">{global._trans("common", "reset")}</button>}
                                    <button className="btn btn-light-primary font-weight-bolder font-size-sm">{global._trans("common", "search")}</button>
                                </Form>
                            </div>
                        </div> */}
                        {/* <div className="separator separator-solid my-3"></div> */}
                        {/* <SageTable
                            columns={[
                                {
                                    title: global._trans('contacts', 'status'), dataIndex: "status", sorter: true, render: status =>
                                        <div className="symbol align-self-start align-self-xxl-center">
                                            <div>{status ? global._trans('contacts', 'active') : global._trans('contacts', 'inactive')}</div>
                                            <i className={`symbol-badge bg-${status ? "success" : "danger"}`}></i>
                                        </div>
                                },
                                { title: global._trans('common', 'firstName'), dataIndex: "nom", sorter: true },
                                { title: global._trans('common', 'lastName'), dataIndex: "prenom", sorter: true },
                                { title: global._trans('common', 'email'), dataIndex: "email", sorter: true },
                                { title: global._trans('common', 'phone'), dataIndex: "telephone", sorter: true },
                                { title: global._trans('common', 'mobile'), dataIndex: "mobile", sorter: true },
                                { title: global._trans('common', 'address'), dataIndex: "address", sorter: true },
                                {
                                    title: '', dataIndex: "id_contact", fixed: 'right', render: id_contact => <InlineBottunSvg
                                        bottuns={[
                                            { iconPath: "/media/svg/icons/Costum/Edit.svg", type: "primary", action: '/contact/' + id_contact },
                                            { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.handleDeleteContact(id_contact) },
                                            { iconPath: "/media/svg/icons/Costum/View.svg", type: "primary", action: '/contact/view/' + id_contact }
                                        ]}
                                    />
                                }
                            ]}
                            callBackSort={this.handleSorter}
                            style={{ height: 610 }}
                            defaultSorter={this.state.filters}
                            data={this.state.listContacts}
                            IterableProps={{
                                rowSelection: {
                                    onChange: this.handleSelectRow,
                                },
                                locale: {
                                    emptyText: global._trans('contacts', 'emptyList')
                                },
                                loading: this.state.loadContacts || this.state.loadingDelete,
                                rowKey: "id_contact",
                                pagination: { total: this.state.total, pageSize: this.state.filters.pageSize, current: parseInt(this.state.filters.page), showSizeChanger: true },
                                onChange: this.handleTableChange
                            }}
                        /> */}
                        <AgGrid
                            rows={this.state.list}
                            columns={this.state.columnDefs}
                            pagination={{total: this.state.total, pageSize: this.state.page_size, current: this.state.page}}
                            onChange={this.handleTableChange}
                            loading={this.state.loading}
                            ref={ref => this.childComponentRef = ref}
                            views_key={"contacts"}
                            onViewSaved={this.handleViewSaved}
                        />
                    </div>
                </Spin>
                <ImportData show={!!this.state.importData} sourceFile={this.state.importData} title={global._trans('common', 'titelImport')} onHide={() => this.handleImportExcel(false)} />
            </>
        )
    }
}
function mapState(state) {
    const { listContacts, currentQuery } = state.contact
    return { listContacts, currentQuery }
}
const connectedClass = connect(mapState, { fetchContacts, deleteContacts })(ListContacts)
export { connectedClass as ListContacts }