export const collectivitieUrls = {
    CREATE_EPIC_URL: 'clients/epics/create',
    FETCH_EPICS_URL: "clients/epics/all",
    DELETE_EPICS_URL: 'clients/epics/delete',
    FETCH_EPIC_URL: "clients/epics/show/",
    EDIT_EPIC_URL: 'clients/epics/edit/',
    UPDATE_EPIC_URL: "clients/epics/update",
    SYNC_EPIC_URL: "clients/epics/sync_insee",
    /**************** */
    CREATE_COMMUNE_URL: 'clients/communs/create',
    FETCH_COMMUNES_URL: "clients/communs/all",
    DELETE_COMMUNES_URL: 'clients/communs/delete',
    FETCH_COMMUNE_URL: "clients/communs/show/",
    EDIT_COMMUNE_URL: 'clients/communs/edit/',
    UPDATE_COMMUNE_URL: "clients/communs/update",
    SYNC_COMMUNE_URL: "clients/communs/sync_insee",
    /********************** */
    CREATE_SYNDICAT_URL: 'clients/syndicats/create',
    FETCH_SYNDICATS_URL: "clients/syndicats/all",
    FETCH_SYNDICAT_URL: "clients/syndicats/show/",
    DELETE_SYNDICATS_URL: "clients/syndicats/delete",
    EDIT_SYNDICAT_URL: 'clients/syndicats/edit/',
    UPDATE_SYNDICAT_URL: "clients/syndicats/update",
    SYNC_SYNDICAT_URL: "clients/syndicats/sync_insee",

    /*********************** */

}