import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class TraitementNox extends Component {
    render() {
        return (
            <CommonComponent keyName="traitementNOX" titleSetting={global._trans('settings', 'traitmentNox')} traitementNOX={this.props.traitementNOX} />
        )
    }
}
function mapState(state) {
    const { listEnums: { traitementNOX } } = state.enums
    return { traitementNOX }
}
const connectedClass = connect(mapState, null)(TraitementNox)
export { connectedClass as TraitementNox }