import React from 'react'
import { Checkbox } from 'antd';
import { ListGroup } from "react-bootstrap";
export const ColumnsTRI = (props) => {
    return (
        <div className="row mx-0">
            <ListGroup.Item>
                <Checkbox name="capaciteHoraire" checked={props.checkedColumns.capaciteHoraire} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'capaciteHoraire')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="capaciteNominale" checked={props.checkedColumns.capaciteNominale} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans("sites", "capaciteNominale")}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="capaciteReglementaire" checked={props.checkedColumns.capaciteReglementaire} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'capaciteReglementaire')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="dernierConstructeur" checked={props.checkedColumns.dernierConstructeur} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'dernierConstructeur')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="dateExtension" checked={props.checkedColumns.dateExtension} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'dateExtension')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="extension" checked={props.checkedColumns.extension} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'extension')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="miseEnService" checked={props.checkedColumns.miseEnService} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'miseEnService')}</Checkbox>
            </ListGroup.Item>
        </div>
    )
}