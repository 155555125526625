import { GEDSlice } from "./contract.reducer";
import * as GEDRequests from "./contract.crud";

import { history } from '../../../config'


const { actions } = GEDSlice;



export const createGEDFile = data => dispatch => {
    dispatch(actions.enabledLoading());
    return GEDRequests
        .createGEDFile(data)
        .then(async ({ ok, data, message }) => {
            if (ok) {
                dispatch(actions.GEDFileCreated(data));
                return { ...data, message }
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
                return false;
            }
        })
        .catch(async ({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
            return false;
        });
};
export const fetchGEDFile = file_id => dispatch => {
    dispatch(actions.enabledLoading());
    return GEDRequests
        .fetchGEDFile(file_id)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.GEDFileFetched(data));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        });
}
export const fetchGEDFiles = QueryParams => dispatch => {
    dispatch(actions.enabledLoading());
    return GEDRequests
        .fetchGEDFiles(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.GEDFilesFetched({ listGEDFiles: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const fetchGEDFilesPost = QueryParams => dispatch => {
    return GEDRequests
        .fetchGEDFilesPost(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.GEDFilesFetched({ listGEDFiles: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const updateGEDFile = file => dispatch => {

    // dispatch(actions.enabledLoading());
    return GEDRequests
        .updateGEDFile(file)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.GEDFileUpdated(data));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        }).finally(() => {
            // dispatch(actions.enabledLoading());
        })
        
};
export const deleteGEDFiles = files => dispatch => {
    return GEDRequests
        .deleteGEDFile(files)
        .then(({ ok, data, files }) => {
            if (ok) {
                dispatch(actions.contractsDeleted({ asyncData: data === "async", files }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};