import React, { Component } from 'react'
import { connect } from "react-redux";
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { fetchEpic } from '../../../../../modules/Collectivities/collectivitie.actions'
import { BasicInfo, Communes, Competances, Sites } from './utils'
import { Contacts } from '../../Common'
import { EntityHistory } from '../../../Commons/EntityHistory';
import { EntityFiles } from '../../../Commons';

class ViewEpic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            epic: {},
            tab: 'basic',
            disabled: true
        }
        props.fetchEpic(props.match.params.idepic)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            this.setState({
                loading: false,
                epic: nextProps.Epic ? nextProps.Epic : {}
            })
        }
    }
    getTabBody() {
        switch (this.state.tab) {
            case "basic": return <BasicInfo epic={this.state.epic} disabled={this.state.disabled} />
            case "contacts": return <Contacts contacts={this.state.epic.contacts} />
            case "communes": return <Communes communes={this.state.epic.communes} />
            case "sites": return <Sites sites={this.state.epic.sites} />
            case "competances": return <Competances competances={{ delegue: this.state.epic.competance_delegue, exercee: this.state.epic.competance_exercee, recu: this.state.epic.competance_recu }} />
            case "history": return <EntityHistory entity={this.state.epic} showStatus={true} />
            case "files": return <EntityFiles entity={this.state.epic} />
        }
    }
    handleTab(tab) {
        this.setState({
            tab: tab
        })
    }
    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div className="card-header border-0 pt-5 pb-0 px-6">
                    <h3 className="card-title align-items-start flex-column mb-0">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('clientsEpic', 'epic')} <span className="text-primary">#{this.state.epic.nomEpic ? this.state.epic.nomEpic : '...'}</span></span>
                    </h3>
                </div>
                <div className="card-body py-4">
                    <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('basic')} className={`nav-link ${this.state.tab === "basic" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'basicInfo')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('contacts')} className={`nav-link ${this.state.tab === "contacts" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'contacts')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('sites')} className={`nav-link ${this.state.tab === "sites" && "active"}`} data-toggle="tab" role="tab">{global._trans('client', 'sites')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('communes')} className={`nav-link ${this.state.tab === "communes" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'communes')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('competances')} className={`nav-link ${this.state.tab === "competances" && "active"}`} data-toggle="tab" role="tab">{global._trans('client', 'competanceDechet')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('history')} className={`nav-link ${this.state.tab === "history" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'history')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('files')} className={`nav-link ${this.state.tab === "files" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'files')}</Link>
                        </li>
                    </ul>
                    <div className="pt-5">{this.getTabBody()}</div>
                    <div className="d-flex justify-content-end">
                        <div className="card-toolbar mx-4">
                            <Link to={`/client/communities/epic${this.props.queryEpic}`} className="btn btn-secondary" >{global._trans('common', 'goBack')}</Link>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}
function mapState(state) {
    const { Epic, queryEpic } = state.collectivitie
    return { Epic, queryEpic }
}
const connectedClass = connect(mapState, { fetchEpic })(ViewEpic)
export { connectedClass as ViewEpic }