import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    errorsAdmin: null,
    listAdmins: {}
};

export const adminsSlice = createSlice({
    name: "admins",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { errors } = action.payload
            state.errorsAdmin = errors;
        },
        adminCreated: (state) => {
            state.errorsAdmin = state.errorsAdmin === false ? null : false
        },
        adminUpdated: (state) => {
            state.errorsAdmin = state.errorsAdmin === false ? null : false
        },
        adminsDeleted: (state, action) => {
            const { asyncData, admins } = action.payload
            if (asyncData) {
                let prevList = state.listAdmins
                prevList.data = prevList.data.filter(admin => admins.indexOf(admin.id_admin) === -1)
                state.listAdmins = prevList
            }
            state.errorsAdmin = state.errorsAdmin === false ? null : false
        },
        adminsFetched: (state, action) => {
            const { listAdmins } = action.payload
            state.errorsAdmin = null
            state.listAdmins = listAdmins
        },
    }
});