import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class Effectifs extends Component {
    render() {
        return (
            <CommonComponent keyName="effectifs" titleSetting={global._trans('common', 'effectifs')} effectifs={this.props.effectifs} />
        )
    }
}
function mapState(state) {
    const { listEnums: { effectifs } } = state.enums
    return { effectifs }
}
const connectedClass = connect(mapState, null)(Effectifs)
export { connectedClass as Effectifs }