import React, { Fragment } from 'react'
import { Card, ListGroup } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { Spin } from 'antd';
import { InlineBottunSvg } from '../../Commons'
export const PersonsMoral = (props) => {
    return (
        <div className="row">
            {props.persons.map((person, key) => {
                return <div key={key} className="col-xl-4 mt-4">
                    <Spin spinning={props.loading === key}>
                        <Card>
                            <Card.Title className="m-0 p-4" tag="h4"><span className="text-muted">{global._trans('contacts', 'persmoral')}</span> ({global._trans('client', person.typePersonMoral.toLowerCase())})</Card.Title>
                            <ListGroup variant="flush">
                                {person.typePersonMoral === "Societe" ?
                                    <ListGroup.Item><strong>Dénomination : </strong>{'  ' + person.denomination}</ListGroup.Item>
                                    :
                                    <ListGroup.Item><strong>{person.name} : </strong>{'  ' + person[person.dataIndex]}</ListGroup.Item>
                                }
                                {/* <ListGroup.Item><strong>{person.name} : </strong>{'  ' + person[person.dataIndex]}</ListGroup.Item> */}
                                <ListGroup.Item>
                                    <strong>{global._trans('contacts', 'fonctionPerson')} : </strong>
                                    <div>{person.fonction_person.map((fnt, fKey) => {
                                        return <Fragment key={fKey}>
                                            <div className="mt-2 d-flex align-items-center justify-content-between">
                                                <span key={fKey}>{fnt.function_string}</span>
                                                <InlineBottunSvg
                                                    bottuns={[
                                                        { iconPath: `/media/svg/icons/Costum/${fnt.status ? "Pause" : "Play"}.svg`, type: fnt.status ? "primary" : "default", action: () => props.handleFunction(key, fKey) },
                                                        { iconPath: `/media/svg/icons/Costum/Trash.svg`, type: "danger", action: () => props.deleteFunction(key, fKey) }
                                                    ]}
                                                />
                                            </div>
                                            <div className="separator separator-solid mt-2"></div>
                                        </Fragment>
                                    })}</div>
                                </ListGroup.Item>
                                <ListGroup.Item><strong>{global._trans('common', 'city')}: </strong>{'  ' + person.city}</ListGroup.Item>
                                <ListGroup.Item><Link to={"/client/communities/syndicat/view/" + person.id_person}><strong>{global._trans('client', 'view' + person.typePersonMoral.toLowerCase())}</strong></Link></ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Spin>
                </div>
            })}
        </div>
    )
}