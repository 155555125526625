import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { SageTable } from './SageTable'
import { fetchUsersPrem } from '../../../modules/User/user.actions'
class UserSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRow: [],
            searchValue: "",
            listsData: [],
            loading: true,
        }
        this.columes = [
            { title: global._trans('common', 'firstName'), dataIndex: "nom", fixed: 'left', ellipsis: true, },
            { title: global._trans('common', 'lastName'), dataIndex: "prenom", ellipsis: true, },
            { title: global._trans('common', 'userName'), dataIndex: "username", ellipsis: true, },
            { title: global._trans('common', 'email'), dataIndex: "email", ellipsis: true, },
        ]
        props.fetchUsersPrem({ pageSize: 15 })
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.validSelect = this.validSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading && nextProps.listUsersPrem.data) {
            this.setState({
                loading: false,
                listsData: nextProps.listUsersPrem.data
            })
        }
    }
    handleSelectRow(selectedRowKeys) {
        this.setState({
            selectedRow: selectedRowKeys
        })
    }
    handleSearch(e) {
        let value = e.target.value
        this.props.fetchUsersPrem({ pageSize: 15, nom: value, prenom: value })
        this.setState({
            searchValue: value,
            loading: true
        })
    }
    validSelect() {
        let elem
        if (this.props.typeSelect === "checkbox") {
            elem = this.state.listsData.filter(obj => this.state.selectedRow.indexOf(obj.id_user) > -1)
        } else {
            elem = this.state.listsData.find(obj => obj.id_user == this.state.selectedRow[0])
        }
        this.props.validSelection(elem)
    }
    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} aria-labelledby="example-modal-sizes-title-lg">
                <div className="px-5 pb-5 pt-2">
                    <Form.Control type="text" name="searchvalue" value={this.state.searchValue} placeholder={global._trans('users', 'searchUsersSelector')} onChange={this.handleSearch} />
                    <SageTable
                        columns={this.columes}
                        data={this.state.listsData}
                        style={{ height: 560 }}
                        IterableProps={{
                            rowSelection: {
                                selectedRowKeys: this.state.selectedRow,
                                onChange: this.handleSelectRow,
                                type: this.props.typeSelect || 'radio'
                            },
                            locale: {
                                emptyText: global._trans('common', 'emptyData')
                            },
                            loading: this.state.loading,
                            rowKey: 'id_user',
                            pagination: false
                        }}
                    />
                    <div className="d-flex justify-content-end mt-2">
                        <Button variant="primary" onClick={this.validSelect}>{global._trans('common', 'validSelect')}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
function mapState(state) {
    const { listUsersPrem } = state.users
    return { listUsersPrem }
}
const connectedClass = connect(mapState, { fetchUsersPrem })(UserSelector)
export { connectedClass as UserSelector }