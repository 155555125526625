import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class InstalationCople extends Component {
    render() {
        return (
            <CommonComponent keyName="installationComplementair" titleSetting={global._trans('settings', 'installComplem')} installationComplementair={this.props.installationComplementair} />
        )
    }
}
function mapState(state) {
    const { listEnums: { installationComplementair } } = state.enums
    return { installationComplementair }
}
const connectedClass = connect(mapState, null)(InstalationCople)
export { connectedClass as InstalationCople }