import React from 'react'
import SVG from "react-inlinesvg";
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
export function InlineBottunSvg(props) {
    let TagName
    return (
        <div className="d-flex">
            {props.bottuns.map((bottun, key) => {
                if (bottun) {
                    TagName = Link
                    if (typeof bottun.action === 'function') {
                        TagName = 'button'
                    }
                    return <TagName role="button" type="button" {...(typeof bottun.action === 'function' ? { onClick: bottun.action } : { to: bottun.action })} key={key} className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-" + bottun.type}>
                        <span className={"svg-icon svg-icon-md svg-icon-" + bottun.type}>
                            <SVG src={toAbsoluteUrl(bottun.iconPath)}></SVG>
                        </span>
                    </TagName>
                }
            })}
        </div>
    )
}