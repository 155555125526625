import * as NotificationRequests from "./notifications.crud";
import { notificationsSlice } from "./notifications.reducer";
const { actions } = notificationsSlice;
export const fetchNotifications = dispatch => {
    return NotificationRequests
        .fetchNotifications()
        .then(({ ok, notifications, count }) => {
            if (ok && Array.isArray(notifications)) {
                dispatch(actions.notificationsFetched({ notifications, count }));
            } else {
                dispatch(actions.catchError({ errors: "Error Found" }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: "Error Found" }));
        });
};
export const readNotification = idNotification => dispatch => {
    return NotificationRequests
        .readNotification(idNotification)
        .then(({ ok, notifications, count, async }) => {
            if (ok && async && Array.isArray(notifications)) {
                dispatch(actions.notificationReaded({ notifications, count }));
            } else {
                dispatch(actions.catchError({ errors: "Error Found" }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: "Error Found" }));
        });
};
export const resetNotification = () => dispatch => {
    return NotificationRequests
        .resetNotification()
        .then(({ ok, notifications, count }) => {
            if (ok && Array.isArray(notifications)) {
                dispatch(actions.notificationReset({ notifications, count }));
            } else {
                dispatch(actions.catchError({ errors: "Error Found" }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: "Error Found" }));
        });
};