import React, { Component } from 'react';
import { Card, ListGroup } from 'react-bootstrap'
import { Spin, Collapse, Modal, Pagination } from 'antd'
import { sitesUserPrem } from '../../../../../../modules/User/user.actions'
import { handleStatusShare, handleDeleteShare } from '../../../../../../modules/Shares/shares.actions'
import { InlineBottunSvg, HeaderTab } from '../../../../Commons'
import { RenewShare } from "./RenewShare"
import moment from 'moment'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../../../../../_sage/_helpers';

import { connect } from 'react-redux';
import { UpdateShares } from './UpdateShares';
const { Panel } = Collapse;
class SharedSites extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            sites: null,
            inQueueShare: null,
            page: 1,
            total: 0,
            currentTab: 0,
            updateSharesGroupe: false
        }
        this.handleRenewShare = this.handleRenewShare.bind(this)

        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.handleValidEdit = this.handleValidEdit.bind(this)
        this.handleSwitchTabs = this.handleSwitchTabs.bind(this)
        this.handleUpdateShare = this.handleUpdateShare.bind(this)
        this.handleDeleteAll = this.handleDeleteAll.bind(this)

        this.handleUpdateShareModalSubmit = this.handleUpdateShareModalSubmit.bind(this)

        this.handleUpdateShareModal = this.handleUpdateShareModal.bind(this)

        this.isInUse = false
        this.fetchSites({ type: 'Site' })
    }
    fetchSites(query) {
        sitesUserPrem(this.props.idUser, query)
            .then(({ ok, data }) => {
                if (ok) {
                    this.setState({
                        loading: false,
                        sites: data.data,
                        page: data.current_page,
                        total: data.total
                    })
                }
            })
            .catch(err => {

            })
            .finally(e => {
                this.setState({
                    loading: false
                })
            })
    }
    handleBlockShare(share) {
        if (!this.isInUse) {
            this.handleLoading()
            this.isInUse = true
            handleStatusShare({ action: 'update-status-one', idShareSite: share.id_share_site })
                .then(({ ok, data }) => {
                    if (ok) {
                        // this.handleValidEdit(indexShare, data)
                    }
                })
                .catch(err => {

                })
                .finally(e => {
                    this.handleLoading()
                    this.isInUse = false
                })
        }
    }
    handleLoading() {
        return false;
        var mapLoading = document.getElementById("kt_loading_for_share")
        if (mapLoading) {
            if (mapLoading.style.display === "none") {
                mapLoading.style.display = "block"
            } else {
                mapLoading.style.display = "none"
            }
        }
    }
    handleRenewShare(indexSahre, action) {
        let result = null
        if (indexSahre >= 0) {
            let curntShare = this.state.sites[indexSahre]
            result = { ...curntShare, site: curntShare.site && curntShare.site.categorieSite, keyIndex: indexSahre, typeChange: action }
        }
        this.setState({
            inQueueShare: result
        })
    }
    handleValidEdit(index, share) {
        let prevState = this.state.sites
        prevState[index] = share
        this.setState({
            sites: prevState,
            inQueueShare: null
        })
    }
    handleDeleteShare(idShareSite, indexShare) {
        Modal.confirm({
            title: global._trans('shares', 'titleDelete') + " #" + idShareSite,
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('shares', 'bodyDelete'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.handleLoading()
                handleDeleteShare({ shares: [idShareSite] })
                    .then(({ data }) => {
                        if (data === "async") {
                            let prevState = this.state.sites
                            prevState.splice(indexShare, 1)
                            this.setState({
                                sites: prevState
                            })
                        }
                    })
                    .catch(err => {

                    })
                    .finally(e => {
                        this.handleLoading()
                    })
            }
        });
    }
    handleSwitchTabs(index) {
        this.fetchSites({ page: this.state.page, type: this.getType(index) })
        this.setState({
            currentTab: index,
            loading: true
        })
    }
    getType(index) {
        let tab = index >= 0 ? index : this.state.currentTab
        switch (tab) {
            case 0: return 'Site'
            case 1: return 'Departement'
            case 2: return 'Region'
        }
    }
    onPaginationChange(pagination) {
        this.setState({
            loading: true
        })
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        this.fetchSites({ page: pagination, type: this.getType() })
    }

    handleUpdateShareModal(name, value) {
        this.setState((state) => ({
            updateSharesGroupe: { ...state.updateSharesGroupe, [name]: value }
        }));
    }

    handleUpdateShareModalSubmit() {
        this.handleUpdateShare('update-dates', { start_date: this.state.updateSharesGroupe.start, end_date: this.state.updateSharesGroupe.end });
    }

    handleUpdateShare(action, data = {}) {
        if (!this.state.sites[0]) return false;

        let id_user_premieum = this.state.sites[0].id_user_premieum;

        handleStatusShare({ action: action, id_user_premieum, type_data_share: this.getType(-1), ...data })
            .then(({ ok, request }) => {
                if (ok) {
                    if (!!request.action) {
                        if (request.action == 'pause-all') {
                            this.setState((state) => {
                                let sites = [...state.sites].map((site) => ({ ...site, is_blocked: true }));
                                return { sites }
                            });
                        } else if (request.action == 'unpause-all') {
                            this.setState((state) => {
                                let sites = [...state.sites].map((site) => ({ ...site, is_blocked: false }));
                                return { sites }
                            });

                        } else if (request.action == 'update-dates') {
                            this.setState((state) => {
                                let sites = [...state.sites].map((site) => ({ ...site, start: request.start_date, end: request.end_date }));
                                return { sites }
                            });

                        }
                    }
                }
            })
            .catch(err => {

            })
            .finally(e => {
                this.setState({ updateSharesGroupe: null })
                this.handleLoading()
                this.isInUse = false
            })
    }
    handleDeleteAll() {
        if (!this.state.sites[0]) {
            console.log('handleDeleteAll: Empty')
            return false;
        }
        let id_user_premieum = this.state.sites[0].id_user_premieum;

        Modal.confirm({
            title: global._trans('shares', 'deleteAll'),
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('shares', 'bodyDelete'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.handleLoading()
                handleDeleteShare({ id_user_premieum, type_data_share: this.getType(-1) })
                    .then(({ data }) => {

                    })
                    .catch(err => {

                    })
                    .finally(e => {
                        this.handleLoading()
                    })
            }
        });
    }
    render() {
        return (
            <>
                <div id="kt_loading_for_share" className="position-fixed bg-light py-4 px-5" style={{ display: "none", zIndex: 10, borderRadius: 12, left: "calc(70% - 95.5px)", top: 150, boxShadow: "1px 1px 18px #00000052" }}><strong>{global._trans("common", "reqeusting")}</strong></div>
                <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">{global._trans("users", "sharedSites")}</h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">{global._trans("users", "bodyShares")}</span>
                    </div>
                </div>
                <div className="form">
                    <HeaderTab style={{ paddingBlock: 10 }} items={[global._trans("common", "sites"), global._trans("common", "departementSiege"), global._trans("common", "regionSiege")]} switchItem={this.handleSwitchTabs} active={this.state.currentTab} />
                    <Spin spinning={this.state.loading}>
                        <div className="card-body">
                            <div className='row'>
                                <div className="col-12 mt-2 mb-2">
                                    <span className='mr-2 font-weight-bold'>Actions :</span>
                                    <button type="button" onClick={(e) => this.handleUpdateShare('pause-all')} className="btn btn-icon btn-light mx-1 btn-sm btn-hover-primary" >
                                        <span className='svg-icon svg-icon-md svg-icon-primary'>
                                            <SVG src={toAbsoluteUrl('/media/svg/icons/Costum/Pause.svg')} />
                                        </span>
                                    </button>

                                    <button type="button" onClick={(e) => this.handleUpdateShare('unpause-all')} className="btn btn-icon btn-light mx-1 btn-sm btn-hover-primary" >
                                        <span className='svg-icon svg-icon-md svg-icon-primary'>
                                            <SVG src={toAbsoluteUrl('/media/svg/icons/Costum/Play.svg')} />
                                        </span>
                                    </button>

                                    <button type="button" onClick={(e) => this.setState({ updateSharesGroupe: { start: null, end: null } })} className="btn btn-icon btn-light mx-1 btn-sm btn-hover-primary" >
                                        <span className='svg-icon svg-icon-md svg-icon-primary'>
                                            <SVG src={toAbsoluteUrl('/media/svg/icons/Costum/Edit.svg')} />
                                        </span>
                                    </button>

                                    <button type="button" onClick={(e) => this.handleDeleteAll()} className="btn btn-icon btn-light mx-1 btn-sm btn-hover-danger" >
                                        <span className='svg-icon svg-icon-md svg-icon-danger'>
                                            <SVG src={toAbsoluteUrl('/media/svg/icons/Costum/Trash.svg')} />
                                        </span>
                                    </button>

                                    {/* <button type="button" onClick={(e) => this.handleUpdateShare('update-all')} className={`btn btn-primary font-weight-bold mr-2`}>Update</button> */}
                                </div>
                            </div>
                            <div className="row">
                                {this.state.sites && this.state.sites.map((site, key) => {
                                    let diff = moment(site.end, "DD/MM/YYYY").diff(moment(), 'days')
                                    return <div className="col-xl-6 mt-7" key={key}>
                                        <Card className='bg-blue-50'>
                                            <Card.Title className="m-0 p-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <span>{global._trans("shares", "statusShare")} : <span className={`text-${diff < 0 ? "danger" : site.is_blocked ? "primary" : "success"}`}>{global._trans("shares", diff < 0 ? "termine" : site.is_blocked ? "blocked" : "enCours")}</span> </span>
                                                    </div>
                                                    <InlineBottunSvg
                                                        bottuns={[
                                                            diff >= 0 && { iconPath: `/media/svg/icons/Costum/${site.is_blocked ? "Play" : "Pause"}.svg`, type: site.is_blocked ? "default" : "primary", action: () => this.handleBlockShare({ id_share_site: site.id_share_site }) },
                                                            diff < 0 && { iconPath: "/media/svg/icons/Costum/Renew.svg", type: "success", action: () => this.handleRenewShare(key) },
                                                            { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.handleDeleteShare(site.id_share_site, key) },
                                                            diff >= 0 && !site.is_blocked && { iconPath: "/media/svg/icons/Costum/Edit.svg", type: "primary", action: () => this.handleRenewShare(key, "edit") }
                                                        ]}
                                                    />
                                                </div>
                                            </Card.Title>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item>
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <strong>{global._trans('shares', 'startDate')} : </strong>
                                                            <span>{site.start}</span>
                                                        </div>
                                                        <div>
                                                            <strong>{global._trans('shares', 'endDate')} : </strong>
                                                            <span>{site.end}</span>
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                {site.site && <>
                                                    <ListGroup.Item><strong>{global._trans('shares', 'denominationSite')} : </strong>{'  ' + site.site.denomination}</ListGroup.Item>
                                                    <ListGroup.Item><strong>{global._trans('shares', 'addressSite')}: </strong>{'  ' + site.site.adresse}</ListGroup.Item>
                                                </>}
                                                {site.departement && <>
                                                    <ListGroup.Item><strong>{global._trans('shares', `shared${site.type_data_share}`)} : </strong>{'  ' + site.departement.name_departement}</ListGroup.Item>
                                                </>}
                                                {site.region && <>
                                                    <ListGroup.Item><strong>{global._trans('shares', `shared${site.type_data_share}`)} : </strong>{'  ' + site.region.name_region}</ListGroup.Item>
                                                </>}
                                                <ListGroup.Item>
                                                    <Collapse>
                                                        {
                                                            !!site.columns && <Panel header={global._trans('shares', 'sharedColumn')} key="1">
                                                                {
                                                                    <>
                                                                        <strong>DATA</strong>:
                                                                        {site.columns.generalInfo && <><strong>{global._trans('shares', 'columnTech')} : </strong>
                                                                            {Object.keys(site.columns.generalInfo).map((elem, key) => {
                                                                                return site.columns.generalInfo[elem] && <span className="px-2" style={{ borderLeft: "1px solid black" }} key={key}>{global._trans("sites", elem)}</span>
                                                                            })}
                                                                            <hr />
                                                                        </>}
                                                                        {site.columns.UVE && <><strong className="pb-2">{global._trans('shares', 'columnTechUVE')} : </strong> <br />
                                                                            {Object.keys(site.columns.UVE).map((elem, key) => {
                                                                                return (
                                                                                    <>
                                                                                        <strong>{global._trans('shares', elem)}:</strong>
                                                                                        {site.columns.UVE[elem] && Object.keys(site.columns.UVE[elem]).filter((col) => site.columns.UVE[elem][col]).join(' | ')} <br />
                                                                                    </>
                                                                                );
                                                                            })}
                                                                            <hr />
                                                                        </>}
                                                                        {site.columns.TRI && <><strong className="py-3">{global._trans('shares', 'columnTechTRI')} : </strong>
                                                                            {site.columns.TRI && Object.keys(site.columns.TRI).map((elem, key) => {
                                                                                return site.columns.TRI[elem] && <span className="px-2" style={{ borderLeft: "1px solid black" }} key={key}>{global._trans("sites", elem)}</span>
                                                                            })}
                                                                            <hr />
                                                                        </>}
                                                                        {site.columns.TMB && <><strong className="py-3">{global._trans('shares', 'columnTechTMB')} : </strong>
                                                                            {site.columns.TMB && Object.keys(site.columns.TMB).map((elem, key) => {
                                                                                return site.columns.TMB[elem] && <span className="px-2" style={{ borderLeft: "1px solid black" }} key={key}>{global._trans("sites", elem)}</span>
                                                                            })}
                                                                            <hr />
                                                                        </>}
                                                                        {site.columns.ISDND && <><strong className="py-3">{global._trans('shares', 'columnTechISDND')} : </strong>
                                                                            {site.columns.ISDND && Object.keys(site.columns.ISDND).map((elem, key) => {
                                                                                return site.columns.ISDND[elem] && <span className="px-2" style={{ borderLeft: "1px solid black" }} key={key}>{global._trans("sites", elem)}</span>
                                                                            })}
                                                                            <hr />
                                                                        </>}
                                                                        {site.columns.Client && <><strong>{global._trans('shares', 'columnsClient')} : </strong>
                                                                            {Object.keys(site.columns.Client).map((elem, key) => {
                                                                                return site.columns.Client[elem] && <span className="px-2" style={{ borderLeft: "1px solid black" }} key={key}>{global._trans("sites", elem)}</span>
                                                                            })}
                                                                            <hr />
                                                                        </>}
                                                                        {site.columns.Company && <><strong>{global._trans('shares', 'columnsSociete')} : </strong>
                                                                            {Object.keys(site.columns.Company).map((elem, key) => {
                                                                                return site.columns.Company[elem] && <span className="px-2" style={{ borderLeft: "1px solid black" }} key={key}>{global._trans("sites", elem)}</span>
                                                                            })}
                                                                        </>}
                                                                        <hr />
                                                                        <strong>GED</strong>: {!!site.files ? Object.keys(site.files).filter((file) => site.files[file]).join(', ') : null}
                                                                        <hr />
                                                                        <strong>Contracts</strong>: {!!site.contracts ? Object.keys(site.contracts).filter((contract) => site.contracts[contract]).join(', ') : null}
                                                                    </>}
                                                            </Panel>
                                                        }
                                                    </Collapse>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Card>
                                    </div>
                                })}</div>
                            <div className="d-flex justify-content-end mt-3">
                                <Pagination current={this.state.page} onChange={this.onPaginationChange} pageSize={12} showSizeChanger={false} total={this.state.total} />
                            </div>
                        </div>

                    </Spin>
                </div>
                {this.state.updateSharesGroupe && <UpdateShares handleSubmit={this.handleUpdateShareModalSubmit} handleChangeName={this.handleUpdateShareModal} infos={this.state.updateSharesGroupe ? this.state.updateSharesGroupe : { start: null, end: null }} loading={false} show={this.state.updateSharesGroupe} onHide={(e) => this.setState({ updateSharesGroupe: null })} />}
                {this.state.inQueueShare && <RenewShare fileCategories={this.props.enums.fileCategory} callBack={this.handleValidEdit} show={this.state.inQueueShare} onHide={() => this.handleRenewShare(-1)} />}
            </>
        )
    }
}

const mapState = (state) => {
    const { listEnums } = state.enums;
    return { enums: listEnums }
}
const connectedClass = connect(mapState, {})(SharedSites)
export { connectedClass as SharedSites }