import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ListUsers } from './ListUsers'
import { CreateUser } from './CreateUser'
import { ViewUser } from './ViewUser'
class UserPage extends Component {
    render() {
        return (
            <Switch>
                <Route
                    path="/users/premium/view/:idUser"
                    component={ViewUser}
                />
                <Route
                    path="/users/premium/:idCreate"
                    component={CreateUser}
                />
                <Route
                    path="/users/premium"
                    component={ListUsers}
                />
            </Switch>
        )
    }
}
export { UserPage as UserPremieum }