import { siteSlice } from "./sites.reducer";
import * as SiteRequests from "./sites.crud";
const { actions } = siteSlice;

export const getGroupes = () => dispatch => {
    return SiteRequests
        .getGroupes()
        .then(res => {
            if(res && res.ok && res.data){
                dispatch(actions.groupsListed(res.data))
            }else{
                dispatch(actions.catchError({errors:"fail to load sites",callType:'getting Groups'}))
            }
        })
        .catch(err => {
            dispatch(actions.catchError({errors:"fail to load sites",callType:'getting Groups'}))
        });
};

export const createSite = SiteToCreate => dispatch => {
    return SiteRequests
        .createSite(SiteToCreate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.siteCreated({}));
            } else {
                dispatch(actions.catchError({ errors: { error: global._trans("common", "messageErrors") } }));
            }
        })
        .catch(({ message, errors, level }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: { listErr: errors, lvl: level } }));
            } else {
                dispatch(actions.catchError({ errors: { error: global._trans("common", "messageErrors") } }));
            }
        });
};
export const updateSite = SiteToUpdate => dispatch => {
    return SiteRequests
        .updateSite(SiteToUpdate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.siteCreated({}));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(({ message, errors, level }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: { listErr: errors, lvl: level } }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        });
};
export const fetchSites = QueryParams => dispatch => {
    return SiteRequests
        .fetchSites(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.sitesFetched({ listSites: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const fetchSitesGet = QueryParams => dispatch => {
    return SiteRequests
        .fetchSitesGet(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.sitesFetched({ listSites: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const fetchSite = idContract => dispatch => {
    return SiteRequests
        .fetchSite(idContract)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.siteFetched({ Site: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const editSite = idSite => dispatch => {
    return SiteRequests
        .editSite(idSite)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.siteFetched({ Site: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const deleteSites = Sites => dispatch => {
    return SiteRequests
        .deleteSites(Sites)
        .then(({ ok, data, sites }) => {
            if (ok) {
                dispatch(actions.sitesDeleted({ asyncData: data === "async", sites: sites }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const fetchDetailShare = (idSite) => dispatch => {
    // dispatch(actions.startCall({callType:callTypes.detailShare}))
    return SiteRequests
        .fetchDetailShare(idSite)
        .then(res => {
            if(res && res.data && res.ok){
                dispatch(actions.detailFetched({detail:res.data}))
            }else{
                console.log(res)
                // dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.detailShare}))
            }
        })
        .catch(err => {
            console.log(err)
            // dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.detailShare}))
        });
};

export const exportSites = (categories = {}) => dispatch => {
    return SiteRequests
        .exportSites(categories)
        .then((data) => { })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
