import React from "react";
import useSound from 'use-sound';

export const DropdownTopbarItemToggler = React.forwardRef((props, ref) => {
  const [playNotif] = useSound('/notification_sound.mp3',
    { volume: 0.6 }
  );
  return (
    <div
      ref={ref}
      className="topbar-item"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
        // playNotif();
      }}
    >
      {props.children}
    </div>
  );
});

DropdownTopbarItemToggler.displayName = 'DropdownTopbarItemToggler';
