import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { Extension, TypeInstalation, TypeDechetAccept, TypeFoursChaudiere, Technologie, ValorisationEnergitique, AutreActivite, TypeDechetRecu, TraitementFumee, InstalationCople, VoiTraitementFumme, TraitementNox, EquipementProcessTF, Reactif, TypeTurboalternateur, ConstructeurInstallUVE, CommonComponentWrapper } from './SiteComponent'
import { TimeLineHelper } from './utils/TimeLineHelper'
class SiteData extends Component {
    state = {
        dataPaths: [
            { path: "/settings/tech/agregateurElectrique", name: global._trans('sites', 'agregateurElectrique'), keyName: 'agregateurElectrique' },
            { path: "/settings/tech/activites", name: global._trans('sites', 'autherActivite'), keyName: 'autreActivite' },
            { path: "/settings/tech/clients", name: global._trans('settings', 'clients'), keyName: 'clients' },
            { path: "/settings/tech/constructeurChaudiere", name: global._trans('sites', 'constructeurChaudiere'), keyName: 'constructeurChaudiere' },
            { path: "/settings/tech/construction", name: global._trans('sites', 'installConstruUve'), keyName: 'constructeurInstallation' },
            { path: "/settings/tech/processtf", name: global._trans('sites', 'equipementTF'), keyName: 'equipeProcessTF' },
            { path: "/settings/tech/extension", name: global._trans('settings', 'extension'), keyName: 'extension' },
            { path: "/settings/tech/instalationcople", name: global._trans('settings', 'installComplem'), keyName: 'installationComplementair' },
            { path: "/settings/tech/marqueEquipement", name: global._trans('sites', 'marqueEquipement'), keyName: 'marqueEquipement' },
            { path: "/settings/tech/RCUIndustirel", name: global._trans('sites', 'RCUIndustirel'), keyName: 'RCUIndustirel' },
            { path: "/settings/tech/reactifNOX", name: global._trans('settings', 'reactifNOX'), keyName: 'reactifNOX' },
            { path: "/settings/tech/reactif", name: global._trans('settings', 'reactif'), keyName: 'reactif' },
            { path: "/settings/tech/technologies", name: global._trans('settings', 'technologie'), keyName: 'technologie' },
            { path: "/settings/tech/traitementfemu", name: global._trans('settings', 'traitmentFumme'), keyName: 'traitementFumee' },
            { path: "/settings/tech/instalation", name: global._trans('settings', 'typeInstall'), keyName: 'typeInstallation' },
            { path: "/settings/tech/turboalternateur", name: global._trans('settings', 'typeTurbu'), keyName: 'typeTerboalternateur' },
            { path: "/settings/tech/typeChaudiere", name: global._trans('sites', 'typeChaudiere'), keyName: 'typeChaudiere' },
            { path: "/settings/tech/dechetaccepter", name: global._trans('settings', 'dechetAccepte'), keyName: 'typeDechetAccepter' },
            { path: "/settings/tech/typeFours", name: global._trans('sites', 'typeFours'), keyName: 'typeFours' },
            { path: "/settings/tech/traitementnox", name: global._trans('settings', 'traitmentNox'), keyName: 'traitementNOX' },
            { path: "/settings/tech/typeEquipement", name: global._trans('sites', 'typeEquipement'), keyName: 'typeEquipement' },
            { path: "/settings/tech/dechetrecu", name: global._trans('settings', 'typeDechetRecu'), keyName: 'typeDechetRecus' },
            { path: "/settings/tech/typeFoursChaudiere", name: global._trans('settings', 'typeFoursChaudiere'), keyName: 'typeFoursChaudiere' },
            { path: "/settings/tech/valorisations", name: global._trans('settings', 'valoriEnergitique'), keyName: 'valorisationEnergitique' },
            { path: "/settings/tech/voitraitement", name: global._trans('settings', 'voiTraitment'), keyName: 'voiTraiFemuee' },
            // paths for valorisations and lines
            // { path: "/settings/tech/fileCategory", name: global._trans('sites', 'fileCategory'), keyName: 'fileCategory' },
        ]
    }
    render() {
        return (
            <div className="card card-custom card-stretch">
                <div className="card-header py-3" style={{ minHeight: "0px" }}>
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">{global._trans('settings', 'siteSettingsTitle')}</h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">{global._trans('settings', 'subTitleSite')}</span>
                    </div>
                </div>
                <div className="card-body py-4">
                    <div className="row">
                        <div className="col-xl-4 border-right">
                            <div className="timeline timeline-6 mt-3">
                                <TimeLineHelper lines={this.state.dataPaths} />
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <Switch>
                                <Redirect from="/settings/tech" exact={true} to="/settings/tech/agregateurElectrique" />
                                {this.state.dataPaths.map((route) => <Route path={route.path} component={(props) => <CommonComponentWrapper keyName={route.keyName} title={route.name} />} />)}
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export { SiteData }