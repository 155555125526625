import { shareSlice } from "./shares.reducer";
import * as ShareRequests from "./shares.crud";
const { actions } = shareSlice;
export const createShare = SiteToShare => dispatch => {
    return ShareRequests
        .shareSite(SiteToShare)
        .then(({ok}) => {
            if (ok) {
                dispatch(actions.shareCreated({}));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") })); 
            }
        })
        .catch(({message,errors}) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        });
};
export const fetchShares = QueryParams => dispatch => {
    return ShareRequests
        .fetchShares(QueryParams)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.sharesFetched({ listShares: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const handleStatusShare = Share => {
    return ShareRequests
        .handleStatus(Share)
};
export const handleDeleteShare = Shares => {
    return ShareRequests
        .handleDelete(Shares)
};
export const handleExtendShare = Shares => {
    return ShareRequests
        .handleExtend(Shares)
};