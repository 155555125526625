import React, { Component } from 'react';
import { Form, Col } from "react-bootstrap";
import { Select } from 'antd';
import { connect } from 'react-redux'
import { ImageCroper, PhonePicker, DepartementSearch, RegionSearch, FileUploader, AdressPicker } from '../../../../Commons'

const sitesStatus = ['VALIDATED', 'NOT_VALIDATED', 'NOT_PUBLISHED'];
const states = [{label: 'Actif', value: 'A'}, {label: 'Fermé', value: 'C'}];
class GeneraleInfo extends Component {
    render() {
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'state')}</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans("common", "state")}
                            value={this.props.syndicat.state}
                            style={{ width: '100%' }}
                            options={states}
                            onChange={(selected) => this.props.handleName('state', selected)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsSyndicat', 'nameSyndicat')}*</Form.Label>
                        <Form.Control type="text" name="nomCourt" defaultValue={this.props.syndicat.nomCourt} placeholder={global._trans('clientsSyndicat', 'nameSyndicat')} onChange={this.props.handleChange} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'denomiLegal')}*</Form.Label>
                        <Form.Control type="text" name="denominationLegale" defaultValue={this.props.syndicat.denominationLegale} placeholder={global._trans('common', 'denomiLegal')} onChange={this.props.handleChange} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'usual_denomination')}</Form.Label>
                        <Form.Control type="text" name="usual_denomination" defaultValue={this.props.syndicat.usual_denomination} placeholder={global._trans('common', 'usual_denomination')} onChange={this.props.handleChange} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Siret</Form.Label>
                        <Form.Control type='number' placeholder={global._trans('clientsSyndicat', 'siret')} defaultValue={this.props.syndicat.siret} name="siret"  onChange={this.props.handleChange} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsSyndicat', 'serin')}*</Form.Label>
                        <Form.Control type='number' placeholder={global._trans('clientsSyndicat', 'serin')} defaultValue={this.props.syndicat.serin} name="serin"  onChange={this.props.handleChange} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'sinoe')}*</Form.Label>
                        <Form.Control type="text" name="sinoe" defaultValue={this.props.syndicat.sinoe} placeholder={global._trans('clientsEpic', 'sinoe')} onChange={this.props.handleChange} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'address')}*</Form.Label>
                        <AdressPicker value={this.props.syndicat.adresse} onChange={this.props._suggestionSelect} />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-0">
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans('common', 'city')}*</Form.Label>
                                <Form.Control type='text' placeholder={global._trans('common', 'city')} name='city' value={this.props.syndicat.city} onChange={({ target: { name, value } }) => this.props.handleName(name, value)} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans('common', 'country')}*</Form.Label>
                                <Form.Control type='text' placeholder={global._trans('common', 'country')} name='country' value={this.props.syndicat.country} onChange={({ target: { name, value } }) => this.props.handleName(name, value)} />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'postcode')}*</Form.Label>
                        <Form.Control type='text' placeholder={global._trans('common', 'postcode')} name='postcode' value={this.props.syndicat.postcode} onChange={({ target: { name, value } }) => this.props.handleName(name, value)} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'email')}*</Form.Label>
                        <Form.Control type="email" name="email" defaultValue={this.props.syndicat.email} placeholder={global._trans('common', 'email')} onChange={this.props.handleChange} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'departementSiege')}*</Form.Label>
                        <DepartementSearch placeholder={global._trans('common', 'selectDepartementSiege')} onChange={(selected) => this.props.handleName('departement_siege', selected)} defaultOptions={this.props.defaultDepartement} value={this.props.syndicat.departement_siege} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'regionSiege')}*</Form.Label>
                        <RegionSearch placeholder={global._trans('common', 'selectRegionSiege')} disabled={true} defaultOptions={this.props.defaultRegions} onChange={(selected) => this.props.handleName('region_siege', selected)} value={this.props.syndicat.region_siege} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'natureJuridque')}*</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('common', 'selectNatureJuridque')}
                            style={{ width: '100%' }}
                            optionFilterProp="label"
                            value={this.props.syndicat.nature_juridique}
                            loading={!this.props.nature_juridique}
                            options={this.props.nature_juridique}
                            onChange={(selected) => this.props.handleName('nature_juridique', selected)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsSyndicat', 'amobe')}</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            optionFilterProp="label"
                            placeholder={global._trans('clientsSyndicat', 'selectAmobe')}
                            style={{ width: '100%' }}
                            value={this.props.syndicat.amobe}
                            loading={!this.props.amobe}
                            options={this.props.amobe}
                            onChange={(selected) => this.props.handleName('amobe', selected)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'webSite')}</Form.Label>
                        <Form.Control type='text' placeholder={global._trans('common', 'webSite')} defaultValue={this.props.syndicat.siteInternet} name='siteInternet' onChange={this.props.handleChange} />
                    </Form.Group>
                </Form.Row>
                <div className="separator separator-solid mb-4"></div>
                <Form.Row>
                    {/* <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsSyndicat', 'ged_rapport')}</Form.Label>
                        <FileUploader files={this.props.syndicat.ged_rapport} onChange={(files) => this.props.handleName('ged_rapport', files)} />
                    </Form.Group> */}
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'logoCompany')}</Form.Label>
                        <ImageCroper images={this.props.syndicat.logo} onChange={(images) => this.props.handleName('logo', images)} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'standrPhone')}</Form.Label>
                        <PhonePicker
                            value={this.props.syndicat.telephoneStandard}
                            onChange={(value) => this.props.handleName("telephoneStandard", value)} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Row>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'population')}</Form.Label>
                                <Form.Control type="number" name="nombreHabitant" defaultValue={this.props.syndicat.nombreHabitant} placeholder={global._trans('common', 'population')} disabled="true" />
                            </Form.Group>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'population_year')}</Form.Label>
                                <Form.Control type="number" name="nombreHabitant_year" defaultValue={this.props.syndicat.nombreHabitant_year || ''} placeholder={global._trans('common', 'population_year')} onChange={this.props.handleChange} />
                            </Form.Group>
                        </Form.Row>
                        {this.props.syndicat.date_enter && <div className="px-2"><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.props.syndicat.date_enter)}</div>}
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans('common', 'effectifs')}</Form.Label>
                                <Select
                                    showSearch
                                    showArrow
                                    className="inputSizeSelect"
                                    placeholder={global._trans('common', 'selectNatureJuridque')}
                                    style={{ width: '100%' }}
                                    optionFilterProp="label"
                                    value={this.props.syndicat.effectifs}
                                    loading={!this.props.effectifs}
                                    options={this.props.effectifs}
                                    onChange={(selected) => this.props.handleName('effectifs', selected)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'effectifs_year')}</Form.Label>
                                <Form.Control type="number" name="effectifs_year" defaultValue={this.props.syndicat.effectifs_year || ''} placeholder={global._trans('common', 'effectifs_year')} onChange={this.props.handleChange} />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "status")}*</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans("sites", "status")}
                            value={this.props.syndicat.status}
                            style={{ width: '100%' }}
                            options={sitesStatus.map((value) => ({ label: global._trans("sites", `status_${value}`), value }))}
                            onSelect={(SelectedValue) => this.props.handleName("status", SelectedValue)}
                        />
                    </Form.Group>
                </Form.Row>
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { nature_juridique, amobe, effectifs } } = state.enums
    return { nature_juridique, amobe, effectifs }
}
const connectedClass = connect(mapState, null)(GeneraleInfo)
export { connectedClass as GeneraleInfo }