import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_sage/_helpers";
import * as Apis from '../../modules/Auth/auth.crud';
import { actions } from '../../modules/Auth/auth.reducer';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { CostumAlert } from '../PrivatePages/Commons'
export const Login = (props) => {
    const initState = {
        username: "",
        password: "",
        forgattenPasswordSuccess: false,
        forgattenPasswordError: '',
        showPasswordLoading: false,
        forgattenPassword: false,
        otpEmail: '',
        otpId: '',
        otpShow: false,
        otpCode: '',
        otpError: '',
        otpLoading: false,
        otpResent: false,
        otpResentLoading: false
    }
    const [state, setState] = useState(initState)
    const [loading, setLoading] = useState(false)
    const [showErrors, setErrors] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory();
    const onChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!loading) {
            setLoading(true)
            setErrors(false)
            if (state.username && state.password) {
                Apis.login(state)
                    .then(res => {
                        if (res && res.ok && res.otp) {
                            setState({
                                ...state,
                                otpEmail: res.email,
                                otpId: res.id,
                                otpShow: true
                            })
                            setLoading(false)
                        } else if (res.ok && ["Admin", "Gestionnaire", "SupAdmin"].indexOf(res.typeuser) > -1) {
                            console.log('here 2!')
                            dispatch(actions.login(res.token))
                            window.location.reload();
                        } else {
                            console.log('here 3!')
                            setErrors(true)
                            setLoading(false)
                        }
                    })
                    .catch(err => {
                        setErrors(true)
                        setLoading(false)
                    })
            }
        }
    }
    const handleForgettenPassword = (e) => {
        e.preventDefault()
        if (state.Email && !state.showPasswordLoading) {
            setState({ ...state, showPasswordLoading: true, forgattenPasswordError: '', forgattenPasswordSuccess: false })
            Apis.sendEmailPassword({ email: state.Email, type: 'adminApp' })
                .then(({ message }) => {
                    if (message === "Email sent successfully") {
                        setState({ ...state, Email: "", forgattenPassword: false, forgattenPasswordError: '', forgattenPasswordSuccess: true })
                    } else {
                        setState({ ...state, forgattenPasswordError: global._trans("common", "messageErrors"), forgattenPasswordSuccess: false  })
                    }
                })
                .catch(({ message, errors }) => {
                    setState({ ...state, forgattenPasswordError: message, forgattenPasswordSuccess: false })
                })
        }
    }
    const handleResend = (e) => {
        e.preventDefault()
        if (!state.otpResent && !state.otpResentLoading) {
            setState({
                ...state,
                otpResentLoading: true
            });
            Apis.login({ username: state.username, password: state.password })
                .then(res => {
                    if (res && res.ok && res.otp) {
                        setState({
                            ...state,
                            otpCode: '',
                            otpError: '',
                            otpResent: true
                        })
                    } else {
                        setState({
                            ...state,
                            otpError: 'Une erreur s\'est produite, veuillez réessayer',
                            otpResentLoading: false
                        })
                    }
                })
                .catch(err => {
                    setState({
                        ...state,
                        otpError: 'Une erreur s\'est produite, veuillez réessayer',
                        otpResentLoading: false
                    })
                })
        }
    }
    const handleOtp = (e) => {
        e.preventDefault()
        if (!state.otpLoading && state.otpCode) {
            setState({ ...state, otpLoading: true, otpError: '' })
            Apis.otp({ id: state.otpId, otp_code: state.otpCode })
                .then(res => {
                    if (res && res.ok && (res.typeuser === "Admin" || res.typeuser === "Gestionnaire" || res.typeuser === "SupAdmin")) {
                        dispatch(actions.login(res.token))
                        window.location.reload();
                    } else {
                        setState({
                            ...state,
                            otpLoading: false,
                            otpError: 'Le code de vérification est incorrect ou expiré'
                        })
                    }
                })
                .catch(err => {
                    setState({
                        ...state,
                        otpLoading: false,
                        otpError: err?.message == 'Too Many Attempts.' ? 'Trop de tentatives, veuillez réessayer après quelques secondes.' : 'Une erreur s\'est produite, veuillez réessayer'
                    })
                })
        }
    }
    return (
        <>
            <div className="w-100 h-100 d-flex justify-content-between align-items-center" style={{ backgroundColor: 'white' }}>
                <div className="loginLeft">
                    <img alt="image_login" src="/media/images/sage2.png" className='sage2Logo' />
                    <img alt="image_login" src="/media/images/sage3.png" className='sage1Logo' />
                </div>
                <div className="loginRight" style={{ zIndex: 10, flex: 1 }}>
                    <div className="d-flex flex-column px-5">
                        <form className="form mt-3 header_shadow user-select-none" onSubmit={handleSubmit}>
                            <h1 className="mt-2 mb-10 fw-bolder ms-4" style={{fontSize: 26}}>Connexion au Centre de données de RINA</h1>
                            {state.forgattenPasswordSuccess &&
                                <div className="form-group mb-7 col mt-6">
                                    <CostumAlert type='success' errors="L'email envoyé avec succès" />
                                </div>}
                            <div className="form-group mb-7 col mt-6">
                                <label className="fs-5 form-label fw-bold">{global._trans("login", "username")}</label>
                                <input className={`form-control form-control-lg form-control-solid fw-normal border ${!showErrors ? 'border-secondary' : 'border-danger'}`} type="text" onChange={onChange} name="username" required value={state.username} placeholder={global._trans("login", "username")} />
                            </div>
                            <div className="form-group mb-6 col position-relative">
                                <label className="fs-5 form-label fw-bold">{global._trans("login", "password")}</label>
                                <h2 className='passwordForgetten' onClick={() => { setState({ ...state, forgattenPassword: true }) }}>Mot de passe oublié ?</h2>
                                <div className={`inputPassowrdLoGIN border ${!showErrors ? 'border-secondary' : 'border-danger'}`}>
                                    <input className={`mr-3 form-control form-control-lg form-control-solid fw-normal`} type={`${state.showPassword ? 'text' : 'password'}`} onChange={onChange} name="password" required value={state.password} placeholder={global._trans("login", "password")} />
                                    {state.showPassword ?
                                        <BsEyeSlash size={23} onClick={() => { setState({ ...state, showPassword: false }) }} />
                                        :
                                        <BsEye size={23} onClick={() => { setState({ ...state, showPassword: true }) }} />
                                    }
                                </div>
                            </div>
                            <div className='my-6 text-center'>
                                {showErrors && <strong className="text-danger fs-6">{global._trans("login", "checkYourInfo")}</strong>}
                            </div>
                            <div className="form-group mb-8 col text-center">
                                <button type="submit" className="btn px-20 fs-6 fw-bolder btn-primary">
                                    {loading ? "Chargement..." : "Connexion"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {state.forgattenPassword && (
                    <div className='modalForgattenPassword'>
                        <div className='content'>
                            <h1 className='py-3'>Mot de passe oublié</h1>
                            <hr />
                            <br />
                            <div className="form-group col">
                                <label className="fs-5 form-label fw-bold">Email</label>
                                <input className={`form-control form-control-lg form-control-solid fw-normal border border-secondary'`} type="email"
                                    onChange={onChange} name="Email" required value={state.Email} placeholder='Email' />
                                <p className='mt-6 ms-1'>Un mail sera envoyé à cette adresse email : <strong>{state.Email}</strong></p>
                                {state.forgattenPasswordError && (
                                    <div className='my-5 text-white bg-danger text-center rounded py-3'>
                                        {state.forgattenPasswordError}
                                    </div>
                                )}
                            </div>
                            <br />
                            <hr />
                            <div className="form-group mb-1 col text-center pt-2 d-flex justify-content-center align-items-center">
                                <button type="button" className="btn px-8 fs-6 me-8 fw-bolder btn-danger"
                                    onClick={() => {
                                        setState({ ...state, forgattenPassword: false, Email: '', forgattenPasswordError: "" })
                                    }}> Annuler </button>
                                <button type="button"
                                    onClick={handleForgettenPassword}
                                    disabled={!state.Email || state.showPasswordLoading}
                                    className="btn px-8 fs-6 fw-bolder btn-success d-flex justify-content-between align-items-center">
                                    {state.showPasswordLoading ?
                                        <>
                                            <AiOutlineLoading3Quarters size={24} className='me-4 loadingAnimation' />
                                            <span>Envoi en cours</span>
                                        </>
                                        :
                                        'Envoyer'
                                    }
                                </button>
                            </div>

                        </div>
                    </div>
                )}
                {state.otpShow && (
                    <div className='modalForgattenPassword'>
                        <div className='content'>
                            <h1 className='py-3'>Vérification requise</h1>
                            <hr />
                            <br />
                            <div className="form-group col">
                                <label className="fs-5 form-label fw-bold">Code de vérification</label>
                                <input className={`form-control form-control-lg form-control-solid fw-normal border border-secondary'`} type="number"
                                        name="otpCode" required onChange={onChange} placeholder='Veuillez saisir le code de vérification' />
                                {state.otpResent ?
                                    <p className='mt-6 ms-1'>Le code de vérification a été renvoyé à cette adresse email : <strong>{state.otpEmail}</strong></p>
                                    :
                                    state.otpResentLoading ?
                                    <p className='mt-6 ms-1'>Renvoi du code de vérification en cours...</p>
                                    :
                                    <p className='mt-6 ms-1'>Un code de vérification a été envoyé à cette adresse email : <strong>{state.otpEmail}</strong><br/>Si vous ne l'avez pas reçu, <a disabled={state.otpResentLoading} style={{fontWeight: '400', color: '#3699ff', cursor: 'pointer', transition: 'all 0.3s'}} onClick={handleResend}>cliquez ici pour le renvoyer.</a></p>
                                }
                                {(state.otpError && (
                                    <div className='text-danger text-center rounded' style={{fontSize: '14px', fontWeight: '500'}}>
                                        {state.otpError}
                                    </div>
                                )) || <br/>}
                            </div>
                            <hr />
                            <div className="form-group mb-1 col text-center pt-2 d-flex justify-content-center align-items-center">
                                <button type="button" className="btn px-8 fs-6 me-8 fw-bolder btn-danger"
                                    onClick={() => {
                                        setState({ ...state, otpShow: false, otpId: '', otpEmail: '', otpCode: '', otpError: '' })
                                    }}> Annuler </button>
                                <button type="button"
                                    onClick={handleOtp}
                                    disabled={!state.otpCode || state.otpLoading}
                                    className="btn px-8 fs-6 fw-bolder btn-success d-flex justify-content-between align-items-center">
                                    {state.otpLoading ?
                                        <>
                                            <AiOutlineLoading3Quarters size={24} className='me-4 loadingAnimation' />
                                            <span>Validation en cours</span>
                                        </>
                                        :
                                        'Valider'
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}