import React from 'react'
import { Link } from 'react-router-dom'
import { SageTable } from '../../Commons'
export const ListClients = (props) => {
    const clientsUrls = {
        Syndicat: '/client/communities/syndicat/view/',
        Epic: '/client/communities/epic/view/',
        Commune: '/client/communities/communes/view/'
    }
    return (
        <div>
            <SageTable
                columns={[
                    { title: "ID", dataIndex: "id_person" },
                    { title: "Type client", dataIndex: "typePersonMoral" },
                    {
                        title: `${global._trans('client', 'commune')} / ${global._trans('client', 'epic')} / ${global._trans('client', 'syndicat')}`, dataIndex: "dataIndex", render: (dataIndex, key, row) => {
                            return <> {row.name} {row[row.dataIndex]}</>
                        }
                    },
                    { title: global._trans('common', 'address'), dataIndex: "adresse" },
                    {
                        title: global._trans('common', 'action'), dataIndex: "id_person", render: (id_person, key, row) => <Link to={clientsUrls[row.typePersonMoral] + id_person}>Voir le client</Link>
                    }
                ]}
                style={{ height: 620 }}
                data={props.clients}
                IterableProps={{
                    emptyText: global._trans('clientsEpic', 'emotyAssociCommune'),
                    rowKey: "id_commune",
                    pagination: false
                }}
            />
        </div>
    )
}