import React from 'react'
import { NavLink } from 'react-router-dom'
export const TimeLineHelper = (props) => {
    return (
        props.lines.map((line, key) => {
            return <div key={key} className="timeline-item align-items-start">
                <NavLink to={line.path} activeClassName="active-timeline-badge" className="timeline-badge"></NavLink>
                <div className="timeline-content d-flex">
                    <NavLink to={line.path} activeClassName="active-timeline-item" className="font-weight-bolder text-dark-75 pl-3 font-size-md">{line.name}</NavLink>
                </div>
            </div>
        })
    )
}