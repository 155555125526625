import { createSlice } from "@reduxjs/toolkit";
const queryString = require('query-string');
const initialEnumsState = {
    listEpics: {},
    listCommunes: {},
    listSyndicats: {},
    querySyndicat:"",
    queryEpic:"",
    queryCommune:"",
    Syndicat: null,
    Epic: null,
    Commune: null,
    EpicErrors: null,
    CommuneErrors: null,
    SyndicatErrors: null
};

export const collectivitieSlice = createSlice({
    name: "collectivitie",
    initialState: initialEnumsState,
    reducers: {
        catchError: (state, action) => {
            const { errors, errorType } = action.payload
            state[errorType] = errors;
        },
        epicCreated: (state) => {
            state.EpicErrors = state.EpicErrors === false ? null : false
            state.Epic=null
        },
        epicsFetched: (state, action) => {
            const { listEpics,currentQuery } = action.payload
            state.queryEpic='?'+queryString.stringify(currentQuery)
            state.listEpics = listEpics
            state.EpicErrors = null
        },
        epicFetched: (state, action) => {
            const { epic } = action.payload
            state.Epic = epic
            state.EpicErrors = null
        },
        epicSynced: (state, action) => {
            state.Epic = {refresh: true}
        },
        epicsDeleted: (state, action) => {
            const { asyncData, epics } = action.payload
            if (asyncData) {
                let prevList = state.listEpics
                prevList.data = prevList.data.filter(epic => epics.indexOf(epic.id_epic) === -1)
                state.listEpics = prevList
            }
            state.EpicErrors = state.EpicErrors === false ? null : false
        },
        /************************************ */
        communeCreated: (state) => {
            state.CommuneErrors = state.CommuneErrors === false ? null : false
            state.Commune=null
        },
        communesFetched: (state, action) => {
            const { listCommunes,currentQuery } = action.payload
            state.queryCommune='?'+queryString.stringify(currentQuery)
            state.listCommunes = listCommunes
            state.CommuneErrors = null
        },
        communeFetched: (state, action) => {
            const { commune } = action.payload
            state.Commune = commune
            state.CommuneErrors = null
        },
        communeSynced: (state, action) => {
            state.Commune = {refresh: true}
        },
        communesDeleted: (state, action) => {
            const { asyncData, communes } = action.payload
            if (asyncData) {
                let prevList = state.listCommunes
                prevList.data = prevList.data.filter(commune => communes.indexOf(commune.id_commune) === -1)
                state.listCommunes = prevList
            }
            state.CommuneErrors = state.CommuneErrors === false ? null : false
        },
        /********************************** */
        syndicatCreated: (state) => {
            state.SyndicatErrors = state.SyndicatErrors === false ? null : false
            state.Syndicat=null
        },
        syndicatsFetched: (state, action) => {
            const { listSyndicats,currentQuery } = action.payload
            state.listSyndicats = listSyndicats
            state.SyndicatErrors = null
            state.querySyndicat='?'+queryString.stringify(currentQuery)
        },
        syndicatFetched: (state, action) => {
            const { syndicat } = action.payload
            state.Syndicat = syndicat
            state.SyndicatErrors = null
        },
        syndicatSynced: (state, action) => {
            state.Syndicat = {refresh: true}
        },
        syndicatsDeleted: (state, action) => {
            const { asyncData, syndicats } = action.payload
            if (asyncData) {
                let prevList = state.listSyndicats
                prevList.data = prevList.data.filter(syndicat => syndicats.indexOf(syndicat.id_syndicat) === -1)
                state.listSyndicats = prevList
            }
            state.SyndicatErrors = state.SyndicatErrors === false ? null : false
        },
    }
});