import React from 'react'
import { SageTable } from '../../Commons'
export const Contacts = (props) => {
    return (
        <>
            <SageTable
                columns={[
                    {
                        title: global._trans('contacts', 'status'), dataIndex: "status", render: status =>
                            <div className="symbol align-self-start align-self-xxl-center">
                                <div>{status ? global._trans('contacts', 'active') : global._trans('contacts', 'inactive')}</div>
                                <i className={`symbol-badge bg-${status ? "success" : "danger"}`}></i>
                            </div>
                    },
                    { title: global._trans('common', 'firstName'), dataIndex: "nom" },
                    { title: global._trans('common', 'lastName'), dataIndex: "prenom" },
                    { title: global._trans('contacts', 'sexe'), dataIndex: "genre" },
                    { title: global._trans('common', 'email'), dataIndex: "email" },
                    { title: global._trans('common', 'phone'), dataIndex: "telephone" },
                    { title: global._trans('common', 'mobile'), dataIndex: "mobile" },
                    { title: global._trans('common', 'address'), dataIndex: "address" },
                    { title: global._trans('common', 'fonction'), dataIndex: "persons_moral", render: (persons_moral) => {
                        try{
                            if(persons_moral[0].fonction_person[0].functionPersonString){
                                return  persons_moral[0].fonction_person[0].functionPersonString.label;
                            }
                            return '';
                        }catch(e){
                            return '';
                        }
                    }},
                ]}
                style={{ height: 540 }}
                data={props.contacts}
                IterableProps={{
                    emptyText: global._trans('contacts', 'emptyContactAsso'),
                    rowKey: "id_contact",
                    pagination: false
                }}
            />
            <br />
        </>
    )
}