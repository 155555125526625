import { createSlice } from "@reduxjs/toolkit";
const queryString = require('query-string');
const initialState = {
    errorsSociete: null,
    listSocietes: {},
    societe: null,
    currentQuery:""
};

export const societeSlice = createSlice({
    name: "societe",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { errors } = action.payload
            state.errorsSociete = errors;
        },
        resetState:(state, action) => {
            action.payload && action.payload.map(elem=>{
                state[elem.name]=elem.value
            })
        },
        societeCreated: (state) => {
            state.errorsSociete = state.errorsSociete === false ? null : false
        },
        societesFetched: (state, action) => {
            const { listSocietes,currentQuery } = action.payload
            state.errorsSociete = null
            state.currentQuery='?'+queryString.stringify(currentQuery)
            state.listSocietes = listSocietes
        },
        societeFetched: (state, action) => {
            const { societe } = action.payload
            state.errorsSociete = null
            state.societe = societe
        },
        societesSynced: (state, action) => {
            state.societe = {refresh: true}
        },
        societesDeleted: (state, action) => {
            const { asyncData, societies } = action.payload
            if (asyncData) {
                let prevList = state.listSocietes
                prevList.data = prevList.data.filter(societe => societies.indexOf(societe.id_societe_exploitant) === -1)
                state.listSocietes = prevList
            }
            state.errorsSociete = state.errorsSociete === false ? null : false
        },
    }
});