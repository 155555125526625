import React, { Component } from 'react';
import { Form, Col } from "react-bootstrap";
class DataTechTMB extends Component {
    render() {
        const { stateTech } = this.props
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Quantité de refus (t)</Form.Label>
                        <Form.Control type="number" value={stateTech.quantiteRefus} placeholder="Quantité de refus (t)" name="quantiteRefus" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>CSR produit (t)</Form.Label>
                        <Form.Control type="number" value={stateTech.CSRProduit} placeholder="CSR produit (t)" name="CSRProduit" disabled={true} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Envoi pour préparation CSR (t)</Form.Label>
                        <Form.Control type="number" defaultValue={stateTech.envoiPreparation} placeholder="Envoi pour préparation CSR (t)" name="envoiPreparation" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Tonnage annuel</Form.Label>
                        <Form.Control type="number" defaultValue={stateTech.tonnageAnnuel} placeholder="Tonnage annuel" name="tonnageAnnuel" disabled={true} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Capacité nominale</Form.Label>
                        <Form.Control type="number" defaultValue={stateTech.capaciteNominal} placeholder="Capacité nominale" name="capaciteNominal" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Dernier constructeur connu</Form.Label>
                        <Form.Control type="text" defaultValue={stateTech.dernierConstruct} placeholder="Dernier constructeur connu" name="dernierConstruct" disabled={true} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Type d'installations</Form.Label>
                        <Form.Control type="text" value={stateTech.typeInstallation} placeholder={global._trans('sites', 'typeInstallation')} name="typeInstallation" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Types de déchets acceptés</Form.Label>
                        <Form.Control type="text" value={!!stateTech.typeDechetAccepter && Array.isArray(stateTech.typeDechetAccepter) ? stateTech.typeDechetAccepter.map((info) => info.value_enum).join(', ') : ''} placeholder={global._trans('sites', 'technologie')} placeholder={global._trans('sites', 'typeDechetAccepter')} name="typeDechetAccepter" disabled={true} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Technologie</Form.Label>
                        <Form.Control type="text" value={!!stateTech.technologie && Array.isArray(stateTech.technologie) ? stateTech.technologie.map((info) => info.value_enum).join(', ') : ''} placeholder={global._trans('sites', 'technologie')} name="technologie" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Valoristaion energétique</Form.Label>
                        <Form.Control type="text" value={!!stateTech.valorisationEnergitique && Array.isArray(stateTech.valorisationEnergitique) ? stateTech.valorisationEnergitique.map((info) => info.value_enum).join(', ') : ''} placeholder={global._trans('sites', 'valorisationEnergitique')} name="valorisationEnergitique" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Autres activite du site</Form.Label>
                        <Form.Control type="text" value={!!stateTech.autreActivite && Array.isArray(stateTech.autreActivite) ? stateTech.autreActivite.map((info) => info.value_enum).join(', ') : ''} placeholder={global._trans('sites', 'autreActivite')} name="autreActivite" disabled={true} />
                    </Form.Group>
                </Form.Row>
            </>
        )
    }
}
export { DataTechTMB }