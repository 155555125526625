import React, { Component } from 'react';
import { Form, Col } from "react-bootstrap";
class DataTechTRI extends Component {
    render() {
        const { stateTech } = this.props
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Capacité horaire Tonnes/h</Form.Label>
                        <Form.Control type="number" placeholder="Capacité horaire Tonnes/h" value={stateTech.capaciteHoraire} name="capaciteHoraire" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Capacité nominale (T/an)</Form.Label>
                        <Form.Control type="number" placeholder="Capacité nominale (T/an)" value={stateTech.capaciteNominale} name="capaciteNominale" disabled={true} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Capacité réglementaire</Form.Label>
                        <Form.Control type="number" placeholder="Capacité réglementaire" value={stateTech.capaciteReglementaire} name="capaciteReglementaire" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Dernier constructeur connu</Form.Label>
                        <Form.Control type="text" placeholder="Dernier constructeur connu" value={stateTech.dernierConstructeur} name="dernierConstructeur" disabled={true} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Date d'extension</Form.Label>
                        <Form.Control type="text" placeholder="Date d'extension" value={stateTech.dateExtension} name="dateExtension" disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Date mise en service</Form.Label>
                        <Form.Control type="text" placeholder="Date mise en service" value={stateTech.miseEnService} name="miseEnService" disabled={true} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="d-block">Extension</Form.Label>
                        <Form.Control type="text" value={stateTech.extension} placeholder={global._trans('sites', 'extension')} name="extension" disabled={true} />
                    </Form.Group>
                </Form.Row>
            </>
        )
    }
}
export { DataTechTRI }