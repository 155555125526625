import React, { Component } from 'react'
import { connect } from "react-redux";
import { NavLink, Switch, Redirect, Route } from 'react-router-dom'
import { ClientData, SiteData, DepartementSiege, RegionsSiege } from './ListSettings'
import * as actions from "../../../modules/Settings/settings.actions";
class Settings extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="d-flex flex-row">
                <div className="h-max-content position-sticky" style={{ top: 80 }}>
                    <div
                        className="flex-row-auto offcanvas-mobile w-250px w-xxl-300px"
                        id="kt_profile_aside"
                    >
                        <div className="card card-custom">
                            <div className="card-body py-4">
                                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                    <div className="navi-item mb-2">
                                        <NavLink to="/settings/tech" className="navi-link py-4" activeClassName="active">
                                            <span className="navi-text font-size-lg">{global._trans('settings', 'siteSettingsTitle')}</span>
                                        </NavLink>
                                    </div>
                                    <div className="navi-item mb-2">
                                        <NavLink to="/settings/clients" className="navi-link py-4" activeClassName="active">
                                            <span className="navi-text font-size-lg">{global._trans('settings', 'clientSettingsTitle')}</span>
                                        </NavLink>
                                    </div>
                                    <div className="navi-item mb-2">
                                        <NavLink to="/settings/regions" className="navi-link py-4" activeClassName="active">
                                            <span className="navi-text font-size-lg">{global._trans('common', 'regionSiege')}</span>
                                        </NavLink>
                                    </div>
                                    <div className="navi-item">
                                        <NavLink to="/settings/departements" className="navi-link py-4" activeClassName="active">
                                            <span className="navi-text font-size-lg">{global._trans('common', 'departementSiege')}</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-row-fluid ml-lg-10">
                    <Switch>
                        <Redirect
                            from="/settings"
                            exact={true}
                            to="/settings/tech"
                        />
                        <Route
                            path="/settings/tech"
                            component={SiteData}
                        />
                        <Route
                            path="/settings/clients"
                            component={ClientData}
                        />
                        <Route
                            path="/settings/departements"
                            component={DepartementSiege}
                        />
                        <Route
                            path="/settings/regions"
                            component={RegionsSiege}
                        />
                    </Switch>
                </div>
            </div>
        )
    }
}
const connectedClass = connect(null, actions)(Settings)
export { connectedClass as Settings }