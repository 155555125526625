import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { notification } from 'antd';
import { importData } from "../../../modules/Common/common.actions";
import { Loader } from './Loader'
export const ImportData = ({ show, onHide, title, sourceFile }) => {
    const [file, setFile] = useState(null);
    const URL_ROOT = process.env.REACT_APP_HOST_API
    let field = '';
    if (sourceFile === 'communes') {
        field = 'Siren'
    } else
        if (sourceFile === 'epics') {
            field = 'Siren'
        } else
            if (sourceFile === 'syndicats') {
                field = 'Siren'
            } else
                if (sourceFile === 'gestionnaires') {
                    field = 'Email'
                } else
                    if (sourceFile === 'societes') {
                        field = 'Siren'
                    } else
                        if (sourceFile === 'contacts') {
                            field = 'Email'
                        } else if (sourceFile === 'contrats') {
                            field = 'contrats'
                        }
                        else {
                            field = ''
                        }

    const examples = {
        'communes': '/media/excels/models/communes.xlsx',
        'contacts': '/media/excels/models/contacts.xlsx',
        'contrats': '/media/excels/models/contrats.xlsx',
        'epics': '/media/excels/models/epics.xlsx',
        'syndicats': '/media/excels/models/syndicats.xlsx',
        'competences_syndicats': '/media/excels/models/competences.xlsx',
        'competences_epcis': '/media/excels/models/competences.xlsx',
        'gestionnaires': '/media/excels/models/gestionnaires.xlsx',
        'sites_isdnd': '/media/excels/models/sites_isdnd.xlsx',
        'sites_tmb': '/media/excels/models/sites_tmb.xlsx',
        'sites_tri': '/media/excels/models/sites_tri.xlsx',
        'sites_uve': '/media/excels/models/sites_uve.xlsx',
        'societes': '/media/excels/models/societes.xlsx',
        'Departments': '/media/excels/models/Departments.xlsx',
        'Regions': false,
        // 'CommunesMAJ': `${URL_ROOT}data/download/CommunesMAJ`,

        // 'EpicsMAJ': `${URL_ROOT}data/download/EpicsMAJ`,

        // 'SyndicatsMAJ': `${URL_ROOT}data/download/SyndicatsMAJ`,


        // 'ContactsMAJ': `${URL_ROOT}data/download/ContactsMAJ`,
    };

    const [loading, setLoading] = useState(false)
    const onChange = (e) => {
        console.log(e.target.files);
        const file = e.target.files[0];
        setFile(file)
    }
    const onConfirm = () => {
        let dataImport = new FormData();
        dataImport.append('file', file)
        dataImport.append('typeData', sourceFile)
        setLoading(true)
        importData(dataImport)
            .then(({ message, ok }) => {
                if (ok && message == "Import Started") {
                    notification.info({
                        message: global._trans('common', 'titleImported'),
                        description: global._trans('common', 'bodyImportedMessage'),
                        placement: 'bottomLeft',
                        // duration: 0,
                        style: {
                            width: 500,
                            padding: '15px 20px',
                            borderRadius: 8
                        },
                    })
                    onHide()
                }
            })
            .catch(({ }) => {

            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <Modal size="md" centered backdrop="static" show={show} onHide={onHide}>
            {loading && <Loader />}
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between px-5 py-4">
                    <span className="card-label font-size-h4 font-weight-bolder text-dark">{title}</span>
                </div>
                <div className="card-body py-4">
                    <div className="">
                        <label>{global._trans('common', 'selectFileExcel')}</label>
                        <input type="file" name="excel_file" onChange={onChange} className="btn btn-light btn-block" />
                    </div>
                </div>
                <div className="pt-5 px-9">
                    {sourceFile && examples[sourceFile] && <p>
                        <a href={examples[sourceFile]} download>Cliquez ici</a> pour telecharger le model d'importation
                    </p>
                    }
                </div>
                {field !== '' &&
                    <div className="pb-5 px-5 text-dark">
                        <hr />
                        <p className='px-4'>{field === 'contrats' ? `La mise à jour n'est pas supportée.`
                            : <>L'insertion/mise à jour sera exécutée en fonction de l'existence du champ '<b>{field}</b>
                            </>
                        }</p>
                    </div>
                }
                <div className="d-flex align-items-center justify-content-end py-3 px-4 border-top">
                    <button className="btn btn-light font-weight-bolder font-size-sm mr-2" onClick={onHide}>
                        {global._trans('common', 'cancelBtn')}
                    </button>
                    <button className="btn btn-primary font-weight-bolder font-size-sm" onClick={onConfirm}>
                        {global._trans('common', 'submitFile')}
                    </button>
                </div>
            </div>
        </Modal >
    )
}