import { CrudService } from "../../../services";
import { societeUrls } from './societe.constants'
export function createSociete(Societe) {
    return CrudService.Post(societeUrls.CREATE_SOCIETE_URL, Societe);
}
export function fetchSocietes(queryParams) {
    return CrudService.Post(societeUrls.FETCH_SOCIETES_URL, queryParams);
}
export function fetchSocietesGet(queryParams) {
    return CrudService.Get(societeUrls.FETCH_SOCIETES_URL, {}, queryParams);
}
export function fetchSociete(idSociete) {
    return CrudService.Get(societeUrls.FETCH_SOCIETE_URL + idSociete, {});
}
export function deleteSocietes(Societies) {
    return CrudService.Delete(societeUrls.DELETE_SOCIETES_URL, Societies);
}
export function editSociete(idSociete) {
    return CrudService.Get(societeUrls.EDIT_SOCIETE_URL + idSociete, {});
}
export function updateSociete(Societe) {
    return CrudService.Patch(societeUrls.UPDATE_SOCIETE_URL, Societe, {});
}
export function syncSocietiesInseeAPI(societies) {
    return CrudService.Post(societeUrls.SYNC_SOCIETES_URL, societies);
}
