import React from 'react'
import { Upload, Button } from 'antd';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
import { uploadImage } from '../../../modules/Common/common.actions'
export const FileUploader = (props) => {
    const handleUpload = (file) => {
        let data = new FormData()
        props.onChange([{
            uid: "-4",
            status: "Chargement..."
        }])
        data.append('file', file.file)
        uploadImage(data)
            .then(({ ok, image }) => {
                if (ok) {
                    props.onChange([image])
                }
            })
            .catch(err => {

            })
    }
    return (
        <div>
            <Upload
                fileList={props.files}
                onRemove={() => props.onChange([])}
                customRequest={handleUpload}
                listType="picture"
            >
                {props.files.length === 0 &&
                    <Button className="d-flex align-items-center" icon={<SVG src={toAbsoluteUrl('/media/svg/icons/Costum/Upload.svg')}></SVG>}>
                        <span className="ml-2">{global._trans("common", "selectFile")}</span>
                    </Button>}
            </Upload>
        </div>
    )
}