import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class CompetenceDechet extends Component {
    render() {
        return (
            <CommonComponent keyName="competence_dechet" titleSetting={global._trans('common', 'compDechetDelgue')} competence_dechet={this.props.competence_dechet} />
        )
    }
}
function mapState(state) {
    const { listEnums: { competence_dechet } } = state.enums
    return { competence_dechet }
}
const connectedClass = connect(mapState, null)(CompetenceDechet)
export { connectedClass as CompetenceDechet }