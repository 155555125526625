import { userSimpleSlice } from "./userSimple.reducer";
import * as userSimpleRequests from "./userSimple.crud";
const { actions } = userSimpleSlice;
export const fetchUsersSimple = QueryParams => dispatch => {
    return userSimpleRequests
        .fetchUsersSimple(QueryParams)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.usersSimpleFetched({ listUsers: data,currentQuery:QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const fetchUsersSimplePost = QueryParams => dispatch => {
    return userSimpleRequests
        .fetchUsersSimplePost(QueryParams)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.usersSimpleFetched({ listUsers: data,currentQuery:QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const updateUserSimple = user => {
    return userSimpleRequests.updateUserSimple(user)     
};