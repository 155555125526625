import { contactSlice } from "./contact.reducer";
import * as contactRequests from "./contact.crud";
const { actions } = contactSlice;
export const createContact = ContactToCreate => dispatch => {
    return contactRequests
        .createContact(ContactToCreate)
        .then(({ok}) => {
            if (ok) {
                dispatch(actions.contactCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(({message,errors}) => {
            if (message==="The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        });
};
export const updateContact = ContactToUpdate => dispatch => {
    return contactRequests
        .updateContacts(ContactToUpdate)
        .then(({ok}) => {
            if (ok) {
                dispatch(actions.contactCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(({message,errors}) => {
            if (message==="The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        });
};
export const fetchContacts = QueryParams => dispatch => {
    return contactRequests
        .fetchContacts(QueryParams)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.contactsFetched({ listContacts: data,currentQuery:QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const fetchContact = idContact => dispatch => {
    return contactRequests
        .fetchContact(idContact)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.contactFetched({ Contact: data }));
            } else {
                dispatch(actions.catchError({ errors:global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const editContact = idContact => dispatch => {
    return contactRequests
        .editContact(idContact)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.contactFetched({ Contact: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const deleteContacts = Contacts => dispatch => {
    return contactRequests
        .deleteContacts(Contacts)
        .then(({ok,data,contacts}) => {
            if (ok) {
                dispatch(actions.contactsDeleted({ asyncData: data === "async", contacts: contacts }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors")}));
        });
};
export const deleteFunctionContact = functionPerson =>{
     return contactRequests.deleteFunctionContact(functionPerson) 
}
export const handleFunctionContacts = functionPerson =>{
     return contactRequests.handleFunctionContacts(functionPerson) 
}
export const resetState = state => dispatch => {
    dispatch(actions.resetState(state));
};