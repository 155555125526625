import React from 'react'
export const FileViewer = (props) => {
    return (
        <div class="ant-upload-list ant-upload-list-picture">
            {props.file &&
                <div class="ant-upload-list-picture-container">
                    <div class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture">
                        <div class="ant-upload-list-item-info">
                            <span class="ant-upload-span">
                                <a class="ant-upload-list-item-thumbnail ant-upload-list-item-file h-auto d-flex justify-content-center align-items-cente" href={props.file.url} style={{ width: 'auto' }} target="_blank" rel="noopener noreferrer">
                                    <span role="img" aria-label="file" class="anticon anticon-file">
                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="file" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M534 352V136H232v752h560V394H576a42 42 0 01-42-42z" fill="#e6f7ff"></path><path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM602 137.8L790.2 326H602V137.8zM792 888H232V136h302v216a42 42 0 0042 42h216v494z" fill="#1890ff"></path></svg>
                                    </span>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" class="ant-upload-list-item-name" title="CloverTechLogoPresentation.pdf" href={props.file.url}>{props.file.name}</a>

                            </span>
                        </div>
                    </div>
                </div>}
        </div>
    )
}