import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, Form, Col, Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../_sage/_partials/controls";
import { createAdmin, updateAdmin } from '../../../modules/Admins/admin.actions'
import { CostumAlert, PhonePicker } from '../Commons'
import { notification, Switch } from 'antd';
class CreateAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nom: '',
            prenom: '',
            email_admin: '',
            phone: '',
            init_password: '',
            username: '',
            status: true,
            ...props.toEdit,
            loadingCreate: false,
            errors: null,
            showAlert: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loadingCreate) {
            this.setState({
                loadingCreate: false,
                showAlert: true,
                errors: nextProps.errorsAdmin
            })
        }
    }
    handleChangeEvent(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleChangeByName(name, value) {
        this.setState({
            [name]: value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        this.setState({
            loadingCreate: true,
            showAlert: false
        })
        let admin = {
            nom: this.state.nom,
            prenom: this.state.prenom,
            email_admin: this.state.email_admin,
            phone: this.state.phone,
            init_password: this.state.init_password,
            username: this.state.username,
            status: this.state.status,
        }
        if (this.state.id_admin) {
            admin.id_admin = this.state.id_admin
            this.props.updateAdmin(admin)
        } else {
            this.props.createAdmin(admin)
        }

    }
    showErrors() {
        if (this.state.showAlert) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                notification.success({
                    message: this.state.id_admin ? global._trans('admins', 'titleMessageUpdate') : global._trans('admins', 'titleMessageCreate'),
                    description: this.state.id_admin ? global._trans('admins', 'bodyMessageUpdate') : global._trans('admins', 'bodyMessageCreate'),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}
                })
                this.props.onClose(true)
            }
        }
    }
    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onClose}>
                {this.state.loadingCreate && <ModalProgressBar />}
                <div className="card-header border-0 py-0 pt-3 mt-4">
                    <h4 className="align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('admins', 'createAdmin')}</span>
                    </h4>
                </div>
                <div className="card-body py-4">
                    {this.showErrors()}
                    <div className="form px-5 pb-5">
                        <Form method="post" onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'firstName')}*</Form.Label>
                                    <Form.Control type="text" name="nom" value={this.state.nom} placeholder={global._trans('common', 'firstName')} onChange={this.handleChangeEvent} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'lastName')}*</Form.Label>
                                    <Form.Control type="text" name="prenom" value={this.state.prenom} placeholder={global._trans('common', 'lastName')} onChange={this.handleChangeEvent} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'email')}*</Form.Label>
                                    <Form.Control type="email" name="email_admin" value={this.state.email_admin} placeholder={global._trans('common', 'email')} onChange={this.handleChangeEvent} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'phone')}</Form.Label>
                                    <PhonePicker
                                        value={this.state.phone}
                                        onChange={(value) => this.handleChangeEvent({ target: { name: 'phone', value: value } })} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'userName')}</Form.Label>
                                    <Form.Control type="text" name="username" value={this.state.username} placeholder={global._trans('common', 'userName')} onChange={this.handleChangeEvent} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'initPassword')}</Form.Label>
                                    <Form.Control disabled={this.state.id_admin && !this.state.init_password} type="text" name="init_password" value={this.state.init_password} placeholder={global._trans('common', 'initPassword')} onChange={this.handleChangeEvent} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('contacts', 'status')}*</Form.Label>
                                    <div className="d-flex align-items-center my-3">
                                        <strong>{global._trans('contacts', 'inactive')}</strong>
                                        <Switch checked={this.state.status} className="mx-3" onChange={(value) => this.handleChangeByName("status", value)} />
                                        <strong>{global._trans('contacts', 'active')}</strong>
                                    </div>
                                </Form.Group>
                            </Form.Row>
                            <div className="d-flex justify-content-end mt-4">
                                <div className="card-toolbar mx-4">
                                    <Button onClick={this.props.onClose} className="btn btn-secondary">{global._trans('common', 'cancelBtn')}</Button>
                                </div>
                                <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{this.state.id_admin ? global._trans('common', 'saveChanges') : global._trans('admins', 'textSubmitAdmin')}</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        )
    }
}
function mapState(state) {
    const { errorsAdmin } = state.admins
    return { errorsAdmin }
}
const connectedClass = connect(mapState, { createAdmin, updateAdmin })(CreateAdmin)
export { connectedClass as CreateAdmin }