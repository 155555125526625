import React from "react";
import { ProgressBar } from "react-bootstrap";

export function ModalProgressBar({ variant = "primary" }) {
  return (
    <ProgressBar
      variant={variant}
      animated
      now={100}
      style={{ height: "6px", width: "100%" }}
    />
  );
}
