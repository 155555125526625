import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom'
import { Button, Form, Col, Card, ListGroup } from "react-bootstrap";
import { Select, Radio, Switch, notification, Spin } from 'antd';
import { ModalProgressBar } from "../../../../_sage/_partials/controls";
import { CostumAlert, PersoneSelector, InlineBottunSvg, PhonePicker, AdressPicker } from '../Commons'
import { createContact, updateContact, editContact, resetState } from "../../../modules/Contacts/contact.actions";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
class CreateContact extends Component {
    constructor(props) {
        super(props)
        let action = props.match.params.idCreate === "new"
        this.state = {
            status: true,
            genre: null,
            nom: '',
            prenom: '',
            telephone: '',
            mobile: '',
            email: '',
            informations: '',
            linkedin: '',
            address: '',
            loadingCreate: false,
            errors: null,
            showAlert: false,
            showmodal: false,
            persons_moral: [],
            loading: !action,
            city: '',
            country: 'France',
            postcode: ''
        }
        this.isNewItem = action
        if (!action) {
            props.editContact(props.match.params.idCreate)
        }
        this.handleChangeEvent = this.handleChangeEvent.bind(this)
        this.onSwichChange = this.onSwichChange.bind(this)
        this._suggestionSelect = this._suggestionSelect.bind(this)
        this.handleHideModal = this.handleHideModal.bind(this)
        this.setSelection = this.setSelection.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loadingCreate) {
            this.setState({
                loadingCreate: false,
                errors: nextProps.contactErrors,
                showAlert: true
            })
        }
        if (this.state.loading && (nextProps.contact || nextProps.contactErrors)) {
            console.log('🚀 => nextProps.contact', JSON.parse(nextProps.contact))
            this.setState({
                ...JSON.parse(nextProps.contact),
                loading: false
            })
        }
    }
    componentWillUnmount() {
        this.props.resetState([{ name: "contact", value: null }])
    }
    handleChangeEvent(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleChange(name, value) {
        this.setState({
            [name]: value
        })
    }
    onSwichChange(checked) {
        this.setState({
            status: checked
        })
    }
    _suggestionSelect(adress) {
        this.setState({
            address: adress.address,
            city: adress.city,
            country: 'France',
            postcode: adress.postcode
        })
    }
    handleHideModal() {
        let prevState = this.state.showmodal
        this.setState({
            showmodal: !prevState
        })
    }
    setSelection(rowsData) {
        let prevState = this.state.persons_moral
        if (!prevState.find(item => (item.id_person == rowsData.id_person && item.typePersonMoral == rowsData.typePersonMoral))) {
            rowsData.fonction_person = [{
                functionPerson: null
            }]
            prevState.push(rowsData)
        }
        this.setState({
            persons_moral: prevState,
            showmodal: false
        })
    }
    handleSelectFunction(row, index, keyFunc) {
        let prevData = this.state.persons_moral
        if (prevData) {
            if (!prevData[index].fonction_person.find(item => item.functionPerson == row)) {
                prevData[index].fonction_person[keyFunc].functionPerson = row
                this.setState({
                    persons_moral: prevData
                })
            }
        }
    }
    handleDeleteItem(index) {
        let prevState = this.state.persons_moral
        prevState.splice(index, 1)
        this.setState({
            persons_moral: prevState
        })
    }
    addFunctionPerson(person) {
        let prevData = this.state.persons_moral
        prevData[person].fonction_person.push({
            functionPerson: null
        })
        this.setState({
            persons_moral: prevData
        })
    }
    removeFunctionPerson(index, pos) {
        let prevState = this.state.persons_moral
        prevState[index].fonction_person.splice(pos, 1)
        this.setState({
            persons_moral: prevState
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        let contact = {
            status: this.state.status,
            genre: this.state.genre,
            nom: this.state.nom,
            prenom: this.state.prenom,
            telephone: this.state.telephone,
            mobile: this.state.mobile,
            email: this.state.email,
            informations: this.state.informations,
            address: this.state.address,
            linkedin: this.state.linkedin,
            city: this.state.city,
            country: this.state.country,
            postcode: this.state.postcode,
            persons_moral: this.state.persons_moral && this.state.persons_moral.map(person => {
                return {
                    type: person.typePersonMoral,
                    id_person: person.id_person,
                    fonction_person: person.fonction_person,
                }
            })
        }
        if (this.isNewItem) {
            this.props.createContact(contact)
        } else {
            contact.id_contact = this.state.id_contact;
            this.props.updateContact(contact)
        }
        this.setState({
            loadingCreate: true,
            showAlert: false
        })
    }
    showErrors() {
        if (this.state.showAlert) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                notification.success({
                    message: this.isNewItem ? global._trans('contacts', 'titleMessageCreate') : global._trans('contacts', 'titleUpdated'),
                    description: this.isNewItem ? global._trans('contacts', 'bodyMessageCreate') : global._trans('contacts', 'bodyUpdated'),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}

                })
                return <Redirect to="/contact" />
            }
        }
    }
    render() {
        return (
            <>
                {this.state.loadingCreate && <ModalProgressBar />}
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{this.isNewItem ? global._trans('contacts', 'createContact') : global._trans('contacts', 'editContact')}</span>
                    </h3>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingCreate}>
                    <div className="card-body py-4">
                        {this.showErrors()}
                        <div className="form px-5 pb-5">
                            <Form method="post" onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('contacts', 'sexe')}*</Form.Label>
                                        <div className="d-flex align-items-center my-1">
                                            <Radio.Group value={this.state.genre} name="genre" onChange={this.handleChangeEvent}>
                                                <Radio.Button value="MME">MME</Radio.Button>
                                                <Radio.Button value="MR">MR</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('contacts', 'status')}*</Form.Label>
                                        <div className="d-flex align-items-center my-3">
                                            <strong>{global._trans('contacts', 'inactive')}</strong>
                                            <Switch className="mx-3" checked={this.state.status} onChange={this.onSwichChange} />
                                            <strong>{global._trans('contacts', 'active')}</strong>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'firstName')}*</Form.Label>
                                        <Form.Control type="text" name="nom" value={this.state.nom} placeholder={global._trans('common', 'firstName')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'lastName')}*</Form.Label>
                                        <Form.Control type="text" name="prenom" value={this.state.prenom} placeholder={global._trans('common', 'lastName')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'phone')}</Form.Label>
                                        <PhonePicker
                                            value={this.state.telephone}
                                            onChange={(value) => this.handleChange("telephone", value)} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'mobile')}</Form.Label>
                                        <PhonePicker
                                            value={this.state.mobile}
                                            onChange={(value) => this.handleChange("mobile", value)} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'email')}*</Form.Label>
                                        <Form.Control type="email" name="email" value={this.state.email} placeholder={global._trans('common', 'email')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('contacts', 'information')}</Form.Label>
                                        <Form.Control name="informations" placeholder={global._trans('contacts', 'information')} value={this.state.informations} onChange={this.handleChangeEvent} as="textarea" rows="3" />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'address')}*</Form.Label>
                                        <AdressPicker value={this.state.address} onChange={this._suggestionSelect} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'city')}*</Form.Label>
                                        <Form.Control name="city" placeholder={global._trans('contacts', 'city')} value={this.state.city} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'postcode')}*</Form.Label>
                                        <Form.Control name="postcode" placeholder={global._trans('contacts', 'postcode')} value={this.state.postcode} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'country')}*</Form.Label>
                                        <Form.Control name="country" placeholder={global._trans('contacts', 'country')} value={this.state.country} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mb-4">
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('contacts', 'LinkedIn')}</Form.Label>
                                        <Form.Control name="linkedin" placeholder={global._trans('contacts', 'LinkedIn')} value={this.state.linkedin} onChange={this.handleChangeEvent} as="textarea" rows="1" />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Label>{global._trans('contacts', 'personMoral')}*</Form.Label>
                                </Form.Row>
                                <div className="row">
                                    {this.state.persons_moral.map((person, key) => {
                                        return <div className="col-xl-4 mt-4" key={key}>
                                            <Card>
                                                <Card.Title className="m-0 p-4 d-flex justify-content-between align-items-center" tag="h4">
                                                    <span>
                                                        <span className="text-muted">{global._trans('contacts', 'persmoral')}</span> ({global._trans('client', person.typePersonMoral.toLowerCase())})
                                                    </span>
                                                    <InlineBottunSvg
                                                        bottuns={[{ iconPath: `/media/svg/icons/Costum/Trash.svg`, type: "danger", action: () => this.handleDeleteItem(key) }]}
                                                    />
                                                </Card.Title>
                                                <ListGroup variant="flush">
                                                    {person.typePersonMoral === "Societe" ?
                                                        <ListGroup.Item><strong>Dénomination : </strong>{'  ' + person.denomination}</ListGroup.Item>
                                                        :
                                                        <ListGroup.Item><strong>{person.name} : </strong>{'  ' + person[person.dataIndex]}</ListGroup.Item>
                                                    }
                                                    <ListGroup.Item>
                                                        <strong>{global._trans('contacts', 'fonctionPerson')} : </strong>
                                                        {person.fonction_person.map((funcPerson, keyf) => {
                                                            return <div key={keyf} className="d-flex align-items-center mb-2">
                                                                <Select
                                                                    key={keyf}
                                                                    showSearch
                                                                    showArrow
                                                                    className="inputSizeSelect"
                                                                    placeholder={global._trans('contacts', 'selectFunctionPerson')}
                                                                    style={{ width: '100%' }}
                                                                    optionFilterProp="label"
                                                                    value={funcPerson.functionPerson}
                                                                    loading={!this.props.function_person}
                                                                    options={this.props.function_person}
                                                                    onChange={(selectedRow) => this.handleSelectFunction(selectedRow, key, keyf)}
                                                                />
                                                                <button type="button" onClick={() => this.removeFunctionPerson(key, keyf)} className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-default"}>
                                                                    <span className={"svg-icon svg-icon-md svg-icon-default"}>
                                                                        <SVG src={toAbsoluteUrl('/media/svg/icons/Costum/Minus.svg')}></SVG>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        })}
                                                        <button type="button" onClick={() => this.addFunctionPerson(key)} className="btn btn-light-primary font-weight-bold btn-sm">{global._trans("contacts", "addFunction")}</button>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item><strong>{global._trans('common', 'city')}: </strong>{'  ' + person.city}</ListGroup.Item>
                                                </ListGroup>
                                            </Card>
                                        </div>
                                    })}
                                    <div className="col-xl-4 mt-4">
                                        <Button variant="outline-secondary" size="md" block onClick={this.handleHideModal}>{global._trans('contacts', 'selectPersonMoral')}</Button>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mt-4">
                                    <div className="card-toolbar mx-4">
                                        <Link to="/contact" className="btn btn-secondary" >{global._trans('common', 'cancelBtn')}</Link>
                                    </div>
                                    <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{this.isNewItem ? global._trans('contacts', 'textSubmit') : global._trans('common', 'saveChanges')}</Button>
                                </div>
                            </Form>
                            {this.state.showmodal && <PersoneSelector show={this.state.showmodal} validSelection={this.setSelection} onHide={this.handleHideModal} />}
                        </div>
                    </div>
                </Spin>
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { function_person } } = state.enums
    const { contactErrors, contact } = state.contact
    return { function_person, contactErrors, contact }
}
const connectedClass = connect(mapState, { createContact, updateContact, editContact, resetState })(CreateContact)
export { connectedClass as CreateContact }