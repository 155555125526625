import React from 'react'
import { Checkbox } from 'antd';
import { ListGroup, Button } from "react-bootstrap";
export const GEDFiles = ({ checkedColumns, handleChange, filesTypes }) => {
    return (
        <div className="row mx-0">
            {!!filesTypes && filesTypes.length > 0 && filesTypes.map((type, index) => {
                return (
                    <>
                        <Button size='sm' key={index} className={`${checkedColumns[type.code] ? 'bg-btn-checked' : 'bg-btn-Unchecked'} mr-3 pr-5 mb-3`}>
                            <Checkbox name={type} checked={checkedColumns[type.code]}
                                className='check-partage'
                                onChange={(e) => handleChange(type.code, e.target.checked)}>{type.label}</Checkbox>
                        </Button>
                    </>
                )
            }
                // <ListGroup.Item>
                //     <Checkbox checked={checkedColumns[type.code]}
                //     onChange={(e) => handleChange(type.code, e.target.checked)} style={{ lineHeight: '32px' }}>{type.label}</Checkbox>
                // </ListGroup.Item>
            )}
        </div>
    )
}