import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Spin } from 'antd';
import { BasicInfoSociete, ContactsList, Sites } from './Common'
import { fetchSociete, resetState } from '../../../modules/Societes/societe.actions'
import { EntityHistory } from '../Commons/EntityHistory';
import { EntityFiles } from '../Commons';

class ViewSocietie extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            societe: {},
            tab: 'basic',
            disabled: true
        }
        this.handleTab = this.handleTab.bind(this)
        props.fetchSociete(props.match.params.idCompany)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.societe) {
                this.setState({
                    loading: false,
                    societe: nextProps.societe
                })
            }
        }
    }
    componentWillUnmount() {
        this.props.resetState([{ name: "societe", value: null }])
    }
    getTabBody() {
        switch (this.state.tab) {
            case 'basic': return <BasicInfoSociete disabled={this.state.disabled} societe={this.state.societe} />
            case 'sites': return <Sites disabled={this.state.disabled} sites={this.state.societe.sites} />
            case 'contacts': return <ContactsList contacts={this.state.societe.contacts} />
            case 'history': return <EntityHistory entity={this.state.societe} showStatus={true} />
            case 'files': return <EntityFiles entity={this.state.societe} />
        }
    }
    handleTab(tab) {
        this.setState({
            tab: tab
        })
    }
    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div className="card-header border-0 pt-5 pb-0 px-6">
                    <h3 className="card-title align-items-start flex-column mb-0">
                        <span className="card-label font-weight-bolder text-dark">Société <span className="text-primary">#{Array.isArray(this.state.societe.groupe) ? this.state.societe.groupe.map((g) => g.value_enum).join(',') : this.state.societe.groupe}</span></span>
                    </h3>
                </div>
                <div className="card-body py-4">
                    <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('basic')} className={` nav-link ${this.state.tab === "basic" && "active"}`} data-toggle="tab" role="tab">Informations de base</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('sites')} className={`nav-link ${this.state.tab === "sites" && "active"}`} data-toggle="tab" role="tab">Sites</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('contacts')} className={`nav-link ${this.state.tab === "contacts" && "active"}`} data-toggle="tab" role="tab">Contacts</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('history')} className={`nav-link ${this.state.tab === "history" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'history')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('files')} className={`nav-link ${this.state.tab === "files" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'files')}</Link>
                        </li>
                    </ul>
                    <div className="pt-5">{this.getTabBody()}</div>
                    <div className="d-flex justify-content-end">
                        <div className="card-toolbar mx-4">
                            <Link to={`/client/companies${this.props.currentQuery}`} className="btn btn-secondary" >Retour</Link>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}
function mapState(state) {
    const { societe, currentQuery } = state.societe
    return { societe, currentQuery }
}
const connectedClass = connect(mapState, { fetchSociete, resetState })(ViewSocietie)
export { connectedClass as ViewSocietie }