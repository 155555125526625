import { CrudService } from "../../../services";
import { siteUrls } from './sites.constants'
export function createSite(Site) {
    return CrudService.Post(siteUrls.CREATE_SITE_URL, Site);
}
export function fetchSites(queryParams) {
    return CrudService.Post(siteUrls.FETCH_SITES_URL, queryParams);
}
export function fetchSitesGet(queryParams) {
    return CrudService.Get(siteUrls.FETCH_SITES_URL, {}, queryParams);
}
export function fetchSite(idSite) {
    return CrudService.Get(siteUrls.FETCH_SITE_URL + idSite, {});
}
export function deleteSites(sites) {
    return CrudService.Delete(siteUrls.DELETE_SITES_URL, sites, {});
}
export function editSite(idSite) {
    return CrudService.Get(siteUrls.EDIT_SITE_URL + idSite, {});
}
export function updateSite(site) {
    return CrudService.Patch(siteUrls.UPDATE_SITE_URL, site, {});
}
export function fetchDetailShare(idSite) {
    return CrudService.Get(siteUrls.FETCH_DETAIL_URL + idSite, {});
}

export function exportSites(data = {}) {
    return CrudService.Get(siteUrls.EXPORT_SITES_URL, data, {});
}
export function getGroupes(QueryParams) {
    return CrudService.Get(siteUrls.FETCH_URL,QueryParams);
}
