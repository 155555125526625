import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
class Dashboard extends Component {
    render() {
        return (
            <div className="card card-custom">
            </div>
        )
    }
}
export { Dashboard }