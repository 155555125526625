import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { Spin } from 'antd';
import { fetchContact, deleteFunctionContact, handleFunctionContacts } from "../../../modules/Contacts/contact.actions";
import { BasicInfo, PersonsMoral } from './utils'
class ViewContact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            tab: 'basic',
            contact: {},
            loadingPerson: null
        }
        this.deleteFunction = this.deleteFunction.bind(this)
        this.handleFunction = this.handleFunction.bind(this)
        props.fetchContact(props.match.params.idcontact)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            this.setState({
                loading: false,
                contact: JSON.parse(nextProps.contact)
            })
        }
    }
    handleTab(tab) {
        this.setState({
            tab: tab
        })
    }
    getTabBody() {
        switch (this.state.tab) {
            case 'basic': return <BasicInfo contact={this.state.contact} />
            case 'persons': return <PersonsMoral handleFunction={this.handleFunction} loading={this.state.loadingPerson} deleteFunction={this.deleteFunction} persons={this.state.contact.persons_moral} />
        }
    }
    handleFunction(person, fnct) {
        let dataFunc = {
            contact: this.state.contact.id_contact,
            function_person: this.state.contact.persons_moral[person].fonction_person[fnct].id_person_function,
            person_moral: this.state.contact.persons_moral[person].id_person_moral
        }
        this.setState({
            loadingPerson: person
        })
        handleFunctionContacts(dataFunc)
            .then(({ ok, async, data }) => {
                if (ok && async) {
                    var prevState = this.state.contact
                    prevState.persons_moral[person].fonction_person[fnct] = data
                    this.setState({
                        contact: prevState
                    })
                }
            })
            .catch(err => {

            })
            .finally(e => {
                this.setState({
                    loadingPerson: null
                })
            })
    }
    deleteFunction(person, fnct) {
        let dataFunc = {
            contact: this.state.contact.id_contact,
            function_person: this.state.contact.persons_moral[person].fonction_person[fnct].id_person_function,
            person_moral: this.state.contact.persons_moral[person].id_person_moral
        }
        this.setState({
            loadingPerson: person
        })
        deleteFunctionContact(dataFunc)
            .then(({ ok, async }) => {
                if (ok && async) {
                    var prevState = this.state.contact
                    prevState.persons_moral[person].fonction_person.splice(fnct, 1)
                    this.setState({
                        contact: prevState
                    })
                }
            })
            .catch(err => {

            })
            .finally(e => {
                this.setState({
                    loadingPerson: null
                })
            })

    }
    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div className="card-header border-0 pt-5 pb-0 px-6">
                    <h3 className="card-title align-items-start flex-column mb-0">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('contacts', 'contact')} <span className="text-primary">#{this.state.contact.nom ? this.state.contact.nom : '...'}</span></span>
                    </h3>
                </div>
                <div className="card-body py-4">
                    <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('basic')} className={`nav-link ${this.state.tab === "basic" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'basicInfoContact')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('persons')} className={`nav-link ${this.state.tab === "persons" && "active"}`} data-toggle="tab" role="tab">{global._trans('contacts', 'personMoral')}</Link>
                        </li>
                    </ul>
                    <div className="pt-5">{this.getTabBody()}</div>
                    <div className="d-flex justify-content-end">
                        <div className="card-toolbar mx-4">
                            <Link to={`/contact${this.props.currentQuery}`} className="btn btn-secondary" >{global._trans('common', 'retoure')}</Link>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}
function mapState(state) {
    const { contact, currentQuery } = state.contact
    return { contact, currentQuery }
}
const connectedClass = connect(mapState, { fetchContact })(ViewContact)
export { connectedClass as ViewContact }