import React from 'react'
import { Checkbox } from 'antd';
import { ListGroup } from "react-bootstrap";
export const GeneralColumns = (props) => {
    return (
        <div className="row mx-0">
            <ListGroup.Item>
                <Checkbox name="denomination" checked={props.checkedColumns.denomination} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'denomenation')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="modeGestion" checked={props.checkedColumns.modeGestion} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'modeGestion')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="categorieSite" checked={props.checkedColumns.categorieSite} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'categorieSite')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="adresse" checked={props.checkedColumns.adresse} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'address')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="siteIntrnet" checked={props.checkedColumns.siteIntrnet} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'webSite')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="telephoneStandrad" checked={props.checkedColumns.telephoneStandrad} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'standrPhone')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="anneeCreation" checked={props.checkedColumns.anneeCreation} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'createYear')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="perdiocitRelance" checked={props.checkedColumns.perdiocitRelance} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'restartPeriod')}</Checkbox>
            </ListGroup.Item>
        </div>
    )
}