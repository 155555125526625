import React, { useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import {updatePassword}  from '../../../../../../app/modules/Auth/auth.crud'
import {CostumAlert} from '../../../../../../app/pages/PrivatePages/Commons'
import SVG from "react-inlinesvg";
import { useDispatch} from 'react-redux';
import { actions } from "../../../../../../app/modules/Auth/auth.reducer";
export function UpdatePassword(props){
  const dispatch = useDispatch()
  const [state,setState]=useState({password:"",cPassword:"",currentPassword:""})
  const [loading,setLoading]=useState(false)
  const [errors,setErrors]=useState(null)
  const handleChnage = (e)=>{
    const {value,name}=e.target
    setState({
      ...state,
      [name]:value
    })
  }
  const handleSubmit = (e)=>{
    e.preventDefault()
    setLoading(true)
    setErrors(null)
    updatePassword(state)
    .then(({ok})=>{
      if(ok){
        let user={
          ...props.userAuth,
          init_password:null
        }
        dispatch(actions.fulfillUser(user))
        props.onHide()
      }else{
        setErrors(global._trans("common","errorsInconue"))
      }
      setLoading(false)
    })
    .catch(({message,errors})=>{
      if(message==="The given data was invalid."){
        setErrors(errors)
      }else{
        setErrors(global._trans("common","errorsInconue"))
      }
      setLoading(false)
    })
  }
    return (
        <Modal size="md" show={props.show} onHide={props.onHide}>
            <form className="card card-custom" onSubmit={handleSubmit}>
            <div className="card-header py-3 header-center">
              <div className="card-title align-items-start flex-column">
                <h3 className="card-label font-weight-bolder text-dark">{global._trans("auth","titleModal")}</h3>
                <span className="text-muted font-weight-bold font-size-sm mt-1">{global._trans("auth","descPassword")}</span>
              </div>
              <div onClick={props.onHide} className="cursor-pointer">
                <span className="svg-icon svg-icon-xl svg-icon-dark">
                  <SVG  src="/media/svg/icons/Costum/Close.svg" />
                </span>
              </div>
            </div>
            <div className="form">
              <div className="card-body">
                {errors && <CostumAlert type={errors!==1?'danger':'success'} errors={errors!==1 ?errors:global._trans("auth","passwordSuccesUpdated")} />}
                <div className="form-group flex-column">
                    <label className="col-xl-6 col-lg-6 col-form-label text-alert">{global._trans("auth","currentPassword")}</label>
                    <div className="col-lg-12 col-xl-12">
                      <input type="password" placeholder={global._trans("auth","currentPassword")} onChange={handleChnage} value={state.currentPassword} className={`form-control form-control-lg form-control-solid mb-2 `}
                        name="currentPassword" />
                    </div>
                  </div>
                  <div className="form-group flex-column">
                    <label className="col-xl-6 col-lg-6 col-form-label text-alert">{global._trans("auth","newPassword")}</label>
                    <div className="col-lg-12 col-xl-12">
                      <input type="password" placeholder={global._trans("auth","newPassword")} onChange={handleChnage} value={state.password} className={`form-control form-control-lg form-control-solid`} name="password" />
                    </div>
                  </div>
                  <div className="form-group flex-column">
                    <label className="col-xl-6 col-lg-6 col-form-label text-alert">{global._trans("auth","confirmPassword")}</label>
                    <div className="col-lg-12 col-xl-12">
                      <input type="password" placeholder={global._trans("auth","confirmPassword")} onChange={handleChnage} value={state.cPassword} className={`form-control form-control-lg form-control-solid `} name="cPassword" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <Button variant="default" className="mr-3" onClick={props.onHide} type='button' disabled={state.loading}>{global._trans('common', 'cancelBtn')}</Button>
                    <Button variant="primary" type='submit' disabled={loading}>{loading?global._trans('common', 'reqeusting'):global._trans('auth', 'save')}</Button>
                  </div>
                </div>
                </div>
              </form>
        </Modal>
    )
}