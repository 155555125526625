import { CrudService } from "../../../services";
import { commonUrls } from './common.constants'
export function uploadImage(FormData) {
    return CrudService.Post(commonUrls.UPLOAD_IMAGE_URL, FormData);
}
export function fetchDepartements(value,query) {
    return CrudService.Get(commonUrls.FETCH_DEPARTMENT_URL,{},{search:value,...query});
}
export function fetchRegions(value,query) {
    return CrudService.Get(commonUrls.FETCH_REGION_URL,{},{search:value,...query});
}
export function fetchHistory(query) {
    return CrudService.Get(commonUrls.FETCH_HISTORY_URL,{},query);
}

export function importData(dataImport) { 
    return CrudService.Post(commonUrls.IMPORT_DATA_URL, dataImport);
}
export function exportData(dataImport) { 
    return CrudService.Post(commonUrls.EXPORT_DATA_URL, dataImport);
}
export function getTableViews(query) { 
    return CrudService.Get(commonUrls.GET_TABLE_VIEWS_URL, {}, query);
}
export function createTableView(data) { 
    return CrudService.Post(commonUrls.CREATE_TABLE_VIEW_URL, data);
}
export function updateTableView(data) { 
    return CrudService.Post(commonUrls.UPDATE_TABLE_VIEW_URL, data);
}
export function deleteTableView(data) { 
    return CrudService.Post(commonUrls.DELETE_TABLE_VIEW_URL, data);
}
export function selectTableView(data) { 
    return CrudService.Post(commonUrls.SELECT_TABLE_VIEW_URL, data);
}
