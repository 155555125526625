import React from 'react';

import { Form, Col, Modal, Button } from "react-bootstrap";
import { DatePicker, Spin } from 'antd';
import moment from 'moment';

import { CostumAlert } from '../../../../Commons'

export const UpdateShares = ({ show, onHide, loading, handleSubmit, handleChangeName, errors, infos }) => {
    return (
        <Modal size="lg" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
            <Spin spinning={loading}>
                <form className="card card-custom">
                    <div className="card-header py-3">
                        <div className="card-title align-items-start flex-column">
                            <h3 className="card-label font-weight-bolder text-dark">{global._trans("shares", "renewDates")}</h3>
                            <span className="text-muted font-weight-bold font-size-sm mt-1">{global._trans("shares", "bodyRenew")}</span>
                        </div>
                    </div>
                    <div className="form">
                        <div className="card-body">
                            {errors && <CostumAlert type={errors !== 1 ? 'danger' : 'success'} errors={errors !== 1 ? errors : global._trans("shares", "successMis")} />}
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('shares', 'startDate')}*</Form.Label>
                                    <DatePicker value={!!infos.start ? moment(infos.start, "DD/MM/YYYY") : null} format="DD/MM/YYYY" onChange={(date, dateString) => handleChangeName("start", dateString)} className="form-control" placeholder={global._trans('shares', 'startDate')} style={{ width: '100%' }} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('shares', 'endDate')}*</Form.Label>
                                    <DatePicker disabledDate={(current) => !!infos.start ? current < moment(infos.start, "DD/MM/YYYY").add(1, 'day') : false} value={!!infos.end ? moment(infos.end, "DD/MM/YYYY") : null} format="DD/MM/YYYY" onChange={(date, dateString) => handleChangeName("end", dateString)} className="form-control" placeholder={global._trans('shares', 'endDate')} style={{ width: '100%' }} />
                                </Form.Group>
                            </Form.Row>

                            <div className="d-flex justify-content-end mt-4">
                                <Button variant="default" className="mr-3" type='button' onClick={onHide}>{global._trans('common', 'cancelBtn')}</Button>
                                <Button variant="primary" onClick={handleSubmit}>{global._trans('shares', 'saveAndShare')}</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Spin>
        </Modal>
    );
}