import { createSlice } from "@reduxjs/toolkit";
const queryString = require('query-string');
const initialState = {
    usersErrors: null,
    listUsersSimple: {}
};

export const userSimpleSlice = createSlice({
    name: "usersSimple",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { errors } = action.payload
            state.usersErrors = errors;
        },
        resetState:(state, action) => {
            action.payload && action.payload.map(elem=>{
                state[elem.name]=elem.value
            })
        },
        usersSimpleFetched: (state, action) => {
            const { listUsers,currentQuery } = action.payload
            state.usersErrors = null
            state.currentQuery="?"+queryString.stringify(currentQuery)
            state.listUsersSimple = listUsers
        },
    }
});