import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap'
import { Select, DatePicker } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { SageTable, PersoneSelector } from '../../../../Commons'
import moment from 'moment'
class CompetanceInfo extends Component {
    state = {
        showSelector: undefined
    }
    setSelection = (selectedRow) => {
        this.props.handleChangeDelegue(this.state.showSelector, selectedRow)
        this.state.showSelector = undefined
    }
    handleModel = (key) => {
        this.setState({
            showSelector: key
        })
    }
    render() {
        return (
            <div className="mt-2">
                <div className="row">
                    <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold">{global._trans("client", "competancEexercee")}:</h5>
                    </div>
                </div>
                <SageTable
                    columns={[
                        { title: global._trans('client', 'code') + "*", dataIndex: "code", render: (code, key) => <Form.Control style={{ width: 80 }} type="text" disabled value={code} name="code" placeholder={global._trans('client', 'code')} onChange={({ target: { name, value } }) => this.props.handleChange("exercee", key, name, value)} /> },
                        {
                            title: global._trans('client', 'competance') + "*", dataIndex: "competence_dechet", render: (competence_dechet, key) => <Select
                                showSearch
                                showArrow
                                className="inputSizeSelect"
                                placeholder={global._trans('common', 'selectCompDechetDelgue')}
                                style={{ width: '100%' }}
                                optionFilterProp="label"
                                defaultValue={competence_dechet}
                                loading={!this.props.competence_dechet}
                                options={this.props.competence_dechet}
                                onChange={(selectedValue) => this.props.handleChange("exercee", key, "competence_dechet", selectedValue)}
                            />
                        },
                        { title: global._trans('client', 'start_date'), dataIndex: "start_date", render: (start_date, key) => <DatePicker format="DD/MM/YYYY" onChange={(date, dateString) => this.props.handleChange("exercee", key, "start_date", dateString)} defaultValue={start_date && moment(start_date, "DD/MM/YYYY")} className="form-control" placeholder={global._trans('client', 'start_date')} /> },
                        { title: global._trans('client', 'end_date'), dataIndex: "end_date", render: (end_date, key) => <DatePicker format="DD/MM/YYYY" disabledDate={(current) => current < moment(this.props.competances.exercee[key].start_date, "DD/MM/YYYY").add(1, 'day')} onChange={(date, dateString) => this.props.handleChange("exercee", key, "end_date", dateString)} defaultValue={end_date && moment(end_date, "DD/MM/YYYY")} className="form-control" placeholder={global._trans('client', 'end_date')} /> },
                        { title: global._trans('client', 'comment'), dataIndex: "comment", render: (comment, key) => <Form.Control name="comment" placeholder={global._trans('client', 'comment')} defaultValue={comment} onChange={({ target: { name, value } }) => this.props.handleChange("exercee", key, name, value)} as="textarea" cols="40" rows="1" /> }
                    ]}
                    tableStyle={{ marginBottom: 0 }}
                    data={this.props.competances.exercee}
                    IterableProps={{}}
                />
                <div className="d-flex">
                    <button type="button" onClick={() => this.props.addCompetance('exercee')} className="btn btn-default font-weight-bold btn-sm">{global._trans("client", "addCompetance")}</button>
                    <button type="button" onClick={() => this.props.deleteCompetance('exercee')} className="btn btn-light-danger ml-2 font-weight-bold btn-sm">{global._trans("client", "deleteCompetance")}</button>
                </div>

                <div className="separator separator-dashed my-3"></div>
                <div className="row">
                    <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold mt-2">{global._trans("client", "competancDelegues")}:</h5>
                    </div>
                </div>
                <SageTable
                    columns={[
                        { title: global._trans('client', 'code') + "*", dataIndex: "code", render: (code, key) => <Form.Control style={{ width: 80 }} type="text" name="code"disabled value={code} placeholder={global._trans('client', 'code')} onChange={({ target: { name, value } }) => this.props.handleChange("delegue", key, name, value)} /> },
                        {
                            title: global._trans('client', 'competance') + "*", dataIndex: "competence_dechet", render: (competence_dechet, key) => <Select
                                showSearch
                                showArrow
                                className="inputSizeSelect"
                                placeholder={global._trans('common', 'selectCompDechetDelgue')}
                                style={{ width: '100%' }}
                                optionFilterProp="label"
                                defaultValue={competence_dechet}
                                loading={!this.props.competence_dechet}
                                options={this.props.competence_dechet}
                                onChange={(selectedValue) => this.props.handleChange("delegue", key, "competence_dechet", selectedValue)}
                            />
                        },
                        {
                            title: global._trans('client', 'delegue_competance') + "*", dataIndex: "delegue_competance", render: (delegue_competance, key) => delegue_competance ? <div>
                                <span><strong>({global._trans('client', delegue_competance.typePersonMoral.toLowerCase())}) : </strong>{'  ' + delegue_competance[delegue_competance.dataIndex]}</span>
                                <Link role="button" className="d-block mt-1 font-weight-bold" onClick={() => this.handleModel(key)}>{global._trans("sites", "changeMtn")}</Link>
                            </div> : <Button variant="outline-secondary" block size="md" onClick={() => this.handleModel(key)}>{global._trans('clientsEpic', 'selectSyndicat')}</Button>
                        },
                        { title: global._trans('client', 'start_date'), dataIndex: "start_date", render: (start_date, key) => <DatePicker format="DD/MM/YYYY" disabledDate={(current) => current < moment(this.props.competances.delegue[key].start_date, "DD/MM/YYYY").add(1, 'day')} onChange={(date, dateString) => this.props.handleChange("delegue", key, "start_date", dateString)} defaultValue={start_date && moment(start_date, "DD/MM/YYYY")} className="form-control" placeholder={global._trans('client', 'start_date')} /> },
                        { title: global._trans('client', 'end_date'), dataIndex: "end_date", render: (end_date, key) => <DatePicker format="DD/MM/YYYY" disabledDate={(current) => current < moment(this.props.competances.delegue[key].start_date, "DD/MM/YYYY").add(1, 'day')} onChange={(date, dateString) => this.props.handleChange("delegue", key, "end_date", dateString)} defaultValue={end_date && moment(end_date, "DD/MM/YYYY")} className="form-control" placeholder={global._trans('client', 'start_date')} /> },
                        { title: global._trans('client', 'comment'), dataIndex: "comment", render: (comment, key) => <Form.Control name="comment" placeholder={global._trans('client', 'comment')} defaultValue={comment} onChange={({ target: { name, value } }) => this.props.handleChange("delegue", key, name, value)} as="textarea" cols="40" rows="1" /> }
                    ]}
                    tableStyle={{ marginBottom: 0 }}
                    data={this.props.competances.delegue}
                    IterableProps={{}}
                />
                <div className="d-flex">
                    <button type="button" onClick={() => this.props.addCompetance('delegue')} className="btn btn-default font-weight-bold btn-sm">{global._trans("client", "addCompetance")}</button>
                    <button type="button" onClick={() => this.props.deleteCompetance('delegue')} className="btn btn-light-danger ml-2 font-weight-bold btn-sm">{global._trans("client", "deleteCompetance")}</button>
                </div>
                {this.state.showSelector >= 0 && <PersoneSelector show={true} blocked={[3, 2]} validSelection={this.setSelection} onHide={this.handleModel} />}
            </div>
        )
    }
}
function mapState(state) {
    const { listEnums: { competence_dechet } } = state.enums
    return { competence_dechet }
}
const connectedClass = connect(mapState, null)(CompetanceInfo)
export { connectedClass as CompetanceInfo }