import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from 'moment';

export const entityName = (entity) => {
    if (!entity) return '';
    switch (entity.type) {
        case 'sites':
            return entity.denomination;
        case 'epics':
            return entity.nomEpic;
        case 'syndicats':
            return entity.nomCourt;
        case 'communes':
            return entity.nomCommune;
        case 'societies':
            return entity.denomination;
        default:
            return 'not found';
    }
}
class EntityFiles extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { files } = this.props.entity;
        return (
            <table className="table">
                <tr>
                    <th>{global._trans('ged', 'ID')}</th>
                    <th>{global._trans('ged', 'name')}</th>
                    <th>{global._trans('ged', 'category')}</th>
                    <th>{global._trans('ged', 'attached_to')}</th>
                    <th>{global._trans('ged', 'entity')}</th>
                    <th>{global._trans('ged', 'date')}</th>
                    <th>{global._trans('ged', 'created_at')}</th>
                </tr>
                {
                    !!files && files.length > 0 && files.map((file) => (<tr>
                        <td>{file.id}</td>
                        <td><a href={file.path} target="_blank">{file.name}</a></td>
                        <td>{file.category.value_enum}</td>
                        <td>{global._trans('ged', file.type)}</td>
                        <td>{entityName({ ...file.entity, type: file.type })}</td>
                        <td>{file.date}</td>
                        <td>{moment(file.created_at).format('DD/MM/YYYY')}</td>
                    </tr>))
                }
            </table>
        )
    }
}

function mapState(state) {
    const { listEnums } = state.enums;
    return { enums: listEnums }
}
const connectedClass = connect(mapState, {})(EntityFiles)
export { connectedClass as EntityFiles }
