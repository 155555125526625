import React from 'react'
import { Form } from 'react-bootstrap'
export const GestionnaireFilters = (props) => {
    return (
        <>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('contacts', 'status')}</Form.Label>
                <Form.Control as="select" name="status" onChange={props.handleChange} defaultValue={props.filters.status}>
                    <option value="all">Tout</option>
                    <option value='active'>{global._trans('contacts', 'active')}</option>
                    <option value='inactive'>{global._trans('contacts', 'inactive')}</option>
                </Form.Control>
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'firstName')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'firstName')} defaultValue={props.filters.nom} name="nom" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'lastName')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'lastName')} defaultValue={props.filters.prenom} name="prenom" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'email')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'email')} defaultValue={props.filters.email} name="email" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'phone')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'phone')} defaultValue={props.filters.phone} name="phone" onChange={props.handleChange} />
            </Form.Group>
        </>
    )
}