import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Modal, Spin } from 'antd'
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
import { InlineBottunSvg, SageTable, FilterSearch } from '../Commons'
import { NavLink } from 'react-router-dom'
import { fetchSites, deleteSites, exportSites } from '../../../modules/Sites/sites.actions'
import { FilterSites, ImportSites } from './utils'
import { siteUrls } from '../../../modules/Sites/sites.constants';
import { message } from 'antd';
import { exportData } from "../../../modules/Common/common.actions";
import { ExportSitesModal } from './utils/ExportSites';
import { AgGrid } from '../Commons';
const queryString = require('query-string');


// const URL_ROOT = process.env.REACT_APP_HOST_API
class ListSites extends Component {
    constructor(props) {
        super(props)
        this.timerId = null;
        this.handleInputChange = this.handleInputChange.bind(this);
        // let asyncLoad = props.currentQuery !== props.history.location.search || !props.currentQuery
        this.state = {
            search: '',
            first_load: true,
            view_save: 0,
            listModes: {},
            loading: true,
            total: 0,
            page: 1,
            page_size: 20,
            list: [],
            columnDefs: [
                {headerName: "#", filter: "agNumberColumnFilter", field: 'id_site'},
                {headerName: global._trans('sites', 'denomenation'), field: 'denomination'},
                {headerName: global._trans('sites', 'categorieSite'), field: 'categorieSite', filter: "multiChoiceFilter", filterParams: { suppressFilter: true, choices: {"UVE": "UVE", "TMB": "TMB", "TRI": "TRI", "ISDND": "ISDND"} }},
                {headerName: global._trans('sites', 'modeGestion'), field: 'latest_contrat.modeGestion', filter: "multiChoiceFilter", filterParams: { 
                    suppressFilter: true, 
                    choices: this.state?.listModes ?? {}
                }},
                {headerName: global._trans('contracts', 'startDate'), field: 'latest_contrat.dateDebut', filter: 'agDateColumnFilter', format: 'd/m/Y'},
                {headerName: global._trans('contracts', 'endDate'), field: 'latest_contrat.dateFin', filter: 'agDateColumnFilter', format: 'd/m/Y'},
                {headerName: global._trans('sites', 'exploitant'), field: "exploitant.client.full_name"},
                {headerName: global._trans('sites', 'exploitant_short'), field: "exploitant.client.short_name"},
                {headerName: global._trans('sites', 'groupe'), field: "exploitant.client.groupe_text"},
                {headerName: global._trans('sites', 'maitre_ouvrage'), field: "client.client.full_name"},
                {headerName: global._trans('sites', 'maitre_ouvrage_short'), field: "client.client.short_name"},
                {headerName: global._trans('sites', 'gestionnaire'), field: 'gestionnaire.email'},
                {headerName: global._trans('common', 'sinoe'), field: 'sinoe'},
                {headerName: global._trans('common', 'regionSiege'), field: 'region_siege.name_region'},
                {headerName: global._trans('common', 'departementSiege'), field: 'departement_siege.name_departement'},
                {headerName: global._trans('clientsSyndicat', 'amobe'), field: 'amobes_text'},
                {headerName: global._trans('common', 'address'), field: 'adresse'},
                {headerName: global._trans('common', 'postcode'), filter: "agNumberColumnFilter", field: 'postcode'},
                {headerName: global._trans('common', 'city'), field: 'city'},
                {headerName: global._trans('common', 'country'), field: 'country'},
                {headerName: global._trans('common', 'siteInternet'), field: 'siteIntrnet'},
                {headerName: global._trans('common', 'telephoneStandard'), field: 'telephoneStandrad'},
                {headerName: global._trans('sites', 'restartPeriod'), field: 'perdiocitRelance'},
                {headerName: global._trans('common', 'anneeCreation'), field: 'anneeCreation'},
                {headerName: global._trans('common', 'status'), field: 'status', type: "statusColumn"},
                {headerName: global._trans('sites', 'lines_number'), filter: "agNumberColumnFilter", field: 'lines_count'},
                {headerName: global._trans('sites', 'tonnageReglementaire'), filter: "agNumberColumnFilter", colId: 'data_tech.data_tech.infos.tonnageReglementaireAp', valueGetter: (params) => parseFloat(params.data.data_tech?.data_tech?.infos?.tonnageReglementaireAp) || null },
                {headerName: global._trans('sites', 'capacite'), filter: "agNumberColumnFilter", colId: 'data_tech.data_tech.infos.capacite', valueGetter: (params) => parseFloat(params.data.data_tech?.data_tech?.infos?.capacite) || null },
                {headerName: global._trans('sites', 'videFour'), filter: "agNumberColumnFilter", colId: 'data_tech.data_tech.infos.videFour', valueGetter: (params) => parseFloat(params.data.data_tech?.data_tech?.infos?.videFour) || null },
                {headerName: global._trans('sites', 'valorisationTypes'), field: 'valorisation_types'},
                {headerName: global._trans('sites', 'electriciteVendue'), field: 'data_tech.data_tech.valorisations.electriciteVendue'},
                {headerName: global._trans('sites', 'chaleurVendue'), field: 'data_tech.data_tech.valorisations.chaleurVendue'},
                {headerName: global._trans('sites', 'performenceEnergetique'), field: 'data_tech.data_tech.valorisations.performenceEnergetique'},
                {headerName: global._trans('sites', 'performenceEnergetiqueDate'), field: 'data_tech.data_tech.valorisations.performenceEnergetiqueDate'},
                {headerName: global._trans('sites', 'performenceEnergetiqueR1'), field: 'data_tech.data_tech.valorisations.performenceEnergetiqueR1'},
                {headerName: global._trans('sites', 'performenceEnergetiqueR1Date'), field: 'data_tech.data_tech.valorisations.performenceEnergetiqueR1Date'},
                {headerName: global._trans('sites', 'informationComplementaire'), field: 'data_tech.data_tech.valorisations.informationComplementaire'},
                {headerName: global._trans('sites', 'updated_at'), field: 'updated_at', valueGetter: (params) => {
                    const date = new Date(params.data.updated_at);
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    return `${year}/${month}/${day}`;
                }},
                {headerName: "", minWidth: 150, colId: "Actions", field: 'id_site', sortable: false, filter: false, cellRendererFramework: params => {
                    return <div className="d-flex">
                        <a href={'/sites/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Edit.svg")}></SVG></span>
                            </button>
                        </a>
                        {this.props.user && this.props.user.typeuser !== "Gestionnaire" &&
                        <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-danger"} onClick={() => this.handleDeleteSite(params.value)}>
                            <span className={"svg-icon svg-icon-md svg-icon-danger"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG></span>
                        </button>}
                        <a href={'/sites/view/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"} to={'/contrat/view/' + params.value}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/View.svg")}></SVG></span>
                            </button>
                        </a>
                    </div>
                }}
            ],
            selectedRows: [],
            loadingDelete: false,
        }
        props.fetchSites({pagination: {page: this.state.page, page_size: this.state.page_size}, first_load: true})
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.mode_gestion.length > 0) {
            this.state.columnDefs.forEach(item => {
                if (item.field == "latest_contrat.modeGestion") {
                    item.filterParams.choices = this.props.mode_gestion?.reduce((acc, item) => {acc[item.label] = item.label;return acc;}, {}) ?? {}
                }
            })
        }
        if (this.state.loading && nextProps.listSites && Array.isArray(nextProps.listSites.data)) {
            this.setState({
                list: nextProps.listSites.data,
                total: nextProps.listSites.total,
                page: nextProps.listSites.current_page,
                page_size: nextProps.listSites.per_page,
                loading: false,
            }, () => {
                this.childComponentRef.onLoadingDone(this.state.first_load)
                if (this.state.first_load) this.setState({first_load: false})
            })
        }
        if (this.state.loadingDelete) {
            this.setState({
                listSites: nextProps.listSites.data,
                loadingDelete: false,
                selectedRows: []
            })

            if (nextProps.siteErrors) {
                message.error(global._trans('settings', 'cantDelete'));
            }

        }
    }
    handleTypeFilter(checked) {
        this.setState({
            filters: {
                ...this.state.filters,
                typeFilter: checked ? "inter" : null
            }
        })
    }
    handleInputChange(event) {
        const { value } = event.target;
        if (this.timerId) {
          clearTimeout(this.timerId);
        }
        this.timerId = setTimeout(() => {
          this.setState({ search: value }, () => {this.childComponentRef.onChange()});
        }, 500);
    }
    handleDeleteSite(idSite) {
        Modal.confirm({
            title: global._trans('sites', 'deleteTitle') + " #" + idSite,
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('sites', 'deleteBody'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadingDelete: true
                })
                this.props.deleteSites({ sites: [idSite] })
            }
        });
    }
    deleteSelected() {
        Modal.confirm({
            title: global._trans('common', 'deleteAllTitle') + " #",
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('sites', 'deleteBody'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadingDelete: true
                })
                this.props.deleteSites({ sites: this.state.selectedRows })
            }
        });
    }
    handleSelectRow(selectedRows) {
        this.setState({
            selectedRows: selectedRows
        })
    }
    // handleTableChange = (pagination, filters) => {
    //     if (pagination.current !== this.state.cuurentPage || pagination.pageSize !== this.state.pageSize) {
    //         let prevfilter = this.state.filters
    //         prevfilter.page = pagination.current
    //         prevfilter.pageSize = pagination.pageSize
    //         this.handleFetchData(prevfilter)
    //     }
    // }
    handleSorter(sorter) {
        let prevfilter = this.state.filters
        prevfilter.sort = sorter.value
        prevfilter.sorter = sorter.current
        this.handleFetchData(prevfilter)
    }
    handleFetchData(filter) {
        this.props.history.push({
            search: queryString.stringify(filter)
        })
        this.props.fetchSites(filter)
        this.setState({
            loadingSites: true
        })
    }
    handleSubmitSearch(e) {
        e.preventDefault()
        this.state.filters = {
            ...this.state.filters,
            ...this.filterTab
        }
        this.handleFetchData(this.state.filters)
    }
    handleChangeSearch(e) {
        const { name, value } = e.target
        this.filterTab[name] = value
    }
    handleResetSearch() {
        let resteData = {
            page: this.state.filters.page,
            pageSize: this.state.filters.pageSize,
            source: this.state.filters.source,
        }
        this.state.filters = resteData
        this.filterTab = {}
        this.toReset.map(item => {
            let formReset = document.getElementById(item)
            if (formReset) formReset.reset();
        })
        this.handleFetchData(resteData)
    }
    showReset() {
        const keys = Object.entries(this.state.filters).filter(item => item[1] && (item[0] !== 'typeFilter'));
        return keys.length > 3
    }
    handleImportExcel = (show) => {
        this.setState({
            importData: show
        })
    }
    handleExportExcel = (show) => {
        this.setState({
            exportData: show
        })
    }

    handleTableChange = (filter, sort, pagination) => {
        var search = {}
        if (this.state.search != '') {
            let columns = ["denomination", "exploitant.client.full_name", "client.client.full_name", "gestionnaire.email", "region_siege.name_region", "departement_siege.name_departement"]
            columns.forEach((column) => {
                search[column] = {filterType: "text", type: "contains", filter: this.state.search}
            })
        }
        this.setState({
            loading: true,
            list: []
        }, () => this.props.fetchSites({sort, filter, pagination, search: this.state.search != '' ? search : null}));
    }
    handleSaveView = () => {
        this.setState({
            view_save: 2
        }, () => {
            this.childComponentRef.saveCurrentView()
        })
    }
    handleViewSaved = (value) => {
        this.setState({
            view_save: value
        })
    }
    render() {
        return (
            <div className={`card card-custom`}>
                <div className="card-header header-center py-1">
                    <h3 className="card-title">
                        <span className="card-label font-weight-bolder text-dark">
                            Sites ({this.state.total} élément trouvé)
                        </span>
                    </h3>
                    <Form.Control type="text" placeholder={global._trans('sites', 'Search...')} onInput={this.handleInputChange} style={{flexGrow: 1, width: 'unset', margin: "0 20px"}}/>
                    <div className="card-toolbar">
                    {this.state.view_save > 0 && <button title={"Enregistrer l'état de la vue (filtre, ordre, disposition et taille des colonnes)"} disabled={this.state.view_save == 2} className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.handleSaveView()}>
                            <span className="svg-icon svg-icon-md" style={{margin: 0}}>
                                <SVG hidden={this.state.view_save != 1} src={toAbsoluteUrl("/media/svg/icons/Costum/Save.svg")} className="h-50 align-self-center"></SVG>
                                <SVG hidden={this.state.view_save != 2} src={toAbsoluteUrl("/media/svg/icons/Costum/Loading.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                        </button>}
                        <button className="mr-3 btn btn-secondary font-weight-bolder font-size-sm " onClick={() => this.childComponentRef.viewsList(true)}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Columns.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Views management')}
                        </button>
                        <button className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => this.handleImportExcel(true)}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'importData')}
                        </button>
                        <button className="btn btn-light-primary font-weight-bolder font-size-sm mx-3" onClick={() => this.handleExportExcel(true)}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            Exporter
                        </button>
                        <NavLink to="/sites/new" className="btn btn-primary font-weight-bolder font-size-sm">
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            Créer un site
                        </NavLink>
                    </div>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingDelete}>
                <div className="card-body py-4">
                    <AgGrid
                        rows={this.state.list}
                        columns={this.state.columnDefs}
                        pagination={{total: this.state.total, pageSize: this.state.page_size, current: this.state.page}}
                        onChange={this.handleTableChange}
                        loading={this.state.loading}
                        ref={ref => this.childComponentRef = ref}
                        views_key={"sites"}
                        onViewSaved={this.handleViewSaved}
                    />
                </div>
                </Spin>
                <ExportSitesModal show={this.state.exportData} filter={this.childComponentRef?.gridApi?.getFilterModel()} columns={this.childComponentRef?.state?.columns.filter(item => item.visible && item.colId != "Actions").reduce((acc, obj) => {acc[obj.colId] = obj.colDef.headerName;return acc;}, {}) || []} title={global._trans('common', 'titelExport')} onHide={() => this.handleExportExcel(false)} />
                <ImportSites show={this.state.importData} title={global._trans('common', 'titelImport')} onHide={() => this.handleImportExcel(false)} />
            </div>
        )
    }
}
function mapState(state) {
    const { listEnums: { mode_gestion } } = state.enums
    const { siteErrors, listSites, currentQuery } = state.sites
    const { user } = state.auth
    return { siteErrors, listSites, currentQuery, mode_gestion, user }
}
const connectedClass = connect(mapState, { fetchSites, deleteSites, exportSites })(ListSites)
export { connectedClass as ListSites }