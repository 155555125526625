import { enumSlice } from "./settings.reducer";
import * as SettingsRequest from "./settings.crud";
const { actions } = enumSlice;

export const CreateEnum = EnumToCreate => dispatch => {
    return SettingsRequest
        .createEnum(EnumToCreate)
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.enumCreated({ newEnum: data, typeEnum: EnumToCreate.key, enemuration: EnumToCreate.enemuration }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const FetchEnums = QueryParams => dispatch => {
    return SettingsRequest
        .fetchEnums()
        .then(({ok,data}) => {
            if (ok) {
                dispatch(actions.enumsFetched({ enumsList: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const DeleteEnum = EnumToDelete => dispatch => {
    return SettingsRequest
        .deleteEnum(EnumToDelete)
        .then(({ok}) => {
            if (ok) {
                dispatch(actions.enumDeleted(EnumToDelete));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: {message:global._trans("common","messageErrors"), action:true} }));
        });
};
/******************** */
export const fetchDepartements = QueryParams => dispatch => {
    return SettingsRequest
        .fetchDepartements(QueryParams)
        .then(({message,list}) => {
            if (message==='async') {
                dispatch(actions.departmentsFetched({ listDeps: list }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const handleDepartement = Departement => {
    return SettingsRequest
        .handleDepartement(Departement)
}
export const deleteDepartement = idDep => {
    return SettingsRequest
        .deleteDepartement(idDep)
}
/******************** */
export const fetchRegions = QueryParams => dispatch => {
    return SettingsRequest
        .fetchRegions(QueryParams)
        .then(({message,list}) => {
            if (message==='async') {
                dispatch(actions.regionsFetched({ listRegs: list }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common","messageErrors") }));
        });
};
export const handleRegion = Region => {
    return SettingsRequest
        .handleRegion(Region)
}
export const deleteRegion = idReg => {
    return SettingsRequest
        .deleteRegion(idReg)
}