import React, { Component } from 'react'
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
import { SageTable, InlineBottunSvg } from '../Commons'
import { Modal } from 'antd'
import { fetchAdmins, deleteAdmins } from '../../../modules/Admins/admin.actions'
import { CreateAdmin } from './CreateAdmin'
import { Button } from 'react-bootstrap';
class ListAdmins extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            loadingDelete: false,
            loadingUpdate: false,
            listAdmins: null,
            total: 0,
            pageSize: 20,
            cuurentPage: 1,
            showAdd: false,
            isInEdit: null
        }
        props.fetchAdmins()
        this.deleteAdmin = this.deleteAdmin.bind(this)
        this.handleAddAdmin = this.handleAddAdmin.bind(this)
        this.handleEditAdmin = this.handleEditAdmin.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.listAdmins && Array.isArray(nextProps.listAdmins.data)) {
                this.setState({
                    listAdmins: nextProps.listAdmins.data,
                    loading: false,
                    cuurentPage: nextProps.listAdmins.current_page,
                    total: nextProps.listAdmins.total
                })
            }
        }
        if (this.state.loadingDelete) {
            this.setState({
                listAdmins: nextProps.listAdmins.data,
                loadingDelete: false
            })
        }
        if (this.state.loadingUpdate) {
            this.setState({
                loadingUpdate: false
            })
        }
    }

    deleteAdmin(idAdmin) {
        Modal.confirm({
            title: global._trans('admins', 'titleMessageDelete'),
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('admins', 'bodyMessageDelete'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.props.deleteAdmins({ admins: [idAdmin] })
                this.setState({
                    loadingDelete: true
                })
            }
        });
    }
    handleEditAdmin(key) {
        //this.props.updateAdmin(record)
        this.setState({
            isInEdit: this.state.listAdmins[key],
            showAdd: true
        })
    }
    handleTableChange = (pagination, filters) => {
        if (pagination.current !== this.state.cuurentPage || pagination.pageSize !== this.state.pageSize) {
            this.props.fetchAdmins({ pageSize: pagination.pageSize, page: pagination.current })
            this.setState({
                loading: true,
                pageSize: pagination.pageSize
            })
        }
    }
    handleAddAdmin(syncData) {
        if (syncData === true) {
            this.props.fetchAdmins({ pageSize: this.state.pageSize, page: this.state.cuurentPage })
        }
        this.setState({
            showAdd: !this.state.showAdd,
            loading: syncData === true,
            isInEdit: null
        })
    }
    render() {
        return (
            <>
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('admins', 'titleAdminList')}</span>
                    </h3>
                    <div className="card-toolbar my-0">
                        <Button className="btn btn-primary font-weight-bolder font-size-sm" onClick={this.handleAddAdmin}>
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('admins', 'createAdmin')}
                        </Button>
                    </div>
                </div>
                <div className="card-body py-4">
                    <SageTable
                        data={this.state.listAdmins}
                        columns={[
                            {
                                title: global._trans('contacts', 'status'), dataIndex: "status", render: status => <div className="symbol align-self-start align-self-xxl-center">
                                    <i className={`symbol-badge symbol-badge-xl bg-${status ? "success" : "danger"}`}></i>
                                </div>
                            },
                            { title: global._trans('common', 'firstName'), dataIndex: 'nom' },
                            { title: global._trans('common', 'lastName'), dataIndex: 'prenom' },
                            { title: global._trans('common', 'userName'), dataIndex: "username" },
                            { title: global._trans('common', 'initPassword'), dataIndex: "init_password", render: init_password => init_password ? init_password : <strong>MdP modifié</strong> },
                            { title: global._trans('common', 'email'), dataIndex: "email_admin" },
                            { title: global._trans('common', 'phone'), dataIndex: "phone", render: phone => '+' + phone },
                            {
                                title: '', dataIndex: "id_admin", fixed: 'right', render: (id_admin, key) => <InlineBottunSvg
                                    bottuns={[
                                        { iconPath: "/media/svg/icons/Costum/Edit.svg", type: "primary", action: () => this.handleEditAdmin(key) },
                                        { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.deleteAdmin(id_admin) }
                                    ]}
                                />
                            }
                        ]}
                        style={{ height: 650 }}
                        IterableProps={{
                            emptyText: global._trans('admins', 'emptyAdmin'),
                            loading: this.state.loading,
                            rowKey: "id_admin",
                            pagination: { total: this.state.total, pageSize: this.state.pageSize, current: this.state.cuurentPage, showSizeChanger: true },
                            onChange: this.handleTableChange
                        }}
                    />
                </div>
                {this.state.showAdd && <CreateAdmin toEdit={this.state.isInEdit} show={this.state.showAdd} onClose={this.handleAddAdmin} />}
            </>
        )
    }
}
function mapState(state) {
    const { errorsAdmin, listAdmins } = state.admins
    return { errorsAdmin, listAdmins }
}
const connectedClass = connect(mapState, { fetchAdmins, deleteAdmins })(ListAdmins)
export { connectedClass as ListAdmins }