import React from 'react'
export function HeaderTab(props) {
    return (
        <div className="card-header card-header-tabs-line" style={props.style}>
            <ul className="nav nav-dark nav-bold nav-tabs nav-tabs-line" data-remember-tab="tab_id" role="tablist" >
                {props.items.map((item, key) => {
                    return <li className="nav-item" key={key}>
                        <a className={`nav-link ` + (props.active === key ? 'active' : '')} data-toggle="tab" onClick={() => props.switchItem(key)}>{!!item.label ? item.label : item}</a>
                    </li>
                })}
            </ul>
        </div>
    )
}