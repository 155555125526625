import { CrudService } from "../../../services";
import { authUrls } from './auth.constants'
export function login(logins) {
    return CrudService.Post(authUrls.LOGIN_URL, logins);
}
export function getUserByToken() {
    return CrudService.Get(authUrls.USER_URL);
}
export function updatePassword(passwordObject) {
    return CrudService.Patch(authUrls.UPDATE_PASSWORD_URL,passwordObject);
}
export function sendEmailPassword(passwordObject) {
    return CrudService.Post(authUrls.SEND_EMAIL_URL,passwordObject);
}
export function otp(data) {
    return CrudService.Post(authUrls.OTP_URL, data);
}