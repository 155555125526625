import React, { createRef, useState } from "react";
import { useLocation } from "react-router";
import { useSelector } from 'react-redux'
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight } from 'react-icons/md';

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const observedDiv = React.useRef(null);
  // const [width, setWidth] = React.useState(265);
  const [dropDownsSidebar, setdropDownsSidebar] = useState({
    GDU: true,
    GT: true,
    GDS: true,
    GP: true,
  });
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  // React.useEffect(() => {
  //   if (!observedDiv.current) {
  //     return;
  //   }
  //   const resizeObserver = new ResizeObserver(() => {
  //     setWidth(observedDiv.current.offsetWidth);
  //   });
  //   resizeObserver.observe(observedDiv.current);
  //   return function cleanup() {
  //     resizeObserver.disconnect();
  //   }
  // },
  //   [observedDiv.current])
  return (
    <>
      <ul id="ulSideBar" className={`menu-nav ${layoutProps.ulClasses} ulSIdeBar`} style={{ position: 'relative' }} ref={observedDiv}>
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Chart-pie.svg")} />
            </span>
            <span className="menu-text">{global._trans('asideBar', 'dashboard')}</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item ${getMenuItemActive("/map/site", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/map/site">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Location-arrow.svg")} />
            </span>
            <span className="menu-text">{global._trans('asideBar', 'map')}</span>
          </NavLink>
        </li>

        {/* ----------------------------------------------------------------------------------------------------  */}

        {user.typeuser !== "Gestionnaire" && <li className="menu-section " style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4 className="menu-text" onClick={() => (setdropDownsSidebar({ ...dropDownsSidebar, GDU: !dropDownsSidebar.GDU }))}>{global._trans('asideBar', 'groupeUsers')}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
          {!dropDownsSidebar.GDU ? <MdOutlineKeyboardArrowRight size={21} color="#7F81A3" /> : <MdOutlineKeyboardArrowDown size={21} color="#7F81A3" />}
        </li>}
        {dropDownsSidebar.GDU && (
          <>
            {user.typeuser === "SupAdmin" &&
              <li
                className={`menu-item ${getMenuItemActive("/admin", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/admin">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/User.svg")} />
                  </span>
                  <span className="menu-text">{global._trans('asideBar', 'admin')}</span>
                </NavLink>
              </li>}
            {user.typeuser !== "Gestionnaire" && <><li
              className={`menu-item ${getMenuItemActive("/manager", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/manager">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/manager.svg")} />
                </span>
                <span className="menu-text">{global._trans('asideBar', 'manager')}</span>
              </NavLink>
            </li>
              <li
                className={`menu-item ${getMenuItemActive("/users", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/users">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/User.svg")} />
                  </span>
                  <span className="menu-text">{global._trans('asideBar', 'users')}</span>
                </NavLink>
              </li>
            </>}
          </>
        )}

        {/* ----------------------------------------------------------------------------------------------------  */}

        <li className="menu-section " style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4 className="menu-text" onClick={() => (setdropDownsSidebar({ ...dropDownsSidebar, GT: !dropDownsSidebar.GT }))}>{global._trans('asideBar', 'groupeClient')}</h4>
          {/* <i className="menu-icon flaticon-more-v2"></i> */}
          {!dropDownsSidebar.GT ? <MdOutlineKeyboardArrowRight size={21} color="#7F81A3" /> : <MdOutlineKeyboardArrowDown size={21} color="#7F81A3" />}
        </li>
        {dropDownsSidebar.GT && (
          <>
            {user.typeuser !== "Gestionnaire" && <><li
              className={`menu-item ${getMenuItemActive(
                "/client/companies"
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/client/companies">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/team-leader.svg")} />
                </span>
                <span className="menu-text">{global._trans('asideBar', 'companies')}</span>
              </NavLink>
            </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/client/communities"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/client/communities">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/business-person.svg")} />
                  </span>
                  <span className="menu-text">{global._trans('asideBar', 'client')}</span>
                </NavLink>
              </li>
            </>}
            <li
              className={`menu-item ${getMenuItemActive(
                "/contact"
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/contact">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Contact.svg")} />
                </span>
                <span className="menu-text">{global._trans('asideBar', 'contacts')}</span>
              </NavLink>
            </li>
          </>
        )}
        {/* ----------------------------------------------------------------------------------------------------  */}

        <li className="menu-section " style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4 className="menu-text" onClick={() => (setdropDownsSidebar({ ...dropDownsSidebar, GDS: !dropDownsSidebar.GDS }))}>{global._trans('asideBar', 'groupeSites')}</h4>
          {/* <i className="menu-icon flaticon-more-v2"></i> */}
          {!dropDownsSidebar.GDS ? <MdOutlineKeyboardArrowRight size={21} color="#7F81A3" /> : <MdOutlineKeyboardArrowDown size={21} color="#7F81A3" />}
        </li>
        {dropDownsSidebar.GDS && (
          <>
            <li
              className={`menu-item ${getMenuItemActive("/sites", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/sites">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Bullet-list.svg")} />
                </span>
                <span className="menu-text">{global._trans('asideBar', 'sites')}</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/contrat"
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/contrat">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Contract.svg")} width={35} />
                </span>
                <span className="menu-text">{global._trans('asideBar', 'contract')}</span>
              </NavLink>
            </li>
            {user.typeuser !== "Gestionnaire" && <>
              <li
                className={`menu-item ${getMenuItemActive("/shares/site", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/shares/site">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Share.svg")} />
                  </span>
                  <span className="menu-text">{global._trans('asideBar', 'share')}</span>
                </NavLink>
              </li></>}
          </>
        )}

        {/* ----------------------------------------------------------------------------------------------------  */}

        {(user.typeuser === "SupAdmin" || user.typeuser === "Admin" || user.typeuser === "Gestionnaire") && <li className="menu-section " style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4 className="menu-text" onClick={() => (setdropDownsSidebar({ ...dropDownsSidebar, GP: !dropDownsSidebar.GP }))}>GED / Paramètres</h4>
          <i className="menu-icon flaticon-more-v2"></i>
          {!dropDownsSidebar.GP ? <MdOutlineKeyboardArrowRight size={21} color="#7F81A3" /> : <MdOutlineKeyboardArrowDown size={21} color="#7F81A3" />}
        </li>}
        {dropDownsSidebar.GP && (
          <>
            {(user.typeuser === "SupAdmin" || user.typeuser === "Admin" || user.typeuser === "Gestionnaire") &&
              <li
                className={`menu-item ${getMenuItemActive("/ged", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/ged">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Settings-2.svg")} />
                  </span>
                  <span className="menu-text">{global._trans('asideBar', 'GED')}</span>
                </NavLink>
              </li>}
            {(user.typeuser === "SupAdmin" || user.typeuser === "Admin") &&
              <li
                className={`menu-item ${getMenuItemActive("/settings", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/settings">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Settings-2.svg")} />
                  </span>
                  <span className="menu-text">{global._trans('asideBar', 'settings')}</span>
                </NavLink>
              </li>}
          </>
        )}

        {/* ----------------------------------------------------------------------------------------------------  */}

        {/* <hr style={{ backgroundColor: 'white', margin: '10px' }} /> */}
        {/* {width !== 70 && (
          <img alt="logo" className="sideBarLogoBottom" src='/media/mapmarkers/imgs/sideBarLogoBottom.png' />          
        )} */}
      </ul>
    </>
  );
}
