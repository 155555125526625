import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class Extension extends Component {
    render() {
        return (
            <CommonComponent keyName="extension" titleSetting={global._trans('settings', 'extension')} extension={this.props.extension} />
        )
    }
}
function mapState(state) {
    const { listEnums: { extension } } = state.enums
    return { extension }
}
const connectedClass = connect(mapState, null)(Extension)
export { connectedClass as Extension }