import React, { Component } from 'react'
import { Form, Col } from "react-bootstrap";
import { Radio, Switch } from 'antd';
export const BasicInfo = (props) => {
    return (
        <Form>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('common', 'firstName')}</Form.Label>
                    <Form.Control type="text" name="nom" value={props.contact.nom} placeholder={global._trans('common', 'firstName')} disabled={true} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('common', 'lastName')}</Form.Label>
                    <Form.Control type="text" name="prenom" value={props.contact.prenom} placeholder={global._trans('common', 'lastName')} disabled={true} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('contacts', 'sexe')}*</Form.Label>
                    <div className="d-flex align-items-center my-1">
                        <Radio.Group value={props.contact.genre} name="genre" disabled={true}>
                            <Radio.Button value="MME">MME</Radio.Button>
                            <Radio.Button value="MR">MR</Radio.Button>
                        </Radio.Group>
                    </div>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('contacts', 'status')}*</Form.Label>
                    <div className="d-flex align-items-center my-3">
                        <strong>{global._trans('contacts', 'inactive')}</strong>
                        <Switch className="mx-3" checked={props.contact.status} disabled={true} />
                        <strong>{global._trans('contacts', 'active')}</strong>
                    </div>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('common', 'phone')}</Form.Label>
                    <Form.Control type="text" name="telephone" value={props.contact.telephone} placeholder={global._trans('common', 'phone')} disabled={true} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('common', 'mobile')}</Form.Label>
                    <Form.Control type="text" name="mobile" value={props.contact.mobile} placeholder={global._trans('common', 'mobile')} disabled={true} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('common', 'email')}</Form.Label>
                    <Form.Control type="email" name="email" value={props.contact.email} placeholder={global._trans('common', 'email')} disabled={true} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('contacts', 'information')}</Form.Label>
                    <Form.Control name="informations" placeholder={global._trans('contacts', 'information')} value={props.contact.informations} disabled={true} as="textarea" rows="3" />
                </Form.Group>
            </Form.Row>
            <Form.Row className="mb-4">
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('common', 'address')}</Form.Label>
                    <Form.Control type="address" name="text" value={props.contact.address} placeholder={global._trans('common', 'address')} disabled={true} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('common', 'city')}</Form.Label>
                    <Form.Control type="city" name="text" value={props.contact.city} placeholder={global._trans('common', 'city')} disabled={true} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('common', 'postcode')}</Form.Label>
                    <Form.Control type="postcode" name="text" value={props.contact.postcode} placeholder={global._trans('common', 'postcode')} disabled={true} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('common', 'country')}</Form.Label>
                    <Form.Control type="country" name="text" value={props.contact.country} placeholder={global._trans('common', 'country')} disabled={true} />
                </Form.Group>
            </Form.Row>
            <Form.Row className="mb-4">
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('contacts', 'LinkedIn')}</Form.Label>
                    <Form.Control name="linkedin" placeholder={global._trans('contacts', 'LinkedIn')} value={props.contact.linkedin} disabled={true} rows="3" />
                </Form.Group>
            </Form.Row>
        </Form>
    )
}