import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { CreateContact } from './CreateContact'
import { ListContacts } from './ListContacts'
import { ViewContact } from './ViewContact'
class ContactPage extends Component {
    render() {
        return (
            <div className="card card-custom">
                <Switch>
                    <Route
                        path="/contact/view/:idcontact"
                        component={ViewContact}
                    />
                    <Route
                        path="/contact/:idCreate"
                        component={CreateContact}
                    />
                    <Route
                        path="/contact"
                        component={ListContacts}
                    />
                </Switch>
            </div>
        )
    }
}
export { ContactPage }