import React, { Component } from 'react';
import { Form, Col } from "react-bootstrap";
import { DataTechUVE as CreateDataTechUVE } from '../CreateSitePages/DataTechUVE';
class DataTechUVE extends Component {
    render() {
        const { stateTech } = this.props
        return (
            <CreateDataTechUVE {...stateTech} disabled={true} />
        );
    }
}
export { DataTechUVE }