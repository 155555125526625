import React, { Component } from 'react'
import { connect } from 'react-redux'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_sage/_helpers";
import { message } from 'antd';
import { TableEditableAction, ImportData } from '../../Commons'
import { fetchRegions, handleRegion, deleteRegion } from '../../../../modules/Settings/settings.actions'
class RegionsSiege extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: (props.regions && props.regions.data) || [],
            loading: !props.regions,
            creating: false,
            page: props.regions && props.regions.current_page,
            total: props.regions && props.regions.total,
            pageSize: 100,
            showImport: false
        }
        this.handleAdd = this.handleAdd.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        if (!props.regions) {
            props.fetchRegions();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.regions) {
                this.setState({
                    loading: false,
                    data: nextProps.regions.data,
                    total: nextProps.regions.total,
                    page: nextProps.regions.current_page
                })
            }
        }
    }
    handleSave(newList, row) {
        this.setState({
            creating: true
        })
        handleRegion(row)
            .then(({ ok, region }) => {
                if (ok) {
                    let newData = newList
                    let index = newList.findIndex(item => item.id_region == row.id_region)
                    newData[index] = region
                    this.setState({
                        data: newData,
                        creating: false
                    })
                } else {
                    this.setState({
                        creating: false
                    })
                }
            })
            .catch(({ }) => {
                this.setState({
                    creating: false
                })
            })
    }
    handleAdd() {
        const { pageSize, data } = this.state;
        const newData = {
            name_region: '',
            slug_region: "",
            region_code: "",
            id_region: null
        };
        this.setState({
            data: [newData, ...data],
            pageSize: pageSize + 1
        });

    }
    handleDelete(idRegion) {
        this.setState({
            creating: true
        })
        deleteRegion(idRegion)
            .then(({ ok, region, errors }) => {
                if (ok === 'async') {
                    var newData = [...this.state.data]
                    let index = newData.findIndex(item => item.id_region == region)
                    newData.splice(index, 1)
                    this.setState({
                        data: newData,
                        creating: false
                    })
                } else if(errors){
                    message.error(global._trans('settings', 'cantDelete'));
                }
                this.setState({
                    creating: false
                })

            })
            .catch(({ }) => {
                this.setState({
                    creating: false
                })
            })
    }
    onPageChange(page) {
        this.setState({
            loading: true
        })
        this.props.fetchRegions({ page: page });
    }
    handleImportExcel = () => {
        this.setState({
            showImport: !this.state.showImport
        })
    }
    render() {
        return (
            <div className="card card-custom card-stretch">
                <div className="card-header py-1 header-center">
                    <div className="card-title flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">{global._trans('common', 'regionSiege')}</h3>
                    </div>
                    <div className="card-toolbar align-items-center">
                        <button className="btn btn-light-success font-weight-bolder font-size-sm mr-2" onClick={this.handleImportExcel}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'importData')}
                        </button>
                        <button className="btn btn-primary font-weight-bolder font-size-sm" onClick={this.handleAdd}>
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('settings', 'addRegion')}
                        </button>
                    </div>
                </div>
                <div className="card-body py-4">
                    <TableEditableAction
                        dataList={this.state.data}
                        columnsList={[
                            { title: global._trans('settings', 'region_name'), dataIndex: 'name_region', editable: true },
                            // { title: global._trans('settings', 'region_slug'), dataIndex: 'slug_region', editable: true },
                            { title: global._trans('settings', 'region_code'), dataIndex: 'region_code', editable: true }
                        ]}
                        dataKey="id_region"
                        scrollY={585}
                        pagination={{ pageSize: this.state.pageSize, showSizeChanger: false, total: this.state.total, current: this.state.page, onChange: this.onPageChange }}
                        saveData={this.handleSave}
                        loading={this.state.loading || this.state.creating}
                        deleteRecord={this.handleDelete}
                    />
                </div>
                <ImportData show={this.state.showImport} title={global._trans('common', 'titelImport')} sourceFile="Regions" onHide={this.handleImportExcel} />
            </div>
        )
    }
}
function mapState(state) {
    const { regions } = state.enums
    return { regions }
}
const connectedClass = connect(mapState, { fetchRegions })(RegionsSiege)
export { connectedClass as RegionsSiege }