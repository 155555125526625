import { collectivitieSlice } from "./collectivitie.reducer";
import * as collectivitieRequests from "./collectivitie.crud";
import { notification } from "antd";
import ReactHtmlParser from 'react-html-parser';

const { actions } = collectivitieSlice;
export const createEpic = EpicToCreate => dispatch => {
    return collectivitieRequests
        .createEpic(EpicToCreate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.epicCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors, errorType: 'EpicErrors' }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
            }
        });
};
export const updateEpic = EpicToUpdate => dispatch => {
    return collectivitieRequests
        .updateEpic(EpicToUpdate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.epicCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors, errorType: 'EpicErrors' }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
            }
        });
};
export const fetchEpics = QueryParams => dispatch => {
    return collectivitieRequests
        .fetchEpics(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.epicsFetched({ listEpics: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
        });
}
export const fetchEpicsGet = QueryParams => dispatch => {
    return collectivitieRequests
        .fetchEpicsGet(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.epicsFetched({ listEpics: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
        });
}
export const fetchEpic = IdEpic => dispatch => {
    return collectivitieRequests
        .fetchEpic(IdEpic)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.epicFetched({ epic: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
        });
}
export const editEpic = IdEpic => dispatch => {
    return collectivitieRequests
        .editEpic(IdEpic)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.epicFetched({ epic: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
        });
}
export const deleteEpics = EpicsToDelete => dispatch => {
    return collectivitieRequests
        .deleteEpics(EpicsToDelete)
        .then(({ ok, data, epics }) => {
            if (ok) {
                dispatch(actions.epicsDeleted({ asyncData: data === "async", epics: epics }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'EpicErrors' }));
        });
}

export const syncEPICsInseeAPI = epics => dispatch => {
    return collectivitieRequests
        .syncEPICsInseeAPI(epics)
        .then(({ ok, data }) => {
            if (ok) {
                let dt = ''
                if (data.length) {
                    data.map(el => {
                        const t = Object.keys(el)[0]
                        dt = dt + '- <b> ' + t + '</b>' + ' : ' + el[t] + '<br />'
                    })
                }
                notification.success({
                    message: 'Les informations ont été mises à jour par l’API.',
                    description: ReactHtmlParser(dt),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}
                })
                dispatch(actions.epicSynced());
            } else {
                notification.error({
                    message: 'Les informations n\'ont pas été synchronisées.',
                    placement: 'topRight',
                    style:{backgroundColor: '#FFF6F8', borderRadius: '8px', border: '1px solid #F44336'}

                })
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
        });
}


/************************************************* */
export const createCommune = CommuneToCreate => dispatch => {
    return collectivitieRequests
        .createCommune(CommuneToCreate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.communeCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors, errorType: 'CommuneErrors' }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        });
};
export const updateCommune = CommuneToUpdate => dispatch => {
    return collectivitieRequests
        .updateCommune(CommuneToUpdate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.communeCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors, errorType: 'CommuneErrors' }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        });
};
export const fetchCommunes = QueryParams => dispatch => {
    return collectivitieRequests
        .fetchCommunes(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.communesFetched({ listCommunes: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
        });
}
export const fetchCommunesGet = QueryParams => dispatch => {
    return collectivitieRequests
        .fetchCommunesGet(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.communesFetched({ listCommunes: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
        });
}
export const deleteCommunes = CommunesToDelete => dispatch => {
    return collectivitieRequests
        .deleteCommunes(CommunesToDelete)
        .then(({ ok, data, communes }) => {
            if (ok) {
                dispatch(actions.communesDeleted({ asyncData: data === "async", communes: communes }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
        });
};
export const fetchCommune = IdCommune => dispatch => {
    return collectivitieRequests
        .fetchCommune(IdCommune)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.communeFetched({ commune: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
        });
}
export const editCommune = IdCommune => dispatch => {
    return collectivitieRequests
        .editCommune(IdCommune)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.communeFetched({ commune: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
        });
}

export const syncCommunesInseeAPI = communes => dispatch => {
    return collectivitieRequests
        .syncCommunesInseeAPI(communes)
        .then(({ ok, data }) => {
            if (ok) {
                let dt = ''
                if (data.length) {
                    data.map(el => {
                        const t = Object.keys(el)[0]
                        dt = dt + '- <b> ' + t + '</b>' + ' : ' + el[t] + '<br />'
                    })
                }
                notification.success({
                    message: 'Les informations ont été mises à jour par l’API.',
                    description: ReactHtmlParser(dt),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}
                })
                dispatch(actions.communeSynced());
            } else {
                notification.error({
                    message: 'Les informations n\'ont pas été synchronisées.',
                    placement: 'topRight',
                    style:{backgroundColor: '#FFF6F8', borderRadius: '8px', border: '1px solid #F44336'}

                })
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
        });
}

export const syncSyndicatsInseeAPI = communes => dispatch => {
    return collectivitieRequests
        .syncSyndicatsInseeAPI(communes)
        .then(({ ok, data }) => {
            if (ok) {
                let dt = ''
                if (data.length) {
                    data.map(el => {
                        const t = Object.keys(el)[0]
                        dt = dt + '- <b> ' + t + '</b>' + ' : ' + el[t] + '<br />'
                    })
                }
                notification.success({
                    message: 'Les informations ont été mises à jour par l’API.',
                    description: ReactHtmlParser(dt),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}
                })
                dispatch(actions.syndicatSynced({ syndicat: data }));
            } else {
                notification.error({
                    message: 'Les informations n\'ont pas été synchronisées.',
                    placement: 'topRight',
                    style:{backgroundColor: '#FFF6F8', borderRadius: '8px', border: '1px solid #F44336'}

                })
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'CommuneErrors' }));
        });
}

/*********************************** */
export const createSyndicat = SyndicatToCreate => dispatch => {
    return collectivitieRequests
        .createSyndicat(SyndicatToCreate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.syndicatCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors, errorType: 'SyndicatErrors' }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
            }
        });
};
export const updateSyndicat = SyndicatToUpdate => dispatch => {
    return collectivitieRequests
        .updateSyndicats(SyndicatToUpdate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.syndicatCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors, errorType: 'SyndicatErrors' }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
            }
        });
};
export const fetchSyndicats = QueryParams => dispatch => {
    return collectivitieRequests
        .fetchSyndicats(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.syndicatsFetched({ listSyndicats: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
        });
}
export const fetchSyndicatsGet = QueryParams => dispatch => {
    return collectivitieRequests
        .fetchSyndicatsGet(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.syndicatsFetched({ listSyndicats: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
        });
}
export const fetchSyndicat = IdSyndicat => dispatch => {
    return collectivitieRequests
        .fetchSyndicat(IdSyndicat)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.syndicatFetched({ syndicat: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
        });
}
export const editSyndicat = IdSyndicat => dispatch => {
    return collectivitieRequests
        .editSyndicat(IdSyndicat)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.syndicatFetched({ syndicat: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
        });
}
export const deleteSyndicats = Syndicats => dispatch => {
    return collectivitieRequests
        .deleteSyndicats(Syndicats)
        .then(({ ok, data, syndicats }) => {
            if (ok) {
                dispatch(actions.syndicatsDeleted({ asyncData: data === "async", syndicats: syndicats }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors"), errorType: 'SyndicatErrors' }));
        });
}
/***************************** */