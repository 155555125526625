import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { SageTable } from './SageTable'
import { fetchGestionaires } from "../../../modules/Gestionaires/gestionaire.actions";
class GestionaireSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRow: [],
            listsData: [],
            loading: true,
            search: ''
        }
        props.fetchGestionaires({ pageSize: 15, ...props.filter })
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.validSelect = this.validSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading && nextProps.listGestionaires.data) {
            this.setState({
                loading: false,
                listsData: nextProps.listGestionaires.data
            })
        }
    }
    handleSelectRow(selectedRowKeys) {
        this.setState({
            selectedRow: selectedRowKeys
        })
    }
    handleSearch(e) {
        this.props.fetchGestionaires({ pageSize: 15, nom: e.target.value, prenom: e.target.value, ...this.props.filter })
        this.setState({
            search: e.target.value,
            loading: true
        })
    }
    validSelect() {
        let elem = this.state.listsData.find(obj => obj.id_gestionnaire == this.state.selectedRow[0])
        this.props.validSelection(elem)
    }
    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} aria-labelledby="example-modal-sizes-title-lg">
                <div className="p-5">
                    <Form.Control type="text" name="searchvalue" value={this.state.search} placeholder="Chercher par nom ou prenom" onChange={this.handleSearch} />
                    <SageTable
                        columns={[
                            { title: "Statut", dataIndex: "status", fixed: 'left', ellipsis: true, render: status => status ? "Active" : "inActive" },
                            { title: "Nom", dataIndex: "nom", fixed: 'left', ellipsis: true, },
                            { title: "Prénom", dataIndex: "prenom", ellipsis: true, },
                            { title: "Email", dataIndex: "email", ellipsis: true, },
                            { title: "Téléphone", dataIndex: "telephone", ellipsis: true, },
                            { title: "Mobile", dataIndex: "mobile", ellipsis: true, }
                        ]}
                        style={{ height: 550 }}
                        data={this.state.listsData}
                        IterableProps={{
                            rowSelection: {
                                selectedRowKeys: this.state.selectedRow,
                                onChange: this.handleSelectRow,
                                type: 'radio'
                            },
                            emptyText: global._trans('common', 'emptyData'),
                            loading: this.state.loading,
                            rowKey: 'id_gestionnaire',
                            pagination: false
                        }}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="primary" onClick={this.validSelect}>{global._trans('common', 'validSelect')}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
function mapState(state) {
    const { listGestionaires } = state.gestionaire
    return { listGestionaires }
}
const connectedClass = connect(mapState, { fetchGestionaires })(GestionaireSelector)
export { connectedClass as GestionaireSelector }