import React from 'react'
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_sage/_helpers";
export const CardInfo = (props) => {
    return (
        <div className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
            <div className="card card-custom">
                <div className="card-body pt-4">
                    <div className="d-flex align-items-center mt-2">
                        <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                            <div className="symbol-label" style={{ backgroundImage: `url(${props.user && props.user.picture ? props.user.picture : toAbsoluteUrl("/media/images/blank.png")})` }}></div>
                        </div>
                        {props.user && <div>
                            <a className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                                {props.user.nom} {props.user.prenom}
                            </a>
                            <div className="text-muted">{props.user.email}</div>
                            <div className="text-muted mt-2"><strong>{global._trans("users", "useSessions")} : </strong> {props.user.NbUserCreated + " / " + props.user.nbAccess}</div>
                        </div>}
                    </div>
                    <hr />
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded mt-2">
                        <div className="navi-item mb-2">
                            <NavLink to={`/users/premium/view/${props.idUser}/info`} className="navi-link py-4" activeClassName="active">
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Compiling.svg")}></SVG>
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">{global._trans("common", "personalInfo")}</span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink to={`/users/premium/view/${props.idUser}/user-access`} className="navi-link py-4" activeClassName="active">
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/AddUser.svg")}></SVG>
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">{global._trans("users", "usersCreated")}</span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink to={`/users/premium/view/${props.idUser}/shared-sites`} className="navi-link py-4" activeClassName="active">
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Share.svg")}></SVG>
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">{global._trans("users", "sharedSites")}</span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink to={`/users/premium${props.query}`} exact={true} className="navi-link py-4" activeClassName="active">
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Back.svg")}></SVG>
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">{global._trans("common", "backToList")}</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}