export var siteUrls = {
    CREATE_SITE_URL: 'sites/create',
    FETCH_SITES_URL: "sites/all",
    FETCH_SITE_URL: "sites/show/",
    DELETE_SITES_URL: "sites/delete",
    EDIT_SITE_URL: "sites/edit/",
    UPDATE_SITE_URL:"sites/update",
    EXPORT_SITES_URL: "sites/export",
    FETCH_DETAIL_URL: "sites/detail/",
    FETCH_URL: "getting/enums_groups/get_data",

}
export const setsiteUrls=()=>{
    siteUrls = {
        CREATE_SITE_URL: 'managers/sites/create',
        FETCH_SITES_URL: "managers/sites/all",
        FETCH_SITE_URL: "managers/sites/show/",
        DELETE_SITES_URL: "managers/sites/delete",
        EDIT_SITE_URL: "managers/sites/edit/",
        UPDATE_SITE_URL:"managers/sites/update",
        EXPORT_SITES_URL: "managers/sites/export",
        FETCH_DETAIL_URL: "sites/detail/",
        FETCH_URL: "getting/enums_groups/get_data",
    }
}

