import React, { Component } from 'react'
import { connect } from "react-redux";
import { Form } from 'react-bootstrap'
import { NavLink, } from 'react-router-dom'
import { Modal } from 'antd'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
import { InlineBottunSvg, SageTable, FilterSearch, ImportData } from '../Commons'
import { fetchGestionaires, deleteGestionaires } from "../../../modules/Gestionaires/gestionaire.actions";
import { GestionnaireFilters } from './utils'
import { message } from 'antd';
import { exportData } from "../../../modules/Common/common.actions";
import { notification } from 'antd';
const queryString = require('query-string');
class ListGestionaires extends Component {
    constructor(props) {
        super(props)
        let asyncLoad = props.currentQuery !== props.history.location.search || !props.currentQuery
        this.state = {
            selectedRows: [],
            loadGestionaires: asyncLoad,
            listGestionaires: props.listGestionaires.data,
            total: props.listGestionaires.total,
            filters: {
                page: 1,
                pageSize: 20,
                source: 'managers',
                ...queryString.parse(props.history.location.search)
            }
        }
        if (asyncLoad) {
            props.fetchGestionaires(this.state.filters)
        }
        this.toReset = ['form_search_list', 'form_search_input']
        this.filterTab = {}
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
        this.handleChangeSearch = this.handleChangeSearch.bind(this)
        this.handleSorter = this.handleSorter.bind(this)
        this.deleteSelected = this.deleteSelected.bind(this)
        this.handleResetSearch = this.handleResetSearch.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loadGestionaires) {
            if (nextProps.listGestionaires && Array.isArray(nextProps.listGestionaires.data)) {
                this.setState({
                    listGestionaires: nextProps.listGestionaires.data,
                    loadGestionaires: false,
                    filters: {
                        ...this.state.filters,
                        page: nextProps.listGestionaires.current_page,
                        pageSize: nextProps.listGestionaires.per_page
                    },
                    total: nextProps.listGestionaires.total,
                    selectedRows: []
                })
            }
        }
    }
    handleDeleteGestionaire(idGestionaire) {
        Modal.confirm({
            title: global._trans('managers', 'titleDelete') + " #" + idGestionaire,
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('managers', 'bodyDelete'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadGestionaires: true
                })
                this.props.deleteGestionaires({ gestionnaires: [idGestionaire] }).then((ok) => {
                    if (!ok) message.error(global._trans('settings', 'cantDelete'));
                    this.setState({
                        loadGestionaires: false
                    })
                })
            }
        });
    }
    handleTypeFilter(checked) {
        this.setState({
            filters: {
                ...this.state.filters,
                typeFilter: checked ? "inter" : null
            }
        })
    }
    handleFetchData(filter) {
        this.props.history.push({
            search: queryString.stringify(filter)
        })
        this.props.fetchGestionaires(filter)
        this.setState({
            loadGestionaires: true
        })
    }
    handleTableChange = (pagination, filters) => {
        if (pagination.current !== this.state.cuurentPage || pagination.pageSize !== this.state.pageSize) {
            let prevFilter = this.state.filters
            prevFilter.pageSize = pagination.pageSize
            prevFilter.page = pagination.current
            this.handleFetchData(prevFilter)
        }
    }
    handleSelectRow(selectedRows) {
        this.setState({
            selectedRows: selectedRows
        })
    }
    handleSorter(sorter) {
        let prevfilter = this.state.filters
        prevfilter.sort = sorter.value
        prevfilter.sorter = sorter.current
        this.handleFetchData(prevfilter)
    }
    deleteSelected() {
        Modal.confirm({
            title: global._trans('common', 'deleteAllTitle'),
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('sites', 'deleteBody'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadGestionaires: true
                })
                this.props.deleteGestionaires({ gestionnaires: this.state.selectedRows })
            }
        });
    }
    handleSubmitSearch(e) {
        e.preventDefault()
        this.state.filters = {
            ...this.state.filters,
            ...this.filterTab
        }
        this.handleFetchData(this.state.filters)
    }

    handleChangeSearch(e) {
        const { name, value } = e.target
        this.filterTab[name] = value
    }
    handleResetSearch() {
        let resteData = {
            page: this.state.filters.page,
            pageSize: this.state.filters.pageSize,
            source: this.state.filters.source,
        }
        this.state.filters = resteData
        this.filterTab = {}
        this.toReset.map(item => {
            let formReset = document.getElementById(item)
            if (formReset) formReset.reset();
        })
        this.handleFetchData(resteData)
    }
    showReset() {
        const keys = Object.entries(this.state.filters).filter(item => item[1] && (item[0] !== 'typeFilter'));
        return keys.length > 3
    }
    handleImportExcel = (nameImport) => {
        this.setState({
            showImport: nameImport
        })
    }
    handleExportExcel = (nameImport) => {
        exportData(nameImport)
        .then(({ message, ok }) => {
            if (ok && message == "Import Started") {
                notification.info({
                    message: global._trans('common', 'titleImported'),
                    description: global._trans('common', 'bodyImportedMessage'),
                    placement: 'bottomLeft',
                    // duration: 0,
                    style: {
                        width: 500,
                        padding: '15px 20px',
                        borderRadius: 8
                    },
                })
                // onHide()
            }
        })
        .catch((e) => {
        console.log('🚀 => e', e)

        })
        .finally((f) => {
            console.log('🚀 => f', f)
            // setLoading(false)
        })
    }
    render() {
        return (
            <div className="card card-custom">
                <div className="card-header header-center py-1">
                    <h3 className="card-title">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('managers', 'titleList')} ({this.state.total} élément trouvé)</span>
                    </h3>
                    <div className="card-toolbar">
                        <button className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => this.handleImportExcel('gestionnaires')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'importData')}
                        </button>
                        <button className="btn btn-light-primary font-weight-bolder font-size-sm mx-3" onClick={() => this.handleExportExcel({ type:'gestionnaires'})}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            Exporter
                        </button>
                        {/* <a href={`${URL_ROOT}sage/export_sites`} download className="btn btn-light-primary font-weight-bolder font-size-sm ml-2 mr-2">
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'exportData')}
                        </a> */}
                        <NavLink to="/manager/new" className="btn btn-primary font-weight-bolder font-size-sm">
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('managers', 'createManager')}
                        </NavLink>
                    </div>
                </div>
                <div className="card-body py-4">
                    <div className="d-flex align-items-center">
                        {this.state.selectedRows.length > 0 && <button type="button" className="btn btn-light-danger font-weight-bolder font-size-sm d-flex align-items-center mr-3" onClick={this.deleteSelected}>
                            <span className="svg-icon svg-icon-md svg-icon-light-danger">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG>
                            </span>
                            <span>{`${global._trans("common", "deleteSelected")} ${this.state.selectedRows.length} ${global._trans("common", "element")}`}</span>
                        </button>}
                        <div className="d-flex align-items-center">
                            <FilterSearch handleSubmit={this.handleSubmitSearch} idForm="form_search_list" isAndType={this.state.filters.typeFilter === "inter"} handleType={this.handleTypeFilter.bind(this)}>
                                <GestionnaireFilters filters={this.state.filters} handleChange={this.handleChangeSearch} />
                            </FilterSearch>
                            <Form id="form_search_input" className="d-flex" onSubmit={this.handleSubmitSearch}>
                                <Form.Control type="text" style={{ width: 350 }} className="mx-2" placeholder={global._trans('common', 'searchData')} defaultValue={this.state.filters.search} name="search" onChange={this.handleChangeSearch}></Form.Control>
                                {this.showReset() && <button type="button" onClick={this.handleResetSearch} className="btn btn-light font-weight-bolder mr-2 font-size-sm">{global._trans("common", "reset")}</button>}
                                <button className="btn btn-light-primary font-weight-bolder font-size-sm">{global._trans("common", "search")}</button>
                            </Form>
                        </div>
                    </div>
                    <div className="separator separator-solid my-3"></div>
                    <SageTable
                        columns={[
                            {
                                title: global._trans('contacts', 'status'), dataIndex: "status", sorter: true, render: status => <div className="symbol align-self-start align-self-xxl-center">
                                    <i className={`symbol-badge symbol-badge-xl bg-${status ? "success" : "danger"}`}></i>
                                </div>
                            },
                            { title: global._trans('common', 'firstName'), dataIndex: "nom", sorter: true },
                            { title: global._trans('common', 'lastName'), dataIndex: "prenom", sorter: true },
                            { title: global._trans('common', 'userName'), dataIndex: "username" },
                            { title: global._trans('common', 'initPassword'), dataIndex: "init_password", ellipsis: true, render: init_password => init_password ? init_password : <strong>MdP modifié</strong> },
                            { title: global._trans('common', 'email'), dataIndex: "email", sorter: true },
                            { title: global._trans('common', 'mobile'), dataIndex: "mobile", sorter: true },
                            {
                                title: '', dataIndex: "id_gestionnaire", fixed: 'right', render: id_gestionnaire => <InlineBottunSvg
                                    bottuns={[
                                        { iconPath: "/media/svg/icons/Costum/View.svg", type: "primary", action: "/manager/view/" + id_gestionnaire },
                                        { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.handleDeleteGestionaire(id_gestionnaire) }
                                    ]}
                                />
                            }
                        ]}
                        style={{ height: 600 }}
                        callBackSort={this.handleSorter}
                        data={this.state.listGestionaires}
                        defaultSorter={this.state.filters}
                        IterableProps={{
                            rowSelection: {
                                onChange: this.handleSelectRow,
                            },
                            emptyText: global._trans('managers', 'emptyList'),
                            loading: this.state.loadGestionaires,
                            rowKey: "id_gestionnaire",
                            pagination: { total: this.state.total, pageSize: this.state.filters.pageSize, current: parseInt(this.state.filters.page), showSizeChanger: true },
                            onChange: this.handleTableChange
                        }}
                    />
                </div>
                <ImportData show={this.state.showImport} title={global._trans('common', 'titelImport')} sourceFile={this.state.showImport} onHide={() => this.handleImportExcel(null)} />
            </div>
        )
    }
}
function mapState(state) {
    const { listEnums: { contract }, enumsLoaded } = state.enums
    const { listGestionaires, currentQuery } = state.gestionaire
    return { enumsLoaded, contract, listGestionaires, currentQuery }
}
const connectedClass = connect(mapState, { fetchGestionaires, deleteGestionaires, exportData })(ListGestionaires)
export { connectedClass as ListGestionaires }