import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class EquipementProcessTF extends Component {

    render() {
        return (
            <CommonComponent keyName="equipeProcessTF" titleSetting={global._trans('sites', 'equipementTF')} equipeProcessTF={this.props.equipeProcessTF} />
        )
    }
}
function mapState(state) {
    const { listEnums: { equipeProcessTF } } = state.enums
    return { equipeProcessTF }
}
const connectedClass = connect(mapState, null)(EquipementProcessTF)
export { connectedClass as EquipementProcessTF }