import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { notification } from 'antd';
import { exportData } from "../../../../modules/Common/common.actions";
import { Loader } from '../../Commons'
export const ExportSitesModal = ({ show, columns, onHide, title, filter }) => {
    const [file, setFile] = useState(null)
    const [typeSites, setTypeSites] = useState(false)
    const [loading, setLoading] = useState(false);

    const onConfirm = () => {

        setLoading(true)
        exportData({ type: typeSites, columns, filter })
            .then(({ message, ok }) => {
                if (ok && message == "Import Started") {
                    notification.info({
                        message: global._trans('common', 'titleImported'),
                        description: global._trans('common', 'bodyImportedMessage'),
                        placement: 'bottomLeft',
                        // duration: 0,
                        style: {
                            width: 500,
                            padding: '15px 20px',
                            borderRadius: 8
                        },
                    })
                    onHide()
                }
            })
            .catch(({ }) => {

            })
            .finally(() => {
                setLoading(false)
            })
    }
    const handleChange = (e) => {
        const { value } = e.target
        setTypeSites(value)
    }
    return (
        <Modal size="md" centered backdrop="static" show={show} onHide={onHide}>
            {loading && <Loader />}
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between px-5 py-4">
                    <span className="card-label font-size-h4 font-weight-bolder text-dark">{title}</span>
                </div>
                <div className="card-body py-4">
                    <div className="mb-3">

                        <label>Exporter les sites de catégorie: </label>
                        <select className="form-control form-control-solid form-control-lg" onChange={handleChange}>
                            <option value="">Sélectionner catégorie</option>
                            <option value="sites_uve">UVE</option>
                            <option value="sites_uve_valorisations">UVE + Valorisations</option>
                            <option value="sites_tri">TRI</option>
                            <option value="sites_tmb">TMB</option>
                            <option value="sites_isdnd">ISDND</option>
                        </select>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end py-3 px-4 border-top">
                    <button className="btn btn-light font-weight-bolder font-size-sm mr-2" onClick={onHide}>
                        {global._trans('common', 'cancelBtn')}
                    </button>
                    <button className="btn btn-primary font-weight-bolder font-size-sm" onClick={onConfirm}>
                        Exporter
                    </button>
                </div>
            </div>
        </Modal>
    )
}