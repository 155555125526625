import { gestionaireSlice } from "./gestionaire.reducer";
import * as gestionaireRequests from "./gestionaire.crud";
const { actions } = gestionaireSlice;
export const createGestionaire = GestionaireToCreate => dispatch => {
    return gestionaireRequests
        .createGestionaire(GestionaireToCreate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.gestionaireCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        });
};
export const deleteGestionaires = GestionairesToDelete => dispatch => {
    return gestionaireRequests
        .deleteGestionaires(GestionairesToDelete)
        .then(async ({ ok, data, gestionnaires }) => {
            if (ok) {
                dispatch(actions.gestionaireDeleted({ asyncData: data === "async", gestionnaires: gestionnaires }));
                return true;
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
                return false;
            }
        })
        .catch(async (err) => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            return false;
        });
};
export const updateGestionaire = GestionaireToUpdate => dispatch => {
    return gestionaireRequests
        .updateGestionaire(GestionaireToUpdate)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.gestionaireFetched({ Gestionaire: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        });
};
export const fetchGestionaires = QueryParams => dispatch => {
    return gestionaireRequests
        .fetchGestionaires(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.gestionairesFetched({ listGestionaires: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const fetchGestionaire = idGestionaire => dispatch => {
    return gestionaireRequests
        .fetchGestionaire(idGestionaire)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.gestionaireFetched({ Gestionaire: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const editGestionaire = idGestionaire => dispatch => {
    return gestionaireRequests
        .editGestionaire(idGestionaire)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.gestionaireFetched({ Gestionaire: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const sitesGestionaire = (idGestionaire, queryParams) => {
    return gestionaireRequests.sitesGestionaire(idGestionaire, queryParams)
};
export const deleteSitesGestionaire = sites => {
    return gestionaireRequests.deleteSitesGestionaire(sites)
}
export const resetState = state => dispatch => {
    dispatch(actions.resetState(state));
};