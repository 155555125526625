import React from 'react'
import { Form } from 'react-bootstrap'
export const FiltersEpic = (props) => {
    return (
        <>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('clientsEpic', 'epicName')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('clientsEpic', 'epicName')} defaultValue={props.filters.nomEpic} name="nomEpic" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>ID</Form.Label>
                <Form.Control type="text" placeholder="ID" defaultValue={props.filters.id_epic} name="id_epic" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'natureJuridque')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'natureJuridque')} defaultValue={props.filters.nature_juridique} name="nature_juridique" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'regionSiege')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'regionSiege')} defaultValue={props.filters.region_siege} name="region_siege" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'departementSiege')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'departementSiege')} defaultValue={props.filters.departement_siege} name="departement_siege" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'serin')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'serin')} defaultValue={props.filters.serin} name="serin" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'address')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'address')} defaultValue={props.filters.address} name="address" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'nbPeople')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'nbPeople')} defaultValue={props.filters.nombreHabitant} name="nombreHabitant" onChange={props.handleChange} />
            </Form.Group>

        </>
    )
}