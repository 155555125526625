import React from 'react'
import { Upload, Button } from 'antd';
import ImgCrop from 'antd-img-crop';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
import { uploadImage } from '../../../modules/Common/common.actions'
export const ImageCroper = (props) => {
    const handleUpload = (file) => {
        let data = new FormData()
        props.onChange([{
            uid: "-4",
            status: "Chargement..."
        }])
        data.append('file', file.file)
        uploadImage(data)
            .then(({ ok, image }) => {
                if (ok) {
                    props.onChange([image])
                }
            })
            .catch(err => {

            })
    }
    return (
        <div>
            {/* <ImgCrop rotate minZoom={0} grid modalTitle={global._trans('common', 'cropImage')}> */}
                <Upload
                    listType="picture"
                    fileList={props.images}
                    onRemove={() => props.onChange([])}
                    customRequest={handleUpload}
                >
                    {props.images.length === 0 && <Button className="d-flex align-items-center" icon={<SVG src={toAbsoluteUrl('/media/svg/icons/Costum/Upload.svg')}></SVG>}>
                        <span className="ml-2">{global._trans("common", "selectFile")}</span>
                    </Button>}
                </Upload>
            {/* </ImgCrop> */}
        </div>
    )
}