import React, { Component } from 'react';
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Form, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { Select, DatePicker, Input } from 'antd';
import moment from 'moment';
import { LoadHistory } from '../../Commons';

const ValorisationTypes = [{ label: 'Electrique', value: 'electric' }, { label: 'Thermique', value: 'thermique' }, { label: 'Hydrogene', value: 'hydrogene' }];

const HistoryPopup = ({ id, type, column, title }) => {
    return (
        <div className="d-flex justify-content-between align-items-center p-2">
            <OverlayTrigger trigger="click" placement="top"
                overlay={(props) => (<Popover
                    id="popover-basic"
                    placement="top"
                    {...props}
                    style={{ ...props.style, width: 400, maxWidth: 400 }}
                >
                    <LoadHistory type={type} data={id} column={column} title={title} />
                </Popover>)}>
                <span role="button" id="tooltip_info" className="text-primary font-weight-bold">{global._trans('common', 'viewAll')}</span>
            </OverlayTrigger>
        </div>
    );
}

const Line = ({ line, onChange, enums, disabled }) => {
    const handleChangeName = (name, value) => onChange(name, value);
    const handleChange = (e) => onChange(e.target.name, e.target.value);
    return (
        <>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'line_name')}</Form.Label>
                    <Form.Control placeholder={global._trans('sites', 'line_name')} value={line.line_name || ''} name="line_name" onChange={handleChange} disabled={disabled} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'line_in_use')}</Form.Label>
                    <Select
                        disabled={disabled}
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'line_in_use')}
                        style={{ width: '100%' }}
                        options={[{label: 'Oui', value: 'oui'}, {label: 'Non', value: 'non'}]}
                        optionFilterProp="label"
                        value={line.line_in_use || "oui"}
                        onChange={(selectedValue) => handleChangeName("line_in_use", selectedValue)}
                    />               
                </Form.Group>
            </Form.Row>
            <h3>Four-Chaudiere</h3>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'capacite')}</Form.Label>
                    <Form.Control type="number" value={line.capacite || ''} placeholder={global._trans('sites', 'capacite')} name="capacite" onChange={handleChange} disabled={disabled} />                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'PCI')}</Form.Label>
                    <Form.Control type="number" value={line.pci || ''} placeholder={global._trans('sites', 'PCI')} name="pci" onChange={handleChange} disabled={disabled} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'typeFours')}</Form.Label>
                    <Select
                        disabled={disabled}
                        value={line.typeFours}
                        placeholder={global._trans('sites', 'typeFours')}
                        name="typeFours"
                        mode='multiple'
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        style={{ width: '100%', overflow: 'scroll' }}
                        options={enums.typeFours}
                        optionFilterProp="label"
                        onChange={(selectedValue) => handleChangeName("typeFours", selectedValue)}
                    />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'constructeurInstallation')}</Form.Label>
                    <Select
                        disabled={disabled}
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'constructeurInstallation')}
                        style={{ width: '100%' }}
                        options={enums.constructeurInstallation}
                        optionFilterProp="label"
                        value={line.constructeurInstallation}
                        onChange={(selectedValue) => handleChangeName("constructeurInstallation", selectedValue)}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'typeChaudiere')}</Form.Label>
                    <Select
                        disabled={disabled}
                        value={line.typeChaudiere}
                        placeholder={global._trans('sites', 'typeChaudiere')}
                        name="typeChaudiere"
                        mode='multiple'
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        style={{ width: '100%', overflow: 'scroll' }}
                        options={enums.typeChaudiere}
                        optionFilterProp="label"
                        onChange={(selectedValue) => handleChangeName("typeChaudiere", selectedValue)}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'constructeurChaudiere')}</Form.Label>
                    <Select
                        disabled={disabled}
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'constructeurChaudiere')}
                        style={{ width: '100%' }}
                        options={enums.constructeurChaudiere}
                        optionFilterProp="label"
                        value={line.constructeurChaudiere}
                        onChange={(selectedValue) => handleChangeName("constructeurChaudiere", selectedValue)}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'debitVapeur')}</Form.Label>
                    <Form.Control type="number" value={line.debitVapeur || ''} placeholder={global._trans('sites', 'debitVapeur')} name="debitVapeur" onChange={handleChange} disabled={disabled} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'cycleVapeurPression')}</Form.Label>
                    <Form.Control placeholder={global._trans('sites', 'cycleVapeurPression')} value={line.cycleVapeurPression || ''} name="cycleVapeurPression" onChange={handleChange} disabled={disabled} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'cycleVapeurTemp')}</Form.Label>
                    <Form.Control placeholder={global._trans('sites', 'cycleVapeurTemp')} value={line.cycleVapeurTemp || ''} name="cycleVapeurTemp" onChange={handleChange} disabled={disabled} />
                </Form.Group>
            </Form.Row>
            <h3>Traitement de fumée denox</h3>
            <Form.Row>
                {/* <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'voiTraiFemuee')}</Form.Label>
                    <Select
                        disabled={disabled}
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'voiTraiFemuee')}
                        style={{ width: '100%' }}
                        options={enums.voiTraiFemuee}
                        optionFilterProp="label"
                        value={line.voiTraiFemuee}
                        onChange={(selectedValue) => handleChangeName("voiTraiFemuee", selectedValue)}
                    />
                </Form.Group> */}
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'traitementFumee')}</Form.Label>
                    <Select
                        disabled={disabled}
                        mode="multiple"
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'traitementFumee')}
                        style={{ width: '100%', overflow: 'scroll' }}
                        options={enums.traitementFumee}
                        optionFilterProp="label"
                        value={line.traitementFumee}
                        onChange={(selectedValue) => handleChangeName("traitementFumee", selectedValue)}
                    />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'equipeProcessTF')}</Form.Label>
                    <Select
                        disabled={disabled}
                        mode="multiple"
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'equipeProcessTF')}
                        style={{ width: '100%', overflow: 'scroll' }}
                        options={enums.equipeProcessTF}
                        optionFilterProp="label"
                        value={line.equipeProcessTF}
                        onChange={(selectedValue) => handleChangeName("equipeProcessTF", selectedValue)}
                    />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'reactif')}</Form.Label>
                    <Select
                        disabled={disabled}
                        mode="multiple"
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'reactif')}
                        style={{ width: '100%', overflow: 'scroll' }}
                        options={enums.reactif}
                        optionFilterProp="label"
                        value={line.reactif}
                        onChange={(selectedValue) => handleChangeName("reactif", selectedValue)}
                    />
                </Form.Group>


            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'traitementNOX')}</Form.Label>
                    <Select
                        disabled={disabled}
                        mode="multiple"
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'traitementNOX')}
                        style={{ width: '100%', overflow: 'scroll' }}
                        options={enums.traitementNOX}
                        optionFilterProp="label"
                        value={line.traitementNOX}
                        onChange={(selectedValue) => handleChangeName("traitementNOX", selectedValue)}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'reactifNOX')}</Form.Label>
                    {/* <Form.Label>{global._trans('sites', 'reactifDENOX')}</Form.Label> */}
                    <Select
                        disabled={disabled}
                        mode="multiple"
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'reactifNOX')}
                        // placeholder={global._trans('sites', 'reactifDENOX')}
                        style={{ width: '100%', overflow: 'scroll' }}
                        options={enums.reactifNOX}
                        optionFilterProp="label"
                        value={line.reactifNOX}
                        onChange={(selectedValue) => handleChangeName("reactifNOX", selectedValue)}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'installationComplementair')}</Form.Label>
                    <Select
                        disabled={disabled}
                        mode='multiple'
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'installationComplementair')}
                        style={{ width: '100%', overflow: 'scroll' }}
                        options={enums.installationComplementair}
                        optionFilterProp="label"
                        value={line.installationComplementair}
                        onChange={(selectedValue) => handleChangeName("installationComplementair", selectedValue)}
                    />
                </Form.Group>
                {/* <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'constructeurInstallation')}</Form.Label>
                    <Select
                        disabled={disabled}
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'constructeurInstallation')}
                        style={{ width: '100%' }}
                        options={enums.constructeurInstallation}
                        optionFilterProp="label"
                        value={line.constructeurInstallation}
                        onChange={(selectedValue) => handleChangeName("constructeurInstallation", selectedValue)}
                    />
                </Form.Group> */}
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Commentaire traitement fumée</Form.Label>
                    <Form.Control type="text" value={line.commentTraitementFumee || ''} onChange={handleChange} placeholder="Commentaire traitement fumée" name="commentTraitementFumee" disabled={disabled} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Date mise en service</Form.Label>
                    <Form.Control type="text" value={line.miseEnService || ''} placeholder="Date mise en service" onChange={handleChange} name="miseEnService" disabled={disabled} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Date revamping</Form.Label>
                    <Form.Control type="text" value={line.revampingDate || ''} placeholder="Date revamping" onChange={handleChange} name="revampingDate" disabled={disabled} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Date arret ligne</Form.Label>
                    <Form.Control type="text" value={line.arretDate || ''} placeholder="Date arret ligne" onChange={handleChange} name="arretDate" disabled={disabled} />
                </Form.Group>
            </Form.Row>
        </>
    )
}
const Valorisation = ({ valorisation, onChange, enums, disabled, id_data_uve }) => {
    const handleChangeName = (name, value) => onChange(name, value);
    const handleChange = (e) => onChange(e.target.name, e.target.value);
    return (
        <>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'type')}</Form.Label>
                    <Select
                        disabled={disabled}
                        showSearch
                        showArrow
                        className="inputSizeSelect"
                        placeholder={global._trans('sites', 'type')}
                        style={{ width: '100%' }}
                        options={ValorisationTypes}
                        optionFilterProp="label"
                        value={valorisation.type}
                        onChange={(selectedValue) => handleChangeName("type", selectedValue)}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{global._trans('sites', 'valorisationName')}</Form.Label>
                    <Form.Control type="text" value={valorisation.name} placeholder={global._trans('sites', 'valorisationName')} name="name" onChange={handleChange} disabled={disabled} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Date mise en service</Form.Label>
                    <Form.Control type="text" value={valorisation.miseEnService} placeholder="Date mise en service" name="miseEnService" onChange={handleChange} disabled={disabled} />
                </Form.Group>
            </Form.Row>
            {
                valorisation.type == 'electric' &&
                <>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{global._trans('sites', 'typeEquipement')}</Form.Label>
                            <Select
                                disabled={disabled}
                                showSearch
                                showArrow
                                className="inputSizeSelect"
                                placeholder={global._trans('sites', 'typeEquipement')}
                                style={{ width: '100%' }}
                                options={enums.typeEquipement}
                                optionFilterProp="label"
                                value={valorisation.typeEquipement}
                                onChange={(selectedValue) => handleChangeName("typeEquipement", selectedValue)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{global._trans('sites', 'marqueEquipement')}</Form.Label>
                            <Select
                                disabled={disabled}
                                showSearch
                                showArrow
                                className="inputSizeSelect"
                                placeholder={global._trans('sites', 'marqueEquipement')}
                                style={{ width: '100%' }}
                                options={enums.marqueEquipement}
                                optionFilterProp="label"
                                value={valorisation.marqueEquipement}
                                onChange={(selectedValue) => handleChangeName("marqueEquipement", selectedValue)}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{global._trans('sites', 'puissanceInstallee')}</Form.Label>
                            <Form.Control type="number" value={valorisation.puissanceInstallee} placeholder={global._trans('sites', 'puissanceInstallee')} name="puissanceInstallee" onChange={handleChange} disabled={disabled} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{global._trans('sites', 'electriciteVendue')}</Form.Label>
                            <Form.Control type="number" value={valorisation.electriciteVendue} placeholder={global._trans('sites', 'electriciteVendue')} name="electriciteVendue" onChange={handleChange} disabled={disabled} />
                            {disabled && <HistoryPopup title={global._trans('sites', 'electriciteVendue')} id={id_data_uve} type={'data_techn_uves'} column={"valorisations.blocks."+valorisation.id} />}
                        </Form.Group>
                    </Form.Row>
                </>
            }
            {valorisation.type == 'thermique' && <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'RCUIndustirel')}</Form.Label>
                        <Select
                            disabled={disabled}
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('sites', 'RCUIndustirel')}
                            style={{ width: '100%' }}
                            options={enums.RCUIndustirel}
                            optionFilterProp="label"
                            value={valorisation.RCUIndustirel}
                            onChange={(selectedValue) => handleChangeName("RCUIndustirel", selectedValue)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'client')}</Form.Label>
                        <Select
                            disabled={disabled}
                            mode="multiple"
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('sites', 'client')}
                            style={{ width: '100%' }}
                            options={enums.clients}
                            optionFilterProp="label"
                            value={valorisation.client}
                            onChange={(selectedValue) => handleChangeName("client", selectedValue)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'puissanceInstallee')}</Form.Label>
                        <Form.Control type="number" value={valorisation.puissanceInstallee} placeholder={global._trans('sites', 'puissanceInstallee')} name="puissanceInstallee" onChange={handleChange} disabled={disabled} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'chaleurVendue')}</Form.Label>
                        <Form.Control type="number" value={valorisation.chaleurVendue} placeholder={global._trans('sites', 'chaleurVendue')} name="chaleurVendue" onChange={handleChange} disabled={disabled} />
                        {disabled && <HistoryPopup title={global._trans('sites', 'chaleurVendue')} id={id_data_uve} type={'data_techn_uves'} column={"valorisations.blocks."+valorisation.id} />}
                    </Form.Group>
                </Form.Row>
            </>}
            {valorisation.type == 'hydrogene' && <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'marqueEquipement')}</Form.Label>
                        <Select
                            disabled={disabled}
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('sites', 'marqueEquipement')}
                            style={{ width: '100%' }}
                            options={enums.marqueEquipement}
                            optionFilterProp="label"
                            value={valorisation.marqueEquipement}
                            onChange={(selectedValue) => handleChangeName("marqueEquipement", selectedValue)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'puissanceElectrolyseur')}</Form.Label>
                        <Form.Control type="number" value={valorisation.puissanceElectrolyseur} placeholder={global._trans('sites', 'puissanceElectrolyseur')} name="puissanceElectrolyseur" onChange={handleChange} disabled={disabled} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'H2Vendue')}</Form.Label>
                        <Form.Control type="number" value={valorisation.H2Vendue} placeholder={global._trans('sites', 'H2Vendue')} name="H2Vendue" onChange={handleChange} disabled={disabled} />
                        {disabled && <HistoryPopup title={global._trans('sites', 'H2Vendue')} id={id_data_uve} type={'data_techn_uves'} column={"valorisations.blocks."+valorisation.id} />}
                    </Form.Group>
                </Form.Row>
            </>}
        </>
    );
}

const TabsMenu = ({ items, onSelectItem, onAddItem, activeItemIndex, onDeleteItem, itemText, disabled }) => (<div className="mt-10 mb-10">
    <div className="header" style={{ background: 'transparent', height: '0' }}>
        <div className="header-menu header-menu-mobile header-menu-layout-default">
            <ul className='menu-nav'>
                {items.map((line, index) => <li key={index.toString()} onClick={() => onSelectItem(index)} className={`menu-item menu-item-rel mx-1 ${index == activeItemIndex ? 'menu-item-active' : ''}`}>
                    <a className={`menu-link`}>
                        <span className="menu-text">{`${itemText}`}-{`${index + 1}`}</span> &nbsp;&nbsp; {!disabled && <span style={{ color: 'red' }} onClick={() => onDeleteItem(index)}>X</span>}
                    </a>
                </li>)}
                {!disabled && <li className={`menu-item menu-item-rel mx-1`}>
                    <button className="btn btn-primary" type="button" onClick={onAddItem}>+</button>
                </li>}
            </ul>
        </div>
    </div>
</div >);

const CommonValorisation = ({ id_data_uve, infos, onChange, enums, disabled }) => {
    const handleChangeName = (name, value) => onChange(name, value);
    const handleChange = (e) => onChange(e.target.name, e.target.value);
    return (<div>

        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'valorisationTypes')}</Form.Label>
                <Select
                    disabled={disabled}
                    mode="multiple"
                    showSearch
                    showArrow
                    className="inputSizeSelect"
                    placeholder={global._trans('sites', 'valorisationTypes')}
                    style={{ width: '100%' }}
                    options={ValorisationTypes}
                    optionFilterProp="label"
                    value={infos.valorisationTypes}
                    onChange={(selectedValue) => handleChangeName("valorisationTypes", selectedValue)}
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'agregateurElectrique')}</Form.Label>
                <Select
                    disabled={disabled}
                    showSearch
                    showArrow
                    className="inputSizeSelect"
                    placeholder={global._trans('sites', 'agregateurElectrique')}
                    style={{ width: '100%' }}
                    options={enums.agregateurElectrique}
                    optionFilterProp="label"
                    value={infos.agregateurElectrique}
                    onChange={(selectedValue) => handleChangeName("agregateurElectrique", selectedValue)}
                />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'performenceEnergetique')}</Form.Label>
                <Form.Control placeholder={global._trans('sites', 'performenceEnergetique')} name="performenceEnergetique" value={infos.performenceEnergetique} onChange={handleChange} disabled={disabled} />
                {disabled && <HistoryPopup title={global._trans('sites', 'performenceEnergetique')} id={id_data_uve} type="data_techn_uves" column='valorisations.performenceEnergetique' />}
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'performenceEnergetiqueDate')}</Form.Label>
                <DatePicker format="YYYY" value={infos.performenceEnergetiqueDate && moment(infos.performenceEnergetiqueDate, "YYYY")} onChange={(date, dateString) => handleChangeName("performenceEnergetiqueDate", dateString)} className="form-control" placeholder={global._trans('sites', 'performenceEnergetiqueDate')} style={{ width: '100%' }} picker="year" disabled={disabled} />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'performenceEnergetiqueR1')}</Form.Label>
                <Form.Control placeholder={global._trans('sites', 'performenceEnergetiqueR1')} name="performenceEnergetiqueR1" value={infos.performenceEnergetiqueR1} onChange={handleChange} disabled={disabled} />
                {disabled && <HistoryPopup title={global._trans('sites', 'performenceEnergetiqueR1')} id={id_data_uve} type="data_techn_uves" column='valorisations.performenceEnergetiqueR1' />}
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'performenceEnergetiqueR1Date')}</Form.Label>
                <DatePicker format="YYYY" value={infos.performenceEnergetiqueR1Date && moment(infos.performenceEnergetiqueR1Date, "YYYY")} onChange={(date, dateString) => handleChangeName("performenceEnergetiqueR1Date", dateString)} className="form-control" placeholder={global._trans('sites', 'performenceEnergetiqueR1Date')} style={{ width: '100%' }} picker="year" disabled={disabled} />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'electriciteVendue')}</Form.Label>
                <Form.Control type="number" value={infos.electriciteVendue} placeholder={global._trans('sites', 'electriciteVendue')} name="electriciteVendue" onChange={handleChange} disabled={disabled} />
                {disabled && <HistoryPopup title={global._trans('sites', 'electriciteVendue')} id={id_data_uve} type="data_techn_uves" column='valorisations.electriciteVendue' />}
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'chaleurVendue')}</Form.Label>
                <Form.Control type="number" value={infos.chaleurVendue} placeholder={global._trans('sites', 'chaleurVendue')} name="chaleurVendue" onChange={handleChange} disabled={disabled} />
                {disabled && <HistoryPopup title={global._trans('sites', 'chaleurVendue')} id={id_data_uve} type="data_techn_uves" column='valorisations.chaleurVendue' />}
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'H2Vendue')}</Form.Label>
                <Form.Control type="number" value={infos.H2Vendue} placeholder={global._trans('sites', 'H2Vendue')} name="H2Vendue" onChange={handleChange} disabled={disabled} />
                {disabled && <HistoryPopup title={global._trans('sites', 'H2Vendue')} id={id_data_uve} type="data_techn_uves" column='valorisations.H2Vendue' />}
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'informationComplementaire')}</Form.Label>
                <Form.Control type='' value={infos.informationComplementaire} placeholder={global._trans('sites', 'informationComplementaire')} name="informationComplementaire" onChange={handleChange} disabled={disabled} />
            </Form.Group>
        </Form.Row>
    </div>);
}
const TechnicalInfos = ({ id_data_uve, infos, onChange, enums, disabled }) => {
    const handleChangeName = (name, value) => onChange(name, value);
    const handleChange = (e) => onChange(e.target.name, e.target.value);

    return <div className='general-tech-infos'>
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'typeDechetRecus')}</Form.Label>
                <Select
                    disabled={disabled}
                    mode="multiple"
                    showSearch
                    showArrow
                    className="inputSizeSelect"
                    placeholder={global._trans('sites', 'typeDechetRecus')}
                    style={{ width: '100%', overflow: 'scroll' }}
                    options={enums.typeDechetRecus}
                    optionFilterProp="label"
                    value={infos.typeDechetRecus}
                    onChange={(selectedValue) => handleChangeName("typeDechetRecus", selectedValue)}
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'installationComplementair')}</Form.Label>
                <Select
                    disabled={disabled}
                    mode="multiple"
                    showSearch
                    showArrow
                    className="inputSizeSelect"
                    placeholder={global._trans('sites', 'installationComplementair')}
                    style={{ width: '100%', overflow: 'scroll' }}
                    options={enums.installationComplementair}
                    optionFilterProp="label"
                    value={infos.installationComplementair}
                    onChange={(selectedValue) => handleChangeName("installationComplementair", selectedValue)}
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'capacite')}</Form.Label>
                <Form.Control type="number" value={infos.capacite} placeholder={global._trans('sites', 'capacite')} name="capacite" onChange={handleChange} disabled={disabled} />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'tonnageReglementaireAp')}</Form.Label>
                <Form.Control type="number" placeholder={global._trans('sites', 'tonnageReglementaireAp')} value={infos.tonnageReglementaireAp} name="tonnageReglementaireAp" onChange={handleChange} disabled={disabled} />
                {disabled && <HistoryPopup title={global._trans('sites', 'tonnageReglementaireAp')} id={id_data_uve} type={'data_techn_uves'} column={'infos.tonnageReglementaireAp'} />}
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>{global._trans('sites', 'videFour')}</Form.Label>
                <Form.Control type="number" value={infos.videFour} placeholder={global._trans('sites', 'videFour')} name="videFour" onChange={handleChange} disabled={disabled} />
                {disabled && <HistoryPopup title={global._trans('sites', 'videFour')} id={id_data_uve} type={'data_techn_uves'} column={'infos.videFour'} />}
            </Form.Group>
        </Form.Row>
    </div>
}
class DataTechUVE extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLine: 0,
            activeValorisationBlock: 0
        }
        this.selectLine = this.selectLine.bind(this);
        this.selectValorisationBlock = this.selectValorisationBlock.bind(this);
    }
    selectLine(index) {
        return this.setState({
            activeLine: index
        });
    }
    selectValorisationBlock(index) {
        return this.setState({
            activeValorisationBlock: index
        });
    }
    render() {
        let { infos, id_data_uve } = this.props;
        let { lines, onAddLine, handleLineChange, handleDeleteLine } = this.props;
        let { valorisations, onAddValorisationBlock, handleValorisationBlockChange, handleValorisationBlockDelete, handleValorisationInfoChange, handleInfoChange } = this.props;

        return (
            <div style={{ position: 'relative' }} className='pt-5'>
                <Form>
                    <h2>{global._trans('sites', 'informationTechniqueGeneral')}</h2>
                    <TechnicalInfos id_data_uve={id_data_uve} disabled={this.props.disabled} infos={infos} onChange={handleInfoChange} enums={this.props.enums} />
                    <hr />

                    <h2>{global._trans('sites', 'lignsIncenerations')}</h2>
                    {(!this.props.disabled || lines.length > 0) && <TabsMenu disabled={this.props.disabled} enums={this.props.enums} items={lines} onAddItem={onAddLine} activeItemIndex={this.state.activeLine} onSelectItem={this.selectLine} itemText='Ligne' onDeleteItem={handleDeleteLine} />}
                    <div className="lines">
                        {lines.length > 0 && (this.state.activeLine > -1 && this.state.activeLine < lines.length) && <Line disabled={this.props.disabled} onChange={(info, value) => handleLineChange(this.state.activeLine, info, value)} line={lines[this.state.activeLine]} enums={this.props.enums} />}
                        {this.props.disabled && lines.length == 0 && <span>Aucune ligne d'incinération.</span>}
                    </div>
                    <hr />

                    <h2>{global._trans('sites', 'commonValorisationInfos')}</h2>
                    <CommonValorisation id_data_uve={id_data_uve} disabled={this.props.disabled} enums={this.props.enums} infos={valorisations} onChange={handleValorisationInfoChange} />
                    <hr />

                    <h2>{global._trans('sites', 'valorisationBlocks')}</h2>
                    {(!this.props.disabled || valorisations.blocks.length > 0) && <TabsMenu disabled={this.props.disabled} items={valorisations.blocks} onAddItem={onAddValorisationBlock} activeItemIndex={this.state.activeValorisationBlock} onSelectItem={this.selectValorisationBlock} itemText='Valorisation' onDeleteItem={handleValorisationBlockDelete} />}
                    <div className="valorisation-blocks">
                        {valorisations.blocks.length > 0 && (this.state.activeValorisationBlock > -1 && this.state.activeValorisationBlock < valorisations.blocks.length) && <Valorisation disabled={this.props.disabled} onChange={(info, value) => handleValorisationBlockChange(this.state.activeValorisationBlock, info, value)} valorisation={valorisations.blocks[this.state.activeValorisationBlock]} enums={this.props.enums} id_data_uve={id_data_uve} />}
                        {this.props.disabled && valorisations.blocks.length == 0 && <span>Aucune valorisation.</span>}
                    </div>
                </Form>
            </div>
        )
    }
}
function mapState(state) {
    return { enums: state.enums.listEnums };
}
const connectedClass = connect(mapState, {})(DataTechUVE)
export { connectedClass as DataTechUVE }