import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { CreateGEDFile } from './CreateGEDFile'
import { ListGED } from './ListGED'
import { ViewGEDFile } from './ViewGED'
class GEDPages extends Component {
    render() {
        return (
            <div className="card card-custom">
                <Switch>
                    <Route
                        path="/ged/view/:idGEDFile"
                        component={ViewGEDFile}
                    />
                    <Route
                        path="/ged/new"
                        component={CreateGEDFile}
                    />
                    <Route
                        path="/ged/:idGEDFile"
                        component={CreateGEDFile}
                    />
                    <Route
                        path="/ged"
                        component={ListGED}
                    />
                </Switch>
            </div>
        )
    }
}
export { GEDPages }