import React, { Component } from 'react'
import { NavLink, Switch, Redirect, Route } from 'react-router-dom'
import { checkIsActive } from "../../../../_sage/_helpers";
import { SyndicatPage, EpicPage, CommunePage } from './ClientTypes'
class Collectivities extends Component {
    getMenuItemActive = (url) => {
        return checkIsActive(this.props.location, url) ? "menu-item-active" : "";
    }
    render() {
        return (
            <>
                <div id="kt_subheader" className='subheader py-2 py-lg-4'>
                    <div className="header" style={{ background: 'transparent', height: '0' }}>
                        <div className="header-menu header-menu-mobile header-menu-layout-default px-8">
                            <ul className='menu-nav'>
                                <li className={`menu-item menu-item-rel mx-1 ${this.getMenuItemActive('/client/communities/syndicat')}`}>
                                    <NavLink className="menu-link" to="/client/communities/syndicat">
                                        <span className="menu-text">{global._trans('client', 'syndicat')}</span>
                                    </NavLink>
                                </li>
                                <li className={`menu-item menu-item-rel mx-1 ${this.getMenuItemActive('/client/communities/epic')}`}>
                                    <NavLink className="menu-link" to="/client/communities/epic">
                                        <span className="menu-text">{global._trans('client', 'epic')}</span>
                                    </NavLink>
                                </li>
                                <li className={`menu-item menu-item-rel mx-1 ${this.getMenuItemActive('/client/communities/communes')}`}>
                                    <NavLink className="menu-link" to="/client/communities/communes">
                                        <span className="menu-text">{global._trans('client', 'commune')}</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card card-custom" style={{ marginTop: "57px" }}>
                    <Switch>
                        <Redirect
                            from="/client/communities"
                            exact={true}
                            to="/client/communities/syndicat"
                        />
                        <Route
                            path="/client/communities/syndicat"
                            component={SyndicatPage}
                        />
                        <Route
                            path="/client/communities/epic"
                            component={EpicPage}
                        />
                        <Route
                            path="/client/communities/communes"
                            component={CommunePage}
                        />
                    </Switch>
                </div>
            </>
        )
    }
}
export { Collectivities }