import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class ModesGestions extends Component {
    render() {
        return (
            <CommonComponent keyName="mode_gestion" titleSetting={global._trans('companies', 'mode_gestion')} mode_gestion={this.props.mode_gestion} />
        )
    }
}
function mapState(state) {
    const { listEnums: { mode_gestion } } = state.enums
    return { mode_gestion }
}
const connectedClass = connect(mapState, null)(ModesGestions)
export { connectedClass as ModesGestions }