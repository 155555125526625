import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, Form, Col, Card, ListGroup } from "react-bootstrap";
import { notification, Spin, Select } from 'antd';
import { createCommune, updateCommune, editCommune, syncCommunesInseeAPI } from "../../../../../modules/Collectivities/collectivitie.actions";
import { Link, Redirect, Prompt } from 'react-router-dom'
import { EpicSelector } from '../../Common'
import { CostumAlert, ImageCroper, DepartementSearch, RegionSearch, AdressPicker } from '../../../Commons'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../../../../_sage/_helpers';
const states = [{label: 'Actif', value: 'A'}, {label: 'Fermé', value: 'C'}];

const sitesStatus = ['VALIDATED', 'NOT_VALIDATED', 'NOT_PUBLISHED'];
class CreateCommune extends Component {
    constructor(props) {
        super(props)
        let action = props.match.params.idCreate === "new"
        this.state = {
            loadingCreate: false,
            loading: !action,
            errors: null,
            showAlert: false,
            showSelect: false,
            serin: '',
            siret: '',
            insee: "",
            city: '',
            country: 'France',
            postcode: '',
            status: 'NOT_VALIDATED',
            logo: [],
            region_siege: null,
            departement_siege: null,
            epic: null,
            adresse: null,
            lat: null,
            lang: null,
            nomCommune: null,
            usual_denomination: null,
            nombreHabitant: null,
            nombreHabitant_year: '',
            state: null
        }
        this.defaultDepartement = []
        this.defaultRegions = []
        this.isNewItem = action
        if (!action) {
            props.editCommune(props.match.params.idCreate)
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this._suggestionSelect = this._suggestionSelect.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this)
        this.hideSelectEpic = this.hideSelectEpic.bind(this)
        this.handleEpic = this.handleEpic.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loadingCreate) {
            this.setState({
                loadingCreate: false,
                errors: nextProps.CommuneErrors,
                showAlert: true
            })
        }
        if (this.state.loading && (nextProps.Commune || nextProps.CommuneErrors)) {
            this.isNewItem = !nextProps.Commune
            this.defaultDepartement = [nextProps.Commune.departement_siege]
            this.defaultRegions = [nextProps.Commune.region_siege]
            this.setState({
                ...nextProps.Commune,
                departement_siege: nextProps.Commune.departement_siege?.value,
                region_siege: nextProps.Commune.region_siege?.value,
                loading: false
            })
        }
        if (nextProps.Commune && nextProps.Commune.refresh) {
            this.setState({
                loading: true
            })
            this.props.editCommune(this.props.match.params.idCreate)
        }
    }
    handleChangeEvent(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
        if (name === 'siret' && value.length < 10) {
            this.setState({
                serin: value
            })
        }
    }
    handleChange(name, value) {
        if (name == 'departement_siege') {
            let { departements } = this.props;
            let id_region = null;
            let selectedDepartement = Array.isArray(departements) ? departements.find((dept) => dept.id_departement == value) : null;
            if (selectedDepartement) id_region = selectedDepartement.region.id_region;
            return this.setState({
                'departement_siege': value,
                'region_siege': id_region,
            })
        }
        this.setState({
            [name]: value
        })
    }
    _suggestionSelect(adress) {
        this.setState({
            adresse: adress.address,
            lat: adress.lat,
            lang: adress.lang,
            city: adress.city,
            country: 'France',
            postcode: adress.postcode
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        if (!this.state.loadingCreate) {
            let commune = {
                id_commune: this.state.id_commune,
                nomCommune: this.state.nomCommune,
                usual_denomination: this.state.usual_denomination,
                adresse: this.state.adresse,
                serin: this.state.serin,
                siret: this.state.siret,
                insee: this.state.insee,
                city: this.state.city,
                country: this.state.country,
                postcode: this.state.postcode,
                status: this.state.status,
                region_siege: this.state.region_siege,
                departement_siege: this.state.departement_siege,
                lat: this.state.lat,
                lang: this.state.lang,
                nombreHabitant: this.state.nombreHabitant,
                nombreHabitant_year: this.state.nombreHabitant_year,
                id_epic: this.state.epic ? this.state.epic.id_epic : null,
                logo: this.state.logo[0] && this.state.logo[0].uid,
                state: this.state.state
            }
            if (this.isNewItem) {
                this.props.createCommune(commune)
            } else {
                this.props.updateCommune(commune)
            }

            this.setState({
                loadingCreate: true,
                showAlert: false
            })
        }

    }
    hideSelectEpic() {
        let prevShow = this.state.showSelect
        this.setState({
            showSelect: !prevShow
        })
    }
    handleEpic(epic) {
        this.setState({
            showSelect: false,
            epic: epic
        })
    }
    showErrors() {
        if (this.state.showAlert) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                notification.success({
                    message: this.isNewItem ? global._trans('clientsCommune', 'titleCreateMessage') : global._trans('clientsCommune', 'messageUpdateTitle'),
                    description: this.isNewItem ? global._trans('clientsCommune', 'bodyCreateMessage') : global._trans('clientsCommune', 'messageUpdateBody'),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}

                })
                return <Redirect to="/client/communities/communes" />
            }
        }
    }
    render() {
        return (
            <>
                {/* <Prompt when={true} message={(location) => global._trans('common', 'areYouSure')} /> */}
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{this.isNewItem ? global._trans('clientsCommune', 'createCommune') : (global._trans('clientsCommune', 'editTitle') + ' - ' + this.state.nomCommune)}</span>
                    </h3>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingCreate}>
                    <div className="card-body py-4">
                        {this.showErrors()}
                        <div className="form px-5 pb-5">
                            <Form method="post" onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'state')}</Form.Label>
                                        <Select
                                            showSearch
                                            showArrow
                                            className="inputSizeSelect"
                                            placeholder={global._trans("common", "state")}
                                            value={this.state.state}
                                            style={{ width: '100%' }}
                                            options={states}
                                            onSelect={(SelectedValue) => this.handleChange("state", SelectedValue)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('clientsCommune', 'communeName')}*</Form.Label>
                                        <Form.Control type="text" name="nomCommune" value={this.state.nomCommune} placeholder={global._trans('clientsCommune', 'communeName')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'usual_denomination')}</Form.Label>
                                        <Form.Control type="text" name="usual_denomination" value={this.state.usual_denomination} placeholder={global._trans('common', 'usual_denomination')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('clientsCommune', 'siret')}</Form.Label>
                                        <Form.Control type="number" name="siret" value={this.state.siret} placeholder={global._trans('clientsCommune', 'siret')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('clientsCommune', 'serin')}*</Form.Label>
                                        <Form.Control type="number" name="serin" value={this.state.serin} placeholder={global._trans('clientsCommune', 'serin')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'departementSiege')}*</Form.Label>
                                        <DepartementSearch placeholder={global._trans('common', 'selectDepartementSiege')} onChange={(selected) => this.handleChange('departement_siege', selected)} defaultOptions={this.defaultDepartement} value={this.state.departement_siege} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'regionSiege')}*</Form.Label>
                                        <RegionSearch placeholder={global._trans('common', 'selectRegionSiege')} defaultOptions={this.defaultRegions} onChange={(selected) => this.handleChange('region_siege', selected)} value={this.state.region_siege} disabled={true} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'address')}*</Form.Label>
                                        <AdressPicker value={this.state.adresse} onChange={this._suggestionSelect} />
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-0">
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>{global._trans('common', 'city')}*</Form.Label>
                                                <Form.Control type='text' placeholder={global._trans('common', 'city')} name='city' value={this.state.city} onChange={this.handleChangeEvent} />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>{global._trans('common', 'country')}*</Form.Label>
                                                <Form.Control type='text' placeholder={global._trans('common', 'country')} name='country' value={this.state.country} onChange={this.handleChangeEvent} />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'postcode')}*</Form.Label>
                                        <Form.Control type='text' placeholder={global._trans('common', 'postcode')} name='postcode' value={this.state.postcode} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'logoCompany')}</Form.Label>
                                        <ImageCroper images={this.state.logo} onChange={(images) => this.handleChange('logo', images)} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('clientsCommune', 'insee')}*</Form.Label>
                                        <Form.Control type="text" name="insee" value={this.state.insee} placeholder={global._trans('clientsCommune', 'insee')} onChange={this.handleChangeEvent} />
                                    </Form.Group>



                                    <Form.Group as={Col}>
                                        <Form.Row>
                                            <Form.Group as={Col} className=" mb-2 ">
                                                <Form.Label>{global._trans('common', 'population')}</Form.Label>
                                                <Form.Control type="number" name="nombreHabitant" value={this.state.nombreHabitant} placeholder={global._trans('common', 'population')} onChange={this.handleChangeEvent} />
                                            </Form.Group>
                                            <Form.Group as={Col} className=" mb-2 ">
                                                <Form.Label>{global._trans('common', 'population_year')}</Form.Label>
                                                <Form.Control type="number" name="nombreHabitant_year" value={this.state.nombreHabitant_year} placeholder={global._trans('common', 'population_year')} onChange={this.handleChangeEvent} />
                                            </Form.Group>
                                        </Form.Row>
                                        {this.state.date_enter && <div className="px-2"><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.state.date_enter)}</div>}
                                    </Form.Group>


                                    {/* <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'nbPeople')}*</Form.Label>
                                        <Form.Control type="text" name="nombreHabitant" value={this.state.nombreHabitant} placeholder={global._trans('common', 'nbPeople')} onChange={this.handleChangeEvent} />
                                        {this.state.date_enter && <div className="p-2"><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.state.date_enter)}</div>}
                                    </Form.Group> */}

                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans("sites", "status")}*</Form.Label>
                                        <Select
                                            showSearch
                                            showArrow
                                            className="inputSizeSelect"
                                            placeholder={global._trans("sites", "status")}
                                            value={this.state.status}
                                            style={{ width: '100%' }}
                                            options={sitesStatus.map((value) => ({ label: global._trans("sites", `status_${value}`), value }))}
                                            onSelect={(SelectedValue) => this.handleChange("status", SelectedValue)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('clientsCommune', 'epicReatache')}*</Form.Label>
                                        {this.state.epic ? <Card>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item><strong>{global._trans('clientsEpic', 'epicName')}: </strong>{'  ' + this.state.epic.nomEpic}</ListGroup.Item>
                                                <ListGroup.Item><strong>{global._trans('common', 'address')}: </strong>{'  ' + this.state.epic.adresse}</ListGroup.Item>
                                                <ListGroup.Item><Card.Link onClick={this.hideSelectEpic}><strong>{global._trans('clientsCommune', 'changeRatachedEpic')}</strong></Card.Link></ListGroup.Item>
                                            </ListGroup>
                                        </Card> :
                                            <Button variant="outline-secondary" size="md" block onClick={this.hideSelectEpic}>{global._trans('clientsCommune', 'selectEpic')}</Button>}
                                    </Form.Group>
                                </Form.Row>
                                <div className="d-flex justify-content-end">
                                    <div className="card-toolbar mx-4">
                                        <Link to="/client/communities/communes" className="btn btn-secondary" >{global._trans('common', 'cancelBtn')}</Link>
                                    </div>
                                    {!this.isNewItem &&
                                        <Button variant="primary" className="mr-2" onClick={() => this.props.syncCommunesInseeAPI({ action: 'sync_array', communes: [this.state.siret ? "siret:"+this.state.siret : "siren:"+this.state.serin] })}>

                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Costum/Compiling.svg"
                                                )} />
                                            {global._trans('common', 'synchronize')}
                                        </Button>
                                    }
                                    <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{this.isNewItem ? global._trans('clientsCommune', 'submitCommune') : global._trans('common', 'saveChanges')}</Button>
                                </div>
                            </Form>
                        </div>
                        {this.state.showSelect && <EpicSelector onHide={this.hideSelectEpic} onSelect={this.handleEpic} show={this.state.showSelect} />}
                    </div>
                </Spin>
            </>
        )
    }
}
function mapState(state) {
    const { CommuneErrors, Commune } = state.collectivitie
    const { departements, regions } = state.enums
    return { CommuneErrors, Commune, departements: departements.data, regions: regions.data }
}
const connectedClass = connect(mapState, { createCommune, updateCommune, editCommune, syncCommunesInseeAPI })(CreateCommune)
export { connectedClass as CreateCommune }