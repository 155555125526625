import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ListEpics } from './ListEpics'
import { CreateEpic } from './CreateEpic'
import { ViewEpic } from './ViewEpic'
class EpicPage extends Component {
    render() {
        return (
            <Switch>
                <Route
                    path="/client/communities/epic/view/:idepic"
                    component={ViewEpic}
                />
                <Route
                    path="/client/communities/epic/:idCreate"
                    component={CreateEpic}
                />
                <Route
                    path="/client/communities/epic"
                    component={ListEpics}
                />
            </Switch>
        )
    }
}
export { EpicPage }