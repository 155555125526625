import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { SageTable } from './SageTable'
import { HeaderTab } from './HeaderTab'
import { fetchEpicsGet, fetchSyndicatsGet, fetchCommunesGet } from "../../../modules/Collectivities/collectivitie.actions";
import { fetchSocietesGet } from "../../../modules/Societes/societe.actions";
import debounce from 'lodash.debounce'
class PersoneSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTab: props.defaultCurrent ? props.defaultCurrent : 0,
            selectedRow: [
                { rows: [] },
                { rows: [] },
                { rows: [] },
                { rows: [] }

            ],
            searchValue: [
                { nmFnc: 'fetchSyndicatsGet', name: 'nomCourt', search: '' },
                { nmFnc: 'fetchEpicsGet', name: 'nomEpic', search: '' },
                { nmFnc: 'fetchCommunesGet', name: 'nomCommune', search: '' },
                { nmFnc: 'fetchSocietesGet', name: 'groupe', search: '' }],
            listsData: [
                {},
                {},
                {},
                {}
            ],
            loading0: true,
            loading1: true,
            loading2: true,
            loading3: true
        }
        this.searchBuffer = 0;
        this.columes = [
            [{ title: "Nom Court", dataIndex: "nomCourt", fixed: 'left', ellipsis: true, },
            { title: "Dénomination légale", dataIndex: "denominationLegale", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
            { title: "Siren", dataIndex: "serin", ellipsis: true, }],

            [{ title: "ID", dataIndex: "id_epic", fixed: 'left', ellipsis: true, },
            { title: "Nom EPIC", dataIndex: "nomEpic", ellipsis: true, },
            { title: "Siren", dataIndex: "serin", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, }],

            [{ title: "ID", dataIndex: "id_commune", fixed: 'left', ellipsis: true, },
            { title: "Nom Commune", dataIndex: "nomCommune", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
            { title: "Nombre habitants", dataIndex: "nombreHabitant", ellipsis: true, }],

            [{ title: "Groupe", dataIndex: "groupe", fixed: 'left', ellipsis: true, render: (groupe) => Array.isArray(groupe) ? groupe.map((g) => g.value_enum).join(',') : groupe },
            { title: "Dénomination", dataIndex: "denomination", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
            { title: "Nombre salarié", dataIndex: "nombreSalarie", ellipsis: true, }]
        ]
        this.search = '';
        this.keysTable = [
            'id_syndicat',
            'id_epic',
            'id_commune',
            'id_societe_exploitant'
        ]
        this.typeClient = [
            { typePersonMoral: 'Syndicat', name: 'Nom Court', dataIndex: 'nomCourt' },
            { typePersonMoral: 'Epic', name: 'Nom EPIC', dataIndex: 'nomEpic' },
            { typePersonMoral: 'Commune', name: 'Nom Commune', dataIndex: 'nomCommune' },
            { typePersonMoral: 'Societe', name: 'Dénomination', dataIndex: 'denomination' }
        ]
        props.fetchEpicsGet({ pageSize: 15 })
        props.fetchCommunesGet({ pageSize: 15 })
        props.fetchSyndicatsGet({ pageSize: 15 })
        props.fetchSocietesGet({ pageSize: 15 })
        this.handleSwitchTabs = this.handleSwitchTabs.bind(this)
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.validSelect = this.validSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this);
        this.debouncedHandleSearch = debounce(this.handleSearch, 2000);
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading0 && nextProps.listSyndicats.data) {
            let newData = [...this.state.listsData]
            newData[0] = nextProps.listSyndicats
            this.setState({
                loading0: false,
                listsData: newData
            })
        }
        if (this.state.loading1 && nextProps.listEpics.data) {
            let newData = [...this.state.listsData]
            newData[1] = nextProps.listEpics
            this.setState({
                loading1: false,
                listsData: newData
            })
        }
        if (this.state.loading2 && nextProps.listCommunes.data) {
            let newData = [...this.state.listsData]
            newData[2] = nextProps.listCommunes
            this.setState({
                loading2: false,
                listsData: newData
            })
        }
        if (this.state.loading3 && nextProps.listSocietes.data) {
            let newData = [...this.state.listsData]
            newData[3] = nextProps.listSocietes
            this.setState({
                loading3: false,
                listsData: newData
            })
        }
    }
    handleSwitchTabs(keyItem) {
        if (this.props.blocked && this.props.blocked.indexOf(keyItem) !== -1) {
            return
        }
        this.setState({
            currentTab: keyItem
        })
    }
    handleSelectRow(selectedRowKeys) {
        let rowsPrev = this.state.selectedRow
        rowsPrev[this.state.currentTab].rows = selectedRowKeys
        this.setState({
            selectedRow: rowsPrev
        })
    }
    async handleSearch(value) {
        this.search = value;

        let prevSearchs = this.state.searchValue
        prevSearchs[this.state.currentTab].search = this.search
        this.props[prevSearchs[this.state.currentTab].nmFnc]({ pageSize: 15, denomination: this.search, address: this.search, [prevSearchs[this.state.currentTab].name]: this.search })
        this.setState({
            searchValue: prevSearchs,
            ['loading' + this.state.currentTab]: true
        })

    }
    validSelect() {
        let { listSyndicats, listEpics, listCommunes, listSocietes } = this.props;
        let elem = [listSyndicats, listEpics, listCommunes, listSocietes][this.state.currentTab].data.find(obj => obj[this.keysTable[this.state.currentTab]] == this.state.selectedRow[this.state.currentTab].rows[0])
        this.props.validSelection({ ...elem, ...this.typeClient[this.state.currentTab], id_person: elem[this.keysTable[this.state.currentTab]] })
    }
    render() {
        let { listSyndicats, listEpics, listCommunes, listSocietes } = this.props;
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} aria-labelledby="example-modal-sizes-title-lg">
                <HeaderTab items={[global._trans("client", "syndicat"), global._trans("client", "epic"), global._trans("client", "commune"), global._trans("client", "societe")]} switchItem={this.handleSwitchTabs} active={this.state.currentTab} />
                <div className="px-5 pb-5 pt-2">
                    <Form.Control type="text" name="searchvalue" placeholder="Chercher par nom ou adresse" onChange={(e) => this.debouncedHandleSearch(e.target.value)} />
                    <SageTable
                        columns={this.columes[this.state.currentTab]}
                        data={[listSyndicats, listEpics, listCommunes, listSocietes][this.state.currentTab].data}
                        style={{ height: 550 }}
                        IterableProps={{
                            rowSelection: {
                                selectedRowKeys: this.state.selectedRow[this.state.currentTab].rows,
                                onChange: this.handleSelectRow,
                                type: 'radio'
                            },
                            emptyText: global._trans('common', 'emptyData'),
                            loading: this.state['loading' + this.state.currentTab],
                            rowKey: this.keysTable[this.state.currentTab],
                            pagination: false
                        }}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="primary" onClick={this.validSelect}>{global._trans('common', 'validSelect')}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
function mapState(state) {
    const { listEpics, listCommunes, listSyndicats } = state.collectivitie
    const { listSocietes } = state.societe
    return { listEpics, listCommunes, listSyndicats, listSocietes }
}
const connectedClass = connect(mapState, { fetchEpicsGet, fetchSyndicatsGet, fetchCommunesGet, fetchSocietesGet })(PersoneSelector)
export { connectedClass as PersoneSelector }