import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { Spin } from 'antd';
import { BasicInfo, Contract, InfoTechnique } from './utils'
import { fetchSite } from '../../../modules/Sites/sites.actions'
import { EntityHistory } from '../Commons/EntityHistory';
import { EntityFiles } from '../Commons';

class ViewSite extends Component {
    constructor(props) {
        super(props)
        this.state = {
            site: {},
            enums: {},
            tab: 'basic',
            loading: true
        }
        props.fetchSite(props.match.params.idSite)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading && nextProps.site) {
            this.setState({
                loading: false,
                site: nextProps.site ? nextProps.site : {},
                enums: nextProps.enums ? nextProps.enums : {}
            })
        }
    }
    handleTab(tab) {
        this.setState({
            tab: tab
        })
    }
    getTabBody() {
        switch (this.state.tab) {
            case 'basic': return <BasicInfo site={this.state.site} enums={this.state.enums} />
            case 'contracts': return <Contract contracts={this.state.site.contracts} />
            case 'tech': return <InfoTechnique tech={this.state.site.data_tech} />
            case 'history': return <EntityHistory entity={this.state.site} showStatus={true} />
            case 'files': return <EntityFiles entity={this.state.site} />
        }
    }
    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div className="card-header border-0 pt-5 pb-0 px-6">
                    <h3 className="card-title align-items-start flex-column mb-0">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('sites', 'siteTitle')} <span className="text-primary">#{this.state.site.denomination ? this.state.site.denomination : '...'}</span></span>
                    </h3>
                </div>
                <div className="card-body py-4">
                    <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                        <li className="nav-item">
                            <a onClick={() => this.handleTab('basic')} className={`nav-link ${this.state.tab === "basic" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'basicInfo')}</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={() => this.handleTab('tech')} className={`nav-link ${this.state.tab === "tech" && "active"}`} data-toggle="tab" role="tab">{global._trans('sites', 'techDataTitle')}</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={() => this.handleTab('contracts')} className={`nav-link ${this.state.tab === "contracts" && "active"}`} data-toggle="tab" role="tab">{global._trans('contracts', 'listTitle')}</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={() => this.handleTab('history')} className={`nav-link ${this.state.tab === "history" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'history')}</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={() => this.handleTab('files')} className={`nav-link ${this.state.tab === "files" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'files')}</a>
                        </li>
                    </ul>
                    <div className="pt-5">{this.getTabBody()}</div>
                    <div className="d-flex justify-content-end">
                        <div className="card-toolbar mx-4">
                            <Link to={`/sites${this.props.currentQuery}`} className="btn btn-secondary" >{global._trans('common', 'goBack')}</Link>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}
function mapState(state) {
    const { site, currentQuery } = state.sites
    const enums = state.enums.listEnums
    return { site, currentQuery, enums }
}
const connectedClass = connect(mapState, { fetchSite })(ViewSite)
export { connectedClass as ViewSite }