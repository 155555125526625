import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class TypeFoursChaudiere extends Component {
    render() {
        return (
            <CommonComponent keyName="typeFoursChaudiere" titleSetting={global._trans('sites', 'typeFoursChaudiere')} typeFoursChaudiere={this.props.typeFoursChaudiere} />
        )
    }
}
function mapState(state) {
    const { listEnums: { typeFoursChaudiere } } = state.enums
    return { typeFoursChaudiere }
}
const connectedClass = connect(mapState, null)(TypeFoursChaudiere)
export { connectedClass as TypeFoursChaudiere }