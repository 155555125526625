import React, { Component } from 'react'
import { connect } from 'react-redux'
import SVG from "react-inlinesvg";
import { message } from 'antd';
import { toAbsoluteUrl } from "../../../../../_sage/_helpers";
import { TableEditableAction, ImportData } from '../../Commons'
import { fetchDepartements, handleDepartement, deleteDepartement } from '../../../../modules/Settings/settings.actions'
class DepartementSiege extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: (props.departements && props.departements.data) || [],
            loading: !props.departements,
            creating: false,
            page: props.departements && props.departements.current_page,
            total: props.departements && props.departements.total,
            pageSize: 120,
            showImport: false
        }
        this.handleAdd = this.handleAdd.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        if (!props.departements) {
            props.fetchDepartements();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.departements) {
                this.setState({
                    loading: false,
                    data: nextProps.departements.data,
                    total: nextProps.departements.total,
                    page: nextProps.departements.current_page
                })
            }
        }
    }
    handleSave(newList, row) {
        this.setState({
            creating: true
        })
        handleDepartement(row)
            .then(({ ok, departement }) => {
                if (ok) {
                    let newData = newList
                    let index = newList.findIndex(item => item.id_departement == row.id_departement)
                    newData[index] = departement
                    this.setState({
                        data: newData,
                        creating: false
                    })
                } else {
                    this.setState({
                        creating: false
                    })
                }
            })
            .catch(({ }) => {
                this.setState({
                    creating: false
                })
            })
    }
    handleAdd() {
        const { pageSize, data } = this.state;
        const newData = {
            name_departement: '',
            slug_departement: "",
            departement_code: "",
            region_code: '',
            id_departement: null
        };
        this.setState({
            data: [newData, ...data],
            pageSize: pageSize + 1
        });
    }
    handleDelete(idDepartement) {
        this.setState({
            creating: true
        })
        deleteDepartement(idDepartement)
            .then(({ ok, departement, errors }) => {
                if (ok === 'async') {
                    var newData = [...this.state.data]
                    let index = newData.findIndex(item => item.id_departement == departement)
                    newData.splice(index, 1)
                    this.setState({
                        data: newData,
                        creating: false
                    })
                } else if (errors) {
                    message.error(global._trans('settings', 'cantDelete'));
                }
                this.setState({
                    creating: false
                })


            })
            .catch(({ }) => {
                this.setState({
                    creating: false
                })
            })
    }
    onPageChange(page) {
        this.setState({
            loading: true
        })
        this.props.fetchDepartements({ page: page });
    }
    handleImportExcel = () => {
        this.setState({
            showImport: !this.state.showImport
        })
    }
    render() {
        return (
            <div className="card card-custom card-stretch">
                <div className="card-header py-1 header-center">
                    <div className="card-title flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">{global._trans('common', 'departementSiege')}</h3>
                    </div>
                    <div className="card-toolbar align-items-center">
                        <button className="btn btn-light-success font-weight-bolder font-size-sm mr-2" onClick={this.handleImportExcel}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'importData')}
                        </button>
                        <button className="btn btn-primary font-weight-bolder font-size-sm" onClick={this.handleAdd}>
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('settings', 'addDepartment')}
                        </button>
                    </div>
                </div>
                <div className="card-body py-4">
                    <TableEditableAction
                        dataList={this.state.data}
                        columnsList={[
                            { title: global._trans('settings', 'departement_name'), dataIndex: 'name_departement', editable: true },
                            // { title: global._trans('settings', 'department_slug'), dataIndex: 'slug_departement', editable: true },
                            { title: global._trans('settings', 'department_code'), dataIndex: 'departement_code', editable: true },
                            { title: global._trans('settings', 'region_code'), dataIndex: 'region_code', render: (dept) => (!!dept && !!dept.region ? `${dept.region.region_code} - ${dept.region.name_region}` : ''), editable: true, type: 'options', options: !!this.props.regions && !!this.props.regions.data ? this.props.regions.data.map((region) => ({ label: `${region.region_code} - ${region.name_region}`, value: region.region_code })) : [] }
                        ]}
                        dataKey="id_departement"
                        scrollY={585}
                        pagination={{ pageSize: this.state.pageSize, showSizeChanger: false, total: this.state.total, current: this.state.page, onChange: this.onPageChange }}
                        saveData={this.handleSave}
                        loading={this.state.loading || this.state.creating}
                        deleteRecord={this.handleDelete}
                    />
                </div>
                <ImportData show={this.state.showImport} title={global._trans('common', 'titelImport')} sourceFile="Departments" onHide={this.handleImportExcel} />
            </div>
        )
    }
}
function mapState(state) {
    const { departements, regions } = state.enums;
    return { departements, regions };
}
const connectedClass = connect(mapState, { fetchDepartements })(DepartementSiege)
export { connectedClass as DepartementSiege }