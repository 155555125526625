import React from 'react'
import PhoneInput from "react-phone-input-2";
import fr from 'react-phone-input-2/lang/fr.json'
export const PhonePicker = (props) => {
    return (
        <PhoneInput
            specialLabel={false}
            inputClass={'w-100 ' + (props.isLg ? "form-control-lg form-control-solid" : "")}
            inputStyle={{ fontSize: props.isLg ? "1.08rem" : "1rem" }}
            enableSearch={true}
            disableSearchIcon={true}
            searchStyle={{ width: "90%", padding: "10px 15px" }}
            searchPlaceholder={global._trans("common", "searchCode")}
            placeholder={global._trans('companies', 'telephoneStandrad')}
            country={"fr"}
            localization={fr}
            value={props.value}
            disabled={props.disabled}
            onChange={props.onChange}
        />
    )
}