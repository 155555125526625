import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class TypeDechetRecu extends Component {
    render() {
        return (
            <CommonComponent keyName="typeDechetRecus" titleSetting={global._trans('settings', 'typeDechetRecu')} typeDechetRecus={this.props.typeDechetRecus} />
        )
    }
}
function mapState(state) {
    const { listEnums: { typeDechetRecus } } = state.enums
    return { typeDechetRecus }
}
const connectedClass = connect(mapState, null)(TypeDechetRecu)
export { connectedClass as TypeDechetRecu }