import React, { Component } from 'react';
import { Form, Col, Card, ListGroup, Button, Image } from "react-bootstrap";
import { Select, DatePicker, Upload } from 'antd';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_sage/_helpers";
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import { PersoneSelector, GestionaireSelector, PhonePicker, DepartementSearch, RegionSearch } from '../../Commons'
import moment from 'moment';
import { AdressPicker } from '../../Commons';

const sitesStatus = ['VALIDATED', 'NOT_VALIDATED', 'NOT_PUBLISHED'];
class GeneralInfoSite extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showClient: false,
            showExploitant: false,
            showGestionaire: false,

        }
        this.handleHideModal = this.handleHideModal.bind(this)
        this.selectCLient = this.selectCLient.bind(this)
        this.selectExploitant = this.selectExploitant.bind(this)
        this.selectGestionaire = this.selectGestionaire.bind(this)
    }
    handleHideModal(name) {
        let prevState = this.state[name]
        this.setState({
            [name]: !prevState
        })
    }
    selectCLient(rowsData) {
        this.state.showClient = false
        this.props.handleName("client", rowsData)
    }
    selectExploitant(rowsData) {
        this.state.showExploitant = false
        this.props.handleName("societe", rowsData)
    }
    selectGestionaire(rowsData) {
        this.state.showGestionaire = false
        this.props.handleName("gestionaire", rowsData)
    }
    handleImageClick = (file_uid) => {
        this.props.handleName("default_image_id", file_uid)
    };
    render() {
        const children = [];
        for (let i = 10; i < 20; i++) {
            const value = `${i.toString(36)}${i}`;
            children.push({ value });
        }
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'denomenation')}*</Form.Label>
                        <Form.Control type="text" placeholder={global._trans('sites', 'denomenation')} value={this.props.stateSite.denomination} name="denomination" onChange={this.props.handleEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "categorieSite")}*</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            value={this.props.stateSite.categorieSite}
                            placeholder={global._trans("sites", "categorieSite")}
                            style={{ width: '100%' }}
                            options={[{ value: "UVE" }, { value: "TRI" }, { value: "TMB" }, { value: "ISDND" }]}
                            onSelect={(SelectedValue) => this.props.handleName("categorieSite", SelectedValue)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'sinoe')}*</Form.Label>
                        <Form.Control type="text" placeholder={global._trans('clientsEpic', 'sinoe')} value={this.props.stateSite.sinoe} name="sinoe" onChange={this.props.handleEvent} />
                    </Form.Group>
                    {this.props.stateSite.modeGestion && <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "modeGestion")}</Form.Label>
                        <Form.Control type="text" placeholder={global._trans('sites', 'modeGestion')} value={this.props.stateSite.modeGestion} disabled={true} />
                    </Form.Group>}
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'departementSiege')}*</Form.Label>
                        <DepartementSearch placeholder={global._trans('common', 'selectDepartementSiege')} onChange={(selected) => this.props.handleName('departement_siege', selected)} defaultOptions={this.props.defaultDepartement} value={this.props.stateSite.departement_siege} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'regionSiege')}*</Form.Label>
                        <RegionSearch placeholder={global._trans('common', 'selectRegionSiege')} defaultOptions={this.props.defaultRegions} onChange={(selected) => this.props.handleName('region_siege', selected)} disabled={true} value={this.props.stateSite.region_siege} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("common", "address")}*</Form.Label>
                        <AdressPicker value={this.props.stateSite.adresse} onChange={this.props._suggestionSelect} />

                        {this.props.stateSite.adresse &&
                            <div className="my-1">
                                <strong>{this.props.stateSite.adresse + '( ' + this.props.stateSite.latitude + ',' + this.props.stateSite.langititude + ' )'}</strong>
                            </div>}
                    </Form.Group>
                    
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'postcode')}*</Form.Label>
                        <Form.Control type='text' placeholder={global._trans('common', 'postcode')} name='postcode' value={this.props.stateSite.postcode} onChange={({ target: { name, value } }) => this.props.handleName(name, value)} />
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'city')}*</Form.Label>
                        <Form.Control type='text' placeholder={global._trans('common', 'city')} name='city' value={this.props.stateSite.city} onChange={({ target: { name, value } }) => this.props.handleName(name, value)} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'country')}*</Form.Label>
                        <Form.Control type='text' placeholder={global._trans('common', 'country')} name='country' value={this.props.stateSite.country} onChange={({ target: { name, value } }) => this.props.handleName(name, value)} />
                    </Form.Group>

                </Form.Row>
                <Form.Row>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>{global._trans("sites", "photoSite")}</Form.Label>
                            <Upload
                                listType="picture-card"
                                fileList={this.props.stateSite.photos}
                                onRemove={this.props.onRemoveFile}
                                customRequest={this.props.uploadFile}
                            >
                                <div>
                                    <SVG src={toAbsoluteUrl('/media/svg/icons/Costum/DragFile.svg')}></SVG>
                                    <div style={{ marginTop: 8 }}>{global._trans("sites", "importFile")}</div>
                                </div>
                            </Upload>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{global._trans("sites", "mainPhotoSite")}</Form.Label>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {this.props.stateSite.photos && this.props.stateSite.photos.length > 0 ?
                                this.props.stateSite.photos.map((file) => (
                                <Card
                                    className='card-hover-effect'
                                    key={file.uid}
                                    style={{
                                    width: 60,
                                    height: 60,
                                    marginRight: 5,
                                    marginBottom: 5,
                                    padding: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    borderRadius: '2px',
                                    border: file.uid === this.props.stateSite.default_image_id ? '2px solid #1890ff' : '1px solid #d9d9d9',
                                    }}
                                    onClick={() => this.handleImageClick(file.uid)}
                                >
                                    <Image
                                    style={{ maxHeight: '50px', maxWidth: '50px', objectFit: 'contain' }}
                                    src={file.url}
                                    alt={file.name}
                                    />
                                </Card>
                                )) : <span style={{fontSize: '12px', color: '#cacaca'}}>Aucune photo.</span>}
                            </div>
                        </Form.Group>
                    </Col>
                    <Col sm={8}>
                        <Form.Row>
                            <Col sm={6}>
                            <Form.Group>
                                <Form.Label>{global._trans("sites", "restartPeriod")}*</Form.Label>
                                <DatePicker format='MM/YYYY' onChange={(date, dateString) => this.props.handleName("perdiocitRelance", dateString)} value={moment(this.props.stateSite.perdiocitRelance, "MM/YYYY")} className="form-control" placeholder={global._trans("sites", "restartPeriod")} style={{ width: '100%' }} picker="month" />
                            </Form.Group>
                            </Col>
                            <Col sm={6}>
                            <Form.Group>
                                <Form.Label>{global._trans("sites", "createYear")}</Form.Label>
                                <DatePicker format='YYYY' onChange={(date, dateString) => this.props.handleName("anneeCreation", dateString)} value={moment(this.props.stateSite.anneeCreation, "YYYY")} className="form-control" placeholder={global._trans("sites", "createYear")} style={{ width: '100%' }} picker="year" />
                            </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans("sites", "comments")}</Form.Label>
                                <Form.Control name="comments" placeholder={global._trans('sites', 'comments')} value={this.props.stateSite.comments} as="textarea" rows="3" onChange={({ target: { name, value } }) => this.props.handleName(name, value)} />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("common", "webSite")}</Form.Label>
                        <Form.Control placeholder={global._trans("common", "webSite")} value={this.props.stateSite.siteIntrnet} name="siteIntrnet" onChange={this.props.handleEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("common", "standrPhone")}</Form.Label>
                        <PhonePicker
                            value={this.props.stateSite.telephoneStandrad}
                            onChange={(value) => this.props.handleName("telephoneStandrad", value)} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsSyndicat', 'amobe')}</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            optionFilterProp="label"
                            placeholder={global._trans('clientsSyndicat', 'selectAmobe')}
                            style={{ width: '100%', overflow: 'scroll' }}
                            value={this.props.stateSite.amobes}
                            loading={!this.props.amobe}
                            options={this.props.amobe}
                            onChange={(selected) => this.props.handleName('amobes', selected)}
                            mode="multiple"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "status")}*</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans("sites", "status")}
                            value={this.props.stateSite.status}
                            style={{ width: '100%' }}
                            options={sitesStatus.map((value) => ({ label: global._trans("sites", `status_${value}`), value }))}
                            onSelect={(SelectedValue) => this.props.handleName("status", SelectedValue)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    {this.props.stateSite.client && <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans("sites", "maitre_ouvrage")} {this.props.stateSite.client && <strong>({global._trans('client', this.props.stateSite.client.typePersonMoral.toLowerCase())})</strong>}</Form.Label>
                        <Card>
                            <ListGroup variant="flush">
                                <ListGroup.Item><strong>{this.props.stateSite.client.name} : </strong>{this.props.stateSite.client[this.props.stateSite.client.dataIndex]}</ListGroup.Item>
                                {this.props.stateSite.client.typePersonMoral == "Societe" && !!this.props.stateSite.client.groupe && <ListGroup.Item><strong>{global._trans("sites", "company_groupe")}</strong> : {this.props.stateSite.client.groupe.map(({ value_enum }) => value_enum).join(', ')} </ListGroup.Item>}
                                <ListGroup.Item><strong>Ville: </strong>{'  ' + this.props.stateSite.client.city}</ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Form.Group>}
                    {this.props.stateSite.societe && <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans("sites", "companyExp")} {this.props.stateSite.societe && <strong>({global._trans('client', this.props.stateSite.societe.typePersonMoral.toLowerCase())})</strong>}</Form.Label>
                        <Card>
                            <ListGroup variant="flush">
                                <ListGroup.Item><strong>{this.props.stateSite.societe.name} : </strong>{this.props.stateSite.societe[this.props.stateSite.societe.dataIndex]}</ListGroup.Item>
                                {this.props.stateSite.societe.typePersonMoral == "Societe" && !!this.props.stateSite.societe.groupe && <ListGroup.Item><strong>{global._trans("sites", "company_groupe")}</strong> : {this.props.stateSite.societe.groupe.map(({ value_enum }) => value_enum).join(', ')} </ListGroup.Item>}
                                <ListGroup.Item><strong>Ville: </strong>{'  ' + this.props.stateSite.societe.city}</ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Form.Group>}
                    <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans("sites", "gestionaireCharge")}</Form.Label>
                        {this.props.stateSite.gestionaire ? <Card>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <div className="row">
                                        <div className="col-xl-6"><strong>{global._trans("common", "firstName")} : </strong>{'  ' + this.props.stateSite.gestionaire.nom}</div>
                                        <div className="col-xl-6"><strong>{global._trans("common", "lastName")} : </strong>{'  ' + this.props.stateSite.gestionaire.prenom}</div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item><strong>{global._trans("common", "email")}: </strong>{'  ' + this.props.stateSite.gestionaire.email}</ListGroup.Item>
                                <ListGroup.Item><strong>{global._trans("contacts", "status")}: </strong>{this.props.stateSite.gestionaire.status ? ' Active' : ' inActive'}</ListGroup.Item>
                                <ListGroup.Item><Card.Link onClick={() => this.handleHideModal('showGestionaire')}><strong>{global._trans("sites", "changeMtn")}</strong></Card.Link></ListGroup.Item>
                            </ListGroup>
                        </Card> :
                            <Button variant="outline-secondary" size="md" block onClick={() => this.handleHideModal('showGestionaire')}>{global._trans("sites", "selectMtn")}</Button>}
                    </Form.Group>
                </Form.Row>
                {this.state.showClient && <PersoneSelector show={this.state.showClient} validSelection={this.selectCLient} onHide={() => this.handleHideModal('showClient')} />}
                {this.state.showExploitant && <PersoneSelector show={this.state.showExploitant} validSelection={this.selectExploitant} onHide={() => this.handleHideModal('showExploitant')} />}
                {this.state.showGestionaire && <GestionaireSelector filter={{ status: 'active' }} show={this.state.showGestionaire} validSelection={this.selectGestionaire} onHide={() => this.handleHideModal('showGestionaire')} />}

            </>
        )
    }
}
export { GeneralInfoSite }