import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class VoiTraitementFumme extends Component {
    render() {
        return (
            <CommonComponent keyName="voiTraiFemuee" titleSetting={global._trans('settings', 'voiTraitment')} voiTraiFemuee={this.props.voiTraiFemuee} />
        )
    }
}
function mapState(state) {
    const { listEnums: { voiTraiFemuee } } = state.enums
    return { voiTraiFemuee }
}
const connectedClass = connect(mapState, null)(VoiTraitementFumme)
export { connectedClass as VoiTraitementFumme }