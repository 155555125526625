import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { updateUserSimple } from '../../../../../modules/UserSimple/userSimple.actions'
export const CreateUpdateUserSimple = (props) => {
    const [state, setState] = useState(props.initUser || { nom: '', prenom: '', username: '', email: '', phone: '', init_password: '', userPrem: props.idUserPrem })
    const [requestState, setRequestState] = useState({ loading: false, errors: {} })
    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (props.initUser) {
            setRequestState({
                ...requestState,
                loading: 0
            })
            updateUserSimple(state)
                .then(({ ok, data }) => {
                    if (ok && data) {
                        setRequestState({
                            ...requestState,
                            loading: 1
                        })
                        props.asyncUsers()
                    } else {
                        setRequestState({
                            ...requestState,
                            loading: 2
                        })
                    }
                })
                .catch(({ errors }) => {
                    setRequestState({
                        errors: errors,
                        loading: 2
                    })
                })
        } else {
            props.createSession(state)
        }
    }
    return (
        <Modal
            onHide={props.handleModel}
            show={true}
            size="lg"
            className="p-2"
            centered
        >
            {(requestState.loading === 0) && <div className="position-absolute t-0 l-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 10, background: "#ffffff40" }}>
                <Spinner animation="border" variant="primary" />
            </div>}
            <div className="px-6 py-6">
                <div>
                    <h4 className="font-weight-bolder">Ajouter un(e) utilisateur</h4>
                </div>
                <div className="separator separator-solid mt-6"></div>
                <form className="pt-4 form" onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div class="form-group col">
                            <label class="font-size-h6 form-label">{global._trans("common", "firstName")}</label>
                            <input class="form-control form-control-lg form-control-solid" type="text" value={state.nom} onChange={handleChange} name="nom" placeholder={global._trans("common", "firstName")} />
                            {(requestState.loading === 2 && requestState.errors) && <span class="form-text font-size-h6 text-danger">{requestState.errors.nom}</span>}
                        </div>
                        <div class="form-group col">
                            <label class="font-size-h6 form-label">{global._trans("common", "lastName")}</label>
                            <input class="form-control form-control-lg form-control-solid" type="text" value={state.prenom} onChange={handleChange} name="prenom" placeholder={global._trans("common", "lastName")} />
                            {(requestState.loading === 2 && requestState.errors) && <span class="form-text font-size-h6 text-danger">{requestState.errors.prenom}</span>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div class="form-group col">
                            <label class="font-size-h6 form-label">{global._trans("common", "userName")}</label>
                            <input class="form-control form-control-lg form-control-solid" type="text" value={state.user.username} onChange={handleChange} name="username" placeholder={global._trans("common", "userName")} />
                            {(requestState.loading === 2 && requestState.errors) && <span class="form-text font-size-h6 text-danger">{requestState.errors.username}</span>}
                        </div>
                        <div class="form-group col">
                            <label class="font-size-h6 form-label">{global._trans("common", "email")}</label>
                            <input class="form-control form-control-lg form-control-solid" type="text" value={state.email_user_sim} onChange={handleChange} name="email" placeholder={global._trans("common", "email")} />
                            {(requestState.loading === 2 && requestState.errors) && <span class="form-text font-size-h6 text-danger">{requestState.errors.email}</span>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div class="form-group col">
                            <label class="font-size-h6 form-label">{global._trans("common", "phone")}</label>
                            <input class="form-control form-control-lg form-control-solid" type="text" value={state.phone} onChange={handleChange} name="phone" placeholder={global._trans("common", "phone")} />
                            {(requestState.loading === 2 && requestState.errors) && <span class="form-text font-size-h6 text-danger">{requestState.errors.phone}</span>}
                        </div>
                        <div class="form-group col">
                            <label class="font-size-h6 form-label">{global._trans('common', 'initPassword')}</label>
                            <input class="form-control form-control-lg form-control-solid" value={state.user.init_password} type="text" onChange={handleChange} name="init_password" placeholder={global._trans('common', 'initPassword')} disabled={state.init_password === null} />
                            {(requestState.loading === 2 && requestState.errors) && <span class="form-text font-size-h6 text-danger">{requestState.errors.init_password}</span>}
                        </div>
                    </div>
                    <div className="form-row justify-content-end pr-5">
                        <button type="button" onClick={props.handleModel} className="btn btn-light mr-3 font-weight-bolder">Annuler</button>
                        <button className="btn btn-primary font-weight-bolder">{props.initUser ? 'Enregistrer' : 'Ajouter utilisateur'}</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}