import React, { Component } from 'react'
import { SageTable } from '../../../../Commons'
class Communes extends Component {
    render() {
        return (
            <>
                <SageTable
                    columns={[
                        { title: "ID", dataIndex: "id_commune" },
                        { title: global._trans('clientsCommune', 'communeName'), dataIndex: "nomCommune" },
                        { title: global._trans('common', 'address'), dataIndex: "adresse" },
                        { title: global._trans('common', 'population'), dataIndex: "nombreHabitant" },
                        { title: global._trans('clientsCommune', 'insee'), dataIndex: "insee" },
                        { title: global._trans('common', 'serin'), dataIndex: "serin" },
                    ]}
                    style={{ height: 540 }}
                    data={this.props.communes}
                    IterableProps={{
                        emptyText: global._trans('clientsEpic', 'emotyAssociCommune'),
                        rowKey: "id_commune",
                        pagination: false
                    }}
                />
                <br />
            </>
        )
    }
}
export { Communes }