import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom'
import { Form, Col, Card, ListGroup, Button } from "react-bootstrap";
import { Select, notification, DatePicker, Spin } from 'antd';
import { CostumAlert, PersoneSelector, SiteSelector, InlineBottunSvg } from '../Commons'
import { createContract, updateContract, editContract } from "../../../modules/Contract/contract.actions";
import moment from 'moment';
class CreateContract extends Component {
    constructor(props) {
        super(props)
        let action = props.match.params.idCreate === "new"
        this.state = {
            dateDebut: null,
            dateFin: null,
            contractant: null,
            client: null,
            autreActivite: null,
            site: null,
            communes: [],
            showForContract: false,
            showForClient: false,
            showForSite: false,
            showForCommunes: false,
            loading: false,
            fetching: !action,
            alerts: false,
            errors: null,
            modeGestion: null,
        }
        this.isNewItem = action
        if (!action) {
            props.editContract(props.match.params.idCreate)
        }
        this.handleHideModal = this.handleHideModal.bind(this)
        this.setSelection = this.setSelection.bind(this)
        this.handleName = this.handleName.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            this.setState({
                loading: false,
                alerts: true,
                errors: nextProps.contractErrors
            })
        }
        if (this.state.fetching && (nextProps.contrat || nextProps.contractErrors)) {
            this.isNewItem = nextProps.contractErrors ? true : false
            this.setState({
                ...nextProps.contrat,
                communes: [...nextProps.contrat.communes],
                fetching: false
            })
        }
    }
    handleName(name, value) {
        this.setState({
            [name]: value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        let contract = {
            id_contrat: this.state.id_contrat,
            dateDebut: this.state.dateDebut,
            dateFin: this.state.dateFin,
            autreActivite: parseInt(this.state.autreActivite),
            contractant_id: !this.state.modeGestion || this.state.modeGestion != "Régie" ? this.state.contractant && this.state.contractant.id_person : null,
            contractant_type: this.state.contractant && this.state.contractant.typePersonMoral,
            client_id: !this.state.modeGestion || this.state.modeGestion != "Gestion privée" ? this.state.client && this.state.client.id_person : null,
            client_type: this.state.client && this.state.client.typePersonMoral,
            site: this.state.site && this.state.site.id_site,
            communes: this.state.communes && this.state.communes.map(commune => {
                return {
                    type: commune.typePersonMoral,
                    id_person: commune.id_person
                }
            }),
            modeGestion: this.state.modeGestion,
        }
        if (this.isNewItem) {
            this.props.createContract(contract)
        } else {
            this.props.updateContract(contract)
        }
        this.setState({
            loading: true,
            alerts: false
        })
    }
    handleHideModal(modelType) {
        let prevState = this.state[modelType]
        this.setState({
            [modelType]: !prevState
        })
    }
    handleDeleteItem(index) {
        let prevState = this.state.communes
        prevState.splice(index, 1)
        this.setState({
            communes: prevState
        })
    }
    setSelection(rowsData) {
        if (this.state.showForContract) {
            this.setState({
                contractant: rowsData,
                showForContract: false
            })
        } else if (this.state.showForClient) {
            this.setState({
                client: rowsData,
                showForClient: false
            })
        } else if (this.state.showForCommunes) {
            let prevCommune = this.state.communes
            if (!prevCommune.find(item => (item.id_person == rowsData.id_person && item.typePersonMoral == rowsData.typePersonMoral))) {
                prevCommune.push({ id_person: rowsData.id_person, city: rowsData.city, typePersonMoral: rowsData.typePersonMoral, name: rowsData.name, dataIndex: rowsData.dataIndex, [rowsData.dataIndex]: rowsData[rowsData.dataIndex], adresse: rowsData.adresse, groupe: rowsData.groupe })
            }
            this.setState({
                communes: prevCommune,
                showForCommunes: false
            })
        } else {
            this.setState({
                site: rowsData,
                showForSite: false
            })
        }

    }
    showErrors() {
        if (this.state.alerts) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                notification.success({
                    message: this.isNewItem ? global._trans('contracts', 'titleMessageCreate') : global._trans('contracts', 'titleUpdateMessage'),
                    description: this.isNewItem ? global._trans('contracts', 'bodyMessageCreate') : global._trans('contracts', 'BodyUpdateMessage'),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}

                })
                return <Redirect to="/contrat" />
            }
        }
    }
    render() {
        return (
            <>
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{this.isNewItem ? global._trans('contracts', 'createContract') : global._trans('contracts', 'editTitle')}</span>
                    </h3>
                </div>
                <Spin spinning={this.state.loading || this.state.fetching}>
                    <div className="card-body py-4">
                        {this.showErrors()}
                        <Form method="post" onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label className="d-block">{global._trans('contracts', 'siteContract')}</Form.Label>
                                    {this.state.site ? <Card>
                                        <ListGroup variant="flush">
                                            <div className="d-flex">
                                                <ListGroup.Item><strong>{global._trans('sites', 'denomination')} : </strong>{'  ' + this.state.site.denomination}</ListGroup.Item>
                                                <ListGroup.Item><strong>{global._trans('sites', 'categorieSite')} : </strong>{'  ' + this.state.site.categorieSite}</ListGroup.Item>
                                                <ListGroup.Item><strong>{global._trans('sites', 'modeGestion')}: </strong>{'  ' + this.state.site.modeGestion}</ListGroup.Item>
                                            </div>
                                            <ListGroup.Item><strong>{global._trans('common', 'city')}: </strong>{'  ' + this.state.site.city}</ListGroup.Item>
                                            <ListGroup.Item><Card.Link onClick={() => this.handleHideModal("showForSite")}><strong>{global._trans('contracts', 'changeSite')}</strong></Card.Link></ListGroup.Item>
                                        </ListGroup>
                                    </Card> :
                                        <Button variant="outline-secondary" size="md" block onClick={() => this.handleHideModal("showForSite")}>{global._trans('contracts', 'selectSite')}</Button>}
                                </Form.Group>
                                {/*<Form.Group as={Col}>
                                    <Form.Label>{global._trans('contracts', 'autherActivContract')}</Form.Label>
                                    <Select
                                        showSearch
                                        showArrow
                                        className="inputSizeSelect"
                                        placeholder={global._trans('contracts', 'selectActiviteContract')}
                                        style={{ width: '100%' }}
                                        optionFilterProp="label"
                                        value={this.state.autreActivite}
                                        loading={!this.props.contract}
                                        options={this.props.contract}
                                        onChange={(selectedRow) => this.handleName('autreActivite', selectedRow)}
                                    />
                                    </Form.Group>*/}
                            </Form.Row>
                            <Form.Row>

                                {this.state.modeGestion != "Gestion privée" && this.state.modeGestion != "Gestion privée" && <Form.Group as={Col}>
                                    <Form.Label className="d-block">{global._trans('client', 'maitre')}</Form.Label>
                                    {this.state.client ? <Card>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item><strong>{this.state.client.name} ({global._trans('client', this.state.client.typePersonMoral.toLowerCase())}): </strong> {'  ' + this.state.client[this.state.client.dataIndex]}</ListGroup.Item>
                                            <ListGroup.Item><strong>{global._trans('common', 'city')}: </strong> {this.state.client.city}</ListGroup.Item>
                                            <ListGroup.Item><Card.Link onClick={() => this.handleHideModal("showForClient")}><strong>{global._trans('contracts', 'changeClient')}</strong></Card.Link></ListGroup.Item>
                                        </ListGroup>
                                    </Card> :
                                        <Button variant="outline-secondary" size="md" block onClick={() => this.handleHideModal("showForClient")}>{global._trans('contracts', 'selectCommunes')}</Button>}
                                </Form.Group>}
                                {/* <Form.Group as={Col}>
                                    <Form.Label className="d-block">{`${global._trans('client', 'maitre')}`}</Form.Label>
                                    {Array.isArray(this.state.communes) && this.state.communes.length > 0 ? <Card>
                                        <ListGroup variant="flush">
                                            {this.state.communes.map((commune, key) => {
                                                return <Fragment key={key}>
                                                    <ListGroup.Item>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <span>
                                                                <strong>{commune.name} ({global._trans('client', commune.typePersonMoral.toLowerCase())}) : </strong>{'  ' + commune[commune.dataIndex]}
                                                            </span>
                                                            <span>
                                                                <InlineBottunSvg
                                                                    bottuns={[{ iconPath: `/media/svg/icons/Costum/Trash.svg`, type: "danger", action: () => this.handleDeleteItem(key) }]}
                                                                />
                                                            </span>
                                                        </div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item style={{ borderWidth: "0 0 6px" }}><strong>{global._trans('common', 'city')}: </strong> {commune.city}</ListGroup.Item>
                                                </Fragment>
                                            })}
                                            <ListGroup.Item><Card.Link onClick={() => this.handleHideModal("showForCommunes")}><strong>{global._trans('contracts', 'changeCommunes')}</strong></Card.Link></ListGroup.Item>
                                        </ListGroup>
                                    </Card> :
                                        <Button variant="outline-secondary" size="md" block onClick={() => this.handleHideModal("showForCommunes")}>{global._trans('contracts', 'selectCommunes')}</Button>}
                                </Form.Group> */}
                                {this.state.modeGestion != "Régie" && <Form.Group as={Col}>
                                    <Form.Label className="d-block">{global._trans('contracts', 'contractant')}</Form.Label>
                                    {this.state.contractant ? <Card>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item><strong>{this.state.contractant.name} ({global._trans('client', this.state.contractant.typePersonMoral.toLowerCase())}): </strong> {'  ' + this.state.contractant[this.state.contractant.dataIndex]}</ListGroup.Item>
                                            <ListGroup.Item><strong>{global._trans('common', 'city')}: </strong> {this.state.contractant.city}</ListGroup.Item>
                                            <ListGroup.Item><Card.Link onClick={() => this.handleHideModal("showForContract")}><strong>{global._trans('contracts', 'changeContract')}</strong></Card.Link></ListGroup.Item>
                                        </ListGroup>
                                    </Card> :
                                        <Button variant="outline-secondary" size="md" block onClick={() => this.handleHideModal("showForContract")}>{global._trans('contracts', 'selectContractant')}</Button>}
                                </Form.Group>}
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('contracts', 'startDate')}</Form.Label>
                                    <DatePicker format="DD/MM/YYYY" value={this.state.dateDebut && moment(this.state.dateDebut, "DD/MM/YYYY")} onChange={(date, dateString) => this.handleName("dateDebut", dateString)} className="form-control" placeholder={global._trans('contracts', 'startDate')} style={{ width: '100%' }} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('contracts', 'endDate')}</Form.Label>
                                    <DatePicker format="DD/MM/YYYY" disabledDate={(current) => current < moment(this.state.dateDebut, "DD/MM/YYYY").add(1, 'day')} value={this.state.dateFin && moment(this.state.dateFin, "DD/MM/YYYY")} onChange={(date, dateString) => this.handleName("dateFin", dateString)} className="form-control" placeholder={global._trans('contracts', 'endDate')} style={{ width: '100%' }} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans("sites", "modeGestion")}</Form.Label>
                                <Select
                                    showSearch
                                    showArrow
                                    className="inputSizeSelect"
                                    placeholder={global._trans("sites", "modeGestion")}
                                    value={this.state.modeGestion}
                                    style={{ width: '100%' }}
                                    options={!!this.props.mode_gestion ? this.props.mode_gestion.map((mode) => ({ value: mode.label })) : []}
                                    onSelect={(SelectedValue) => this.handleName("modeGestion", SelectedValue)}
                                />
                            </Form.Group>
                            </Form.Row>
                            <div className="d-flex justify-content-end mt-4">
                                <div className="card-toolbar mx-4">
                                    <Link to="/contrat" className="btn btn-secondary" >{global._trans('common', 'cancelBtn')}</Link>
                                </div>
                                <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{this.isNewItem ? global._trans('contracts', 'textSubmitCont') : global._trans('common', 'saveChanges')}</Button>
                            </div>
                        </Form>
                        {(this.state.showForContract || /*this.state.showForCommunes*/ this.state.showForClient) && <PersoneSelector defaultCurrent={this.state.showForContract ? 3 : 0} show={this.state.showForContract || /*this.state.showForCommunes*/ this.state.showForClient} validSelection={this.setSelection} onHide={() => this.handleHideModal(this.state.showForContract ? "showForContract" : /*"showForCommunes"*/ "showForClient")} />}
                        {this.state.showForSite && <SiteSelector show={this.state.showForSite} validSelection={this.setSelection} onHide={() => this.handleHideModal("showForSite")} />}
                        {/*this.state.showForCommunes && <CommuneSelector show={this.state.showForCommunes} validSelection={(item) => this.setSelection(item, ["communes", "showForCommunes"])} onHide={() => this.handleHideModal("showForCommunes")} />*/}
                    </div>
                </Spin>
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { contract, mode_gestion } } = state.enums
    const { contractErrors, contrat } = state.contract
    return { contract, contractErrors, contrat, mode_gestion }
}
const connectedClass = connect(mapState, { createContract, updateContract, editContract })(CreateContract)
export { connectedClass as CreateContract }