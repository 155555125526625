import React, { useState } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';

const DepartementSearch = (props) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)


    const getDepts = (search) => {
        let result = !!props.departements && Array.isArray(props.departements) && props.departements.length > 0 ? props.departements : [];

        if (!!search && search != '') {
            result = result.filter((dept) => dept.name_departement.toUpperCase().includes(search.toUpperCase()));
        }
        return result;
    }

    const handleSearch = (search) => {
        setData(getDepts(search));
    }

    let options = !!data ? data : getDepts(null);

    if (!!props.selectedRegion) {
        options = options.filter((dept) => dept.region_code == props.selectedRegion);
    }

    options = options.map((dept) => ({ label: `${dept.departement_code} - ${dept.name_departement}`, value: dept.id_departement }));

    return (
        <Select
            disabled={props.disabled}
            showSearch
            showArrow
            className="inputSizeSelect"
            placeholder={props.placeholder}
            style={{ width: '100%' }}
            optionFilterProp="label"
            value={props.value}
            notFoundContent={global._trans("common", "noThingToShow")}
            loading={loading}
            options={options}
            onSearch={handleSearch}
            onSelect={props.onChange}
        />
    )
}

function mapState(state) {
    const { departements } = state.enums;
    return { departements: departements.data };
}
const connectedClass = connect(mapState, {})(DepartementSearch)
export { connectedClass as DepartementSearch }