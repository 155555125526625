import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./auth.crud";
import { actionTypes } from "./auth.constants";

const initialAuthState = {
    user: null,
    authToken: '',
};
export const Authreducer = persistReducer(
    { storage, key: "sage", whitelist: ["authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.Login: {
              const { authToken } = action.payload;
      
              return { authToken, user: undefined };
            }
            case actionTypes.Logout: {
              // TODO: Change this code. Actions in reducer aren't allowed.
              return initialAuthState;
            }
      
            case actionTypes.UserLoaded: {
              const { user } = action.payload;
              return { ...state, user };
            }
      
            default:
              return state;
          }
    }
);

export const actions = {
    login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
    logout: () => ({ type: actionTypes.Logout }),
    fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } })
  };
  
  export function* saga() {
    
  }