import { CrudService } from "../../../services";
import { collectivitieUrls } from './collectivitie.constants'
export function createEpic(Epic) {
    return CrudService.Post(collectivitieUrls.CREATE_EPIC_URL, Epic);
}
export function fetchEpics(queryParams) {
    return CrudService.Post(collectivitieUrls.FETCH_EPICS_URL, queryParams);
}
export function fetchEpicsGet(queryParams) {
    return CrudService.Get(collectivitieUrls.FETCH_EPICS_URL, {}, queryParams);
}
export function deleteEpics(Epics) {
    return CrudService.Delete(collectivitieUrls.DELETE_EPICS_URL, Epics);
}
export function fetchEpic(idEPic) {
    return CrudService.Get(collectivitieUrls.FETCH_EPIC_URL + idEPic);
}
export function updateEpic(Epic) {
    return CrudService.Patch(collectivitieUrls.UPDATE_EPIC_URL, Epic);
}
export function editEpic(idEPic) {
    return CrudService.Get(collectivitieUrls.EDIT_EPIC_URL + idEPic);
}

export function syncEPICsInseeAPI(epics) {
    return CrudService.Post(collectivitieUrls.SYNC_EPIC_URL, epics);
}


/******************************* */


export function createCommune(Commune) {
    return CrudService.Post(collectivitieUrls.CREATE_COMMUNE_URL, Commune);
}
export function fetchCommunes(queryParams) {
    return CrudService.Post(collectivitieUrls.FETCH_COMMUNES_URL, queryParams);
}
export function fetchCommunesGet(queryParams) {
    return CrudService.Get(collectivitieUrls.FETCH_COMMUNES_URL, {}, queryParams);
}
export function deleteCommunes(Communes) {
    return CrudService.Delete(collectivitieUrls.DELETE_COMMUNES_URL, Communes);
}
export function fetchCommune(idCommune) {
    return CrudService.Get(collectivitieUrls.FETCH_COMMUNE_URL + idCommune);
}
export function updateCommune(Commune) {
    return CrudService.Patch(collectivitieUrls.UPDATE_COMMUNE_URL, Commune);
}
export function editCommune(idCommune) {
    return CrudService.Get(collectivitieUrls.EDIT_COMMUNE_URL + idCommune);
}


export function syncCommunesInseeAPI(communes) {
    return CrudService.Post(collectivitieUrls.SYNC_COMMUNE_URL, communes);
}

/**************************** */


export function createSyndicat(Syndicat) {
    return CrudService.Post(collectivitieUrls.CREATE_SYNDICAT_URL, Syndicat);
}
export function fetchSyndicats(queryParams) {
    return CrudService.Post(collectivitieUrls.FETCH_SYNDICATS_URL, queryParams);
}
export function fetchSyndicatsGet(queryParams) {
    return CrudService.Get(collectivitieUrls.FETCH_SYNDICATS_URL, {}, queryParams);
}
export function fetchSyndicat(idSyndicat) {
    return CrudService.Get(collectivitieUrls.FETCH_SYNDICAT_URL + idSyndicat);
}
export function deleteSyndicats(Syndicats) {
    return CrudService.Delete(collectivitieUrls.DELETE_SYNDICATS_URL, Syndicats);
}
export function editSyndicat(idSyndicat) {
    return CrudService.Get(collectivitieUrls.EDIT_SYNDICAT_URL + idSyndicat);
}
export function updateSyndicats(Syndicat) {
    return CrudService.Patch(collectivitieUrls.UPDATE_SYNDICAT_URL, Syndicat);
}


export function syncSyndicatsInseeAPI(syndicats) {
    return CrudService.Post(collectivitieUrls.SYNC_SYNDICAT_URL, syndicats);
}

/********************************** */

