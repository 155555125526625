import React, { Component } from 'react'
import { SageTable } from '../../../../Commons'
class Sites extends Component {
    render() {
        return (
            <>
                <SageTable
                    columns={[
                        { title: global._trans('sites', 'categorieSite'), dataIndex: "categorieSite" },
                        { title: global._trans('sites', 'modeGestion'), dataIndex: "modeGestion" },
                        { title: global._trans('sites', 'denomenation'), dataIndex: "denomination" },
                        { title: global._trans('sites', 'createYear'), dataIndex: "anneeCreation" },
                        { title: global._trans('sites', 'restartPeriod'), dataIndex: "perdiocitRelance" },
                        { title: global._trans('common', 'standrPhone'), dataIndex: "telephoneStandrad" },
                        { title: global._trans('common', 'webSite'), dataIndex: "siteIntrnet" },
                        { title: global._trans('common', 'address'), dataIndex: "adresse" },

                    ]}
                    style={{ height: 540 }}
                    data={this.props.sites}
                    IterableProps={{
                        locale: {
                            emptyText: global._trans('clientsSyndicat', 'emptySitesAssociat')
                        },
                        rowKey: "id_contact",
                        pagination: false
                    }}
                />
                <br />
            </>
        )
    }
}
export { Sites }