import React, { Component } from 'react';
import { Form, Col } from "react-bootstrap";
import { Select } from 'antd';
import { connect } from 'react-redux'
import { ImageCroper, PhonePicker, DepartementSearch, RegionSearch, AdressPicker } from '../../../../Commons'
const sitesStatus = ['VALIDATED', 'NOT_VALIDATED', 'NOT_PUBLISHED'];
const states = [{label: 'Actif', value: 'A'}, {label: 'Fermé', value: 'C'}];
class GeneraleInfo extends Component {
    render() {
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'state')}</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans("common", "state")}
                            value={this.props.epic.state}
                            style={{ width: '100%' }}
                            options={states}
                            onSelect={(SelectedValue) => this.props.handleChange("state", SelectedValue)}                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'epicName')}*</Form.Label>
                        <Form.Control type="text" name="nomEpic" value={this.props.epic.nomEpic} placeholder={global._trans('clientsEpic', 'epicName')} onChange={this.props.handleChangeEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsSyndicat', 'nameSyndicat')}*</Form.Label>
                        <Form.Control type="text" name="nom_court" value={this.props.epic.nom_court} placeholder={global._trans('clientsSyndicat', 'nameSyndicat')} onChange={this.props.handleChangeEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'usual_denomination')}</Form.Label>
                        <Form.Control type="text" name="usual_denomination" value={this.props.epic.usual_denomination} placeholder={global._trans('common', 'usual_denomination')} onChange={this.props.handleChangeEvent} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'siret')}</Form.Label>
                        <Form.Control type="number" name="siret" value={this.props.epic.siret} placeholder={global._trans('clientsEpic', 'siret')} onChange={this.props.handleChangeEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'serin')}*</Form.Label>
                        <Form.Control type="number" name="serin" value={this.props.epic.serin} placeholder={global._trans('clientsEpic', 'serin')} onChange={this.props.handleChangeEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('clientsEpic', 'sinoe')}*</Form.Label>
                        <Form.Control type="text" name="sinoe" value={this.props.epic.sinoe} placeholder={global._trans('clientsEpic', 'sinoe')} onChange={this.props.handleChangeEvent} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'address')}*</Form.Label>
                        <AdressPicker value={this.props.epic.adresse} onChange={this.props._suggestionSelect} />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-0">
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans('common', 'city')}*</Form.Label>
                                <Form.Control type='text' placeholder={global._trans('common', 'city')} name='city' value={this.props.epic.city} onChange={this.props.handleChangeEvent} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans('common', 'country')}*</Form.Label>
                                <Form.Control type='text' placeholder={global._trans('common', 'country')} name='country' value={this.props.epic.country} onChange={this.props.handleChangeEvent} />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'postcode')}*</Form.Label>
                        <Form.Control type='text' placeholder={global._trans('common', 'postcode')} name='postcode' value={this.props.epic.postcode} onChange={this.props.handleChangeEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'webSite')}*</Form.Label>
                        <Form.Control type='text' value={this.props.epic.siteInternet} placeholder={global._trans('common', 'webSite')} name='siteInternet' onChange={this.props.handleChangeEvent} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'departementSiege')}*</Form.Label>
                        <DepartementSearch placeholder={global._trans('common', 'selectDepartementSiege')} onChange={(selected) => this.props.handleChange('departement_siege', selected)} defaultOptions={this.props.defaultDepartement} value={this.props.epic.departement_siege} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'regionSiege')}*</Form.Label>
                        <RegionSearch placeholder={global._trans('common', 'selectRegionSiege')} defaultOptions={this.props.defaultRegions} onChange={(selected) => this.props.handleChange('region_siege', selected)} value={this.props.epic.region_siege} disabled={true} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'natureJuridque')}*</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('common', 'selectNatureJuridque')}
                            style={{ width: '100%' }}
                            optionFilterProp="label"
                            value={this.props.epic.nature_juridique}
                            loading={!this.props.nature_juridique}
                            options={this.props.nature_juridique}
                            onChange={(selectedValue) => this.props.handleChange("nature_juridique", selectedValue)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'logoCompany')}</Form.Label>
                        <ImageCroper images={this.props.epic.logo} onChange={(images) => this.props.handleChange('logo', images)} />
                    </Form.Group>
                </Form.Row>
                <div className="separator separator-solid mb-4"></div>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'standrPhone')}</Form.Label>
                        <PhonePicker
                            value={this.props.epic.telephoneStandard}
                            onChange={(value) => this.props.handleChange("telephoneStandard", value)} />
                    </Form.Group>
                    {this.props.epic.is_new != true && <Form.Group as={Col}>
                        <Form.Row>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'population')}</Form.Label>
                                <Form.Control type="number" name="nombreHabitant" defaultValue={this.props.epic.nombreHabitant} placeholder={global._trans('common', 'population')} disabled="true" />
                            </Form.Group>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'population_year')}</Form.Label>
                                <Form.Control type="number" name="nombreHabitant_year" defaultValue={this.props.epic.nombreHabitant_year} placeholder={global._trans('common', 'population_year')} onChange={this.props.handleChangeEvent} />
                            </Form.Group>
                        </Form.Row>
                        {this.props.epic.date_enter && <div className="px-2"><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.props.epic.date_enter)}</div>}
                    </Form.Group>}
                    <Form.Group as={Col}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{global._trans('common', 'effectifs')}</Form.Label>
                                <Select
                                    showSearch
                                    showArrow
                                    className="inputSizeSelect"
                                    placeholder={global._trans('common', 'selectNatureJuridque')}
                                    style={{ width: '100%' }}
                                    optionFilterProp="label"
                                    value={this.props.epic.effectifs}
                                    loading={!this.props.effectifs}
                                    options={this.props.effectifs}
                                    onChange={(selected) => this.props.handleChange('effectifs', selected)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'effectifs_year')}</Form.Label>
                                <Form.Control type="number" name="effectifs_year" defaultValue={this.props.epic.effectifs_year} placeholder={global._trans('common', 'effectifs_year')} onChange={this.props.handleChangeEvent} />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "status")}*</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans("sites", "status")}
                            value={this.props.epic.status}
                            style={{ width: '100%' }}
                            options={sitesStatus.map((value) => ({ label: global._trans("sites", `status_${value}`), value }))}
                            onSelect={(SelectedValue) => this.props.handleChange("status", SelectedValue)}
                        />
                    </Form.Group>
                </Form.Row>
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { nature_juridique, effectifs } } = state.enums
    return { nature_juridique, effectifs }
}
const connectedClass = connect(mapState, null)(GeneraleInfo)
export { connectedClass as GeneraleInfo }