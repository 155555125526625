import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_sage/_helpers";
import * as Apis from '../../modules/Auth/auth.crud';
import { CostumAlert } from '../PrivatePages/Commons'
import { actions } from '../../modules/Auth/auth.reducer';

export const OTP = (props) => {
    const { id, email, username, password } = props.location.state || {};
    const initState = {
        otpCode: '',
        otpError: '',
        otpLoading: false,
        otpResent: false,
        otpResentLoading: false
    }
    const [state, setState] = useState(initState)
    const dispatch = useDispatch()
    const onChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!state.otpLoading && state.otpCode) {
            setState({ ...state, otpLoading: true, otpError: '' })
            Apis.otp({ id: id, otp_code: state.otpCode })
                .then(res => {
                    if (res && res.ok && (res.typeuser === "Admin" || res.typeuser === "Gestionnaire" || res.typeuser === "SupAdmin")) {
                        dispatch(actions.login(res.token))
                        window.location.reload();
                    } else {
                        setState({
                            ...state,
                            otpLoading: false,
                            otpError: 'Le code de vérification est incorrect ou expiré'
                        })
                    }
                })
                .catch(err => {
                    setState({
                        ...state,
                        otpLoading: false,
                        otpError: err?.message == 'Too Many Attempts.' ? 'Trop de tentatives, veuillez réessayer après quelques secondes.' : 'Une erreur s\'est produite, veuillez réessayer'
                    })
                })
        }
    }
    const handleResend = (e) => {
        if (!state.otpResent && !state.otpResentLoading) {
            setState({
                ...state,
                otpResentLoading: true
            });
            Apis.login({ username: username, password: password })
                .then(res => {
                    if (res && res.ok && res.otp) {
                        setState({
                            ...state,
                            otpCode: '',
                            otpError: '',
                            otpResent: true
                        })
                    } else {
                        setState({
                            ...state,
                            otpError: 'Une erreur s\'est produite, veuillez réessayer',
                            otpResentLoading: false
                        })
                    }
                })
                .catch(err => {
                    setState({
                        ...state,
                        otpError: 'Une erreur s\'est produite, veuillez réessayer',
                        otpResentLoading: false
                    })
                })
        }
    }
    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
                    <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" style={{ backgroundImage: `url(${toAbsoluteUrl("/media/images/bg-5.jpg")})`, minWidth: 600 }}>
                        <div className="d-flex flex-row-fluid flex-column justify-content-between">
                            <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                                <img
                                    alt="Logo"
                                    className="max-h-70px"
                                    src={toAbsoluteUrl("/Sage.svg")}
                                />
                            </Link>
                            <div className="flex-column-fluid d-flex flex-column justify-content-center">
                                <h3 className="font-size-h1 mb-5 text-white">{global._trans("login", "welcome")}!</h3>
                                <p className="font-weight-lighter text-white opacity-80"></p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                            <div className="login-form login-signin" id="kt_login_signin_form" style={{ width: "400px" }}>
                                <div className="text-center mb-10">
                                    <h3 className="font-size-h1">{global._trans("auth", "Verification required")}</h3>
                                    {state.otpResent ?
                                        <p className="text-muted font-weight-bold">{global._trans("auth", "A verification code has been resent to this email address")} : <strong>{email}</strong></p>
                                        :
                                        state.otpResentLoading ?
                                        <p className='text-muted font-weight-bold'>{global._trans("auth", "Resending the verification code in progress...")}</p>
                                        :
                                        <p className='text-muted font-weight-bold'>{global._trans("auth", "A verification code has been sent to this email address")} : <strong>{email}</strong><br/>{global._trans("auth", "If you haven't received it")}, <a disabled={state.otpResentLoading} style={{fontWeight: '400', color: '#3699ff', cursor: 'pointer', transition: 'all 0.3s'}} onClick={handleResend}>{global._trans("auth", "click here to resend it.")}</a></p>
                                    }
                                    <br/>
                                    {state.otpError && <CostumAlert type='danger' errors={state.otpError} />}
                                </div>
                                <form className="form fv-plugins-bootstrap fv-plugins-framework" onSubmit={handleSubmit}>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label className="font-size-h6 font-weight-bolder text-dark">{global._trans("auth", "Verification code")}</label>
                                        <input placeholder={global._trans("auth", "Please enter the verification code")} onChange={onChange} type="number" className={`form-control form-control-solid h-auto py-5 px-6 `} name="otpCode" />
                                    </div>
                                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                                        <button id="kt_login_signin_submit" type="submit" className={`btn btn-primary font-weight-bold px-9 py-4 my-3`} disabled={!state.otpCode || state.otpLoading}>
                                            <span>{state.otpLoading ? global._trans("auth", "Validation in progress...") : global._trans("auth", "Validate")}</span>
                                        </button>
                                    </div>
                                    <Link to="/login" className="text-sm font-weight-bold">{global._trans("auth", "login")}</Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}