import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import './dist/ag-grid.css';
import './dist/ag-theme-balham.css';
import './dist/ag-grid-styles.css';
import { AG_GRID_LOCALE_FR } from './dist/ag_grid_fr';
import { Pagination, Modal } from 'antd'
import { ViewsList } from './dist/ViewsList';
import { getTableViews, createTableView, updateTableView, deleteTableView, selectTableView } from "../../../modules/Common/common.actions";
import MultiChoiceFilterComponent from '../Commons/MultiChoiceFilterComponent';
import moment from 'moment';

class AgGrid extends Component {
    constructor(props) {
        super(props)
        this.state = {
            localeText: AG_GRID_LOCALE_FR,
            defaultColDef: {
                resizable: true,
                filter: true,
                filterParams: {suppressAndOrCondition: true},
                sortable: true,
                comparator: () => { return 0 },
            },
            columnDefs: props.columns.map((columnDef) => {
                if (columnDef.filter === 'agDateColumnFilter') {
                  return {...columnDef, filterParams: {...columnDef.filterParams, comparator: (filterValue, cellValue) => this.customDateComparator(filterValue, cellValue, columnDef.field || columnDef.colId), debounceMs: 1000, suppressAndOrCondition: true}};
                }
                return columnDef;
            }),
            sort: {},
            filter: {},
            is_filtered: false,
            is_sorted: false,
            pagination: {
                page: props.pagination.current,
                page_size: props.pagination.pageSize,
            },
            views: [],
            is_views_open: false,
            views_loading: true,
            views_action: "list",
            columns: [],
            state: [],
            selected_view_id: 0
        }
        this.gridOptions = {
            onFirstDataRendered: () => {
                this.isGridDrawing = false
            }
        }
        getTableViews({key: this.props.views_key})
        .then((data) => {
            const view = data.find((view) => view.selected)?.id ?? 0
            this.setState({
                views: data,
                selected_view_id: view,
                views_loading: false
            })
            this.handleViewChange(this.state.selected_view_id, true)
        }).catch(() => {
            this.setState({views_loading: false})
            this.handleViewChange(this.state.selected_view_id, true)
        })
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridColumnApi.setColumnState(this.state.state)
        this.setState({
            columns: this.gridColumnApi.getAllColumns()
        })
        this.isGridDrawing = true
    };
    getRowStyle = (params) => {
        return {
          display: 'flex',
          alignItems: 'center',
        };
    }
    onFilterChanged = (event) => {
        if (!this.state.is_filtered) {
            this.onChange()
        }
        this.setState({is_filtered: false})
    }
    onSortChanged = (event) => {
        if (!this.state.is_sorted) {
            this.onChange()
        }
        this.setState({is_sorted: false})
    }
    onPaginationChanged = (page, page_size) => {
        this.state.pagination = {page: page, page_size: page_size}
        this.onChange()
    }
    onChange = () => {
        var filter_list = {}
        Object.keys(this.gridApi.getFilterModel()).forEach(key => {
            var item = this.gridApi.getFilterModel()[key]
            if (item.filterType == "date" && this.gridColumnApi.getColumn(key).colDef.format) {
                filter_list[key] = {
                    ...item,
                    format: this.gridColumnApi.getColumn(key).colDef.format
                }
            } else {
                filter_list[key] = item
            }
        })
        this.setState({
            filter: filter_list,
            sort: this.gridApi.getSortModel().map((sortItem) => {
                return {
                    ...sortItem,
                    date: this.gridColumnApi.getColumn(sortItem.colId).colDef.filter == "agDateColumnFilter",
                    format: this.gridColumnApi.getColumn(sortItem.colId).colDef.format ? this.gridColumnApi.getColumn(sortItem.colId).colDef.format : null
                };
            })
        }, () => {this.props.onChange(this.state.filter, this.state.sort, this.state.pagination)})
    }
    onLoadingDone = (first_load) => {
        if (!first_load) {
            const view = this.state
            if ((view.filter && Object.keys(view.filter).length > 0) || (Object.keys(this.gridApi.getFilterModel()).length > 0)) {
                this.setState({is_filtered: true}, () => {this.gridApi.setFilterModel(view.filter || {})}, () => {
                    this.setState({state: this.gridColumnApi.getColumnState()})
                })
            }
            if ((view.sort && Object.keys(view.sort).length > 0) || (Object.keys(this.gridApi.getSortModel()).length > 0)) {
                this.setState({is_sorted: true}, () => {this.gridApi.setSortModel(view.sort || {})}, () => {
                    this.setState({state: this.gridColumnApi.getColumnState()})
                })
            }
        }
    }
    customDateComparator = (filterValue, cellValue, colId) => {
        let filter = this.gridApi.getFilterModel()[colId]
        if (filter.type == "inRange") {
            if (moment(filterValue).format("YYYY-MM-DD") == filter.dateFrom) {
                return 1
            }
            return -1
        }
        let mapping = {
            "equals": 0,
            "greaterThan": 1,
            "lessThan": -1,
            "notEqual": -1
        }
        return mapping[filter.type]
    }
    viewsList = (visible) => {
        if (visible) {
            this.setState({ views_action: "list" })
            if (this.childComponentRef?.state) {
                this.childComponentRef.state.selected_view_id = this.state.views.find((view) => view.selected)?.id ?? null
            }
        }
        this.setState({ is_views_open: visible })
    }
    fetchTableViews(id) {
        this.setState({views_loading: true})
        getTableViews({key: this.props.views_key})
        .then((data) => {
            this.setState({
                views: data
            }, () => {
                if (id) this.handleViewChange(id)
            })
        })
        .finally(() => {
            this.setState({views_loading: false})
            this.handleViewsSwitch("list")
        })
    }
    handleViewsSwitch = (action) => {
        this.setState({views_action: action})
    }
    handleViewUpdate = (data) => {
        const { view_id, view_name, view_columns, view_selected } = data;
        const updatedColumnState = this.gridColumnApi.getAllColumns().map(column => {
            return { colId: column.getColId(), hide: !view_columns.includes(column.getColId()) };
        });
        this.setState({views_loading: true})
        updateTableView({id: view_id, name: view_name, state: updatedColumnState, selected: view_selected})
        .then(() => {
            if (view_selected) {
                this.fetchTableViews(view_id)
            } else {
                this.fetchTableViews()
            }
        })
        .catch(() => this.setState({views_loading: false}))
    }
    handleViewDelete = (id, name) => {
        this.setState({
            is_views_open: false
        })
        Modal.confirm({
            title: global._trans('common', 'Delete the view') + " '" + name + "'",
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('common', 'Are you sure to delete this view ?'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            size: 'md',
            centered: true,
            onOk: () => {
                this.setState({
                    is_views_open: true,
                    views_loading: true
                })
                deleteTableView({id: id}).finally(() => this.fetchTableViews())
            },
            onCancel: () => {
                this.setState({
                    is_views_open: true
                })
            },
        });
    }
    handleViewCreate = (data) => {
        const { view_name, view_columns } = data;
        const updatedColumnState = this.gridColumnApi.getAllColumns().map(column => {
            return { colId: column.getColId(), hide: !view_columns.includes(column.getColId()) };
        });
        this.setState({views_loading: true})
        createTableView({key: this.props.views_key, name: view_name, selected: false, state: updatedColumnState})
        .then(() => this.fetchTableViews())
        .catch(() => this.setState({views_loading: false}))
    }
    handleViewChange = (id, first_load = false) => {
        if (id == 0) {
            this.gridColumnApi.resetColumnState();
            this.gridApi.sizeColumnsToFit({});
            this.props.onViewSaved(0)
            if (!first_load) {
                this.setState({filter: {}, sort: {}}, () => {this.props.onChange(this.state.filter, this.state.sort, this.state.pagination)})
            }
        } else {
            const view = this.state.views.find((view) => view.id === id)
            if (first_load) {
                if (view.filter && Object.keys(view.filter).length > 0) {
                    this.setState({is_filtered: true}, () => {this.gridApi.setFilterModel(view.filter)})
                }
                if (view.sort && view.sort.length > 0) {
                    this.setState({is_sorted: true}, () => {this.gridApi.setSortModel(view.sort)})
                }
            } else {
                this.setState({filter: view.filter, sort: view.sort}, () => {this.props.onChange(this.state.filter, this.state.sort, this.state.pagination)})
            }
            if (!this.isGridDrawing) this.gridColumnApi.setColumnState(view.state)
            else setTimeout(() => {
                this.gridColumnApi.setColumnState(view.state)
            }, 1000);
            
            // this.gridColumnApi.setColumnState(view.state)
            this.setState({state: this.gridColumnApi.getColumnState()})
            this.props.onViewSaved(1)
        }
    }
    handleViewsClose = (id) => {
        this.viewsList(false)
        if (id !== undefined && this.state.selected_view_id !== id) {
            this.setState({selected_view_id: id})
            selectTableView({key: this.props.views_key, id: id})
            .then(() => this.fetchTableViews())
        }
    }
    saveCurrentView = () => {
        const view = this.state.views.find(view => view.id == this.state.selected_view_id)
        updateTableView({id: view.id, state: this.gridColumnApi.getColumnState(), filter: this.state.filter, sort: this.state.sort})
        .then(() => {
            this.fetchTableViews()
            this.props.onViewSaved(1)
        })
        .catch(() => this.props.onViewSaved(1))
    }
    render() {
        const columnTypes = {
            statusColumn: {
                filter: "multiChoiceFilter",
                filterParams: { 
                    suppressFilter: true, 
                    choices: {
                        'VALIDATED': global._trans("sites", `status_VALIDATED`),
                        'NOT_VALIDATED': global._trans("sites", `status_NOT_VALIDATED`),
                        'NOT_PUBLISHED': global._trans("sites", `status_NOT_PUBLISHED`)
                    }
                },
                valueFormatter: (params) => global._trans("sites", `status_${params.value}`)
            },
            stateColumn: {
                headerName: global._trans('common', 'state'),
                field: 'state',
                filter: "multiChoiceFilter",
                filterParams: { 
                    suppressFilter: true,
                    choices: {"A": "Actif", "C": "Fermé", "": "Indéfini"} 
                },
                cellRendererFramework: params => {
                    return <div className={`symbol d-flex align-items-center`}>
                        <i className={`state-badge symbol-badge symbol-badge-xl bg-${params.value ? (params.value == "A" ? "success" : "danger") : "dark"}`}></i><span className={`state-bade-text`}>{params.value ? (params.value == "A" ? "Actif" : "Fermé") : "Indéfini"}</span>
                    </div>
                }
            }
        };
        const frameworkComponents = {
            multiChoiceFilter: MultiChoiceFilterComponent
        };
        return (
            <div>
                <ViewsList 
                    isOpen={this.state.is_views_open} 
                    views={this.state.views} 
                    onSwitch={this.handleViewsSwitch} 
                    onCreate={this.handleViewCreate} 
                    onUpdate={this.handleViewUpdate} 
                    onDelete={this.handleViewDelete} 
                    onClose={this.handleViewsClose} 
                    onChange={this.handleViewChange}
                    loading={this.state.views_loading}
                    action={this.state.views_action}
                    columns={this.state.columns}
                    ref={ref => this.childComponentRef = ref}
                />
                <div className="ag-theme-balham" style={{height: 650, width: "100%"}}>
                    <AgGridReact
                        rowData={this.props.rows || []}
                        defaultColDef={this.state.defaultColDef}
                        columnDefs={this.state.columnDefs || []}
                        defaultFilterOptions={this.state.defaultFilterOptions}
                        onGridReady={this.onGridReady}
                        sideBar={{ toolPanels: ['columns'] }}
                        rowHeight={40}
                        getRowStyle={this.getRowStyle}
                        localeText={this.state.localeText}
                        onSortChanged={this.onSortChanged}
                        onFilterChanged={this.onFilterChanged}
                        onComponentStateChanged={this.onComponentStateChanged}
                        columnTypes={columnTypes}
                        frameworkComponents={frameworkComponents}
                        gridOptions={this.gridOptions}
                        >
                    </AgGridReact>
                </div>
                {this.props.pagination && <div className="d-flex justify-content-end mt-3">
                    <Pagination {...this.props.pagination} showSizeChanger={true} onChange={(page, page_size) => this.onPaginationChanged(page, page_size)} />
                </div>}
            </div>
        )
    }
}

function setText(selector, text) {
    let node = document.querySelector(selector)
    if (node) node.innerHTML = text;
}

export { AgGrid }