import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class Contract extends Component {
    render() {
        return (
            <CommonComponent keyName="contract" titleSetting={global._trans('contracts', 'autherActivContract')} contract={this.props.contract} />
        )
    }
}
function mapState(state) {
    const { listEnums: { contract } } = state.enums
    return { contract }
}
const connectedClass = connect(mapState, null)(Contract)
export { connectedClass as Contract }