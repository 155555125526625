import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ListCommunes } from './ListCommunes'
import { CreateCommune } from './CreateCommune'
import { ViewCommune } from './ViewCommune'
class CommunePage extends Component {
    render() {
        return (
            <Switch>
                <Route
                    path="/client/communities/communes/view/:idCommune"
                    component={ViewCommune}
                />
                <Route
                    path="/client/communities/communes/:idCreate"
                    component={CreateCommune}
                />
                <Route
                    path="/client/communities/communes"
                    component={ListCommunes}
                />

            </Switch>
        )
    }
}
export { CommunePage }