import React, { useMemo, useState } from "react";
import {useSelector} from 'react-redux'
import objectPath from "object-path";
import {Button} from 'react-bootstrap'
import { useHtmlClassService } from "../_core/MetronicLayout";
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Alert } from 'antd';
import { ScrollTop } from "./extras/ScrollTop";
import {LayoutInit} from "./LayoutInit";
import {UpdatePassword} from './extras/dropdowns/password/UpdatePassword'

export function Layout({ children }) {
    const { user } = useSelector((state) => state.auth);
    const [showPassword,setShowPassword]=useState(false)
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended")
        };
    }, [uiService]);
    const handlePassword = ()=>{
        setShowPassword(!showPassword)
    }
    return (
        <>
            <HeaderMobile />
            <div id="kt_root_app" className="d-flex flex-column flex-root">
                <div className="d-flex flex-row flex-column-fluid page">
                    <Aside />
                    <div className="d-flex flex-column flex-row-fluid wrapper" style={{ paddingTop: "50px" }} id="kt_wrapper">
                        <Header />
                        <div
                            id="kt_content"
                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                        >
                            {/*<SubHeader />*/}
                            {!layoutProps.contentExtended && (
                                <div className="d-flex flex-column-fluid">
                                    <div className={layoutProps.contentContainerClasses} style={{maxWidth:"100%"}}>
                                        {children}
                                    </div>
                                </div>
                            )}
                            {layoutProps.contentExtended && { children }}
                        </div>
                    </div>
                </div>
            </div>
            <ScrollTop />
            {user.init_password && <div className="fixed-bottom">
                <Alert
                    message={<><span className="text-warning">{global._trans("auth","actionRequired")} : </span><strong>{global._trans("common","messageSec")}</strong></>}
                    type="warning"
                    showIcon
                    action={<Button variant="warning" className="mr-2" onClick={handlePassword}>{global._trans('auth', 'changePassword')}</Button>}
                />
            </div>}
            {showPassword && <UpdatePassword show={true} userAuth={user} onHide={handlePassword}/>}
            <ScrollTop/>
            <LayoutInit />
        </>
    )
}
