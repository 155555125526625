import { createSlice } from "@reduxjs/toolkit";
const queryString = require('query-string');
const initialState = {
    siteErrors: null,
    listSites: {},
    currentQuery:"",
    site:null,
    detailShare:null,
    groupsList:null,
    typeEquipement:null,
    traitementFumee:null,
    modeGestion:null,
    amobe:null
};

export const siteSlice = createSlice({
    name: "sites",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.siteErrors = action.payload.errors;
        },
        siteCreated: (state) => {
            state.siteErrors = state.siteErrors === false ? null : false
        },
        sitesFetched: (state, action) => {
            const { listSites ,currentQuery} = action.payload
            state.siteErrors = null
            state.currentQuery='?'+queryString.stringify(currentQuery)
            state.listSites = listSites
        },
        siteFetched: (state, action) => {
            const { Site } = action.payload
            state.siteErrors = null
            state.site = Site
        },
        sitesDeleted: (state, action) => {
            const { asyncData,sites} = action.payload
            if (asyncData) {
                let prevList = state.listSites
                prevList.data = prevList.data.filter(site => sites.indexOf(site.id_site) === -1)
                state.listSites = prevList
            }
            state.siteErrors = state.siteErrors === false ? null : false
        },
        detailFetched:(state, action) => {
            const { detail } = action.payload
            state.detailShare = detail
        },
        groupsListed:(state, action) => {
            state.groupsList = action.payload.groupsList
            state.traitementFumee = action.payload.traitementFumee
            state.typeEquipement = action.payload.typeEquipement
            state.modeGestion = action.payload.modeGestion
            state.amobe = action.payload.amobe
        },
    }
});