import React, { useState, useRef } from 'react';
import { Form } from 'react-bootstrap'
/* global google */

export function AdressPicker(props) {
    const [search, setSearch] = useState(props.value || '');
    const ref = useRef();
    const componentForm = [
        'location',
        'locality',
        'administrative_area_level_1',
        'country',
        'postal_code',
    ];
    const addressNameFormat = {
        'street_number': 'short_name',
        'route': 'long_name',
        'locality': 'long_name',
        'administrative_area_level_1': 'short_name',
        'country': 'long_name',
        'postal_code': 'short_name',
    };
    const getAddressComp = function (type, place) {
        for (const component of place.address_components) {
            if (component.types[0] === type) {
                return component[addressNameFormat[type]];
            }
        }
        return '';
    };
    const useOnClickOutside = (e) => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            document.removeEventListener("mousedown", useOnClickOutside);
        } else if (!ref) {
            document.removeEventListener("mousedown", useOnClickOutside);
        }
    }
    const handleSearch = (e) => {
        const { value, name } = e.target
        setSearch(value)
        const autocompleteInput = document.getElementById('location-input')
        const autocomplete = new google.maps.places.Autocomplete(autocompleteInput, {
            fields: ["address_components", "geometry", "name"],
            types: ["address"],
        });
        autocomplete.addListener('place_changed', function () {
            const place = autocomplete.getPlace();
            let chossedValue = {}
            for (const component of componentForm) {
                if (component !== 'location') {
                    const ff = getAddressComp(component, place);
                    if (component === 'locality') {
                        chossedValue['city'] = ff
                    }
                    if (component === 'postal_code') {
                        chossedValue['postcode'] = ff
                    }
                    if (component === 'country') {
                        chossedValue['country'] = ff
                    }
                }
            }
            const lat = place.geometry.location.lat()
            const lng = place.geometry.location.lng()
            chossedValue['address'] = place.name
            chossedValue['lat'] = lat
            chossedValue['lang'] = lng

            setSearch(place.name)
            // setModalOpen(true)
            props.onChange(chossedValue)
        })
    }
    React.useEffect(() => {
        if (props) {
            setSearch(props.value)      
        }
    }, [props]);
    return (
        <div className="position-relative" ref={ref}>
            <Form.Control type='text' placeholder={global._trans('common', 'searchAdress')} id='location-input' className="mb-1" value={search} name="search" onChange={handleSearch} />
        </div>
    )
}