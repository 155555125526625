import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    count: 0,
    notifications: [],
    errors: null
};

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.errors = action.payload.errors;
        },
        notificationsFetched: (state, action) => {
            const { count, notifications } = action.payload
            state.notifications = notifications;
            state.count = count;
        },
        notificationReaded: (state, action) => {
            const { count, notifications } = action.payload
            state.notifications = notifications;
            state.count = count;
        },
        notificationReset: (state, action) => {
            const { count, notifications } = action.payload
            state.notifications = notifications;
            state.count = count;
        },
    }
});