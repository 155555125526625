import React, { useState } from 'react';
import { Table, Input, Select, Form } from 'antd';
import { InlineBottunSvg } from '.'
const EditableCell = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    type,
    options,
    render,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                >
                    {
                        type == 'options' ? (<Select
                            className="inputSizeSelect"
                            placeholder={global._trans('common', 'region_code')}
                            style={{ width: '100%' }}
                            options={options}
                        />) : (<Input />)
                    }
                </Form.Item>
            ) : (
                type == 'options' ? render(record) : children
            )}
        </td>
    );
};

const TableEditableAction = ({ dataList, pagination, deleteRecord, loading, saveData, handleTableChange, columnsList, dataKey, scrollY }) => {

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record[dataKey] === editingKey;
    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record[dataKey]);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...dataList];
            const index = newData.findIndex((item) => key === item[dataKey]);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                saveData(newData, { ...item, ...row });
                setEditingKey('');
            } else {
                newData.push(row);
                saveData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        ...columnsList,
        {
            title: global._trans('common', 'action'), dataIndex: dataKey, render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <InlineBottunSvg
                        bottuns={[
                            { iconPath: "/media/svg/icons/Costum/Save.svg", type: "success", action: () => save(record[dataKey]) },
                            { iconPath: "/media/svg/icons/Costum/Close.svg", type: "primary", action: cancel }
                        ]}
                    />
                ) : (
                    <InlineBottunSvg
                        bottuns={[
                            { iconPath: "/media/svg/icons/Costum/Edit.svg", type: "primary", action: () => edit(record) },
                            { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => deleteRecord(record[dataKey]) }
                        ]}
                    />
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                type: col.type,
                options: col.options,
                render: () => col.render(record),
                editing: isEditing(record)
            }),
        };
    });
    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                dataSource={dataList}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={pagination}
                rowKey={dataKey}
                scroll={{ y: scrollY || 500 }}
                loading={loading}
                onChange={handleTableChange}
            />
        </Form>
    );
};
export { TableEditableAction }