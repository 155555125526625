import React from 'react'
import { Table } from 'antd';
class CostumTable extends React.Component {
  constructor(props) {
    super(props);
    this.rowSelection = {
    }
  }
  render() {
    const { columns, data, IterableProps } = this.props;

    return (
      <div className="py-2">
        <Table {...IterableProps} columns={columns} dataSource={data} rowClassName='costumMaxSize' scroll={{ x: 'max-content', y: 500, }} />
      </div>
    );
  }
}
export { CostumTable }