import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom'
import { Button, Form, Col } from "react-bootstrap";
import { Select, Radio, Switch, notification, Spin } from 'antd';
import { CostumAlert, PhonePicker } from '../Commons'
import { createGestionaire } from "../../../modules/Gestionaires/gestionaire.actions";
class CreateGestionaire extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: true,
            genre: null,
            nom: '',
            prenom: '',
            telephone: '',
            mobile: '',
            email: '',
            societe: null,
            loadingCreate: false,
            loading: false,
            errors: null,
            showAlert: false,
        }
        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onSwichChange = this.onSwichChange.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loadingCreate) {
            this.setState({
                loadingCreate: false,
                errors: nextProps.errorsGestionaire,
                showAlert: true
            })
        }
    }
    handleChangeEvent(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    onSwichChange(checked) {
        this.setState({
            status: checked
        })
    }
    showErrors() {
        if (this.state.showAlert) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                notification.success({
                    message: global._trans('managers', 'titleMessageCreate'),
                    description: global._trans('managers', 'bodyMessageCreate'),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}
                })
                return <Redirect to="/manager" />
            }
        }
    }
    handleChangeName(name, value) {
        this.setState({
            [name]: value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        let gestionnaire = {
            status: this.state.status,
            genre: this.state.genre,
            nom: this.state.nom,
            prenom: this.state.prenom,
            telephone: this.state.telephone,
            mobile: this.state.mobile,
            email: this.state.email,
            societe: this.state.societe,
        }
        this.props.createGestionaire(gestionnaire)
        this.setState({
            loadingCreate: true,
            showAlert: false
        })
    }
    render() {
        return (
            <div className="card card-custom">
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('managers', 'createManager')}</span>
                    </h3>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingCreate}>
                    <div className="card-body py-4">
                        {this.showErrors()}
                        <div className="form px-5 pb-5">
                            <Form method="post" onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('contacts', 'sexe')}*</Form.Label>
                                        <div className="d-flex align-items-center my-1">
                                            <Radio.Group value={this.state.genre} name="genre" onChange={this.handleChangeEvent}>
                                                <Radio.Button value="MME">MME</Radio.Button>
                                                <Radio.Button value="MR">MR</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('contacts', 'status')}*</Form.Label>
                                        <div className="d-flex align-items-center my-3">
                                            <strong>{global._trans('contacts', 'inactive')}</strong>
                                            <Switch checked={this.state.status} className="mx-3" onChange={this.onSwichChange} />
                                            <strong>{global._trans('contacts', 'active')}</strong>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'firstName')}*</Form.Label>
                                        <Form.Control type="text" name="nom" value={this.state.nom} placeholder={global._trans('common', 'firstName')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'lastName')}*</Form.Label>
                                        <Form.Control type="text" name="prenom" value={this.state.prenom} placeholder={global._trans('common', 'lastName')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'mobile')}*</Form.Label>
                                        <PhonePicker
                                            value={this.state.mobile}
                                            onChange={(value) => this.handleChangeName("mobile", value)} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'phone')}</Form.Label>
                                        <PhonePicker
                                            value={this.state.telephone}
                                            onChange={(value) => this.handleChangeName("telephone", value)} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'email')}*</Form.Label>
                                        <Form.Control type="email" name="email" value={this.state.email} placeholder={global._trans('common', 'email')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('managers', 'contractList')}*</Form.Label>
                                        <Select
                                            showSearch
                                            showArrow
                                            className="inputSizeSelect"
                                            placeholder={global._trans('managers', 'selectContract')}
                                            style={{ width: '100%' }}
                                            optionFilterProp="label"
                                            value={this.state.societe}
                                            options={[{ label: "SAGE ENGINEERING", value: "Sage_engineering" }, { label: "SAGE EXPERT", value: "Sage_expert" }, { label: "SAGE INDUSTRY", value: "Sage_industry" }]}
                                            onChange={(selectedValue) => this.handleChangeName("societe", selectedValue)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <div className="d-flex justify-content-end mt-4">
                                    <div className="card-toolbar mx-4">
                                        <Link to="/manager" className="btn btn-secondary" >{global._trans('common', 'cancelBtn')}</Link>
                                    </div>
                                    <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{global._trans('managers', 'textSubmit')}</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}
function mapState(state) {
    const { errorsGestionaire } = state.gestionaire
    return { errorsGestionaire }
}
const connectedClass = connect(mapState, { createGestionaire })(CreateGestionaire)
export { connectedClass as CreateGestionaire }