import React from 'react'
import { Checkbox } from 'antd';
import { ListGroup } from "react-bootstrap";
export const SocieteInformation = (props) => {
    return (
        <div className="row mx-0">
            <ListGroup.Item>
                <Checkbox name="company_groupe" checked={props.checkedColumns.company_groupe} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('companies', 'groupe')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_denomination" checked={props.checkedColumns.company_denomination} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'denomenation')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_usual_denomination" checked={props.checkedColumns.company_usual_denomination} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'usual_denomination')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_serin" checked={props.checkedColumns.company_serin} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('companies', 'serin')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_sinoe" checked={props.checkedColumns.company_sinoe} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('clientsEpic', 'sinoe')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_nature_juridique" checked={props.checkedColumns.company_nature_juridique} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'natureJuridque')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_codeape" checked={props.checkedColumns.company_codeape} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('companies', 'codeape')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_siteInternet" checked={props.checkedColumns.company_siteInternet} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'webSite')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_telephoneStandard" checked={props.checkedColumns.company_telephoneStandard} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'standrPhone')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_effectifs" checked={props.checkedColumns.company_effectifs} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('companies', 'nombreSalarie')}</Checkbox>
            </ListGroup.Item>

            <ListGroup.Item>
                <Checkbox name="company_adresse" checked={props.checkedColumns.company_adresse} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'address')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_city" checked={props.checkedColumns.company_city} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'city')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_country" checked={props.checkedColumns.company_country} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'country')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="company_postcode" checked={props.checkedColumns.company_postcode} onChange={props.handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'postcode')}</Checkbox>
            </ListGroup.Item>
        </div>
    )
}