import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Modal, Spin } from 'antd'
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
import { InlineBottunSvg, SageTable, FilterSearch } from '../Commons'
import { NavLink } from 'react-router-dom'
import { fetchGEDFiles, fetchGEDFilesPost, deleteGEDFiles, updateGEDFile } from '../../../modules/GED/contract.actions';
import moment from 'moment'
import { entityName } from '../Commons';
import { FilterPicker } from '../MapSites/FilterPicker';
import { AgGrid } from '../Commons';
import { message } from 'antd';
// import { FilterSites } from './utils'
const queryString = require('query-string');

class ListGED extends Component {
    constructor(props) {
        super(props)
        let asyncLoad = props.currentQuery !== props.history.location.search || !props.currentQuery
        this.state = {
            first_load: true,
            loading_update: false,
            listModes: {},
            loading: true,
            total: 0,
            page: 1,
            page_size: 20,
            list: [],
            loadingDelete: false,
            columnDefs: [
                {headerName: "#", field: 'id', filter: 'agNumberColumnFilter'},
                {headerName: global._trans('ged', 'name'), field: 'name', cellRendererFramework: params => {
                    return <a href={params.data.path} target='_blank'>{params.data.name}</a>
                }},
                {headerName: global._trans("ged", "category"), field: 'category.value_enum', filter: "multiChoiceFilter", filterParams: { 
                    suppressFilter: true, 
                    choices: this.state?.listModes ?? {}
                }},
                {headerName: global._trans("ged", "attached_to"), field: 'type', valueFormatter: params => global._trans('ged', params.value)},
                {headerName: global._trans("ged", "entity"), field: 'entity.full_name'},
                {headerName: global._trans("ged", "date"), field: 'date'},
                {headerName: global._trans("ged", "created_at"), field: 'created_at', format: 'Y-m-d\\TH:i:s.u\\Z', filter: 'agDateColumnFilter', valueFormatter: params => moment(params.value).format("DD/MM/YYYY")},
                {headerName: "", minWidth: 150, colId: "Actions", sortable: false, filter: false, cellRendererFramework: params => {
                    return <div className="d-flex">
                        <button onClick={() => this.handleDeleteGEDFile(params.data.id)} role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-danger"}>
                            <span className={"svg-icon svg-icon-md svg-icon-danger"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG></span>
                        </button>
                        <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"} onClick={() => this.handleArchiveDocument(params.data, !params.data.shareable)}>
                            <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl((params.data.shareable ? "/media/svg/icons/Costum/Pause.svg" : "/media/svg/icons/Costum/Play.svg"))}></SVG></span>
                        </button>
                        <a href={'/ged/view/' + params.data.id}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"} to={'/ged/view/' + params.data.id}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/View.svg")}></SVG></span>
                            </button>
                        </a>
                    </div>
                }}
            ],
            view_save: 0,

            // selectedRows: [],
            // listSites: [],
            // filters: {
            //     page: 1,
            //     pageSize: 10,
            //     source: 'sites'
            // },
            // loadingSites: asyncLoad,
            // loadingDelete: false,
            importData: false
        }
        props.fetchGEDFilesPost({pagination: {page: this.state.page, page_size: this.state.page_size}, first_load: true})

        this.filterTab = {}
        this.toReset = ['form_search_list', 'form_search_input']
        this.deleteSelected = this.deleteSelected.bind(this)
        this.handleDeleteGEDFile = this.handleDeleteGEDFile.bind(this)

        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
        this.handleChangeSearch = this.handleChangeSearch.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.handleResetSearch = this.handleResetSearch.bind(this)
        this.handleSorter = this.handleSorter.bind(this)
        this.handlePagination = this.handlePagination.bind(this)
        this.handleArchiveDocument = this.handleArchiveDocument.bind(this)
    }
    componentDidMount() {
        // this.props.fetchGEDFiles({ page: 1, per_page: 10 });
    }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        if (this.props.fileCategory.length > 0) {
            this.state.columnDefs.forEach(item => {
                if (item.field == "category.value_enum") {
                    item.filterParams.choices = this.props.fileCategory?.reduce((acc, item) => {acc[item.label] = item.label;return acc;}, {}) ?? {}
                }
            })
        }
        if (this.state.loading && nextProps.listGEDFiles && nextProps.listGEDFiles.data) {
            this.setState({
                list: nextProps.listGEDFiles.data,
                total: nextProps.listGEDFiles.total,
                page: nextProps.listGEDFiles.current_page,
                page_size: nextProps.listGEDFiles.per_page,
                loading: false,
            }, () => {
                this.childComponentRef.onLoadingDone(this.state.first_load)
                if (this.state.first_load) this.setState({first_load: false})
            })
        }
        if (this.state.loading_update && nextProps.listGEDFiles && nextProps.listGEDFiles.data) {
            this.setState({
                loading_update: false,
                list: nextProps.listGEDFiles.data
            })
        }
    }
    handleTypeFilter(checked) {
        this.setState({
            filters: {
                ...this.state.filters,
                typeFilter: checked ? "inter" : null
            }
        })
    }
    handleDeleteGEDFile(idGEDFile) {
        Modal.confirm({
            title: global._trans('sites', 'Etes vous sûr ? #') + idGEDFile,
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('sites', 'Vous etes sur le point de supprimer un fichier'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loading: true
                })
                this.props.deleteGEDFiles({ files: [idGEDFile] }).then((ok) => {
                    if (!ok) message.error(global._trans('settings', 'cantDelete'));
                    this.setState({
                        loading: false
                    })
                })
            }
        });
    }
    deleteSelected() {
        Modal.confirm({
            title: global._trans('common', 'deleteAllTitle') + " #",
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('sites', 'deleteBody'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadingDelete: true
                })
                this.props.deleteSites({ sites: this.state.selectedRows })
            }
        });
    }
    handleSelectRow(selectedRows) {
        this.setState({
            selectedRows: selectedRows
        })
    }
    handleTableChange = (filter, sort, pagination) => {
        this.setState({
            loading: true,
            list: []
        }, () => this.props.fetchGEDFilesPost({sort, filter, pagination}));
    }

    handleSorter(sorter) {
        this.setState((state) => ({
            filters: { ...state.filters, sort: sorter.value, sorter: sorter.current }
        }), () => this.props.fetchGEDFiles({ ...this.state.filters, page: 1 }))
    }

    handleSubmitSearch(e) {
        e.preventDefault()
        this.props.fetchGEDFiles({ ...this.state.filters, page: 1 });
    }
    handleChangeSearch(e) {
        const { name, value } = e.target
        this.setState((state) => ({
            filters: { ...state.filters, search: value }
        }));
    }
    handleResetSearch() {
        let resteData = {
            page: this.state.filters.page,
            pageSize: this.state.filters.pageSize,
            source: this.state.filters.source,
        }
        this.state.filters = resteData
        this.filterTab = {}
        this.toReset.map(item => {
            let formReset = document.getElementById(item)
            if (formReset) formReset.reset();
        })
        this.handleFetchData(resteData)
    }
    showReset() {
        const keys = Object.entries(this.state.filters).filter(item => item[1] && (item[0] !== 'typeFilter'));
        return keys.length > 3
    }
    handleImportExcel = (show) => {
        this.setState({
            importData: show
        })
    }
    handlePagination({ current, pageSize }) {
        this.setState((state) => ({
            filters: { ...state.filters, page: current }
        }), () => this.props.fetchGEDFiles(this.state.filters));
    }

    handleArchiveDocument(document, shareable) {
        this.setState({
            list: [],
            loading_update: true
        }, () => {
            this.props.updateGEDFile({ id: document.id, shareable })
        })
    }
    handleSaveView = () => {
        this.setState({
            view_save: 2
        }, () => {
            this.childComponentRef.saveCurrentView()
        })
    }
    handleViewSaved = (value) => {
        this.setState({
            view_save: value
        })
    }
    render() {
        return (
            <div className={`card card-custom`}>
                <div className="card-header header-center py-1">
                    <h3 className="card-title">
                        <span className="card-label font-weight-bolder text-dark">
                            {global._trans("common", "gedTitle")} ({this.props.listGEDFiles.total} éléments trouvés)
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        {this.state.view_save > 0 && <button title={"Enregistrer l'état de la vue (filtre, ordre, disposition et taille des colonnes)"} disabled={this.state.view_save == 2} className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.handleSaveView()}>
                            <span className="svg-icon svg-icon-md" style={{margin: 0}}>
                                <SVG hidden={this.state.view_save != 1} src={toAbsoluteUrl("/media/svg/icons/Costum/Save.svg")} className="h-50 align-self-center"></SVG>
                                <SVG hidden={this.state.view_save != 2} src={toAbsoluteUrl("/media/svg/icons/Costum/Loading.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                        </button>}
                        <button className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.childComponentRef.viewsList(true)}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Columns.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Views management')}
                        </button>
                        <NavLink to="/ged/new" className="btn btn-primary font-weight-bolder font-size-sm">
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            Télécharger un fichier
                        </NavLink>
                    </div>
                </div>
                <Spin spinning={this.state.loading || this.state.loading_update}>
                    <div className="card-body py-4">
                        <AgGrid
                                rows={this.state.list}
                                columns={this.state.columnDefs}
                                pagination={{total: this.state.total, pageSize: this.state.page_size, current: this.state.page}}
                                onChange={this.handleTableChange}
                                loading={this.state.loading}
                                ref={ref => this.childComponentRef = ref}
                                views_key={"ged"}
                                onViewSaved={this.handleViewSaved}
                            />
                    </div>
                </Spin>
            </div>
        )
    }
}

function mapState(state) {
    const { listEnums: { fileCategory } } = state.enums;
    let { listGEDFiles, loading } = state.ged;
    return { listGEDFiles, loading, fileCategory };
}

const connectedClass = connect(mapState, { fetchGEDFiles, fetchGEDFilesPost, deleteGEDFiles, updateGEDFile })(ListGED)
export { connectedClass as ListGED }
