import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class CodeApe extends Component {
    render() {
        return (
            <CommonComponent keyName="codeape" titleSetting={global._trans('companies', 'codeape')} codeape={this.props.codeape} />
        )
    }
}
function mapState(state) {
    const { listEnums: { codeape } } = state.enums
    return { codeape }
}
const connectedClass = connect(mapState, null)(CodeApe)
export { connectedClass as CodeApe }