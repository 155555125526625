import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { SageTable } from './SageTable'
import { fetchCommunes } from '../../../modules/Collectivities/collectivitie.actions'
class CommuneSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRow: [],
            searchValue: "",
            listsData: [],
            loading: true,
        }
        this.columes = [
            { title: "ID", dataIndex: "id_commune", fixed: 'left', ellipsis: true, },
            { title: "Nom Commune", dataIndex: "nomCommune", ellipsis: true, },
            { title: "Adresse", dataIndex: "adresse", ellipsis: true, },
            { title: "Nombre habitants", dataIndex: "nombreHabitant", ellipsis: true, }
        ]
        props.fetchCommunes({ pageSize: 15 })
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.validSelect = this.validSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading && nextProps.listCommunes.data) {
            this.setState({
                loading: false,
                listsData: nextProps.listCommunes.data
            })
        }
    }
    handleSelectRow(selectedRowKeys) {
        this.setState({
            selectedRow: selectedRowKeys
        })
    }
    handleSearch(e) {
        let value = e.target.value
        this.props.fetchCommunes({ pageSize: 15, address: value, nomCommune: value, list: this.state.selectedRow.join('.') })
        this.setState({
            searchValue: value,
            loading: true
        })
    }
    validSelect() {
        let elem = this.state.listsData.filter(obj => this.state.selectedRow.indexOf(obj.id_commune) > -1)
        this.props.validSelection(elem)
    }
    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} aria-labelledby="example-modal-sizes-title-lg">
                <div className="px-5 pb-5 pt-2">
                    <Form.Control type="text" name="searchvalue" value={this.state.searchValue} placeholder={global._trans('clientsCommune', 'CommuneSelector')} onChange={this.handleSearch} />
                    <SageTable
                        columns={this.columes}
                        data={this.state.listsData}
                        style={{ height: 550 }}
                        IterableProps={{
                            rowSelection: {
                                selectedRowKeys: this.state.selectedRow,
                                onChange: this.handleSelectRow
                            },
                            emptyText: global._trans('common', 'emptyData'),
                            loading: this.state.loading,
                            rowKey: 'id_commune',
                            pagination: false
                        }}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="primary" onClick={this.validSelect}>{global._trans('common', 'validSelect')}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
function mapState(state) {
    const { listCommunes } = state.collectivitie
    return { listCommunes }
}
const connectedClass = connect(mapState, { fetchCommunes })(CommuneSelector)
export { connectedClass as CommuneSelector }