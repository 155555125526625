import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom'
import { Form, Col, Card, ListGroup, Button } from "react-bootstrap";
import { notification, DatePicker, Spin, Upload } from 'antd';
import { CostumAlert, PersoneSelector, SiteSelector, InlineBottunSvg, FileUploader, EntitySelector, EntityFiles } from '../Commons'

import { createGEDFile } from "../../../modules/GED/contract.actions";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_sage/_helpers";

import { SageTable } from '../Commons/SageTable'

import { Select } from 'antd';
import moment from 'moment';
class CreateGEDFile extends Component {
    constructor(props) {
        super(props)
        let action = props.match.params.idCreate === "new"
        this.state = {
            showForSite: false,

            file: null,
            fileCategory: '',
            shareable: true,
            entities: [],
            date: null,

            loading: false,
            fetching: false,
            alerts: false,
            errors: null
        }
        this.isNewItem = action
        // if (!action) {
        //     props.editContract(props.match.params.idCreate)
        // }
        this.handleHideModal = this.handleHideModal.bind(this)
        this.setSelection = this.setSelection.bind(this)
        this.handleName = this.handleName.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.deleteAttachement = this.deleteAttachement.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        // if (this.state.loading) {
        //     this.setState({
        //         loading: false,
        //         alerts: true,
        //         errors: nextProps.contractErrors
        //     })
        // }
        // if (this.state.fetching && (nextProps.contrat || nextProps.contractErrors)) {
        //     this.isNewItem = nextProps.contractErrors ? true : false
        //     this.setState({
        //         ...nextProps.contrat,
        //         communes: [...nextProps.contrat.communes],
        //         fetching: false
        //     })
        // }
    }
    handleName(name, value) {
        return this.setState((state) => ({
            [name]: value
        }));
    }
    handleSubmit(e) {
        e.preventDefault()
        let {
            file,
            fileCategory,
            entities,
            date,
            shareable
        } = this.state;

        let data = new FormData();
        data.append('file', file);
        data.append('shareable', shareable);
        data.append('fileCategory', fileCategory);
        data.append('entities', JSON.stringify(entities));
        data.append('date', date);
        this.setState({
            loading: true,
            alerts: false
        })
        return this.props.createGEDFile(data).then((redirect) => {
            console.log({ redirect })
            if (redirect) {
                notification.success({
                    message: redirect.message,
                    description: redirect.message,
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}

                })
                this.props.history.push('/ged');
            }
        });
    }
    handleHideModal(modelType) {
        let prevState = this.state[modelType]
        this.setState({
            [modelType]: !prevState
        })
    }
    handleDeleteItem(index) {
        let prevState = this.state.communes
        prevState.splice(index, 1)
        this.setState({
            communes: prevState
        })
    }
    deleteAttachement(index) {
        if (!window.confirm('Etes vous sur ?')) return false;
        return this.setState((state) => {
            let entities = [...state.entities];
            entities.splice(index, 1);
            return {
                entities
            };
        });
    }
    setSelection(selected) {
        return this.setState((state) => ({
            entities: [...state.entities, selected],
            showForSite: false
        }));

        // if (this.state.showForContract) {
        //     this.setState({
        //         contractant: rowsData,
        //         showForContract: false
        //     })
        // } else if (this.state.showForCommunes) {
        //     let prevCommune = this.state.communes
        //     if (!prevCommune.find(item => (item.id_person == rowsData.id_person && item.typePersonMoral == rowsData.typePersonMoral))) {
        //         prevCommune.push({ id_person: rowsData.id_person, city: rowsData.city, typePersonMoral: rowsData.typePersonMoral, name: rowsData.name, dataIndex: rowsData.dataIndex, [rowsData.dataIndex]: rowsData[rowsData.dataIndex], adresse: rowsData.adresse })
        //     }
        //     this.setState({
        //         communes: prevCommune,
        //         showForCommunes: false
        //     })
        // } else {
        //     this.setState({
        //         site: rowsData,
        //         showForSite: false
        //     })
        // }

    }
    showErrors() {
        if (this.state.alerts) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                notification.success({
                    message: this.isNewItem ? global._trans('contracts', 'titleMessageCreate') : global._trans('contracts', 'titleUpdateMessage'),
                    description: this.isNewItem ? global._trans('contracts', 'bodyMessageCreate') : global._trans('contracts', 'BodyUpdateMessage'),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}

                })
                return <Redirect to="/contrat" />
            }
        }
    }
    render() {
        let entities = this.state.entities.map(({ type, elem }, index) => {
            switch (type) {
                case 'sites':
                    return { type, name: elem.denomination, city: elem.city, index };
                case 'epics':
                    return { type, name: elem.nomEpic, city: elem.city, index };
                case 'syndicats':
                    return { type, name: elem.nomCourt, city: elem.city, index };

                case 'communes':
                    return { type, name: elem.nomCommune, city: elem.city, index };

                case 'societe_exploitants':
                    return { type, name: elem.denomination, city: elem.city, index };

                default:
                    return null;
            }
        })
        return (
            <>
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('ged', 'createTitle')}</span>
                    </h3>
                </div>
                <Spin spinning={this.state.loading || this.state.fetching}>
                    <div className="card-body py-4">
                        {this.showErrors()}
                        <Form method="post" onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>&nbsp;</Form.Label>
                                    <Form.File filename={!!this.state.file ? this.state.file.name : ''} onChange={(e) => this.handleName('file', e.target.files[0])} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans("ged", "fileCategory")}*</Form.Label>
                                    <Select
                                        showSearch
                                        showArrow
                                        className="inputSizeSelect"
                                        value={this.state.fileCategory}
                                        placeholder={global._trans("ged", "fileCategory")}
                                        style={{ width: '100%' }}
                                        options={this.props.enums.fileCategory}
                                        onSelect={(SelectedValue) => this.handleName("fileCategory", SelectedValue)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('ged', 'Date')}*</Form.Label>
                                    <Form.Control type="text" name="date" value={null} placeholder={global._trans('ged', 'Date')} onChange={(e) => this.handleName(e.target.name, e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans("ged", "shareable")}*</Form.Label>
                                    <Select
                                        showSearch
                                        showArrow
                                        className="inputSizeSelect"
                                        value={this.state.shareable}
                                        placeholder={global._trans("ged", "shareable")}
                                        style={{ width: '100%' }}
                                        options={[{ value: false, label: global._trans("ged", "non-shareable") }, { value: true, label: global._trans("ged", "shareable") }]}
                                        onSelect={(SelectedValue) => this.handleName("shareable", SelectedValue)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label className="d-block">&nbsp;</Form.Label>
                                    <Button disabled={this.state.entities.length > 0} variant="outline-secondary" size="md" block
                                        onClick={() => this.state.entities.length < 1 && this.handleHideModal("showForSite")}>{global._trans('ged', 'attache')}</Button>
                                </Form.Group>
                            </Form.Row>
                            <h4 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">{global._trans('ged', 'attachedPartiesAndSites')}</span>
                            </h4>
                            <SageTable
                                columns={[
                                    { title: "Type", dataIndex: "type" },
                                    // { title: "Ville", dataIndex: "city" },
                                    { title: "Nom", dataIndex: "name", fixed: 'left' },
                                    {
                                        title: global._trans('common', 'action'), fixed: 'right', dataIndex: "index", render: index => <InlineBottunSvg
                                            bottuns={[
                                                { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.deleteAttachement(index) },
                                            ]}
                                        />
                                    }
                                ]}
                                data={entities}
                                style={{ height: 400 }}
                                IterableProps={{
                                    emptyText: global._trans('admins', 'No attached entities'),
                                    loading: this.state.loading,
                                    // rowKey: ,
                                    onChange: this.handleTableChange
                                }}
                            />

                            <div className="d-flex justify-content-end mt-4">
                                <div className="card-toolbar mx-4">
                                    <Link to="/ged" className="btn btn-secondary" >{global._trans('common', 'cancelBtn')}</Link>
                                </div>
                                <Button variant="primary" onClick={this.handleSubmit} type='submit' disabled={this.state.loadingCreate}>{this.isNewItem ? global._trans('contracts', 'textSubmitCont') : global._trans('common', 'saveChanges')}</Button>
                            </div>
                        </Form>
                        {this.state.showForSite && <EntitySelector blocked={[]} show={this.state.showForSite} validSelection={this.setSelection} onHide={() => this.handleHideModal('showForSite')} />}
                    </div>
                </Spin>
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { fileCategory } } = state.enums;

    return { enums: { fileCategory } }
}
const connectedClass = connect(mapState, { createGEDFile })(CreateGEDFile)
export { connectedClass as CreateGEDFile }