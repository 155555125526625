import React from 'react'
import { Form } from 'react-bootstrap'
export const FiltersCommune = (props) => {
    return (
        <>
            <Form.Group className="mb-2">
                <Form.Label>ID</Form.Label>
                <Form.Control type="text" placeholder="ID" defaultValue={props.filters.id_commune} name="id_commune" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('clientsCommune', 'communeName')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('clientsCommune', 'communeName')} defaultValue={props.filters.nomCommune} name="nomCommune" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('clientsCommune', 'insee')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('clientsCommune', 'insee')} defaultValue={props.filters.insee} name="insee" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'serin')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'serin')} defaultValue={props.filters.serin} name="serin" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'address')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'address')} defaultValue={props.filters.address} name="address" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'nbPeople')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'nbPeople')} defaultValue={props.filters.nombreHabitant} name="nombreHabitant" onChange={props.handleChange} />
            </Form.Group>

        </>
    )
}