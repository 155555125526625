import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ListContracts } from './ListContracts'
import { CreateContract } from './CreateContract'
import { ViewContract } from "./ViewContract"
class ContractPage extends Component {
    render() {
        console.log(global._trans('titles', 'app'))
        return (
            <div className="card card-custom">
                <Switch>
                    <Route
                        path="/contrat/view/:idContract"
                        component={ViewContract}
                    />
                    <Route
                        path="/contrat/:idCreate"
                        component={CreateContract}
                    />
                    <Route
                        path="/contrat"
                        component={ListContracts}
                    />
                </Switch>
            </div>
        )
    }
}
export { ContractPage }