import React from 'react'
import { Form } from 'react-bootstrap'
export const FilterSites = (props) => {
    return (
        <>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans("sites", "categorieSite")}</Form.Label>
                <Form.Control as="select" name="categorieSite" onChange={props.handleChange} defaultValue={props.filters.categorieSite}>
                    <option value="all">Tout</option>
                    <option value="UVE">UVE</option>
                    <option value="TRI">TRI</option>
                    <option value="TMB">TMB</option>
                    <option value="ISDND">ISDND</option>
                </Form.Control>
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans("sites", "modeGestion")}</Form.Label>
                <Form.Control as="select" name="modeGestion" onChange={props.handleChange} defaultValue={props.filters.modeGestion}>
                    <option value="all">Tout</option>
                    <option value="Gestion privée">Gestion privée</option>
                    <option value="Prestation de service">Prestation de service</option>
                    <option value="Regie">Regie</option>
                    <option value="DSP">DSP</option>
                    <option value="MPS">MPS</option>
                    <option value="MGP">MGP</option>
                </Form.Control>
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('sites', 'denomenation')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('sites', 'denomenation')} defaultValue={props.filters.denomination} name="denomination" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans("common", "address")}</Form.Label>
                <Form.Control type="text" placeholder={global._trans("common", "address")} defaultValue={props.filters.adresse} name="adresse" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans("common", "standrPhone")}</Form.Label>
                <Form.Control type="text" placeholder={global._trans("common", "standrPhone")} defaultValue={props.filters.telephoneStandrad} name="telephoneStandrad" onChange={props.handleChange} />
            </Form.Group>
        </>
    )
}