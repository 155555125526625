import React, { Component } from 'react'
import { NavLink, Switch, Redirect, Route } from 'react-router-dom'
import { checkIsActive } from "../../../../_sage/_helpers";
import { UserPremieum, UserSimple } from './UserTypes'
class Users extends Component {
    getMenuItemActive = (url) => {
        return checkIsActive(this.props.location, url) ? "menu-item-active" : "";
    }
    render() {
        return (
            <>
                <div id="kt_subheader" className='subheader py-2 py-lg-4'>
                    <div className="header" style={{ background: 'transparent', height: '0' }}>
                        <div className="header-menu header-menu-mobile header-menu-layout-default px-8">
                            <ul className='menu-nav'>
                                <li className={`menu-item menu-item-rel mx-1 ${this.getMenuItemActive('/users/premium')}`}>
                                    <NavLink className="menu-link" to="/users/premium">
                                        <span className="menu-text">{global._trans('users', 'userPrem')}</span>
                                    </NavLink>
                                </li>
                                <li className={`menu-item menu-item-rel mx-1 ${this.getMenuItemActive('/users/simple')}`}>
                                    <NavLink className="menu-link" to="/users/simple">
                                        <span className="menu-text">{global._trans('users', 'userSimple')}</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "60px" }} />
                <Switch>
                    <Redirect
                        from="/users"
                        exact={true}
                        to="/users/premium"
                    />
                    <Route
                        path="/users/premium"
                        component={UserPremieum}
                    />
                    <Route
                        path="/users/simple"
                        component={UserSimple}
                    />
                </Switch>
            </>
        )
    }
}
export { Users }