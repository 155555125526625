import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { MapSlice, enumSlice, Authreducer, saga, collectivitieSlice, societeSlice, contactSlice, gestionaireSlice, siteSlice, adminsSlice, contractSlice, userSlice, shareSlice, commonSlice, userSimpleSlice, notificationsSlice, GEDSlice } from "../../app/modules";

export const rootReducer = combineReducers({
  auth: Authreducer,
  enums: enumSlice.reducer,
  collectivitie: collectivitieSlice.reducer,
  societe: societeSlice.reducer,
  contact: contactSlice.reducer,
  gestionaire: gestionaireSlice.reducer,
  sites: siteSlice.reducer,
  map: MapSlice.reducer,
  admins: adminsSlice.reducer,
  contract: contractSlice.reducer,
  users: userSlice.reducer,
  shares: shareSlice.reducer,
  common: commonSlice.reducer,
  usersSimple: userSimpleSlice.reducer,
  notifications: notificationsSlice.reducer,
  ged: GEDSlice.reducer,
});

export function* rootSaga() {
  yield all([saga()]);
}
