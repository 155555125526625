import { societeSlice } from "./societe.reducer";
import * as societeRequests from "./societe.crud";
import { notification } from "antd";
import ReactHtmlParser from 'react-html-parser';

const { actions } = societeSlice;
/******************************* */
export const createSociete = SocieteToCreate => dispatch => {
    return societeRequests
        .createSociete(SocieteToCreate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.societeCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        });
};
export const updateSociete = SocieteToUpdate => dispatch => {
    return societeRequests
        .updateSociete(SocieteToUpdate)
        .then(({ ok }) => {
            if (ok) {
                dispatch(actions.societeCreated());
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(({ message, errors }) => {
            if (message === "The given data was invalid.") {
                dispatch(actions.catchError({ errors: errors }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        });
};
/****************************** */
export const fetchSocietes = QueryParams => dispatch => {
    return societeRequests
        .fetchSocietes(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.societesFetched({ listSocietes: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const fetchSocietesGet = QueryParams => dispatch => {
    return societeRequests
        .fetchSocietesGet(QueryParams)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.societesFetched({ listSocietes: data, currentQuery: QueryParams }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const fetchSociete = IdSociete => dispatch => {
    return societeRequests
        .fetchSociete(IdSociete)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.societeFetched({ societe: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const editSociete = IdSociete => dispatch => {
    return societeRequests
        .editSociete(IdSociete)
        .then(({ ok, data }) => {
            if (ok) {
                dispatch(actions.societeFetched({ societe: data }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const deleteSocietes = SocietiesToDelete => dispatch => {
    return societeRequests
        .deleteSocietes(SocietiesToDelete)
        .then(({ ok, data, societes }) => {
            if (ok) {
                dispatch(actions.societesDeleted({ asyncData: data === "async", societies: societes }));
            } else {
                dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
            }
        })
        .catch(err => {
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        });
};
export const syncSocietiesInseeAPI = societies => dispatch => {
    return societeRequests
        .syncSocietiesInseeAPI(societies)
        .then(({ ok, data, societes }) => {
            if (ok) {
                let dt = ''
                if (data.length) {
                    data.map(el => {
                        const t = Object.keys(el)[0]
                        dt = dt + '- <b> ' + t + '</b>' + ' : ' + el[t] + '<br />'
                    })
                }
                notification.success({
                    message: 'Les informations ont été mises à jour par l’API.',
                    description: ReactHtmlParser(dt),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}
                })
                dispatch(actions.societesSynced());
            } else {
        
            notification.error({
                message: 'Les informations n\'ont pas été synchronisées.',
                placement: 'topRight',
                style:{backgroundColor: '#FFF6F8', borderRadius: '8px', border: '1px solid #F44336'}
            })
            dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
        }
        })
        .catch (err => {
    dispatch(actions.catchError({ errors: global._trans("common", "messageErrors") }));
});
};
/********************** */
export const resetState = state => dispatch => {
    dispatch(actions.resetState(state));
};