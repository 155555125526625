import { CrudService } from "../../../services";
import { adminUrls } from './admin.constants'
export function createAdmin(Admin) {
    return CrudService.Post(adminUrls.CREATE_ADMIN_URL, Admin);
}
export function fetchAdmins(queryParams) {
    return CrudService.Get(adminUrls.FETCH_ADMINS_URL, {}, queryParams);
}
export function deleteAdmins(Admins) {
    return CrudService.Delete(adminUrls.DELETE_ADMINS_URL, Admins);
}
export function updateAdmin(Admin) {
    return CrudService.Patch(adminUrls.UPDATE_ADMIN_URL, Admin);
}

