import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { CostumTable, SageTable } from '../../Commons'
import { fetchSyndicats } from "../../../../modules/Collectivities/collectivitie.actions";
class SyndicatSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            listSyndicats: [],
            pageSize: 20,
            total: 0,
            cuurentPage: 1,
            loadSyndicats: true
        }
        this.rowSelected = null
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.validSelect = this.validSelect.bind(this)
        props.fetchSyndicats()
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loadSyndicats) {
            if (nextProps.listSyndicats && Array.isArray(nextProps.listSyndicats.data)) {
                this.setState({
                    listSyndicats: nextProps.listSyndicats.data,
                    loadSyndicats: false,
                    cuurentPage: nextProps.listSyndicats.current_page,
                    total: nextProps.listSyndicats.total
                })
            }

        }
    }
    handleSelectRow(selectedRowKeys) {
        this.rowSelected = selectedRowKeys
    }
    handleTableChange = (pagination, filters) => {
        if (pagination.current !== this.state.cuurentPage || pagination.pageSize !== this.state.pageSize) {
            this.props.fetchSyndicats({ pageSize: pagination.pageSize, page: pagination.current })
            this.setState({
                loadSyndicats: true,
                pageSize: pagination.pageSize
            })
        }
    }
    validSelect() {
        let syndicatToReturn = null
        if (this.rowSelected && this.rowSelected[0]) {
            syndicatToReturn = this.state.listSyndicats.find(obj => obj.id_syndicat == this.rowSelected[0])
            syndicatToReturn = {
                id_syndicat: syndicatToReturn.id_syndicat,
                nomCourt: syndicatToReturn.nomCourt,
                adresse: syndicatToReturn.adresse
            }
            this.props.onSelect(syndicatToReturn)
        }
    }
    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} aria-labelledby="example-modal-sizes-title-lg">
                <div className="p-5">
                    <h4 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('clientsSyndicat', 'selectSyndicToRatach')}</span>
                    </h4>
                    <SageTable
                        columns={[
                            { title: global._trans('clientsSyndicat', 'nameSyndicat'), dataIndex: "nomCourt" },
                            { title: global._trans('common', 'denomiLegal'), dataIndex: "denominationLegale" },
                            { title: global._trans('common', 'natureJuridque'), dataIndex: "nature_juridique" },
                            { title: global._trans('common', 'regionSiege'), dataIndex: "region_siege" },
                            { title: global._trans('common', 'departementSiege'), dataIndex: "departement_siege" },
                            { title: global._trans('common', 'compDechetDelgue'), dataIndex: "competence_dechet" },
                            { title: global._trans('clientsSyndicat', 'amobe'), dataIndex: "amobe" },
                            { title: global._trans('common', 'serin'), dataIndex: "serin" },
                            { title: global._trans('common', 'address'), dataIndex: "adresse" },
                            { title: global._trans('common', 'nbPeople'), dataIndex: "nombreHabitant" },
                            { title: global._trans('common', 'standrPhone'), dataIndex: "telephoneStandard" }
                        ]}
                        style={{ height: 550 }}
                        data={this.state.listSyndicats}
                        IterableProps={{
                            rowSelection: {
                                onChange: this.handleSelectRow,
                                type: 'radio'
                            },
                            emptyText: global._trans('clientsSyndicat', 'emptyList'),
                            loading: this.state.loadSyndicats,
                            rowKey: "id_syndicat",
                            pagination: { total: this.state.total, pageSize: this.state.pageSize, current: this.state.cuurentPage },
                            onChange: this.handleTableChange
                        }}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="primary" onClick={this.validSelect}>{global._trans('common', 'validSelect')}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
function mapState(state) {
    const { listSyndicats } = state.collectivitie
    return { listSyndicats }
}
const connectedClass = connect(mapState, { fetchSyndicats })(SyndicatSelector)
export { connectedClass as SyndicatSelector }