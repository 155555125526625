import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, ListGroup } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { Empty } from 'antd';
import { SageTable, InlineBottunSvg } from '../../Commons/';
import moment from 'moment';


class Contract extends Component {
    render() {
        const { contracts, contract } = this.props
        return (<SageTable
            data={contracts}
            columns={[
                { title: global._trans('contracts', 'id'), dataIndex: 'id_contrat' },
                { title: global._trans('contracts', 'contractant'), dataIndex: 'id_contrat', render: (id_contrat, index, contract) => contract.contractant?.denomination },
                { title: global._trans("sites", "maitre_ouvrage"), dataIndex: 'id_contrat', render: (id_contrat, index, contract) => contract.client ? contract.client[contract.client.dataIndex] : ''/*!!contract.communes && contract.communes.length > 0 ? contract.communes.map(({ nomCourt }) => nomCourt).join(',') : ''*/ },
                { title: global._trans('contracts', 'siteContract'), dataIndex: 'id_contrat', render: (id_contrat, index, contract) => contract.site.denomination },
                { title: global._trans('contracts', 'modeGestion'), dataIndex: 'id_contrat', render: (id_contrat, index, contract) => contract.modeGestion },
                { title: global._trans('contracts', 'categorieSite'), dataIndex: 'id_contrat', render: (id_contrat, index, contract) => contract.site.categorieSite },

                { title: global._trans('contracts', 'startDate'), dataIndex: 'dateDebut', render: (dateDebut, index, contract) => dateDebut ? dateDebut : '' },
                { title: global._trans('contracts', 'endDate'), dataIndex: 'dateFin', render: (dateFin, index, contract) => dateFin ? dateFin : '' },
                // { title: global._trans('common', 'groupeContractant'), dataIndex: 'id_contrat', render: (id_contrat, index, contract) => Array.isArray(contract.contractant.groupe) ? contract.contractant.groupe.map((g) => g.value_enum).join(', ') : '' }
                {
                    title: global._trans('contracts', 'action'), dataIndex: "id_contrat", fixed: 'right', render: (id_contrat, key) => <InlineBottunSvg
                        bottuns={[

                            { iconPath: "/media/svg/icons/Costum/Edit.svg", type: "primary", action: '/contrat/' + id_contrat },
                            { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.deleteContact(id_contrat) },
                            { iconPath: "/media/svg/icons/Costum/View.svg", type: "primary", action: '/contrat/view/' + id_contrat }

                        ]}
                    />
                }
            ]}
            style={{ height: 650 }}
            IterableProps={{

                // emptyText: global._trans('admins', 'emptyAdmin'),
                // loading: this.state.loading,
                // rowKey: "id_admin",
                // pagination: { total: this.state.total, pageSize: this.state.pageSize, current: this.state.current, showSizeChanger: false },
                // onChange: this.onPaginationChange

            }}
        />);
        return (
            <div className="row">
                {contracts && contracts.length > 0 ? contracts.map((contrac, key) => {
                    let activite = contract.find(activite => activite.value == parseInt(contrac.autreActivite))
                    return <div key={key} className="col-xl-4 mt-4">
                        <Card>
                            <Card.Title className="m-0 p-4 d-flex justify-content-between" tag="h4"><span>{global._trans('contracts', 'debut') + " : " + contrac.dateDebut}</span><span>{global._trans('contracts', 'fin') + " : " + contrac.dateFin}</span></Card.Title>
                            <ListGroup variant="flush">
                                {activite && <ListGroup.Item><strong>{global._trans('contracts', 'autherActivContract')} : </strong>{'  ' + activite.label}</ListGroup.Item>}
                                <ListGroup.Item><strong>{global._trans('contracts', 'groupeContractant')} : </strong>{'  ' + contrac.contractant.groupe}</ListGroup.Item>
                                <ListGroup.Item><strong>{global._trans('contracts', 'adressContractant')}: </strong>{'  ' + contrac.contractant.adresse}</ListGroup.Item>
                                <div className="d-flex justify-content-between">
                                    <ListGroup.Item><Link to={"/contrat/view/" + contrac.id_contrat}><strong>{global._trans('contracts', 'viewContract')}</strong></Link></ListGroup.Item>
                                    <ListGroup.Item><Link to={"/client/companies/view/" + contrac.contractant.id_societe_exploitant}><strong>{global._trans('contracts', 'goContract')}</strong></Link></ListGroup.Item>
                                </div>
                            </ListGroup>
                        </Card>
                    </div>
                }) : <div className="d-flex justify-content-center w-100 my-3">
                    <Empty description={global._trans('sites', 'noContract')} />
                </div>}
            </div>
        )
    }
}
function mapState(state) {
    const { listEnums: { contract } } = state.enums
    return { contract }
}
const connectedClass = connect(mapState, {})(Contract)
export { connectedClass as Contract }