import React from 'react';
export function EntityHistory({ entity, showStatus }) {
    return (
        <table className='table'>
            <tr>
                <th>{global._trans("entity_history", "informations")}</th>
                <th>{global._trans("entity_history", "details")}</th>
                <th>{global._trans("entity_history", "user")}</th>
            </tr>
            <tr>
                <td>{global._trans("entity_history", "creation_date")}</td>
                <td>{entity.created_at && global._toFormatDate(entity.created_at)}</td>
            </tr>
            <tr>
                <td>{global._trans("entity_history", "updated_date")}</td>
                <td>{entity.updated_at && global._toFormatDate(entity.updated_at)}</td>
                <td>{!!entity.updated_by && entity.updated_by.nom}</td>
            </tr>
            {
                showStatus && <tr>
                    <td>{global._trans("entity_history", "status")}</td>
                    <td>{global._trans("sites", `status_${entity.status}`)}</td>
                    <td>{!!entity.status_updated_by && entity.status_updated_by.nom}</td>
                </tr>
            }
            {!!entity.effectif_history && entity.effectif_history.map((tr) => (
                <tr>
                    <td>Population: {tr.prev_value}</td>
                    <td>Date: {global._toFormatDate(tr.date_reference)}</td>
                    <td>Ecrasé par: {!!tr.updated_by && tr.updated_by.nom}</td>
                </tr>
            ))}
        </table>
    );
}
