import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_helpers";
import SVG from "react-inlinesvg";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { UpdatePassword } from './password/UpdatePassword'

export function UserProfileDropdown() {
  const [showPassword, setShowPassword] = useState(false)
  const { user } = useSelector((state) => state.auth);
  const handleModalPassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          style={{ cursor: 'pointer' }}
          className={
            "px-0"
          }
        >
          <img src="/media/avatar.png" alt="avatar logo" width='45' style={{ marginRight: '10px' }} />
          {/* <span className="symbol symbol-35 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {user.nom && user.nom[0]}
            </span>
          </span> */}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top" style={{ backgroundImage: `url(${toAbsoluteUrl("/media/images/bg-5.jpg")})` }}>
          <div className="">
            {/* <span className="symbol-label text-success font-weight-bold font-size-h4">
              {user.nom[0]}
            </span> */}
            <img src="/media/passwordO.png" alt="avatar logo" width='45' style={{ marginRight: '10px' }} />
            {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
          </div>
          <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
            {user.nom} {user.prenom}
          </div>
        </div>
        <div className="navi navi-spacer-x-0 pt-5">
          <Link role="button" to="#" onClick={handleModalPassword} className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Password.svg")} />
                </span>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">{global._trans("auth", "changePassword")}</div>
                <div className="text-muted">{global._trans("auth", "bodyPassword")}</div>
              </div>
            </div>
          </Link>
          <div className="navi-separator mt-3"></div>
          <div className="navi-footer  px-8 py-5">
            <Link to="/logout" className="btn btn-light-primary font-weight-bold">{global._trans("login", "logout")}</Link>
          </div>
        </div>
        {showPassword && <UpdatePassword show={true} userAuth={user} onHide={handleModalPassword} />}
      </Dropdown.Menu>
    </Dropdown>
  );
}
