import React from 'react'
import { Form } from 'react-bootstrap'
export const FiltersSyndicat = (props) => {
    return (
        <>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('clientsSyndicat', 'nameSyndicat')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('clientsSyndicat', 'nameSyndicat')} defaultValue={props.filters.nomCourt} name="nomCourt" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'denomiLegal')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'denomiLegal')} defaultValue={props.filters.denomination} name="denomination" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'natureJuridque')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'natureJuridque')} defaultValue={props.filters.nature_juridique} name="nature_juridique" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'regionSiege')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'regionSiege')} defaultValue={props.filters.region_siege} name="region_siege" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'departementSiege')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'departementSiege')} defaultValue={props.filters.departement_siege} name="departement_siege" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'compDechetDelgue')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'compDechetDelgue')} defaultValue={props.filters.competence_dechet} name="competence_dechet" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('clientsSyndicat', 'amobe')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('clientsSyndicat', 'amobe')} defaultValue={props.filters.amobe} name="amobe" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'address')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'address')} defaultValue={props.filters.address} name="address" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'serin')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'serin')} defaultValue={props.filters.serin} name="serin" onChange={props.handleChange} />
            </Form.Group>
        </>
    )
}