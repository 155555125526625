import React, { Component } from 'react'
// import { AirlineSeatFlatRounded } from '@material-ui/icons';
import SVG from "react-inlinesvg";
import qs from 'qs'
import { loadLMaps } from './LoadMapScript'
import { connect } from 'react-redux'
import { fetchSitesMap } from '../../../modules/MapSites/mapsites.actions'
import { getGroupes } from '../../../modules/Sites/sites.actions'
import { FilterPicker } from './FilterPicker'
import { toAbsoluteUrl } from '../../../../_sage/_helpers';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import { FilterPickerAdvanced } from './FilterPickerAdvanced';

const queryString = require('query-string');
class MapSites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crntLat: 47.294918273627104,
            crntLan: 2.191770665347577,
            crntZoom: 5.3,
            currentStyle: 2,
            data_sites: [],
            querySearch: {},
            searchBtnClicked: false,
            showFilter: false,
            listSitesMap: [],
            groupsListData: [],
            typeEquipementData: [],
            modeGestionData: [],
            amobeData: [],
            traitementFumeeData: [],
        }
        // this.initQuery = {}
        // this.initQuery = queryString.parse(props.history.location.search)
        var searchQuery = this.props.location.search.replace('?', '')
        this.initQuery = qs.parse(searchQuery)
        this.colors = {
            tmb: "#01b26b",
            isdnd: "#cd0000",
            uve: "#2a244e",
            tri: "#187DE4"
        }
        this.markers = []
        this.map = null;
        this.scopeMap = null
        this.callback = this.callback.bind(this)
        this.handleFetchBound = this.handleFetchBound.bind(this)
        this.handleDrawer = this.handleDrawer.bind(this)
        this.handleFetchFilter = this.handleFetchFilter.bind(this)
        // this.SiteCard = this.SiteCard.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        const { groupsList, typeEquipement, traitementFumee, modeGestion, amobe } = nextProps
        if (groupsList) {
            this.setState({ groupsListData: groupsList })
        }
        if (traitementFumee) {
            this.setState({ traitementFumeeData: traitementFumee })
        }
        if (typeEquipement) {
            this.setState({ typeEquipementData: typeEquipement })
        }
        if (modeGestion) {
            this.setState({ modeGestionData: modeGestion })
        }
        if (amobe) {
            this.setState({ amobeData: amobe })
        }
    }
    componentDidMount() {
        loadLMaps(this.callback)
        this.props.getGroupes()
    }
    callback() {
        this.scopeMap = window.mapboxgl
        this.scopeMap.accessToken = process.env.REACT_APP_KEY_MAP
        this.map = new this.scopeMap.Map({
            container: 'map_sites', // container ID
            style: 'mapbox://styles/mapbox/streets-v11', // style URL
            center: [4.191770665347577, 47.294918273627104],
            zoom: 5,
            maxZoom: 20,
            minZoom: 1,
            minPitch: 10
        });
        this.map.addControl(new MapboxLanguage({
            defaultLanguage: 'fr'
        }));
        this.handleFetchBound(this.initQuery)
        this.map.on('zoomend', this.handleFetchBound);
        this.map.on('moveend', this.handleFetchBound);
    }
    handleFetchFilter(query) {
        this.initQuery = query
        this.handleFetchBound()
    }
    handleDataMap(lat, lang, QueryParams) {
        var mapLoading = document.getElementById("kt_loading_map")
        if (mapLoading) {
            mapLoading.style.display = "block"
        }
        fetchSitesMap(lat, lang, QueryParams)
            .then(({ ok, data, siteAlll }) => {
                if (mapLoading) {
                    mapLoading.style.display = "none"
                }
                if (ok) {
                    this.markers.map(mark => {
                        mark.marker.remove()
                    })
                    this.setState({
                        data_sites: data
                    })
                    this.setState({ listSitesMap: data })
                    data.map(site => {
                        var popup = new this.scopeMap.Popup({ className: "mySiteCard" })
                            // .setHTML(this.SiteCard(site.adresse))
                            // .setHTML("<b>" + site.adresse + "</b><br>")
                            // <div class='p-4 d-flex flex-row justify-content-between align-items-center'> 
                            //     .setHTML(`
                            // <div class='pt-3 px-4 pb-0 text-left' id='btnPlusDtsMarkerCard' data-idAccess=${site.id_access} data-idSite=${site.id_site}>
                            //     <div class='mb-4' style='text-align: center'> 
                            //             <img src=${site.photos.length ? site.photos[site.photos.length - 1].url : "/media/factory.png"}
                            //             alt="a picture" width="250px" height="150">
                            //         </div>
                            //             <div class='text-dark font-weight-bold text-left' style='font-family: Poppins, Helvetica;' > 
                            //             <p class='mb-1' style='font-size: 14px; word-break: break-word;'><b>${site.deno}</b></p>
                            //             <p class='mb-1' style='font-wieght: 400; word-break: break-word;' >${site.adresse}</p>                      
                            //             <hr class='hr_Marker_card'/>
                            //                 <div class='d-flex justify-content-between align-items-center mb-4'>
                            //                     ${site.iconType === 'TMB' ?
                            //             "<div class='marker markerTmb'></div>"
                            //             :
                            //             site.iconType === 'ISDND' ?
                            //                 "<div class='marker markerIsdnd'></div>"
                            //                 :
                            //                 site.iconType === 'UVE' ?
                            //                     "<div class='marker markerUve'></div>"
                            //                     :
                            //                     site.iconType === 'TRI' ?
                            //                         "<div class='marker markerTri'></div>"
                            //                         :
                            //                         ''
                            //         }                                                                              
                            //                 </div>
                            //                 </div>
                            //                 </div>
                            //      `)
                            // <img src=${siteAlll.find((el) => el.id_site === site.id_site).photos.length ? siteAlll.find((el) => el.id_site === site.id_site).photos[siteAlll.find((el) => el.id_site === site.id_site).photos.length - 1].url : "/media/factory.png"}
                            // alt="a picture" width="300px" height="160" style="border-radius: 6px">
                            .setHTML(`
                        <div class='pb-0 text-left' style='background-color: #fff; z-index: 2' id='btnPlusDtsMarkerCard' data-idAccess=${site.id_access} data-idSite=${site.id_site}>
                             <div class='mb-4' style='text-align: center'> 

                                     <img src=${siteAlll.find((el) => el.id_site === site.id_site).default_image
                                    ? siteAlll.find((el) => el.id_site === site.id_site).default_image.url :
                                    siteAlll.find((el) => el.id_site === site.id_site).photos.length ?
                                        siteAlll.find((el) => el.id_site === site.id_site).photos[siteAlll.find((el) => el.id_site === site.id_site).photos.length - 1].url
                                        : "/media/factory.png"
                                }
                                             alt="a picture" width="300px" height="160" style="border-radius: 6px">
                            </div>
                            <div class='text-dark font-weight-bold text-left' style='font-family: Poppins, Helvetica;' > 
                            <div class="d-flex justify-content-center align-items-center w-100"><p style='font-size: 14px; font-weight: 600' class='NameSitePosition ${site.iconType} '>${site.denomination}</p></div>
                            <p class='iconTypePosition ${site.iconType}'>${site.iconType}</p>
                            ${siteAlll.find((el) => el.id_site === site.id_site).logo_exploitant ?
                                    `<div class='logoSitePosition ${site.iconType}'>
                                <img src=${siteAlll.find((el) => el.id_site === site.id_site).logo_exploitant}
                                 alt="a picture" width="110" height="28" style="border-radius: 8px;object-fit: cover;">
                                 </div>`
                                    : ''}
                            <p class='${site.iconType} linearGray' style='font-weight: 600; font-size: 15px; word-break: break-word; color: black; margin:15px auto 10px auto; width: max-content; max-width:220px; text-align:center; ' title="${global._trans('mapSites', 'client')}">${siteAlll.find((el) => el.id_site === site.id_site).client?.client ? siteAlll.find((el) => el.id_site === site.id_site).client.client[siteAlll.find((el) => el.id_site === site.id_site).client.client.dataIndex] || '' : "-"}</p>                                                                                      
                            <p class='${site.iconType} linearGray' style='font-weight: 400; font-size: 14px; word-break: break-word; color: black; margin: 0 auto 30px auto; width: max-content; max-width:220px; text-align:center; ' title="${global._trans('mapSites', 'exploitant')}">
                            ${siteAlll.find((el) => el.id_site === site.id_site).exploitant?.client ? siteAlll.find((el) => el.id_site === site.id_site).exploitant.client[siteAlll.find((el) => el.id_site === site.id_site).exploitant.client.dataIndex] || siteAlll.find((el) => el.id_site === site.id_site).exploitant.client.denomination || '' : "-"}
                            </p>
                            ${site.iconType === 'UVE' ?
                                    `<div class='d-flex justify-content-between align-items-start'>
                                     <div class='d-flex justify-content-between align-items-center'>
                                        <p class='${site.iconType} py-1 linearGray' style='color: black; font-weight: 600' title="${global._trans('mapSites', 'capaciteReglementaire')}">
                                        ${siteAlll.find((el) => el.id_site === site.id_site).tonnage} T
                                        </p>
                                        <p class='UVENBRLIGNE mx-4 linearGray' style='color: black; font-weight: 700' title="${global._trans('mapSites', 'lines')}">
                                            ${siteAlll.find((el) => el.id_site === site.id_site).nb_lines}
                                        </p>
                                    </div>
                                    <div class='d-flex justify-content-between align-items-center'>
                                    ${siteAlll.find((el) => el.id_site === site.id_site).types_valorisation
                                        && siteAlll.find((el) => el.id_site === site.id_site).types_valorisation.includes('hydrogene')
                                        ?
                                        `<img src='/media/images/2023imgs/hydro.png' alt="a picture" style="border-radius: 8px;object-fit: cover; margin-right:10px;" title="${global._trans('mapSites', 'hydrogeneValorisation')}"/>`
                                        :
                                        ''
                                    }
                                        ${siteAlll.find((el) => el.id_site === site.id_site).types_valorisation
                                        && siteAlll.find((el) => el.id_site === site.id_site).types_valorisation.includes('thermique')
                                        ?
                                        `<img src='/media/images/2023imgs/thermique.png' alt="a picture" width="34" height="34" style="border-radius: 8px;object-fit: cover;margin-right:10px;" title="${global._trans('mapSites', 'thermicValorisation')}"/>`
                                        :
                                        ''
                                    }
                                        ${siteAlll.find((el) => el.id_site === site.id_site).types_valorisation
                                        && siteAlll.find((el) => el.id_site === site.id_site).types_valorisation.includes('electric')
                                        ?
                                        `<img src='/media/images/2023imgs/elec.png' alt="a picture" style="border-radius: 8px;object-fit: cover;" title="${global._trans('mapSites', 'electricValorisation')}"/>`
                                        :
                                        ''
                                    }
                                    </div>
                                </div>`
                                    : ''}
                        </div>
                        `)
                        popup.on('open', () => {
                            let button = document.querySelector('div[id="btnPlusDtsMarkerCard"][data-idAccess="' + site.id_access + '"][data-idSite="' + site.id_site + '"]');
                            button.onclick = () => {
                                window.open(`/sites/details/${site.id_site}`, '_blank');
                                // this.props.history.push('/site/1/11')
                            }
                            // button.addEventListener('click', event => {
                            // });
                        })
                        const el = document.createElement('div');
                        if (site.iconType === 'TMB') {
                            el.className = 'marker markerTmb';
                        }
                        if (site.iconType === 'ISDND') {
                            el.className = 'marker markerIsdnd';
                        }
                        if (site.iconType === 'UVE') {
                            let text = document.createTextNode(siteAlll.find((el) => el.id_site === site.id_site).nb_lines || 0);
                            el.className = `marker markerUve-${siteAlll.find((el) => el.id_site === site.id_site).types_valorisation_code}`;
                            el.appendChild(text);
                        }
                        if (site.iconType === 'TRI') {
                            el.className = 'marker markerTri';
                        }
                        var marker = new this.scopeMap.Marker(el)
                            // var marker = new this.scopeMap.Marker({
                            //     color: this.colors[site.iconType.toLowerCase()]
                            // })
                            .setLngLat([site.lang, site.lat])
                            .setPopup(popup)
                            .addTo(this.map);
                        this.markers.push({ marker: marker })
                    })
                    // if (this.state.searchBtnClicked && data.length > 0) {
                    //     this.map.flyTo({
                    //         center: [14, 47.294918273627104],
                    //         zoom: 4.5,
                    //         essential: true
                    //         })
                    //         this.setState({
                    //             searchBtnClicked: false
                    //         })
                    //     }
                    //     if (Object.keys(this.initQuery).length !== 0 && data.length > 0) {
                    //     this.map.flyTo({
                    //         center: [14, 47.294918273627104],
                    //         zoom: 4.5,
                    //         essential: true
                    //     })
                    // }
                    // if (Object.keys(this.initQuery).length === 0) {                        
                    //         this.map.flyTo({
                    //             center: [14, 47.294918273627104],
                    //             zoom: 4.5,
                    //             essential: true
                    //         })
                    // }
                }
            })
            .catch(err => {

            })
    }
    handleFetchBound() {
        let bounds = this.map.getBounds()
        if (bounds && bounds._ne && bounds._sw) {
            const { lat: latmax, lng: lngmax } = bounds._ne
            const { lat: latmin, lng: lngmin } = bounds._sw
            this.handleDataMap(latmin + "," + latmax, lngmin + "," + lngmax, this.initQuery)
        }
    }
    handleDrawer() {
        let prevState = this.state.showFilter
        this.setState({
            showFilter: !prevState
        })
    }
    changeMapStyle = (style) => {
        if (this.state.currentStyle !== style) {
            if (style === 1) {
                this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v12');
            }
            if (style === 2) {
                this.map.setStyle('mapbox://styles/mapbox/streets-v11');
            }
            if (style === 3) {
                this.map.setStyle('mapbox://styles/mapbox/outdoors-v12');
            }
            if (style === 4) {
                this.map.setStyle('mapbox://styles/mapbox/streets-v12');
            }
            if (style === 5) {
                this.map.setStyle('mapbox://styles/mapbox/dark-v11');
            }
            this.setState({
                currentStyle: style
            })
        }
    }
    changeZoom = (zoomSize) => {
        // handleFetchFilter()
        const curZoom = this.map.getZoom()
        if (zoomSize === 'in') {
            this.map.setZoom((curZoom + 0.1))
        } else {
            this.map.setZoom((curZoom - 0.1))
        }
        // this.setState({
        //     zoom: zoomSize
        // })
        // this.map.setZoom(zoomSize);
    }
    render() {
        return (
            <div className="card position-relative h-100 overflow-hidden shadow">
                {/* <FilterPicker handleSearch={this.handleFetchFilter} history={this.props.history} /> */}
                <div id="kt_loading_map" className="position-absolute bg-light py-4 px-5" style={{ display: "none", zIndex: 405, borderRadius: 12, left: "calc(50% - 95.5px)", top: 65, boxShadow: "1px 1px 18px #00000052" }}>
                    <strong>{global._trans("mapSites", "loadingSites")}</strong>
                </div>
                <div style={{ backgroundColor: 'white', padding: '15px', borderRadius: '5px', zIndex: 401, top: 0, left: 0, position: 'absolute', width: '350px', margin: '0 auto' }}>
                    <div className="d-flex mb-4 justify-content-center align-items-center" >
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" onClick={() => this.changeMapStyle(2)} className={`btn btn-sm btn-${this.state.currentStyle === 2 ? 'success' : 'light'}`}>Street</button>
                            <button type="button" onClick={() => this.changeMapStyle(1)} className={`btn btn-sm btn-${this.state.currentStyle === 1 ? 'success' : 'light'}`}>Satellite</button>
                            <button type="button" onClick={() => this.changeMapStyle(3)} className={`btn btn-sm btn-${this.state.currentStyle === 3 ? 'success' : 'light'}`}>Light</button>
                            <button type="button" onClick={() => this.changeMapStyle(4)} className={`btn btn-sm btn-${this.state.currentStyle === 4 ? 'success' : 'light'}`}>outdoors</button>
                            <button type="button" onClick={() => this.changeMapStyle(5)} className={`btn btn-sm btn-${this.state.currentStyle === 5 ? 'success' : 'light'}`}>Dark</button>


                            {/* <button type="button" onClick={() => this.changeMapStyle(1)} className={`btn btn-lg btn-${this.state.currentStyle === 1 ? 'primary' : 'light'}`}>Satellite 2</button> */}
                            {/* <button type="button" onClick={() => this.changeMapStyle(2)} className={`btn btn-lg btn-${this.state.currentStyle === 2 ? 'primary' : 'light'}`}>Street</button> */}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button"
                                style={{ borderRadius: '4px' }}
                                onClick={() => {
                                    this.map.flyTo({
                                        center: [6.191770665347577, 47.294918273627104],
                                        zoom: 4.5,
                                        essential: true
                                    })
                                }} className={`btn btn-sm btn-dark`}>Europe</button>
                            <button type="button"
                                style={{ borderRadius: '4px' }}
                                onClick={() => {
                                    this.map.flyTo({
                                        center: [-63.27238249999999, 10.1922065],
                                        essential: true
                                    })
                                }} className={`btn btn-sm btn-dark mx-5`}>Guadeloupe</button>
                            <button type="button"
                                style={{ borderRadius: '4px' }}
                                onClick={() => {
                                    this.map.flyTo({
                                        center: [55.5364801, -21.1307379],
                                        essential: true
                                    })
                                }} className={`btn btn-sm btn-dark`}>La réunion</button>
                        </div>
                    </div>
                </div>
                <div className="position-absolute bg-black transition d-flex p-3 rounded cursor-pointer header_shadow bg-dark" onClick={this.handleDrawer}
                    style={{ zIndex: 1031, top: 20, ...(this.state.showFilter ? { right: "510px" } : { right: "20px" }) }}>
                    {this.state.showFilter ? <BsArrowRight size={23} color='white' /> : <BsArrowLeft size={23} color='white' />}
                </div>
                <div className="d-flex position-absolute flex-column boxbtnZoom" style={{ zIndex: 401, bottom: 20, left: 20 }}>
                    {/* <div className="btn-group" role="group" aria-label="Basic example"> */}
                    <button type="button" onClick={() => this.changeZoom('in')} className={`btnZoom`}>+</button>
                    <button type="button" onClick={() => this.changeZoom('out')} className={`btnZoom`}>-</button>
                    {/* </div> */}
                </div>
                {/* <div className='d-flex justify-content-between  h-100 overflow-hidden' style={{ maxHeight: "calc(100vh - 62px)" }}>
                </div> */}
                <div id="map_sites" className="h-100"></div>
                {this.state.showFilter && (
                    <FilterPickerAdvanced handleSearch={this.handleFetchFilter} history={this.props.history} data={this.state} />
                )}
                {/* <div className='d-flex justify-content-between h-100 overflow-hidden' style={{ maxHeight: "calc(100vh - 175px)" }}>
                    {(Object.keys(this.initQuery).length !== 0 && this.state.data_sites.length !== 0) ?
                        <div className="py-4 pl-4 pr-0" style={{ width: '55%' }}>
                            <p style={{ color: '#6B7280', padding: '10px', fontSize: '15px' }}>{this.state.data_sites.length} Clients</p>
                            <hr />
                            <div className="h-100" style={{ overflowY: 'scroll' }}>
                                {this.state.data_sites &&
                                    this.state.data_sites.map(site => {
                                        return (
                                            <div className='d-flex justify-content-start align-items-start mb-4 p-3' key={site.id_site}>
                                                <div class='mr-4' style={{}}>
                                                    <img src="http://localhost:6001/media/factory.png" alt="a picture" width="270px" height="170" className='rounded' />
                                                </div>
                                                <div class='ml-4 position-relative' style={{ flex: 1 }}>
                                                    <button className="btn p-0 m-0 position-absolute"
                                                        style={{ zIndex: 406, top: 0, right: 10 }} onClick={() => { }}>
                                                        <span className="svg-icon svg-icon-md">
                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Vectorheart.svg")} className="h-50 align-self-center"></SVG>
                                                        </span>
                                                    </button>
                                                    <h3 style={{ color: '#6B7280', fontWeight: '800' }} className='mb-4'>Région XXXXX</h3>
                                                    <p className='mb-3' style={{ color: '#6B7280', fontWeight: '300' }}>{site.adresse}</p>
                                                    <p className='mb-3' style={{ fontSize: '14px', fontWeight: '800', color: '#374151' }}>{site.deno}</p>
                                                    <div style={{ width: '40px', borderBottom: '1px solid #E5E7EB', margin: '15px 0' }}></div>
                                                    <h4 className='mb-0 mr-4' style={{ fontSize: '14px', fontWeight: '800' }}>Groupe<span className='ml-4' style={{ color: '#0E8F2A', fontSize: '14px', fontWeight: '500' }}>{site.iconType}</span></h4>
                                                    <div style={{ width: '40px', borderBottom: '1px solid #E5E7EB', margin: '15px 0' }}></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        :
                        null
                    }
                    <div id="map_sites" className=""
                        style={{ width: (Object.keys(this.initQuery).length !== 0 && this.state.data_sites.length !== 0) ? '43%' : '100%',
                        paddingRight: (Object.keys(this.initQuery).length !== 0 && this.state.data_sites.length !== 0) ? '5px' : '0' }}></div>
                </div> */}
                {/* // :
                // <div id="map_sites" className="h-100 border border-primary rounded"></div> */}
            </div>
        )
    }
}
// export { MapSites }

function mapState(state) {
    const { groupsList, typeEquipement, traitementFumee, modeGestion, amobe } = state.sites
    return { groupsList, typeEquipement, traitementFumee, modeGestion, amobe }
}
const connectedClass = connect(mapState, { getGroupes })(MapSites)
export { connectedClass as MapSites }