import React, { Component } from 'react'
import { connect } from "react-redux";
import { Spin } from 'antd';
import { fetchSyndicat } from "../../../../../modules/Collectivities/collectivitie.actions";
import { Link } from 'react-router-dom';
import { BasicInfo, SitesSyndicat, Competances, EpicsSyndicat } from './utils'
import { Contacts } from '../../Common'
import { cloneDeep } from 'lodash';

import { EntityHistory } from '../../../Commons/EntityHistory';
import { EntityFiles } from '../../../Commons';

class ViewSyndicat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            syndicat: {},
            tab: 'basic',
            disabled: true
        }
        props.fetchSyndicat(props.match.params.idsyndicat)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            this.setState({
                loading: nextProps.Syndicat ? false : true,
                syndicat: nextProps.Syndicat ? nextProps.Syndicat : {}
            })
        }
    }
    getTabBody() {
        switch (this.state.tab) {
            case "basic": return <BasicInfo disabled={this.state.disabled} syndicat={this.state.syndicat} />
            case "contacts": {
                let contacts = !!this.state.syndicat.contacts && Array.isArray(this.state.syndicat.contacts) && this.state.syndicat.contacts.length > 0 ? cloneDeep(this.state.syndicat.contacts).map((contact) => {
                    if (Array.isArray(contact.persons_moral)) {
                        contact.persons_moral = contact.persons_moral.map((person) => {
                            if (Array.isArray(person.fonction_person)) {
                                person.fonction_person = person.fonction_person.map((fonction_person) => {
                                    fonction_person.functionpersonString = this.props.function_person.find((func) => func.value == fonction_person.functionperson);
                                    return fonction_person;
                                })
                            }
                            return person;
                        });
                    }
                    console.log("CONTACT", contact);
                    return contact;
                }) : [];
                return <Contacts contacts={contacts} />
            }
            case "sites": return <SitesSyndicat sites={this.state.syndicat.sites} />
            case "epics": return <EpicsSyndicat epics={this.state.syndicat.epics} />
            case "competances": return <Competances competances={{ delegue: this.state.syndicat.competance_delegue, exercee: this.state.syndicat.competance_exercee, recu: this.state.syndicat.competance_recu }} />
            case "history": return <EntityHistory entity={this.state.syndicat} showStatus={true} />
            case "files": return <EntityFiles entity={this.state.syndicat} />
        }
    }
    handleTab(tab) {
        this.setState({
            tab: tab
        })
    }
    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div className="card-header border-0 pt-5 pb-0 px-6">
                    <h3 className="card-title align-items-start flex-column mb-0">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('clientsSyndicat', 'titleView')} <span className="text-primary">#{this.state.syndicat.nomCourt ? this.state.syndicat.nomCourt : '...'}</span></span>
                    </h3>
                </div>
                <div className="card-body py-4">
                    <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('basic')} className={`nav-link ${this.state.tab === "basic" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'identity')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('contacts')} className={`nav-link ${this.state.tab === "contacts" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'contacts')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('epics')} className={`nav-link ${this.state.tab === "epics" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'epics')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('sites')} className={`nav-link ${this.state.tab === "sites" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'sites')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('competances')} className={`nav-link ${this.state.tab === "competances" && "active"}`} data-toggle="tab" role="tab">{global._trans('client', 'competanceDechet')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('history')} className={`nav-link ${this.state.tab === "history" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'history')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => this.handleTab('files')} className={`nav-link ${this.state.tab === "files" && "active"}`} data-toggle="tab" role="tab">{global._trans('common', 'files')}</Link>
                        </li>
                    </ul>
                    <div className="pt-5">{this.getTabBody()}</div>
                    <div className="d-flex justify-content-end">
                        <div className="card-toolbar mx-4">
                            <Link to={`/client/communities/syndicat${this.props.querySyndicat}`} className="btn btn-secondary" >{global._trans('common', 'goBack')}</Link>
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}
function mapState(state) {
    const { Syndicat, querySyndicat } = state.collectivitie;
    const { listEnums: { function_person } } = state.enums;
    return { Syndicat, querySyndicat, function_person }
}
const connectedClass = connect(mapState, { fetchSyndicat })(ViewSyndicat)
export { connectedClass as ViewSyndicat }