import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class AutreActivite extends Component {
    render() {
        return (
            <CommonComponent keyName="autreActivite" titleSetting={global._trans('sites', 'autherActivite')} autreActivite={this.props.autreActivite} />
        )
    }
}
function mapState(state) {
    const { listEnums: { autreActivite } } = state.enums
    return { autreActivite }
}
const connectedClass = connect(mapState, null)(AutreActivite)
export { connectedClass as AutreActivite }