import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal, Spin } from 'antd'
import { Link } from 'react-router-dom'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_sage/_helpers";
import { fetchContracts, deleteContracts } from "../../../modules/Contract/contract.actions";
import { ImportData } from '../Commons'
import { AgGrid } from '../Commons';
import { message } from 'antd';
import { exportData } from "../../../modules/Common/common.actions";
import { notification } from 'antd';

class ListContracts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            first_load: true,
            listModes: {},
            loading: true,
            total: 0,
            page: 1,
            page_size: 20,
            list: [],
            loadingDelete: false,
            columnDefs: [
                {headerName: "#", field: 'id_contrat', filter: 'agNumberColumnFilter'},
                {headerName: global._trans('contracts', 'contractant'), field: "contractant.full_name"},
                {headerName: global._trans("sites", "maitre_ouvrage"), field: "client.full_name"},
                {headerName: global._trans('contracts', 'siteContract'), field: 'site.denomination'},
                {headerName: global._trans('contracts', 'modeGestion'), field: 'modeGestion', filter: "multiChoiceFilter", filterParams: { 
                    suppressFilter: true, 
                    choices: this.state?.listModes ?? {}
                }},
                {headerName: global._trans('contracts', 'categorieSite'), field: 'site.categorieSite', filter: "multiChoiceFilter", filterParams: { suppressFilter: true, choices: {"UVE": "UVE", "TMB": "TMB", "TRI": "TRI", "ISDND": "ISDND"} }},
                {headerName: global._trans('contracts', 'startDate'), field: 'dateDebut', filter: 'agDateColumnFilter', format: 'd/m/Y'},
                {headerName: global._trans('contracts', 'endDate'), field: 'dateFin', filter: 'agDateColumnFilter', format: 'd/m/Y'},
                {headerName: "", minWidth: 150, colId: "Actions", field: 'id_contrat', sortable: false, filter: false, cellRendererFramework: params => {
                    return <div className="d-flex">
                        <a href={'/contrat/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Edit.svg")}></SVG></span>
                            </button>
                        </a>
                        <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-danger"} onClick={() => this.deleteContact(params.value)}>
                            <span className={"svg-icon svg-icon-md svg-icon-danger"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG></span>
                        </button>
                        <a href={'/contrat/view/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"} to={'/contrat/view/' + params.value}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/View.svg")}></SVG></span>
                            </button>
                        </a>
                    </div>
                }}
            ],
            view_save: 0
        }
        props.fetchContracts({pagination: {page: this.state.page, page_size: this.state.page_size}, first_load: true})
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.mode_gestion.length > 0) {
            this.state.columnDefs.forEach(item => {
                if (item.field == "modeGestion") {
                    item.filterParams.choices = this.props.mode_gestion?.reduce((acc, item) => {acc[item.label] = item.label;return acc;}, {}) ?? {}
                }
            })
        }
        if (this.state.loading && nextProps.listContracts && nextProps.listContracts.data) {
            this.setState({
                list: nextProps.listContracts.data,
                total: nextProps.listContracts.total,
                page: nextProps.listContracts.current_page,
                page_size: nextProps.listContracts.per_page,
                loading: false,
            }, () => {
                this.childComponentRef.onLoadingDone(this.state.first_load)
                if (this.state.first_load) this.setState({first_load: false})
            })
        }
    }
    deleteContact(idContract) {
        Modal.confirm({
            title: global._trans('contracts', 'deleteContract') + " #" + idContract,
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('contracts', 'bodyDeleteContract'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loading: true
                })
                this.props.deleteContracts({ contracts: [idContract] }).then((ok) => {
                    if (!ok) message.error(global._trans('settings', 'cantDelete'));
                    this.setState({
                        loading: false
                    })
                })
            }
        });
    }
    handleImportExcel = (show) => {
        this.setState({
            importData: show
        })
    }
    handleExportExcel = (nameImport) => {
        let columns = {}
        this.childComponentRef.state.columns.filter(item => item.visible && item.colId != "Actions").forEach(item => {
            columns[item.colId] = item.colDef.headerName
        });
        exportData({...nameImport, columns, filter: this.childComponentRef.gridApi.getFilterModel()})
        .then(({ message, ok }) => {
            if (ok && message == "Import Started") {
                notification.info({
                    message: global._trans('common', 'titleImported'),
                    description: global._trans('common', 'bodyImportedMessage'),
                    placement: 'bottomLeft',
                    // duration: 0,
                    style: {
                        width: 500,
                        padding: '15px 20px',
                        borderRadius: 8
                    },
                })
                // onHide()
            }
        })
        .catch((e) => {
        console.log('🚀 => e', e)

        })
        .finally((f) => {
            console.log('🚀 => f', f)
            // setLoading(false)
        })
    }
    handleTableChange = (filter, sort, pagination) => {
        this.setState({
            loading: true,
            list: []
        }, () => this.props.fetchContracts({sort, filter, pagination}));
    }
    handleSaveView = () => {
        this.setState({
            view_save: 2
        }, () => {
            this.childComponentRef.saveCurrentView()
        })
    }
    handleViewSaved = (value) => {
        this.setState({
            view_save: value
        })
    }
    render() {
        return (
            <>
                <div className="card-header header-center py-3">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('contracts', 'listTitle')} ({this.state.total} éléments trouvés)</span>
                    </h3>
                    <div className="card-toolbar my-0">
                        {this.state.view_save > 0 && <button title={"Enregistrer l'état de la vue (filtre, ordre, disposition et taille des colonnes)"} disabled={this.state.view_save == 2} className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.handleSaveView()}>
                            <span className="svg-icon svg-icon-md" style={{margin: 0}}>
                                <SVG hidden={this.state.view_save != 1} src={toAbsoluteUrl("/media/svg/icons/Costum/Save.svg")} className="h-50 align-self-center"></SVG>
                                <SVG hidden={this.state.view_save != 2} src={toAbsoluteUrl("/media/svg/icons/Costum/Loading.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                        </button>}
                        <button className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.childComponentRef.viewsList(true)}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Columns.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Views management')}
                        </button>
                        <button className="btn btn-light-success font-weight-bolder font-size-sm " onClick={() => this.handleImportExcel('contrats')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'importData')}
                        </button>
                        <button className="btn btn-light-primary font-weight-bolder font-size-sm mx-3" onClick={() => this.handleExportExcel({ type:'contrats'})}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            Exporter
                        </button>
                        <Link to="/contrat/new" className="btn btn-primary font-weight-bolder font-size-sm">
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Costum/Add.svg"
                                    )}
                                    className="h-50 align-self-center"
                                ></SVG>
                            </span>
                            {global._trans('contracts', 'createContract')}
                        </Link>
                    </div>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingDelete}>
                    <div className="card-body py-4" style={{ minHeight: "650px", width: '100%' }}>
                        <AgGrid
                            rows={this.state.list}
                            columns={this.state.columnDefs}
                            pagination={{total: this.state.total, pageSize: this.state.page_size, current: this.state.page}}
                            onChange={this.handleTableChange}
                            loading={this.state.loading}
                            ref={ref => this.childComponentRef = ref}
                            views_key={"contracts"}
                            onViewSaved={this.handleViewSaved}
                        />
                    </div>
                </Spin>
                <ImportData show={!!this.state.importData} sourceFile={'contrats'} title={global._trans('common', 'titelImport')} onHide={() => this.handleImportExcel(false)} />
            </>
        )
    }
}

function mapState(state) {
    const { listEnums: { contract, mode_gestion } } = state.enums
    const { contractErrors, listContracts } = state.contract
    return { contract, contractErrors, listContracts, mode_gestion }
}
const connectedClass = connect(mapState, { fetchContracts, deleteContracts })(ListContracts)
export { connectedClass as ListContracts }