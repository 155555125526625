import React, { Component } from 'react'
import { Checkbox } from 'antd';

class MultiChoiceFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_load: true,
      checkedStates: Object.keys(this.props.choices).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}),
    };
  }

  handleReset = () => {
    const { checkedStates } = this.state
    const checked = Object.keys(checkedStates).filter(key => checkedStates[key] === true)
    if (checked.length > 0) {
      const unchecked = Object.keys(checkedStates).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {})
      this.setState({
        checkedStates: unchecked
      }, () => {
        this.props.filterChangedCallback();
      })
    }
  }

  handleChange = (event) => {
    let { name, checked } = event.target;
    this.setState((prev) => {
      return {checkedStates: {...prev.checkedStates, [name]: checked}}
    }, () => {
      this.props.filterChangedCallback();
    })
  }

  doesFilterPass = (params) => {
    return true
  };

  isFilterActive = (e) => {
    return Object.keys(this.state?.checkedStates).filter(key => this.state?.checkedStates[key] === true).length > 0
  };

  getModel = () => {
    const { checkedStates } = this.state
    const checked = Object.keys(checkedStates).filter(key => checkedStates[key] === true)
    if (checked.length > 0) {
      return {
        filterType: 'multichoice',
        filter: checked,
      };
    }
    return null;
  };

  setModel = (model) => {
    let filter = {}
    if (model) filter = model.filter;
    if (Object.keys(filter).length > 0) {
      let checked = {}
      Object.keys(filter).forEach(key => checked[filter[key]] = true)
      this.state.checkedStates = {...this.state.checkedStates, ...checked}
      this.setState((prevState) => {
        return {checkedStates: {...prevState.checkedStates, ...checked}}
      })
    }
  }

  render() {
    const { checkedStates } = this.state;
    return <div className={`ag-filter-body-wrapper`} style={{minWidth: "177px"}}>
      {this.props.choices && Object.keys(this.props.choices).map((key) => {
        return <Checkbox checked={checkedStates[key]} key={key} name={key} onChange={this.handleChange} style={{ margin: 0, fontSize: "12px", padding: "5px 10px", border: "1px solid #EBEDF3" }}>
          {this.props.choices[key]}
        </Checkbox>
      })}
      <button className={`btn btn-primary btn-sm`} onClick={this.handleReset} disabled={Object.keys(checkedStates).filter(key => checkedStates[key] === true).length == 0} style={{padding: "4px", margin: "5px 10px"}}>{global._trans('common', 'Reset')}</button>
    </div>
    
  }
}



export default MultiChoiceFilterComponent;
