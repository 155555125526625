import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, Form, Col, Card, ListGroup } from "react-bootstrap";
import { notification } from 'antd';
import { ModalProgressBar } from "../../../../../../_sage/_partials/controls";
import { Link, Redirect } from 'react-router-dom'
import { CostumAlert, PersoneSelector, PhonePicker } from '../../../Commons'
import { createUserPrem } from '../../../../../modules/User/user.actions'
class CreateUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            errors: null,
            alert: false,
            showSelect: false,
            nom: null,
            prenom: null,
            email: null,
            phone: "",
            nbSession: null,
            showSelect: false,
            client: null
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this)
        this.hideSelectClient = this.hideSelectClient.bind(this)
        this.setSelection = this.setSelection.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            this.setState({
                loading: false,
                errors: nextProps.usersErrors,
                alert: true
            })
        }
    }
    handleChangeEvent(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleChange(name, value) {
        this.setState({
            [name]: value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        if (!this.state.loading) {
            if (this.state.client) {
                let user = {
                    nom: this.state.nom,
                    prenom: this.state.prenom,
                    email: this.state.email,
                    nbSession: this.state.nbSession,
                    phone: this.state.phone,
                    client: this.state.client
                }
                this.props.createUserPrem(user)
                this.setState({
                    loading: true,
                    alert: false
                })
            }
        }

    }
    hideSelectClient() {
        let prevShow = this.state.showSelect
        this.setState({
            showSelect: !prevShow
        })
    }
    setSelection(client) {
        this.setState({
            showSelect: false,
            client: client
        })
    }
    showErrors() {
        if (this.state.alert) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                notification.success({
                    message: global._trans('users', 'titleMessagePremCreate'),
                    description: global._trans('users', 'bodyMessagePremCreate'),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}

                })
                return <Redirect to="/users/premium" />
            }
        }
    }
    render() {
        return (
            <div className="card card-custom">
                {this.state.loading && <ModalProgressBar />}
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('users', 'titleUserPremCreate')}</span>
                    </h3>
                </div>
                <div className="card-body py-4">
                    {this.showErrors()}
                    <div className="form px-5 pb-5">
                        <Form method="post" onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'firstName')}*</Form.Label>
                                    <Form.Control type="text" name="nom" placeholder={global._trans('common', 'firstName')} onChange={this.handleChangeEvent} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'lastName')}*</Form.Label>
                                    <Form.Control type="text" name="prenom" placeholder={global._trans('common', 'lastName')} onChange={this.handleChangeEvent} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'email')}*</Form.Label>
                                    <Form.Control type="email" name="email" placeholder={global._trans('common', 'email')} onChange={this.handleChangeEvent} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('users', 'nbSession')}*</Form.Label>
                                    <Form.Control type="number" name="nbSession" placeholder={global._trans('users', 'nbSession')} onChange={this.handleChangeEvent} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('common', 'phone')}</Form.Label>
                                    <PhonePicker
                                        value={this.state.phone}
                                        onChange={(value) => this.handleChange("phone", value)} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('users', 'moralPerson')}*</Form.Label>
                                    {this.state.client ? <Card>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item><strong>{this.state.client.name} : </strong>{'  ' + this.state.client[this.state.client.dataIndex]}</ListGroup.Item>
                                            <ListGroup.Item><strong>Adresse: </strong>{'  ' + this.state.client.adresse}</ListGroup.Item>
                                            <ListGroup.Item><Card.Link onClick={this.hideSelectClient}><strong>Changer le client rattacher</strong></Card.Link></ListGroup.Item>
                                        </ListGroup>
                                    </Card> :
                                        <Button variant="outline-secondary" size="md" block onClick={this.hideSelectClient}>{global._trans('users', 'selectPerson')}</Button>}
                                </Form.Group>
                            </Form.Row>
                            <div className="d-flex justify-content-end">
                                <div className="card-toolbar mx-4">
                                    <Link to="/users/premium" className="btn btn-secondary" >{global._trans('common', 'cancelBtn')}</Link>
                                </div>
                                <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{global._trans('users', 'submitText')}</Button>
                            </div>
                        </Form>
                    </div>
                    {this.state.showSelect && <PersoneSelector show={this.state.showSelect} validSelection={this.setSelection} onHide={this.hideSelectClient} />}
                </div>
            </div>
        )
    }
}
function mapState(state) {
    const { usersErrors } = state.users
    return { usersErrors }
}
const connectedClass = connect(mapState, { createUserPrem })(CreateUser)
export { connectedClass as CreateUser }