import React, { Component } from "react";
import { Layout } from "../../_sage/layout";
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import { NotFound, MapSites, SitesPage, Settings, Collectivities, SocietiePage, ContactPage, GestionairePage, AdminsPage, ContractPage, Users, SharePage, LoadingApp, Login, OTP, Logout, ForgetPassword, Dashboard, GEDPages } from '../pages'
import { connect } from "react-redux";
import { FetchEnums, fetchRegions, fetchDepartements } from "../modules/Settings/settings.actions";
import { setsiteUrls } from '../modules/Sites/sites.constants'
import { setmapUrls } from '../modules/MapSites/mapsites.constants'
import Echo from "laravel-echo"
class Routes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            actionType: props.authToken ? 1 : 2
        }
    }
    componentWillReceiveProps(nextProps) {
        const { authToken, user } = nextProps
        if (authToken && user) {
            this.props.FetchEnums();
            this.props.fetchRegions();
            this.props.fetchDepartements();
            if (user.typeuser === "Gestionnaire") {
                setsiteUrls()
                setmapUrls()
            }
            this.setState({
                actionType: 3
            })

            

            // window.Echo = new Echo({
            //     broadcaster: 'pusher',
            //     key: process.env.REACT_APP_PUSHER_APP_KEY,
            //     // wsHost: process.env.REACT_APP_SOCKET_HOST,
            //     // wsPort: process.env.REACT_APP_SOCKET_PORT,
            //     forceTLS: false,
            //     disableStats: true,
            // });
        } else {
            this.setState({
                actionType: 2
            })
        }
    }
    renderComponent(page) {
        return <Layout>{page}</Layout>
    }
    render() {
        return (
            this.state.actionType === 1 ?
                <LoadingApp /> : this.state.actionType === 2 ?
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/otp" component={(props) => <OTP {...props} />} />
                        <Route path="/forget-password" component={ForgetPassword} />
                        <Route path="/password/:token" component={ForgetPassword} />
                        <Redirect from="*" to="/login" />
                    </Switch> :
                    <Switch>
                        <Route path="/map/site" >{(props) => this.renderComponent(<MapSites {...props} />)}</Route>
                        {this.props.user && this.props.user.typeuser === "SupAdmin" && <Route path="/admin" >{(props) => this.renderComponent(<AdminsPage {...props} />)}</Route>}
                        <Route path="/manager" >{(props) => this.renderComponent(<GestionairePage {...props} />)}</Route>
                        <Route path="/users" >{(props) => this.renderComponent(<Users {...props} />)}</Route>
                        <Route path="/client/companies" >{(props) => this.renderComponent(<SocietiePage {...props} />)}</Route>
                        <Route path="/client/communities" >{(props) => this.renderComponent(<Collectivities {...props} />)}</Route>
                        <Route path="/contact" >{(props) => this.renderComponent(<ContactPage {...props} />)}</Route>
                        <Route path="/contrat" >{(props) => this.renderComponent(<ContractPage {...props} />)}</Route>
                        <Route path="/sites" >{(props) => this.renderComponent(<SitesPage {...props} />)}</Route>
                        <Route path="/shares/site" >{(props) => this.renderComponent(<SharePage {...props} />)}</Route>
                        {this.props.user && (this.props.user.typeuser === "SupAdmin" || this.props.user.typeuser === "Admin" || this.props.user.typeuser === "Gestionnaire") && <Route path="/ged" >{(props) => this.renderComponent(<GEDPages  {...props} />)}</Route>}
                        {this.props.user && (this.props.user.typeuser === "SupAdmin" || this.props.user.typeuser === "Admin") && <Route path="/settings" >{(props) => this.renderComponent(<Settings  {...props} />)}</Route>}
                        <Route path="/logout" component={Logout} />
                        <Route path="/error" component={NotFound} />
                        <Redirect from="/" exact={true} to="/map/site" />
                        <Redirect from="/login" exact={true} to="/map/site" />
                        <Redirect from="/otp" exact={true} to="/map/site" />
                        <Redirect from="*" to="/error" />
                    </Switch>
        )
    }
}
function mapState(state) {
    const { authToken, user } = state.auth
    return { authToken, user }
}
const connectedClass = connect(mapState, { FetchEnums, fetchRegions, fetchDepartements })(Routes)
export { connectedClass as Routes }