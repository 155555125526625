import React from 'react';
import { Checkbox } from 'antd';
import { ListGroup, Button } from "react-bootstrap";

export const TechColumns = ({ columns, onSelectRow, typeShare, dataShare, onCheckboxChange, onSecondLevelCheckboxChange }) => {
    console.log(' 🚀  => dataShare:', dataShare)
    let checkedColumns = dataShare[0].columns;
    if (typeShare == 'Site') {
        let categories = dataShare.map(site => (site.categorieSite))
        columns = Object.keys(columns).filter((column) => ['generalInfo', 'Client', 'Company'].concat(categories).includes(column)).reduce((acc, column, index) => ({
            ...acc,
            [column]: columns[column]
        }), {});
    } else {
        columns = Object.keys(columns).filter(column => column !== 'contracts').reduce((acc, column, index) => ({
            ...acc,
            [column]: columns[column]
        }), {});
    }
    return <div>
        {Object.keys(columns).map((column, index) => (
            <>
                <ColumnRow key={index}
                    onSelectRow={(secondLevelRow) => onSelectRow(column, secondLevelRow)}
                    onSecondLevelCheckboxChange={(col, key, value) => onSecondLevelCheckboxChange(column, col, key, value)}
                    checkedColumns={checkedColumns[column]}
                    onChange={(key, value) => {
                        // console.log(' 🚀  => column:', column)
                        // console.log(' 🚀  => key:', key,  ' > value', value)
                        onCheckboxChange(column, key, value)
                    }}
                    columnName={column}
                    column={columns[column]} />
                <br />
                <hr />
                <br />
            </>
        ))}
    </div>
};

const ColumnRow = ({ column, columnName, checkedColumns, onChange, onSecondLevelCheckboxChange, onSelectRow }) => {
    if (Array.isArray(column)) {
        return (
            <div className="ColumnRow mb-3 ColumnRow-Partage" >
                <div className='flx'>
                    <strong className="d-block mb-5">{global._trans('shares', columnName)} : </strong>
                    <Button variant="primary" size="sm" className="ml-2" onClick={() => onSelectRow(false)}>Tout sélectionner</Button>
                </div>
                <div className="row mx-0 gap-3">
                    {column.map((col, index) =>
                        <>
                            <Button size='sm' key={index} className={`${checkedColumns[col] ? 'bg-btn-checked' : 'bg-btn-Unchecked'} mr-3 pr-5 mb-3`}>
                                <Checkbox name={col} checked={!!checkedColumns ? checkedColumns[col] : false}
                                    className='check-partage'
                                    onChange={(e) => onChange(col, e.target.checked)}>{global._trans('sites', col)}</Checkbox>
                            </Button>
                        </>
                    )}
                </div>
            </div>
        )
    } else {
        return Object.keys(column).map((col) => <ColumnRow
            onSelectRow={() => onSelectRow(col)} onChange={(key, value) => onSecondLevelCheckboxChange(col, key, value)}
            checkedColumns={!!checkedColumns && !!checkedColumns[col] ? checkedColumns[col] : {}}
            columnName={col} column={column[col]} />);
    }

}

