import React, { Component } from "react";
import { SageTable } from '../../Commons'
class Sites extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <SageTable
            columns={[
                { title: global._trans('sites', 'denomenation'), dataIndex: "denomination", sorter: true },
                { title: global._trans("sites", "categorieSite"), dataIndex: "categorieSite", sorter: true },
                { title: global._trans("sites", "modeGestion"), dataIndex: "modeGestion", sorter: true },
                { title: global._trans("sites", "restartPeriod"), dataIndex: "perdiocitRelance", sorter: true },
                { title: global._trans("common", "address"), dataIndex: "adresse", sorter: true },
                { title: global._trans("common", "standrPhone"), dataIndex: "telephoneStandrad", sorter: true },
                { title: global._trans("sites", "createYear"), dataIndex: "anneeCreation", sorter: true },
            ]}
            style={{ height: 605 }}
            data={this.props.sites}
            IterableProps={{
                emptyText: global._trans("sites", "noSitesAttached"),
                rowKey: "id_site"
            }}
        />
    }
}

export { Sites };