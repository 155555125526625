export const actionTypes = {
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    UserLoaded: "[Load User] Auth API",
    SetUser: "[Set User] Action"
}
export const authUrls = {
    LOGIN_URL: 'login',
    USER_URL: "info/me",
    SEND_EMAIL_URL: "users/send-admin-email",
    UPDATE_PASSWORD_URL:"password/update",
    OTP_URL: 'otp'
}