import React, { Component } from 'react'
import { connect } from "react-redux";
import { Form } from 'react-bootstrap'
import { Modal, Spin } from 'antd'
import { NavLink, } from 'react-router-dom'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_sage/_helpers";
import { InlineBottunSvg, SageTable, FilterSearch, AgGrid } from '../../../Commons'
import { fetchUsersPrem, deleteUsersPrem, fetchUsersPremPost } from '../../../../../modules/User/user.actions'
import { FilterUsers } from './utils'
const queryString = require('query-string');
class ListUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            first_load: true,
            loading: true,
            total: 0,
            page: 1,
            page_size: 20,
            list: [],
            loadingDelete: false,
            columnDefs: [
                {headerName: "", minWidth: 120, colId: "Actions", field: 'id_user_premieum', sortable: false, filter: false, cellRendererFramework: params => {
                    return <div className="d-flex">
                        <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-danger"} onClick={() => this.handleDeleteUser(params.value)}>
                            <span className={"svg-icon svg-icon-md svg-icon-danger"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG></span>
                        </button>
                        <a href={'/users/premium/view/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"} to={'/users/premium/view/' + params.value}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/View.svg")}></SVG></span>
                            </button>
                        </a>
                    </div>
                }},
                {headerName: "#", field: 'id_user_premieum', filter: 'agNumberColumnFilter'},
                {headerName: global._trans('common', 'firstName'), field: "nom"},
                {headerName: global._trans('common', 'lastName'), field: "prenom"},
                {headerName: global._trans('common', 'userName'), field: "user.username"},
                {headerName: global._trans('common', 'initPassword'), field: "user.init_password", cellRendererFramework: params => params.value ? params.value : <strong>MdP modifié</strong> },
                {headerName: global._trans('users', 'nbSession'), field: "nbAccess", filter: 'agNumberColumnFilter'},
                {headerName: global._trans('common', 'email'), field: "email_user_prem"},
                {headerName: global._trans('common', 'phone'), field: "phone"},
                {headerName: global._trans('users', 'moralPerson'), field: "client.client.full_name", flex: 1},
            ],
            view_save: 0
        }
        this.toReset = ['form_search_list', 'form_search_input']
        this.filterTab = {}
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.handleSorter = this.handleSorter.bind(this)
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
        this.handleChangeSearch = this.handleChangeSearch.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.handleDeleteUser = this.handleDeleteUser.bind(this)
        this.deleteSelected = this.deleteSelected.bind(this)
        this.handleResetSearch = this.handleResetSearch.bind(this)
        props.fetchUsersPremPost({pagination: {page: this.state.page, page_size: this.state.page_size}, first_load: true})
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.listUsersPrem && Array.isArray(nextProps.listUsersPrem.data)) {
                this.setState({
                    list: nextProps.listUsersPrem.data,
                    total: nextProps.listUsersPrem.total,
                    page: nextProps.listUsersPrem.current_page,
                    page_size: nextProps.listUsersPrem.per_page,
                    loading: false,
                }, () => {
                    this.childComponentRef.onLoadingDone(this.state.first_load)
                    if (this.state.first_load) this.setState({first_load: false})
                })
            }
        }
        if (this.state.loadingDelete) {
            this.setState({
                loading: true,
                loadingDelete: false,
            }, () => {
                this.props.fetchUsersPremPost({pagination: {page: this.state.page, page_size: this.state.page_size}})
            })
        }
    }
    handleSelectRow(selectedRows) {
        this.setState({
            selectedRows: selectedRows
        })
    }
    handleTypeFilter(checked) {
        this.setState({
            filters: {
                ...this.state.filters,
                typeFilter: checked ? "inter" : null
            }
        })
    }
    handleTableChange = (filter, sort, pagination) => {
        this.setState({
            loading: true,
            list: []
        }, () => this.props.fetchUsersPremPost({sort, filter, pagination}));
    }
    handleSaveView = () => {
        this.setState({
            view_save: 2
        }, () => {
            this.childComponentRef.saveCurrentView()
        })
    }
    handleViewSaved = (value) => {
        this.setState({
            view_save: value
        })
    }
    handleSorter(sorter) {
        let prevfilter = this.state.filters
        prevfilter.sort = sorter.value
        prevfilter.sorter = sorter.current
        this.handleFetchData(prevfilter)
    }
    handleFetchData(filter) {
        this.props.history.push({
            search: queryString.stringify(filter)
        })
        this.props.fetchUsersPrem(filter)
        this.setState({
            loading: true
        })
    }
    handleDeleteUser(idUser) {
        Modal.confirm({
            title: global._trans('users', 'deleteTitle') + " #" + idUser,
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('users', 'bodyDelete'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadingDelete: true,
                    list: []
                })
                this.props.deleteUsersPrem({ users: [idUser] })
            }
        });
    }
    deleteSelected() {
        Modal.confirm({
            title: global._trans('common', 'deleteAllTitle'),
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('sites', 'deleteBody'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadingDelete: true
                })
                this.props.deleteUsersPrem({ users: this.state.selectedRows })
            }
        });
    }
    handleSubmitSearch(e) {
        e.preventDefault()
        this.state.filters = {
            ...this.state.filters,
            ...this.filterTab
        }
        this.handleFetchData(this.state.filters)
    }
    handleChangeSearch(e) {
        const { name, value } = e.target
        this.filterTab[name] = value
    }
    handleResetSearch() {
        let resteData = {
            page: this.state.filters.page,
            pageSize: this.state.filters.pageSize,
            source: this.state.filters.source,
        }
        this.state.filters = resteData
        this.filterTab = {}
        this.toReset.map(item => {
            let formReset = document.getElementById(item)
            if (formReset) formReset.reset();
        })
        this.handleFetchData(resteData)
    }
    showReset() {
        const keys = Object.entries(this.state.filters).filter(item => item[1] && (item[0] !== 'typeFilter'));
        return keys.length > 3
    }
    render() {
        return (
            <div className="card card-custom">
                <div className="card-header header-center py-1">
                    <h3 className="card-title">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('users', 'titleListPrem')} ({this.state.total} élément trouvé)</span>
                    </h3>
                    <div className="card-toolbar">
                            {this.state.view_save > 0 && <button title={"Enregistrer l'état de la vue (filtre, ordre, disposition et taille des colonnes)"} disabled={this.state.view_save == 2} className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.handleSaveView()}>
                                <span className="svg-icon svg-icon-md" style={{margin: 0}}>
                                    <SVG hidden={this.state.view_save != 1} src={toAbsoluteUrl("/media/svg/icons/Costum/Save.svg")} className="h-50 align-self-center"></SVG>
                                    <SVG hidden={this.state.view_save != 2} src={toAbsoluteUrl("/media/svg/icons/Costum/Loading.svg")} className="h-50 align-self-center"></SVG>
                                </span>
                            </button>}
                            <button className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.childComponentRef.viewsList(true)}>
                                <span className="svg-icon svg-icon-md">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Columns.svg")} className="h-50 align-self-center"></SVG>
                                </span>
                                {global._trans('common', 'Views management')}
                            </button>
                        <NavLink to="/users/premium/new" className="btn btn-primary font-weight-bolder font-size-sm">
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('users', 'newUserPremi')}
                        </NavLink>
                    </div>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingDelete}>
                    <div className="card-body py-4">
                        <AgGrid
                                rows={this.state.list}
                                columns={this.state.columnDefs}
                                pagination={{total: this.state.total, pageSize: this.state.page_size, current: this.state.page}}
                                onChange={this.handleTableChange}
                                loading={this.state.loading}
                                ref={ref => this.childComponentRef = ref}
                                views_key={"users_premieums"}
                                onViewSaved={this.handleViewSaved}
                            />
                    </div>
                </Spin>
            </div>
        )
    }
}
function mapState(state) {
    const { listUsersPrem, currentQuery } = state.users
    return { listUsersPrem, currentQuery }
}
const connectedClass = connect(mapState, { fetchUsersPrem, fetchUsersPremPost, deleteUsersPrem })(ListUsers)
export { connectedClass as ListUsers }