import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class NatureJuridique extends Component {
    render() {
        return (
            <CommonComponent keyName="nature_juridique" titleSetting={global._trans('common', 'natureJuridque')} nature_juridique={this.props.nature_juridique} />
        )
    }
}
function mapState(state) {
    const { listEnums: { nature_juridique } } = state.enums
    return { nature_juridique }
}
const connectedClass = connect(mapState, null)(NatureJuridique)
export { connectedClass as NatureJuridique }