import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ListUsers } from './ListUsers'
class UserPage extends Component {
    render() {
        return (
            <Switch>
                <Route
                    path="/users/simple"
                    component={ListUsers}
                />
            </Switch>
        )
    }
}
export { UserPage as UserSimple }