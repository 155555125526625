import React, { Component } from 'react';
import { Form, Col } from "react-bootstrap";
import { connect } from 'react-redux'
import { Select } from 'antd';
class DataTechTMB extends Component {
    render() {
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'quantiteRefus')}</Form.Label>
                        <Form.Control type="number" value={this.props.stateTech.quantiteRefus} placeholder={global._trans('sites', 'quantiteRefus')} name="quantiteRefus" onChange={this.props.handleEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'CSRProduit')}</Form.Label>
                        <Form.Control type="number" value={this.props.stateTech.CSRProduit} placeholder={global._trans('sites', 'CSRProduit')} name="CSRProduit" onChange={this.props.handleEvent} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'envoiPreparation')}</Form.Label>
                        <Form.Control type="number" value={this.props.stateTech.envoiPreparation} placeholder={global._trans('sites', 'envoiPreparation')} name="envoiPreparation" onChange={this.props.handleEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'tonnageAnnuel')}</Form.Label>
                        <Form.Control type="number" value={this.props.stateTech.tonnageAnnuel} placeholder={global._trans('sites', 'tonnageAnnuel')} name="tonnageAnnuel" onChange={this.props.handleEvent} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'capaciteNominal')}</Form.Label>
                        <Form.Control type="number" value={this.props.stateTech.capaciteNominal} placeholder={global._trans('sites', 'capaciteNominal')} name="capaciteNominal" onChange={this.props.handleEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'dernierConstruct')}</Form.Label>
                        <Form.Control type="text" value={this.props.stateTech.dernierConstruct} placeholder={global._trans('sites', 'dernierConstruct')} name="dernierConstruct" onChange={this.props.handleEvent} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'typeInstallation')}</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('sites', 'typeInstallation')}
                            style={{ width: '100%' }}
                            options={this.props.typeInstallation}
                            optionFilterProp="label"
                            value={this.props.stateTech.typeInstallation}
                            onChange={(selectedValue) => this.props.handleName("typeInstallation", selectedValue)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'typeDechetAccepter')}</Form.Label>
                        <Select
                            mode="multiple"
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('sites', 'typeDechetAccepter')}
                            style={{ width: '100%', overflow: 'scroll' }}
                            options={this.props.typeDechetAccepter}
                            optionFilterProp="label"
                            value={this.props.stateTech.typeDechetAccepter}
                            onChange={(selectedValue) => this.props.handleName("typeDechetAccepter", selectedValue)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'technologie')}</Form.Label>
                        <Select
                            mode='multiple'
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('sites', 'technologie')}
                            style={{ width: '100%', overflow: 'scroll' }}
                            options={this.props.technologie}
                            optionFilterProp="label"
                            value={this.props.stateTech.technologie}
                            onChange={(selectedValue) => this.props.handleName("technologie", selectedValue)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'valorisationEnergitique')}</Form.Label>
                        <Select
                            mode='multiple'
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('sites', 'valorisationEnergitique')}
                            style={{ width: '100%', overflow: 'scroll' }}
                            options={this.props.valorisationEnergitique}
                            optionFilterProp="label"
                            value={this.props.stateTech.valorisationEnergitique}
                            onChange={(selectedValue) => this.props.handleName("valorisationEnergitique", selectedValue)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'autreActivite')}</Form.Label>
                        <Select
                            mode='multiple'
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('sites', 'autreActivite')}
                            style={{ width: '100%', overflow: 'scroll' }}
                            options={this.props.autreActivite}
                            optionFilterProp="label"
                            value={this.props.stateTech.autreActivite}
                            onChange={(selectedValue) => this.props.handleName("autreActivite", selectedValue)}
                        />
                    </Form.Group>
                </Form.Row>
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { typeInstallation, typeDechetAccepter, technologie, valorisationEnergitique, autreActivite } } = state.enums
    return { typeInstallation, typeDechetAccepter, technologie, valorisationEnergitique, autreActivite }
}
const connectedClass = connect(mapState, {})(DataTechTMB)
export { connectedClass as DataTechTMB }