import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { notification } from 'antd';
import { importData } from "../../../../modules/Common/common.actions";
import { Loader } from '../../Commons'
export const ImportSites = ({ show, onHide, title }) => {
    const [file, setFile] = useState(null)
    const [typeSites, setTypeSites] = useState(false)
    const [loading, setLoading] = useState(false);
    const URL_ROOT = process.env.REACT_APP_HOST_API;
    const examplesLinks = {
        sites_isdnd: '/media/excels/models/sites_isdnd.xlsx',
        sites_tmb: '/media/excels/models/sites_tmb.xlsx',
        sites_tri: '/media/excels/models/sites_tri.xlsx',
        sites_uve: '/media/excels/models/sites_uve.xlsx'
        // SitesTMB: '/media/excels/SITES_TMB.xlsx',
        // SitesTMBMAJ: `${URL_ROOT}data/download/SitesTMBMAJ`,
        // SitesISDND: '/media/excels/SITES_ISDND.xlsx',
        // SitesISDNDMAJ: `${URL_ROOT}data/download/SitesISDNDMAJ`,
        // SitesUVE: '/media/excels/SITE_UVE.xlsx',
        // SitesTRI: ''
    };

    const onChange = (e) => {
        const file = e.target.files[0];
        setFile(file)
    }
    const onConfirm = () => {
        let dataImport = new FormData();
        dataImport.append('file', file)
        dataImport.append('typeData', typeSites)
        setLoading(true)
        importData(dataImport)
            .then(({ message, ok }) => {
                if (ok && message == "Import Started") {
                    notification.info({
                        message: global._trans('common', 'titleImported'),
                        description: global._trans('common', 'bodyImportedMessage'),
                        placement: 'bottomLeft',
                        // duration: 0,
                        style: {
                            width: 500,
                            padding: '15px 20px',
                            borderRadius: 8
                        },
                    })
                    onHide()
                }
            })
            .catch(({ }) => {

            })
            .finally(() => {
                setLoading(false)
            })
    }
    const handleChange = (e) => {
        const { value } = e.target
        setTypeSites(value)
    }
    return (
        <Modal size="md" centered backdrop="static" show={show} onHide={onHide}>
            {loading && <Loader />}
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between px-5 py-4">
                    <span className="card-label font-size-h4 font-weight-bolder text-dark">{title}</span>
                </div>
                <div className="card-body py-4">
                    <div className="mb-3">

                        <label>{global._trans('sites', 'categorieSite')}</label>
                        <select className="form-control form-control-solid form-control-lg" onChange={handleChange}>
                            <option value="">Sélectionner catégorie</option>
                            <option value="sites_uve">UVE</option>
                            <option value="sites_tri">TRI</option>
                            <option value="sites_tmb">TMB</option>
                            <option value="sites_isdnd">ISDND</option>
                            {/* <option value="SitesTMB">TMB</option>
                            <option value="SitesTMBMAJ">Mise a jour TMB</option>
                            <option value="SitesISDND">ISDND</option>
                            <option value="SitesISDNDMAJ">Mise a jour ISDND</option>
                            <option value="SitesUVE">UVE</option>
                            <option disabled value="SitesTRI">TRI</option> */}
                        </select>
                    </div>
                    <div className="">
                        <label>{global._trans('common', 'selectFileExcel')}</label>
                        <input type="file" name="excel_file" onChange={onChange} className="btn btn-light btn-block" />
                    </div>
                    <div className="pt-8 px-5">
                        {typeSites && <p>
                            <a href={examplesLinks[typeSites]} download>Cliquez ici</a> pour telecharger le model d'importation {typeSites}
                        </p>}
                    </div>
                    <div className="pb-5 px-5 text-dark">
                        <hr />
                        <p className=''>L'insertion/mise à jour sera exécutée en fonction de l'existence du champ <b>'Sinoe'</b></p>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end py-3 px-4 border-top">
                    <button className="btn btn-light font-weight-bolder font-size-sm mr-2" onClick={onHide}>
                        {global._trans('common', 'cancelBtn')}
                    </button>
                    <button className="btn btn-primary font-weight-bolder font-size-sm" onClick={onConfirm}>
                        {global._trans('common', 'submitFile')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}