import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    shareErrors: null,
    listShares: {},
    share:null
};
export const shareSlice = createSlice({
    name: "shares",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.shareErrors = action.payload.errors;
        },
        shareCreated: (state) => {
            state.shareErrors = state.shareErrors === false ? null : false
        },
        sharesFetched: (state, action) => {
            const { listShares } = action.payload
            state.shareErrors = null
            state.listShares = listShares
        },
        shareHandled: (state, action) => {
            const {} = action.payload
        }
    }
});