import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { Steps, notification, Spin } from 'antd';
import { createSyndicat, editSyndicat, updateSyndicat, syncSyndicatsInseeAPI } from "../../../../../modules/Collectivities/collectivitie.actions";
import { CostumAlert } from '../../../Commons'
import { Link, Redirect, Prompt } from 'react-router-dom';
import { GeneraleInfo } from './CreateSteps/GeneraleInfo'
import { CompetanceInfo } from './CreateSteps/CompetancesInfo'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_sage/_helpers';
class CreateSyndicat extends Component {
    constructor(props) {
        super(props)
        let action = props.match.params.idCreate === "new"
        console.log('🚀 => action', action)
        this.state = {
            nature_juridique: null,
            effectifs: null,
            effectifs_year: null,
            region_siege: null,
            departement_siege: null,
            status: 'NOT_VALIDATED',
            competances: {
                exercee: [],
                delegue: []
            },
            amobe: null,
            adresse: null,
            lang: null,
            lat: null,
            city: '',
            country: 'France',
            postcode: '',
            nomCourt: '',
            denominationLegale: '',
            usual_denomination: '',
            serin: '',
            siret: '',
            siteInternet: '',
            telephoneStandard: '',
            nombreHabitant: '',
            nombreHabitant_year: '',
            email: "",
            sinoe: "",
            logo: [],
            // ged_rapport: [],
            loadingCreate: false,
            loading: !action,
            errors: null,
            showAlert: false,
            currentStep: 0,
            state: null
        }
        this.defaultDepartement = []
        this.defaultRegions = []
        this.isNewItem = action
        if (!action) {
            props.editSyndicat(props.match.params.idCreate)
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this._suggestionSelect = this._suggestionSelect.bind(this)
        this.addCompetance = this.addCompetance.bind(this)
        this.handleChangeDelegue = this.handleChangeDelegue.bind(this)
        this.handleCompetanceInfo = this.handleCompetanceInfo.bind(this)
        this.handleDeleteCompetance = this.handleDeleteCompetance.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loadingCreate) {
            this.setState({
                loadingCreate: false,
                errors: nextProps.SyndicatErrors,
                showAlert: true
            })
        }
        if (this.state.loading && (nextProps.Syndicat || nextProps.SyndicatErrors)) {
            this.isNewItem = nextProps.SyndicatErrors ? true : false
            let syndicat = nextProps.Syndicat ? JSON.parse(nextProps.Syndicat) : {}
            this.setState({
                loading: false
            })
            if (syndicat) {
                this.defaultDepartement = [syndicat.departement_siege]
                this.defaultRegions = [syndicat.region_siege]
                this.setState({
                    ...syndicat,
                    departement_siege: !!syndicat.departement_siege ? syndicat.departement_siege.value : '',
                    region_siege: !!syndicat.region_siege ? syndicat.region_siege.value : '',
                    loading: false,
                })
            }
        }
        if (nextProps.Syndicat && nextProps.Syndicat.refresh) {
            this.setState({
                loading: true
            })
            this.props.editSyndicat(this.props.match.params.idCreate)
        }
    }
    handleChangeEvent(e) {
        const { name, value } = e.target
        this.state[name] = value
        if (name === 'siret' && value.length < 10) {
            this.setState({
                serin: value
            })
        }
    }

    isJsonObject(strData) {
        try {
            JSON.parse(strData);
        } catch (e) {
            return false;
        }
        return true;
    }

    handleChange(name, value) {
        if (name == 'departement_siege') {
            let { departements } = this.props;
            let id_region = null;
            let selectedDepartement = Array.isArray(departements) ? departements.find((dept) => dept.id_departement == value) : null;
            if (selectedDepartement) id_region = selectedDepartement.region.id_region;
            return this.setState({
                'departement_siege': value,
                'region_siege': id_region,
            })
        }
        this.setState({
            [name]: value
        })
    }
    _suggestionSelect(adress) {
        this.setState({
            adresse: adress.address,
            lat: adress.lat,
            lang: adress.lang,
            city: adress.city,
            country: adress.country,
            postcode: adress.postcode
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        if (this.state.currentStep === 0) {
            this.setState({
                currentStep: 1
            })
            return 0
        }
        if (!this.state.loadingCreate) {
            let syndicat = {
                nature_juridique: this.state.nature_juridique,
                effectifs: this.state.effectifs,
                effectifs_year: this.state.effectifs_year,
                region_siege: this.state.region_siege,
                departement_siege: this.state.departement_siege,
                amobe: this.state.amobe,
                adresse: this.state.adresse,
                lang: this.state.lang,
                lat: this.state.lat,
                city: this.state.city,
                country: this.state.country,
                status: this.state.status,
                postcode: this.state.postcode,
                nomCourt: this.state.nomCourt,
                denominationLegale: this.state.denominationLegale,
                usual_denomination: this.state.usual_denomination,
                serin: this.state.serin,
                siret: this.state.siret,
                siteInternet: this.state.siteInternet,
                telephoneStandard: this.state.telephoneStandard,
                nombreHabitant: this.state.nombreHabitant,
                nombreHabitant_year: this.state.nombreHabitant_year,
                email: this.state.email,
                sinoe: this.state.sinoe,
                logo: this.state.logo[0] && this.state.logo[0].uid,
                // ged_rapport: this.state.ged_rapport[0] && this.state.ged_rapport[0].uid,
                competance_exercee: this.state.competances.exercee,
                competance_delegue: this.state.competances.delegue,
                state: this.state.state
            }
            if (this.isNewItem) {
                this.props.createSyndicat(syndicat)
            } else {
                syndicat.id_syndicat = this.state.id_syndicat
                this.props.updateSyndicat(syndicat)
            }
            this.setState({
                loadingCreate: true,
                showAlert: false
            })
        }
    }
    showErrors() {
        if (this.state.showAlert) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                notification.success({
                    message: this.isNewItem ? global._trans('clientsSyndicat', 'titleCreateMessage') : global._trans('clientsSyndicat', 'messageUpdateTitle'),
                    description: this.isNewItem ? global._trans('clientsSyndicat', 'bodyCreateMessage') : global._trans('clientsSyndicat', 'messageUpdateBody'),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}

                })
                return <Redirect to="/client/communities/syndicat" />
            }
        }
    }
    handleDeleteCompetance(type) {
        this.setState((prevState) => {
            let newDelegue = prevState.competances[type]
            newDelegue.pop()
            return {
                competances: {
                    ...prevState.competances,
                    [type]: newDelegue
                }
            }
        })
    }

    handleChangeDelegue(key, value) {
        this.setState((prevState) => {
            let newDelegue = prevState.competances.delegue
            newDelegue[key].delegue_competance = value
            return {
                competances: {
                    ...prevState.competances,
                    delegue: newDelegue
                }
            }
        })
    }
    handleCompetanceInfo(type, key, name, value) {
        console.log({ type, key, name, value })
        let competances = {
            ...this.state.competances,
            [type]: [...this.state.competances[type]]
        }

        let competance = { ...this.state.competances[type][key] };
        if (name == 'competence_dechet') {
            let comp = this.props.competence_dechet.find((comp) => comp.value == value);
            competance = {
                ...competance,
                competence_dechet: comp.value,
                competence_dechet_name: comp.label,
                code: comp.code
            }
        } else {
            competance[name] = value;
        }

        competances[type].splice(key, 1, competance);
        console.log({ competance });
        this.setState({
            competances
        });
    }
    addCompetance(type) {
        this.setState((state) => ({
            competances: {
                ...state.competances,
                [type]: [...state.competances[type], { code: '', owner_competance: null, delegue_competance: null, start_date: null, end_date: null, comment: '' }]
            }
        }))
    }
    getContent() {
        switch (this.state.currentStep) {
            case 0: return <GeneraleInfo syndicat={this.state} _suggestionSelect={this._suggestionSelect} handleChange={this.handleChangeEvent} handleName={this.handleChange} defaultDepartement={this.defaultDepartement} defaultRegions={this.defaultRegions} />
            case 1: return <CompetanceInfo competances={this.state.competances} addCompetance={this.addCompetance} deleteCompetance={this.handleDeleteCompetance} handleChangeDelegue={this.handleChangeDelegue} handleChange={this.handleCompetanceInfo} />
        }
    }
    render() {
        let syndicatData = this.props.Syndicat ? !this.isJsonObject(this.props.Syndicat) ? this.props.Syndicat : JSON.parse(this.props.Syndicat) : {};
        return (
            <>
                {/* <Prompt when={true} message={(location) => global._trans('common', 'areYouSure')} /> */}
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{this.isNewItem ? global._trans('clientsSyndicat', 'createSyndi') : `${global._trans('clientsSyndicat', 'editTitle')} - ${!!syndicatData ? syndicatData.denominationLegale : ''}`}</span>
                    </h3>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingCreate}>
                    <div className="separator separator-solid mt-2"></div>
                    <div className="mx-5 px-5 py-4">
                        <Steps current={this.state.currentStep}>
                            <Steps.Step title={global._trans('common', 'informationsGeneral')} />
                            <Steps.Step title="Compétences déchet" />
                        </Steps>
                    </div>
                    <div className="separator separator-solid"></div>
                    <div className="card-body py-4">
                        {this.showErrors()}
                        <div className="form px-5 pb-5">
                            <Form method="post" onSubmit={this.handleSubmit}>
                                {this.getContent()}
                                <div className="d-flex justify-content-end">
                                    <div className="card-toolbar mx-4">
                                        {this.state.currentStep === 0 ? <Link to="/client/communities/syndicat" className="btn btn-secondary" >{global._trans('common', 'cancelBtn')}</Link> : <Link role="button" className="btn btn-secondary" onClick={() => {
                                            this.setState({
                                                currentStep: 0
                                            })
                                        }}>{global._trans('common', 'goBack')}</Link>}
                                    </div>
                                    {!this.isNewItem &&
                                        <Button variant="primary" className="mr-2" onClick={() => this.props.syncSyndicatsInseeAPI({ action: 'sync_array', syndicats: [this.state.siret ? "siret:"+this.state.siret : "siren:"+this.state.serin] })}>
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Costum/Compiling.svg"
                                                )} />
                                            {global._trans('common', 'synchronize')}
                                        </Button>
                                    }
                                    <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{this.state.currentStep === 0 ? global._trans("common", "nextInfo") : this.isNewItem ? global._trans('clientsSyndicat', 'textSubmitSynd') : global._trans('common', 'saveChanges')}</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Spin>
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { nature_juridique, competence_dechet, amobe, effectifs }, departements, regions } = state.enums
    const { SyndicatErrors, Syndicat } = state.collectivitie
    return { nature_juridique, effectifs, competence_dechet, amobe, SyndicatErrors, Syndicat, departements: departements.data, regions: regions.data }
}
const connectedClass = connect(mapState, { createSyndicat, editSyndicat, updateSyndicat, syncSyndicatsInseeAPI })(CreateSyndicat)
export { connectedClass as CreateSyndicat }