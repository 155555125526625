import { Image, Select } from 'antd';
import React, { Component } from 'react';
import { Col, Form, Popover, OverlayTrigger } from "react-bootstrap";
import { connect } from 'react-redux';
import { LoadHistory } from '../../Commons'
const states = {"A": "Actif", "C": "Fermé"}
class BasicInfoSociete extends Component {
    toolTipContent() {
        return <Popover id="popover-basic" style={{ maxWidth: 500 }}>
            <LoadHistory type="Societe" data={this.props.societe.id_societe_exploitant} column="effectifs_new" title={global._trans("common", "historyEffectif")} />
        </Popover>
    }
    render() {
        return (
            <Form>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'state')}</Form.Label>
                        <div className="d-flex align-items-center p-2" style={{border: "1px solid #E4E6EF", borderRadius: "0.42rem" ,fontSize: "1rem", padding: "0.65rem 1rem !important", height: "calc(1.5em + 1.3rem + 2px)"}}>
                            <i style={{border: "2px solid #ffffff", borderRadius: "100%"}} className={`state-badge symbol-badge symbol-badge-xl bg-${this.props.societe.state ? (this.props.societe.state == "A" ? "success" : "danger") : "dark"}`}></i><span class="state-bade-text">{this.props.societe.state ? (this.props.societe.state == "A" ? "Actif" : "Fermé") : "Indéfini"}</span>
                        </div>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('companies', 'groupe')}</Form.Label>
                        <Select
                            mode='multiple'
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            style={{ width: '100%' }}
                            optionFilterProp="label"
                            disabled={this.props.disabled}
                            value={Array.isArray(this.props.societe.groupe) ? this.props.societe.groupe.map((g) => g.value_enum) : []}
                            loading={!this.props.groupeList}
                            options={this.props.groupeList}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'denomenation')}</Form.Label>
                        <Form.Control type="text" disabled={this.props.disabled} name="denomination" value={this.props.societe.denomination} placeholder={global._trans('sites', 'denomenation')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'usual_denomination')}</Form.Label>
                        <Form.Control type="text" disabled={this.props.disabled} name="usual_denomination" value={this.props.societe.usual_denomination} placeholder={global._trans('common', 'usual_denomination')} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'address')}</Form.Label>
                        <Form.Control disabled={true} placeholder={global._trans('common', 'address')} name='adresse' value={this.props.societe.adresse} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'city')}</Form.Label>
                        <Form.Control type='text' disabled={this.props.disabled} placeholder={global._trans('common', 'city')} name='city' value={this.props.societe.city} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'country')}</Form.Label>
                        <Form.Control type='text' disabled={this.props.disabled} placeholder={global._trans('common', 'country')} name='country' value={this.props.societe.country} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'postcode')}</Form.Label>
                        <Form.Control type='text' disabled={this.props.disabled} placeholder={global._trans('common', 'postcode')} name='postcode' value={this.props.societe.postcode} />
                    </Form.Group>

                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('companies', 'siret')}</Form.Label>
                        <Form.Control type='text' disabled={this.props.disabled} placeholder={global._trans('companies', 'siret')} name='siret' value={this.props.societe.siret} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('companies', 'serin')}</Form.Label>
                        <Form.Control type='text' disabled={this.props.disabled} placeholder={global._trans('companies', 'serin')} name='serin' value={this.props.societe.serin} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'sinoe')}</Form.Label>
                        <Form.Control type='text' disabled={this.props.disabled} placeholder={global._trans('common', 'sinoe')} name='sinoe' value={this.props.societe.sinoe} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'natureJuridque')}</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            style={{ width: '100%' }}
                            optionFilterProp="label"
                            disabled={this.props.disabled}
                            value={this.props.societe.nature_juridique}
                            loading={!this.props.nature_juridique}
                            options={this.props.nature_juridique}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('companies', 'codeape')}</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            style={{ width: '100%' }}
                            optionFilterProp="label"
                            disabled={this.props.disabled}
                            value={this.props.societe.codeape}
                            loading={!this.props.codeape}
                            options={this.props.codeape}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('common', 'webSite')}</Form.Label>
                        <Form.Control type="text" disabled={this.props.disabled} name="siteInternet" value={this.props.societe.siteInternet} placeholder={global._trans('common', 'webSite')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans('common', 'logoCompany')}</Form.Label>
                        <Image
                            width={110}
                            src={this.props.societe.logo}
                        />
                    </Form.Group>
                </Form.Row>
                <div className="separator separator-solid mb-4"></div>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('companies', 'telephoneStandrad')}</Form.Label>
                        <Form.Control type="text" disabled={this.props.disabled} name="telephoneStandrad" value={this.props.societe.telephoneStandrad} placeholder={global._trans('companies', 'telephoneStandrad')} />
                    </Form.Group>



                    <Form.Group as={Col}>
                        <Form.Row>
                            {/* <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('companies', 'nombreSalarie')}</Form.Label>
                                <Form.Control type="number" disabled={this.props.disabled} name="nombreSalarie" value={this.props.societe.effectifs} placeholder={global._trans('companies', 'nombreSalarie')} />
                            </Form.Group> */}
                             <Form.Group as={Col}  className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'effectifs')}</Form.Label>
                                <Select
                                    showSearch
                                    showArrow
                                    className="inputSizeSelect"
                                    style={{ width: '100%' }}
                                    optionFilterProp="label"
                                    disabled={this.props.disabled}
                                    value={this.props.societe.effectifs_new}
                                    loading={!this.props.effectifs}
                                    options={this.props.effectifs}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className=" mb-2 ">
                                <Form.Label>{global._trans('common', 'nbPeopleAnnee')}</Form.Label>
                                <Form.Control type="number" name="effectifs_year" disabled={this.props.disabled} value={this.props.societe.effectifs_year} placeholder={global._trans('common', 'nbPeopleAnnee')} />
                            </Form.Group>
                        </Form.Row>
                        <div className="d-flex justify-content-between align-items-center p-2">
                            <span><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.props.societe.date_enter)}</span>
                            <OverlayTrigger trigger="click" placement="top" overlay={this.toolTipContent()}>
                                <span role="button" id="tooltip_info" className="text-primary font-weight-bold">{global._trans('common', 'viewAll')}</span>
                            </OverlayTrigger>
                        </div>
                    </Form.Group>



                    {/* <Form.Group as={Col}>
                        <Form.Label>{global._trans('companies', 'nombreSalarie')}</Form.Label>
                        <Form.Control type="text" disabled={this.props.disabled} name="nombreSalarie" value={global._toStringSpace(this.props.societe.effectifs)} placeholder={global._trans('companies', 'nombreSalarie')} />
                        <div className="d-flex justify-content-between align-items-center p-2">
                            <span><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.props.societe.date_enter)}</span>
                            <OverlayTrigger trigger="click" placement="top" overlay={this.toolTipContent()}>
                                <span role="button" id="tooltip_info" className="text-primary font-weight-bold">{global._trans('common', 'viewAll')}</span>
                            </OverlayTrigger>
                        </div>
                    </Form.Group> */}
                </Form.Row>
            </Form>
        )
    }
}
function mapState(state) {
    const { listEnums: { nature_juridique, codeape, groupeList, effectifs } } = state.enums
    return { nature_juridique, codeape, groupeList, effectifs }
}
const connectedClass = connect(mapState, {})(BasicInfoSociete)
export { connectedClass as BasicInfoSociete };
