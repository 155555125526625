
export const AG_GRID_LOCALE_FR = {
    
    // Set Filter
    selectAll: '(Tout sélectionner)',
    selectAllSearchResults: '(Sélectionner tous les résultats de la recherche)',
    searchOoo: 'Recherche...',
    blanks: '(Vierges)',
    noMatches: 'Aucune correspondance',

    // Number Filter & Text Filter
    filterOoo: 'Filtrer...',
    equals: 'Est égal à',
    notEqual: 'Est différent de',
    blank: 'Vierge',
    notBlank: 'Pas Vierge',
    empty: 'Choisissez-en un',

    // Number Filter
    lessThan: 'Inférieur à',
    greaterThan: 'Supérieur à',
    lessThanOrEqual: 'Inférieur ou égal',
    greaterThanOrEqual: 'Supérieur ou égal',
    inRange: 'Dans l\'intervalle',
    inRangeStart: 'À partir de',
    inRangeEnd: 'Jusqu\'à',

    // Text Filter
    contains: 'Contient',
    notContains: 'Ne contient pas',
    startsWith: 'Commence par',
    endsWith: 'Se termine par',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'ET',
    orCondition: 'OU',

    // Filter Buttons
    applyFilter: 'Appliquer',
    resetFilter: 'Réinitialiser',
    clearFilter: 'Effacer',
    cancelFilter: 'Annuler',

    // Filter Titles
    textFilter: 'Filtre de texte',
    numberFilter: 'Filtre numérique',
    dateFilter: 'Filtre de dates',
    setFilter: 'Définir le filtre',

    // Group Column Filter
    groupFilterSelect: 'Sélectionnez un field:',

    // Other
    loadingOoo: 'Chargement...',
    loadingError: 'Erreur',
    noRowsToShow: 'Aucune donnée à afficher',
    enabled: 'Activé',

    more: 'Plus',
    to: 'à  ',
    of: 'sur',
    page: 'Page',
    nextPage: 'Page suivante',
    lastPage: 'Dernière page',
    firstPage: 'Première page',
    previousPage: 'Page précédente',

} 