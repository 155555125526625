import React, { Component } from 'react'
import { SageTable } from '../../../../Commons'
import moment from 'moment';
class Competances extends Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold">{global._trans("client", "competancEexercee")}:</h5>
                    </div>
                </div>
                <SageTable
                    columns={[
                        { title: global._trans('client', 'code'), dataIndex: "code" },
                        { title: global._trans('client', 'competance'), dataIndex: "competence_dechet_name" },
                        { title: global._trans('client', 'start_date'), dataIndex: "start_date", render: (start_date) => !!start_date ? moment(start_date, 'DD/MM/YY').format('DD-MM-YYYY') : '' },
                        { title: global._trans('client', 'end_date'), dataIndex: "end_date", render: (end_date) => !!end_date ? moment(end_date, 'DD/MM/YY').format('DD-MM-YYYY') : '' },
                        { title: global._trans('client', 'recuDelegue'), dataIndex: "owner_competance", render: (owner_competance, key) => {
                            if(!owner_competance){
                                return '';
                            }
                            if(owner_competance.typePersonMoral == 'Syndicat'){
                                return owner_competance.nomCourt;
                            }else if(owner_competance.typePersonMoral == 'Epic'){
                                return owner_competance.nomEpic;
                            }
                            return '';
                        }},
                        { title: global._trans('client', 'comment'), dataIndex: "comment" },
                    ]}
                    tableStyle={{ marginBottom: 0 }}
                    data={this.props.competances.exercee}
                    IterableProps={{}}
                />
                <div className="separator separator-dashed my-5"></div>
                <div className="row">
                    <div className="col-lg-9 col-xl-6">
                        <h5 className="font-weight-bold">{global._trans("client", "competancDelegues")}:</h5>
                    </div>
                </div>
                <SageTable
                    columns={[
                        { title: global._trans('client', 'code'), dataIndex: "code" },
                        { title: global._trans('client', 'competance'), dataIndex: "competence_dechet_name" },
                        {
                            title: global._trans('client', 'delegue_competance'), dataIndex: "delegue_competance", render: delegue_competance => <div>
                                <span><strong>({global._trans('client', delegue_competance.typePersonMoral.toLowerCase())}) : </strong>{'  ' + delegue_competance[delegue_competance.dataIndex]}</span>
                            </div>
                        },
                        { title: global._trans('client', 'start_date'), dataIndex: "start_date", render: (start_date) => !!start_date ? moment(start_date, 'DD/MM/YY').format('DD-MM-YYYY') : '' },
                        { title: global._trans('client', 'end_date'), dataIndex: "end_date", render: (end_date) => !!end_date ? moment(end_date, 'DD/MM/YY').format('DD-MM-YYYY') : '' },
                        { title: global._trans('client', 'comment'), dataIndex: "comment" },
                    ]}
                    tableStyle={{ marginBottom: 0 }}
                    data={this.props.competances.delegue}
                    IterableProps={{}}
                />
            </div>
        )
    }
}
export { Competances }