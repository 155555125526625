import React, { useState } from 'react';
import { Select } from 'antd';

import { connect } from "react-redux";
const RegionSearch = (props) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const handleSearch = (search) => {

        if (!!search && search != '') {
            setData(props.regions.filter((region) => region.name_region.toUpperCase().includes(search.toUpperCase())));
        } else {
            setData(props.regions);
        }
    }

    let options = !!data ? data : props.regions;
    options = !!options && Array.isArray(options) && options.length > 0 ? options.map((region) => ({ label: `${region.region_code} - ${region.name_region}`, value: region.id_region })) : []

    return (
        <Select
            disabled={props.disabled}
            showSearch
            showArrow
            className="inputSizeSelect"
            placeholder={props.placeholder}
            style={{ width: '100%' }}
            optionFilterProp="label"
            value={props.value}
            notFoundContent={global._trans("common", "noThingToShow")}
            loading={loading}
            options={options}
            onSearch={handleSearch}
            onSelect={props.onChange}
        />
    )
}

function mapState(state) {
    const { regions } = state.enums;
    return { regions: regions.data };
}
const connectedClass = connect(mapState, {})(RegionSearch)
export { connectedClass as RegionSearch }