import { createSlice } from "@reduxjs/toolkit";
const queryString = require('query-string');
const initialState = {
    usersErrors: null,
    listUsersPrem: {},
    userPrem:null,
    currentQuery:""
};

export const userSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { errors } = action.payload
            state.usersErrors = errors;
        },
        userCreated: (state) => {
            state.usersErrors = state.usersErrors === false ? null : false
        },
        usersPremFetched: (state, action) => {
            const { listUsers,currentQuery } = action.payload
            state.usersErrors = null
            state.currentQuery="?"+queryString.stringify(currentQuery)
            state.listUsersPrem = listUsers
        },
        userPremFetched: (state, action) => {
            const { user } = action.payload
            state.usersErrors = null
            state.userPrem = user
        },
        usersPremDeleted: (state, action) => {
            const { asyncData, users } = action.payload
            if (asyncData) {
                let prevList = state.listUsersPrem
                prevList.data = prevList.data.filter(user => users.indexOf(user.id_user) === -1)
                state.listUsersPrem = prevList
            }
            state.usersErrors = null
        },
        resetState:(state, action) => {
            action.payload && action.payload.map(elem=>{
                state[elem.name]=elem.value
            })
        }
    }
});