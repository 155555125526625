import React from 'react'
import { Checkbox } from 'antd';
import { ListGroup } from "react-bootstrap";
export const InformationClient = ({ checkedColumns, handleChange }) => {
    return (
        <div className="row mx-0">
            <ListGroup.Item>
                <Checkbox name="client_nomEpic" checked={checkedColumns.client_nomEpic} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('clientsEpic', 'epicName')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_nom_court" checked={checkedColumns.client_nom_court} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('clientsSyndicat', 'nameSyndicat')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_denomination" checked={checkedColumns.client_denomination} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('sites', 'denomenation')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_usual_denomination" checked={checkedColumns.client_usual_denomination} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'usual_denomination')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_serin" checked={checkedColumns.client_serin} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('clientsEpic', 'serin')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_siteInternet" checked={checkedColumns.client_siteInternet} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'webSite')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_telephoneStandard" checked={checkedColumns.client_telephoneStandard} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'standrPhone')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_nature_juridique" checked={checkedColumns.client_nature_juridique} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'natureJuridque')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_nomCourt" checked={checkedColumns.client_nomCourt} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('clientsSyndicat', 'nameSyndicat')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_denominationLegale" checked={checkedColumns.client_denominationLegale} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'denomiLegal')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_sinoe" checked={checkedColumns.client_sinoe} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('clientsEpic', 'sinoe')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_amobe" checked={checkedColumns.client_amobe} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('clientsSyndicat', 'amobe')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_nomCommune" checked={checkedColumns.client_nomCommune} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('clientsCommune', 'communeName')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_insee" checked={checkedColumns.client_insee} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('clientsCommune', 'insee')}</Checkbox>
            </ListGroup.Item>


            <ListGroup.Item>
                <Checkbox name="client_adresse" checked={checkedColumns.client_adresse} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'address')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_city" checked={checkedColumns.client_city} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'city')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_country" checked={checkedColumns.client_country} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'country')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_postcode" checked={checkedColumns.client_postcode} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'postcode')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_region_siege" checked={checkedColumns.client_region_siege} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'regionSiege')}</Checkbox>
            </ListGroup.Item>
            <ListGroup.Item>
                <Checkbox name="client_departement_siege" checked={checkedColumns.client_departement_siege} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'departementSiege')}</Checkbox>
            </ListGroup.Item>

            <ListGroup.Item>
                <Checkbox name="client_nombreHabitant" checked={checkedColumns.client_nombreHabitant} onChange={handleChange} style={{ lineHeight: '32px' }}>{global._trans('common', 'nbPeople')}</Checkbox>
            </ListGroup.Item>
        </div>
    )
}