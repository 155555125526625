import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal, Spin } from 'antd'
import { Form } from 'react-bootstrap'
import { NavLink, } from 'react-router-dom'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_sage/_helpers";
import { InlineBottunSvg, SageTable, FilterSearch, ImportData } from '../../../Commons'
import { fetchSyndicats, deleteSyndicats, syncSyndicatsInseeAPI } from "../../../../../modules/Collectivities/collectivitie.actions";
import { FiltersSyndicat } from './utils'
import { exportData } from "../../../../../modules/Common/common.actions";
import { notification } from 'antd';
import { message } from 'antd';
import { AgGrid } from '../../../Commons';

const queryString = require('query-string');
class ListSyndicats extends Component {
    constructor(props) {
        super(props)
        // let asyncLoad = props.querySyndicat !== props.history.location.search || !props.querySyndicat
        this.state = {
            first_load: true,
            view_save: 0,
            loading: true,
            total: 0,
            page: 1,
            page_size: 20,
            list: [],
            columnDefs: [
                {headerName: "#", filter: "agNumberColumnFilter", field: 'id_syndicat'},
                {type: 'stateColumn'},
                {headerName: global._trans('clientsSyndicat', 'nameSyndicat'), field: 'nomCourt'},
                {headerName: global._trans('common', 'denomiLegal'), field: 'denominationLegale'},
                {headerName: global._trans('common', 'usual_denomination'), field: 'usual_denomination'},
                {headerName: global._trans('common', 'natureJuridque'), field: 'nature_juridique.value_enum'},
                {headerName: global._trans('common', 'regionSiege'), field: 'region_siege.name_region'},
                {headerName: global._trans('common', 'departementSiege'), field: 'departement_siege.name_departement'},
                {headerName: global._trans('clientsSyndicat', 'amobe'), field: 'amobe.value_enum'},
                {headerName: global._trans('common', 'address'), field: 'adresse'},
                {headerName: global._trans('common', 'postcode'), field: 'postcode'},
                {headerName: global._trans('common', 'city'), field: 'city'},
                {headerName: global._trans('common', 'country'), field: 'country'},
                {headerName: global._trans('clientsEpic', 'serin'), field: 'serin'},
                {headerName: global._trans('common', 'siret'), field: 'siret'},
                {headerName: global._trans('common', 'sinoe'), field: 'sinoe'},
                {headerName: global._trans('common', 'effectifs'), field: 'effectifs'},
                {headerName: global._trans('common', 'effectifs_year'), field: 'effectifs_year'},
                {headerName: global._trans('common', 'nbPeople'), field: 'nombreHabitant'},
                {headerName: global._trans('common', 'population_year'), field: 'nombreHabitant_year'},
                {headerName: global._trans('common', 'email'), field: 'email'},
                {headerName: global._trans('common', 'siteInternet'), field: 'siteInternet'},
                {headerName: global._trans('common', 'telephoneStandard'), field: 'telephoneStandard'},
                {headerName: global._trans('common', 'status'), field: 'status', type: "statusColumn"},
                {headerName: "",  minWidth: 150, colId: "Actions", field: 'id_syndicat', sortable: false, filter: false, cellRendererFramework: params => {
                    return <div className="d-flex">
                        <a href={'/client/communities/syndicat/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Edit.svg")}></SVG></span>
                            </button>
                        </a>
                        <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-danger"} onClick={() => this.handleDeleteSyndicat(params.value)}>
                            <span className={"svg-icon svg-icon-md svg-icon-danger"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG></span>
                        </button>
                        <a href={'/client/communities/syndicat/view/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"} to={'/contrat/view/' + params.value}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/View.svg")}></SVG></span>
                            </button>
                        </a>
                    </div>
                }}
            ],

            // selectedRows: [],
            // list_data: (props.history.location.search && props.listSyndicats.data) || [],
            // total: props.listSyndicats.total,
            // filters: {
            //     page: 1,
            //     pageSize: 20,
            //     source: 'syndicats',
            //     ...queryString.parse(props.history.location.search)
            // },
            // loadSyndicats: asyncLoad,
            loadingDelete: false,
            // showImport: false
        }
        // this.filterTab = {}
        // this.toReset = ['form_search_list', 'form_search_input']
        // this.deleteSelected = this.deleteSelected.bind(this)
        // this.handleSelectRow = this.handleSelectRow.bind(this)
        // this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
        // this.handleChangeSearch = this.handleChangeSearch.bind(this)
        // this.handleTableChange = this.handleTableChange.bind(this)
        // this.handleDeleteSyndicat = this.handleDeleteSyndicat.bind(this)
        // this.handleSorter = this.handleSorter.bind(this)
        // this.handleResetSearch = this.handleResetSearch.bind(this)
        // if (asyncLoad) {
        //     props.fetchSyndicats(this.state.filters)
        // }
        props.fetchSyndicats({pagination: {page: this.state.page, page_size: this.state.page_size}, first_load: true})

    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.listSyndicats && Array.isArray(nextProps.listSyndicats.data)) {
                this.setState({
                    list: nextProps.listSyndicats.data,
                    total: nextProps.listSyndicats.total,
                    page: nextProps.listSyndicats.current_page,
                    page_size: nextProps.listSyndicats.per_page,
                    loading: false,
                }, () => {
                    this.childComponentRef.onLoadingDone(this.state.first_load)
                    if (this.state.first_load) this.setState({first_load: false})
                })
            }

        }
        if (this.state.loadingDelete) {
            this.setState({
                list_data: nextProps.listSyndicats.data,
                loadingDelete: false,
                selectedRows: []
            })
            if (nextProps.SyndicatErrors) {
                message.error(global._trans('settings', 'cantDelete'));
            }

        }
    }
    handleSelectRow(selectedRowKeys) {
        this.setState({
            selectedRows: selectedRowKeys
        })
    }
    deleteSelected() {
        Modal.confirm({
            title: global._trans('common', 'deleteAllTitle'),
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('sites', 'deleteBody'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadingDelete: true
                })
                this.props.deleteSyndicats({ syndicats: this.state.selectedRows })
            }
        });
    }
    // handleTableChange = (pagination, filters) => {
    //     if (pagination.current !== this.state.cuurentPage || pagination.pageSize !== this.state.pageSize) {
    //         let prevfilter = this.state.filters
    //         prevfilter.page = pagination.current
    //         prevfilter.pageSize = pagination.pageSize
    //         this.handleFetchData(prevfilter)
    //     }
    // }
    handleSorter(sorter) {
        let prevfilter = this.state.filters
        prevfilter.sort = sorter.value
        prevfilter.sorter = sorter.current
        this.handleFetchData(prevfilter)
    }
    handleTypeFilter(checked) {
        this.setState({
            filters: {
                ...this.state.filters,
                typeFilter: checked ? "inter" : null
            }
        })
    }
    handleFetchData(filter) {
        this.props.history.push({
            search: queryString.stringify(filter)
        })
        this.props.fetchSyndicats(filter)
        this.setState({
            loadSyndicats: true
        })
    }
    handleDeleteSyndicat(idSyndicat) {
        Modal.confirm({
            title: global._trans('clientsSyndicat', 'titleDeleteSyndi'),
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('clientsSyndicat', 'bodyDeleteSyndi'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.props.deleteSyndicats({ syndicats: [idSyndicat] })
                this.setState({
                    loadingDelete: true
                })
            }
        });
    }
    handleSubmitSearch(e) {
        e.preventDefault()
        this.state.filters = {
            ...this.state.filters,
            ...this.filterTab
        }
        this.handleFetchData(this.state.filters)
    }
    handleChangeSearch(e) {
        const { name, value } = e.target
        this.filterTab[name] = value
    }
    handleResetSearch() {
        let resteData = {
            page: this.state.filters.page,
            pageSize: this.state.filters.pageSize,
            source: this.state.filters.source,
        }
        this.state.filters = resteData
        this.filterTab = {}
        this.toReset.map(item => {
            let formReset = document.getElementById(item)
            if (formReset) formReset.reset();
        })
        this.handleFetchData(resteData)
    }
    showReset() {
        const keys = Object.entries(this.state.filters).filter(item => item[1] && (item[0] !== 'typeFilter'));
        return keys.length > 3
    }
    handleImportExcel = (nameImport) => {
        this.setState({
            showImport: nameImport
        })
    }
    handleExportExcel = (nameImport) => {
        let columns = {}
        this.childComponentRef.state.columns.filter(item => item.visible && item.colId != "Actions").forEach(item => {
            columns[item.colId] = item.colDef.headerName
        });
        exportData({...nameImport, columns, filter: this.childComponentRef.gridApi.getFilterModel()})
        .then(({ message, ok }) => {
            if (ok && message == "Import Started") {
                notification.info({
                    message: global._trans('common', 'titleImported'),
                    description: global._trans('common', 'bodyImportedMessage'),
                    placement: 'bottomLeft',
                    // duration: 0,
                    style: {
                        width: 500,
                        padding: '15px 20px',
                        borderRadius: 8
                    },
                })
                // onHide()
            }
        })
        .catch((e) => {
        console.log('🚀 => e', e)

        })
        .finally((f) => {
            console.log('🚀 => f', f)
            // setLoading(false)
        })
    }

    handleTableChange = (filter, sort, pagination) => {
        this.setState({
            loading: true,
            list: []
        }, () => this.props.fetchSyndicats({sort, filter, pagination}));
    }
    handleSaveView = () => {
        this.setState({
            view_save: 2
        }, () => {
            this.childComponentRef.saveCurrentView()
        })
    }
    handleViewSaved = (value) => {
        this.setState({
            view_save: value
        })
    }

    render() {
        return (
            <>
                <div className="card-header header-center py-1">
                    <h3 className="card-title">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('clientsSyndicat', 'titleList')} ({this.state.total} élément trouvé)</span>
                    </h3>
                    <div className="card-toolbar">
                    {this.state.view_save > 0 && <button title={"Enregistrer l'état de la vue (filtre, ordre, disposition et taille des colonnes)"} disabled={this.state.view_save == 2} className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.handleSaveView()}>
                            <span className="svg-icon svg-icon-md" style={{margin: 0}}>
                                <SVG hidden={this.state.view_save != 1} src={toAbsoluteUrl("/media/svg/icons/Costum/Save.svg")} className="h-50 align-self-center"></SVG>
                                <SVG hidden={this.state.view_save != 2} src={toAbsoluteUrl("/media/svg/icons/Costum/Loading.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                        </button>}
                        <button className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.childComponentRef.viewsList(true)}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Columns.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Views management')}
                        </button>
                        <button className="btn btn-light font-weight-bolder font-size-sm mr-3" onClick={() => this.handleImportExcel('competences_syndicats')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('clientsSyndicat', 'importCompo')}
                        </button>
                        <button className="btn btn-light-success font-weight-bolder font-size-sm " onClick={() => this.handleImportExcel('syndicats')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'importData')}
                        </button>
                        {/* <button className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => this.handleImportExcel('SyndicatsMAJ')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Import MAJ')}
                        </button> */}
                        <button className="btn btn-light-primary font-weight-bolder font-size-sm mx-3" onClick={() => this.handleExportExcel({ type:'syndicats'})}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            Exporter
                        </button>
                        <NavLink to="/client/communities/syndicat/new" className="btn btn-primary font-weight-bolder font-size-sm">
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('clientsSyndicat', 'createSyndi')}
                        </NavLink>
                    </div>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingDelete}>
                    <div className="card-body py-4">
                        {/* <div className="d-flex align-items-center">
                            {this.state.selectedRows.length > 0 && <button type="button" className="btn btn-light-danger font-weight-bolder font-size-sm d-flex align-items-center mr-3" onClick={this.deleteSelected}>
                                <span className="svg-icon svg-icon-md svg-icon-light-danger">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG>
                                </span>
                                <span>{`${global._trans("common", "deleteSelected")} ${this.state.selectedRows.length} ${global._trans("common", "element")}`}</span>
                            </button>}
                            <div className="d-flex align-items-center">
                                <FilterSearch handleSubmit={this.handleSubmitSearch} idForm="form_search_list" isAndType={this.state.filters.typeFilter === "inter"} handleType={this.handleTypeFilter.bind(this)}>
                                    <FiltersSyndicat filters={this.state.filters} handleChange={this.handleChangeSearch} />
                                </FilterSearch>
                                <Form id="form_search_input" className="d-flex" onSubmit={this.handleSubmitSearch}>
                                    <Form.Control type="text" style={{ width: 350 }} className="mx-2" placeholder={global._trans('common', 'searchData')} defaultValue={this.state.filters.search} name="search" onChange={this.handleChangeSearch}></Form.Control>
                                    {this.showReset() && <button type="button" onClick={this.handleResetSearch} className="btn btn-light font-weight-bolder mr-2 font-size-sm">{global._trans("common", "reset")}</button>}
                                    <button className="btn btn-light-primary font-weight-bolder font-size-sm">{global._trans("common", "search")}</button>
                                </Form>
                            </div>
                        </div> */}
                        {/* <div className="separator separator-solid my-3"></div> */}
                        {/* <SageTable
                            columns={[
                                {
                                    title: global._trans('common', 'state'), dataIndex: "state", sorter: false, render: state => <div className="symbol align-self-start align-self-xxl-center">
                                        <i className={`state-badge symbol-badge symbol-badge-xl bg-${state ? (state == "A" ? "success" : "danger") : "dark"}`}></i><span class="state-bade-text">{state ? (state == "A" ? "Actif" : "Fermé") : "Indéfini"}</span>
                                    </div>
                                },
                                { title: global._trans('clientsSyndicat', 'nameSyndicat'), dataIndex: "nomCourt", sorter: true },
                                { title: global._trans('common', 'denomiLegal'), dataIndex: "denominationLegale", sorter: true, },
                                { title: global._trans('common', 'natureJuridque'), dataIndex: "nature_juridique", sorter: true, },
                                { title: global._trans('common', 'regionSiege'), dataIndex: "region_siege", sorter: true, },
                                { title: global._trans('common', 'departementSiege'), dataIndex: "departement_siege", sorter: true, },
                                { title: global._trans('clientsSyndicat', 'amobe'), dataIndex: "amobe", sorter: true, },
                                { title: global._trans('common', 'address'), dataIndex: "adresse", sorter: true, },
                                { title: global._trans('clientsEpic', 'serin'), dataIndex: "serin", sorter: true, },
                                { title: global._trans('common', 'nbPeople'), dataIndex: "nombreHabitant", sorter: true, },
                                {
                                    title: '', dataIndex: "id_syndicat", fixed: 'right', render: (id_syndicat, index, syndicat) => <InlineBottunSvg
                                        bottuns={[
                                            { iconPath: "/media/svg/icons/Costum/Edit.svg", type: "primary", action: '/client/communities/syndicat/' + id_syndicat },
                                            { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.handleDeleteSyndicat(id_syndicat) },
                                            { iconPath: "/media/svg/icons/Costum/View.svg", type: "primary", action: '/client/communities/syndicat/view/' + id_syndicat }
                                        ]}
                                    />
                                }
                            ]}
                            style={{ height: 545 }}
                            callBackSort={this.handleSorter}
                            data={this.state.list_data}
                            defaultSorter={this.state.filters}
                            IterableProps={{
                                rowSelection: {
                                    onChange: this.handleSelectRow,
                                },
                                emptyText: global._trans('clientsSyndicat', 'emptyList'),
                                loading: this.state.loadSyndicats || this.state.loadingDelete,
                                rowKey: "id_syndicat",
                                pagination: { total: this.state.total, pageSize: this.state.filters.pageSize, current: parseInt(this.state.filters.page), showSizeChanger: true },
                                onChange: this.handleTableChange
                            }}
                        /> */}
                        <AgGrid
                            rows={this.state.list}
                            columns={this.state.columnDefs}
                            pagination={{total: this.state.total, pageSize: this.state.page_size, current: this.state.page}}
                            onChange={this.handleTableChange}
                            loading={this.state.loading}
                            ref={ref => this.childComponentRef = ref}
                            views_key={"syndicats"}
                            onViewSaved={this.handleViewSaved}
                        />
                    </div>
                </Spin>
                <ImportData show={this.state.showImport} title={global._trans('common', 'titelImport')} sourceFile={this.state.showImport} onHide={() => this.handleImportExcel(null)} />
            </>
        )
    }
}
function mapState(state) {
    const { SyndicatErrors, listSyndicats, querySyndicat } = state.collectivitie
    return { SyndicatErrors, listSyndicats, querySyndicat }
}
const connectedClass = connect(mapState, { fetchSyndicats, deleteSyndicats, syncSyndicatsInseeAPI })(ListSyndicats)
export { connectedClass as ListSyndicats }