import { Select } from 'antd';
import React, { useState } from 'react';

import { connect } from "react-redux";
const RegionSearchFilter = (props) => {

    const options = props.type === 'dep' ? props.departements : props.regions;
    const regs = !!options && Array.isArray(options) && options.length > 0 ? options.map((region) => ({ label: `${region.region_code} - ${region.name_region}`, value: region.id_region })) : []
    const deps = !!options && Array.isArray(options) && options.length > 0 ? options.map((region) => ({ label: `${region.departement_code} - ${region.name_departement}`, value: region.id_departement })) : []
    const opts = props.type === 'dep' ? deps : regs
    return (
        <>
            <label className="fs-6 form-label text-white">{props.placeholder}</label>
            <Select
                mode="multiple"
                showSearch
                allowClear
                placeholder={'select ... '}
                style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                optionFilterProp="label"
                size="small"
                value={props.value}
                filterOption={(input, Option) =>
                    Option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={props.onChange}
            >
                <Select.Option value='all'>Tout</Select.Option>
                {opts.map((el) => {
                    return <Select.Option value={el.value} key={el.value}>{el.label}</Select.Option>
                })}
            </Select>
        </>
    )
}

function mapState(state) {
    const { regions, departements } = state.enums;
    return { regions: regions.data, departements: departements.data };
}
const connectedClass = connect(mapState, {})(RegionSearchFilter)
export { connectedClass as RegionSearchFilter }