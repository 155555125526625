import React, { Component } from 'react'
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { Modal, Spin } from 'antd'
import { toAbsoluteUrl } from "../../../../../../_sage/_helpers";
import { InlineBottunSvg, SageTable, FilterSearch, ImportData } from '../../../Commons'
import { fetchEpics, deleteEpics, syncEPICsInseeAPI } from "../../../../../modules/Collectivities/collectivitie.actions";
import { FiltersEpic } from './utils'
import { message } from 'antd';
import { exportData } from "../../../../../modules/Common/common.actions";
import { notification } from 'antd';
import { AgGrid } from '../../../Commons';

const queryString = require('query-string');
class ListEpics extends Component {
    constructor(props) {
        super(props)
        // let asyncLoad = props.queryEpic !== props.history.location.search || !props.queryEpic
        this.state = {
            first_load: true,
            view_save: 0,
            loading: true,
            total: 0,
            page: 1,
            page_size: 20,
            list: [],
            columnDefs: [
                {headerName: "#", filter: "agNumberColumnFilter", field: 'id_epic'},
                {type: 'stateColumn'},
                {headerName: global._trans('clientsEpic', 'epicName'), field: 'nomEpic'},
                {headerName: global._trans('clientsEpic', 'nom_court'), field: 'nom_court'},
                {headerName: global._trans('common', 'usual_denomination'), field: 'usual_denomination'},
                {headerName: global._trans('common', 'natureJuridque'), field: 'nature_juridique.value_enum'},
                {headerName: global._trans('common', 'regionSiege'), field: 'region_siege.name_region'},
                {headerName: global._trans('common', 'departementSiege'), field: 'departement_siege.name_departement'},
                {headerName: global._trans('clientsEpic', 'nomSyndicat'), field: 'syndicat.nomCourt'},
                {headerName: global._trans('clientsEpic', 'serin'), field: 'serin'},
                {headerName: global._trans('common', 'siret'), field: 'siret'},
                {headerName: global._trans('common', 'sinoe'), field: 'sinoe'},
                {headerName: global._trans('common', 'address'), field: 'adresse'},
                {headerName: global._trans('common', 'postcode'), field: 'postcode'},
                {headerName: global._trans('common', 'city'), field: 'city'},
                {headerName: global._trans('common', 'country'), field: 'country'},
                {headerName: global._trans('common', 'siteInternet'), field: 'siteInternet'},
                {headerName: global._trans('common', 'telephoneStandard'), field: 'telephoneStandard'},
                {headerName: global._trans('common', 'nbPeople'), field: 'nombreHabitant', filter: "agNumberColumnFilter"},
                {headerName: global._trans('common', 'population_year'), field: 'nombreHabitant_year'},
                {headerName: global._trans('common', 'effectifs'), field: 'effectifs'},
                {headerName: global._trans('common', 'effectifs_year'), field: 'effectifs_year', filter: "agNumberColumnFilter"},
                {headerName: global._trans('common', 'status'), field: 'status', type: "statusColumn"},
                {headerName: "",  minWidth: 150, colId: "Actions", field: 'id_epic', sortable: false, filter: false, cellRendererFramework: params => {
                    return <div className="d-flex">
                        <a href={'/client/communities/epic/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Edit.svg")}></SVG></span>
                            </button>
                        </a>
                        <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-danger"} onClick={() => this.handleDeleteEpic(params.value)}>
                            <span className={"svg-icon svg-icon-md svg-icon-danger"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG></span>
                        </button>
                        <a href={'/client/communities/epic/view/' + params.value}>
                            <button role="button" type="button" className={"btn btn-icon btn-light mx-1 btn-sm btn-hover-primary"} to={'/contrat/view/' + params.value}>
                                <span className={"svg-icon svg-icon-md svg-icon-primary"}><SVG src={toAbsoluteUrl("/media/svg/icons/Costum/View.svg")}></SVG></span>
                            </button>
                        </a>
                    </div>
                }}
            ],



            // selectedRows: [],
            // listEpics: (props.history.location.search && props.listEpics.data) || [],
            // total: props.listEpics.total,
            // filters: {
            //     page: 1,
            //     pageSize: 20,
            //     source: 'epics',
            //     ...queryString.parse(props.history.location.search)
            // },
            // loadEpics: asyncLoad,
            loadingDelete: false,
            // showImport: false
        }
        // this.toReset = ['form_search_list', 'form_search_input']
        // this.filterTab = {}
        // this.deleteSelected = this.deleteSelected.bind(this)
        // this.handleSelectRow = this.handleSelectRow.bind(this)
        // this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
        // this.handleChangeSearch = this.handleChangeSearch.bind(this)
        // this.handleTableChange = this.handleTableChange.bind(this)
        // this.handleSorter = this.handleSorter.bind(this)
        // this.handleResetSearch = this.handleResetSearch.bind(this)
        // if (asyncLoad) {
        //     props.fetchEpics(this.state.filters)
        // }

        props.fetchEpics({pagination: {page: this.state.page, page_size: this.state.page_size}, first_load: true})

    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.listEpics && Array.isArray(nextProps.listEpics.data)) {
                this.setState({
                    loading: false,
                    list: nextProps.listEpics.data,
                    total: nextProps.listEpics.total,
                    page: nextProps.listEpics.current_page,
                    page_size: nextProps.listEpics.per_page,

                    // listEpics: nextProps.listEpics.data,
                    // loadEpics: false,
                    // filters: {
                    //     ...this.state.filters,
                    //     page: nextProps.listEpics.current_page,
                    //     pageSize: nextProps.listEpics.per_page
                    // },
                    // total: nextProps.listEpics.total,
                    // selectedRows: []
                    loading: false,
                }, () => {
                    this.childComponentRef.onLoadingDone(this.state.first_load)
                    if (this.state.first_load) this.setState({first_load: false})
                })
            }
        }
        if (this.state.loadingDelete) {

            this.setState({
                loadingDelete: false,
                listEpics: nextProps.listEpics.data,
                selectedRows: []
            })

            if (nextProps.EpicErrors) {
                message.error(global._trans('settings', 'cantDelete'));
            }
        }
    }
    handleExportExcel = (nameImport) => {
        let columns = {}
        this.childComponentRef.state.columns.filter(item => item.visible && item.colId != "Actions").forEach(item => {
            columns[item.colId] = item.colDef.headerName
        });
        exportData({...nameImport, columns, filter: this.childComponentRef.gridApi.getFilterModel()})
        .then(({ message, ok }) => {
            if (ok && message == "Import Started") {
                notification.info({
                    message: global._trans('common', 'titleImported'),
                    description: global._trans('common', 'bodyImportedMessage'),
                    placement: 'bottomLeft',
                    // duration: 0,
                    style: {
                        width: 500,
                        padding: '15px 20px',
                        borderRadius: 8
                    },
                })
                // onHide()
            }
        })
        .catch((e) => {
        console.log('🚀 => e', e)

        })
        .finally((f) => {
            console.log('🚀 => f', f)
            // setLoading(false)
        })
    }
    mergeData(data, nextProps) {
        return data.map(epic => ({
            ...epic, region_siege: this.getTextValue(epic.region_siege, nextProps.region_siege), nature_juridique: this.getTextValue(epic.nature_juridique, nextProps.nature_juridique), departement_siege: this.getTextValue(epic.departement_siege, nextProps.departement_siege), competence_dechet: this.getTextValue(epic.competence_dechet, nextProps.competence_dechet)
        }))
    }
    getTextValue(idEnum, objetSearch) {
        return objetSearch.find(obj => obj.value === idEnum).label
    }
    handleDeleteEpic(idEpic) {
        Modal.confirm({
            title: global._trans('clientsEpic', 'titleDeleteEpic') + " #" + idEpic,
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('clientsEpic', 'bodyDeleteEpic'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.props.deleteEpics({ epics: [idEpic] })
                this.setState({
                    loadingDelete: true
                })
            }
        });
    }
    deleteSelected() {
        Modal.confirm({
            title: global._trans('common', 'deleteAllTitle'),
            okButtonProps: { type: "primary", danger: true },
            content: global._trans('sites', 'deleteBody'),
            okText: global._trans('common', 'supBtn'),
            cancelText: global._trans('common', 'cancelBtn'),
            onOk: () => {
                this.setState({
                    loadingDelete: true
                })
                this.props.deleteEpics({ epics: this.state.selectedRows })
            }
        });
    }
    handleSelectRow(selectedRow) {
        this.setState({
            selectedRows: selectedRow
        })
    }
    // handleTableChange = (pagination, filters) => {
    //     if (pagination.current !== this.state.cuurentPage || pagination.pageSize !== this.state.pageSize) {
    //         let prevfilter = this.state.filters
    //         prevfilter.page = pagination.current
    //         prevfilter.pageSize = pagination.pageSize
    //         this.handleFetchData(prevfilter)
    //     }
    // }
    handleSorter(sorter) {
        let prevfilter = this.state.filters
        prevfilter.sort = sorter.value
        prevfilter.sorter = sorter.current
        this.handleFetchData(prevfilter)
    }
    handleTypeFilter(checked) {
        this.setState({
            filters: {
                ...this.state.filters,
                typeFilter: checked ? "inter" : null
            }
        })
    }
    handleFetchData(filter) {
        this.props.history.push({
            search: queryString.stringify(filter)
        })
        this.props.fetchEpics(filter)
        this.setState({
            loadEpics: true
        })
    }
    handleSubmitSearch(e) {
        e.preventDefault()
        this.state.filters = {
            ...this.state.filters,
            ...this.filterTab
        }
        this.handleFetchData(this.state.filters)
    }
    handleChangeSearch(e) {
        const { name, value } = e.target
        this.filterTab[name] = value
    }
    handleResetSearch() {
        let resteData = {
            page: this.state.filters.page,
            pageSize: this.state.filters.pageSize,
            source: this.state.filters.source,
        }
        this.state.filters = resteData
        this.filterTab = {}
        this.toReset.map(item => {
            let formReset = document.getElementById(item)
            if (formReset) formReset.reset();
        })
        this.handleFetchData(resteData)
    }
    showReset() {
        const keys = Object.entries(this.state.filters).filter(item => item[1] && (item[0] !== 'typeFilter'));
        return keys.length > 3
    }
    handleImportExcel = (item) => {
        this.setState({
            showImport: item
        })
    }



    handleTableChange = (filter, sort, pagination) => {
        this.setState({
            loading: true,
            list: []
        }, () => this.props.fetchEpics({sort, filter, pagination}));
    }

    handleSaveView = () => {
        this.setState({
            view_save: 2
        }, () => {
            this.childComponentRef.saveCurrentView()
        })
    }
    handleViewSaved = (value) => {
        this.setState({
            view_save: value
        })
    }

    render() {
        return (
            <>
                <div className="card-header header-center py-1">
                    <h3 className="card-title">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('clientsEpic', 'titleList')} ({this.state.total} élément trouvé)</span>
                    </h3>
                    <div className="card-toolbar">
                    {this.state.view_save > 0 && <button title={"Enregistrer l'état de la vue (filtre, ordre, disposition et taille des colonnes)"} disabled={this.state.view_save == 2} className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.handleSaveView()}>
                            <span className="svg-icon svg-icon-md" style={{margin: 0}}>
                                <SVG hidden={this.state.view_save != 1} src={toAbsoluteUrl("/media/svg/icons/Costum/Save.svg")} className="h-50 align-self-center"></SVG>
                                <SVG hidden={this.state.view_save != 2} src={toAbsoluteUrl("/media/svg/icons/Costum/Loading.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                        </button>}
                        <button className="btn btn-secondary font-weight-bolder font-size-sm mr-3" onClick={() => this.childComponentRef.viewsList(true)}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Columns.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Views management')}
                        </button>
                        <button className="btn btn-light font-weight-bolder font-size-sm mr-3" onClick={() => this.handleImportExcel('competences_epcis')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('clientsSyndicat', 'importCompo')}
                        </button>
                        <button className="btn btn-light-success font-weight-bolder font-size-sm " onClick={() => this.handleImportExcel('epics')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'importData')}
                        </button>
                        {/* <button className="btn btn-light-success font-weight-bolder font-size-sm " onClick={() => this.handleImportExcel('EpicsMAJ')}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('common', 'Import MAJ')}
                        </button> */}
                        <button className="btn btn-light-primary font-weight-bolder font-size-sm mx-3" onClick={() => this.handleExportExcel({ type:'epics'})}>
                            <span className="svg-icon svg-icon-md">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/ImportExcel.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            Exporter
                        </button>
                        <NavLink to="/client/communities/epic/new" className="btn btn-primary font-weight-bolder font-size-sm">
                            <span className="svg-icon svg-icon-md svg-icon-white">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Add.svg")} className="h-50 align-self-center"></SVG>
                            </span>
                            {global._trans('clientsEpic', 'createEpic')}
                        </NavLink>
                    </div>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingDelete}>
                    <div className="card-body py-4">
                        {/* <div className="d-flex align-items-center">
                            {this.state.selectedRows.length > 0 && <button type="button" className="btn btn-light-danger font-weight-bolder font-size-sm d-flex align-items-center mr-3" onClick={this.deleteSelected}>
                                <span className="svg-icon svg-icon-md svg-icon-light-danger">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Costum/Trash.svg")}></SVG>
                                </span>
                                <span>{`${global._trans("common", "deleteSelected")} ${this.state.selectedRows.length} ${global._trans("common", "element")}`}</span>
                            </button>}
                            <div className="d-flex align-items-center">
                                <FilterSearch handleSubmit={this.handleSubmitSearch} idForm="form_search_list" isAndType={this.state.filters.typeFilter === "inter"} handleType={this.handleTypeFilter.bind(this)}>
                                    <FiltersEpic filters={this.state.filters} handleChange={this.handleChangeSearch} />
                                </FilterSearch>
                                <Form id="form_search_input" className="d-flex" onSubmit={this.handleSubmitSearch}>
                                    <Form.Control type="text" style={{ width: 350 }} className="mx-2" placeholder={global._trans('common', 'searchData')} defaultValue={this.state.filters.search} name="search" onChange={this.handleChangeSearch}></Form.Control>
                                    {this.showReset() && <button type="button" onClick={this.handleResetSearch} className="btn btn-light font-weight-bolder mr-2 font-size-sm">{global._trans("common", "reset")}</button>}
                                    <button className="btn btn-light-primary font-weight-bolder font-size-sm">{global._trans("common", "search")}</button>
                                </Form>
                            </div>
                        </div> */}
                        {/* <div className="separator separator-solid my-3"></div> */}
                        {/* <SageTable
                            columns={[
                                {
                                    title: global._trans('common', 'state'), dataIndex: "state", sorter: false, render: state => <div className="symbol align-self-start align-self-xxl-center">
                                        <i className={`state-badge symbol-badge symbol-badge-xl bg-${state ? (state == "A" ? "success" : "danger") : "dark"}`}></i><span class="state-bade-text">{state ? (state == "A" ? "Actif" : "Fermé") : "Indéfini"}</span>
                                    </div>
                                },
                                { title: "ID", dataIndex: "id_epic", sorter: true },
                                { title: global._trans('clientsEpic', 'epicName'), dataIndex: "nomEpic", sorter: true },
                                { title: global._trans('common', 'natureJuridque'), dataIndex: "nature_juridique", sorter: true },
                                { title: global._trans('common', 'regionSiege'), dataIndex: "region_siege", sorter: true },
                                { title: global._trans('common', 'departementSiege'), dataIndex: "departement_siege", sorter: true },
                                { title: global._trans('clientsEpic', 'serin'), dataIndex: "serin", sorter: true },
                                { title: global._trans('common', 'address'), dataIndex: "adresse", sorter: true },
                                { title: global._trans('common', 'nbPeople'), dataIndex: "nombreHabitant", sorter: true },
                                {
                                    title: '', dataIndex: "id_epic", fixed: 'right', render: (id_epic, index, epic) => <InlineBottunSvg
                                        bottuns={[
                                            { iconPath: "/media/svg/icons/Costum/Edit.svg", type: "primary", action: '/client/communities/epic/' + id_epic },
                                            { iconPath: "/media/svg/icons/Costum/Trash.svg", type: "danger", action: () => this.handleDeleteEpic(id_epic) },
                                            { iconPath: "/media/svg/icons/Costum/View.svg", type: "primary", action: '/client/communities/epic/view/' + id_epic }
                                        ]}
                                    />
                                }
                            ]}
                            data={this.state.listEpics}
                            style={{ height: 550 }}
                            callBackSort={this.handleSorter}
                            defaultSorter={this.state.filters}
                            IterableProps={{
                                rowSelection: {
                                    onChange: this.handleSelectRow,
                                },
                                locale: {
                                    emptyText: global._trans('clientsEpic', 'emptyList')
                                },
                                loading: this.state.loadEpics || this.state.loadingDelete,
                                rowKey: "id_epic",
                                pagination: { total: this.state.total, pageSize: this.state.filters.pageSize, current: parseInt(this.state.filters.page), showSizeChanger: true },
                                onChange: this.handleTableChange
                            }}
                        /> */}
                        <AgGrid
                            rows={this.state.list}
                            columns={this.state.columnDefs}
                            pagination={{total: this.state.total, pageSize: this.state.page_size, current: this.state.page}}
                            onChange={this.handleTableChange}
                            loading={this.state.loading}
                            ref={ref => this.childComponentRef = ref}
                            views_key={"epcis"}
                            onViewSaved={this.handleViewSaved}
                        />
                    </div>
                </Spin>
                <ImportData show={this.state.showImport} title={global._trans('common', 'titelImport')} sourceFile={this.state.showImport} onHide={() => this.handleImportExcel(null)} />
            </>
        )
    }
}
function mapState(state) {
    const { EpicErrors, listEpics, queryEpic } = state.collectivitie
    return { EpicErrors, listEpics, queryEpic }
}
const connectedClass = connect(mapState, { fetchEpics, deleteEpics, syncEPICsInseeAPI, exportData })(ListEpics)
export { connectedClass as ListEpics }