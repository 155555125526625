import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    GEDErrors: null,
    listGEDFiles: {},
    file: null,
    loading: false
};

export const GEDSlice = createSlice({
    name: "ged",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const { errors } = action.payload
            state.GEDErrors = errors;
            state.loading = false;
        },
        enabledLoading: (state) => {
            state.loading = true;
        },
        GEDFileCreated: (state) => {
            state.GEDErrors = state.GEDErrors === false ? null : false
            state.loading = false
        },
        GEDFilesFetched: (state, action) => {
            const { listGEDFiles } = action.payload
            state.GEDErrors = null
            state.listGEDFiles = listGEDFiles;
            state.loading = false
        },
        GEDFileFetched: (state, action) => {
            state.file = action.payload;
            state.GEDErrors = null;
            state.loading = false;
        },
        GEDFileUpdated: (state, action) => {
            state.loading = false;
            let files = [...state.listGEDFiles.data];
            let fileIndex = files.findIndex(({ id }) => id == action.payload.id);
            if (fileIndex < 0) return;
            files.splice(fileIndex, 1, action.payload)
            state.listGEDFiles.data = files;
        },
        contractFetched: (state, action) => {

        },
        contractsDeleted: (state, action) => {
            const { asyncData, contracts } = action.payload
            if (asyncData) {
                let prevList = state.listContracts
                prevList.data = prevList.data.filter(contract => contracts.indexOf(contract.id_contrat) === -1)
                state.listContracts = prevList
            }
            state.GEDErrors = state.contractErrors === false ? null : false
        }
    }
});