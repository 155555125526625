import React from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../../modules/Auth/auth.reducer';
export const Logout = (props) => {
    const dispatch = useDispatch()
    dispatch(actions.login(null))
    //window.location.href = "/login"
    return (
        <div>

        </div>
    )
}