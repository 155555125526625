import React, { useState } from 'react'
import { Form, Col, Modal, Button } from "react-bootstrap";
import { DatePicker, Spin, Checkbox } from 'antd';
import moment from 'moment';
import { handleExtendShare } from '../../../../../../modules/Shares/shares.actions'
import { CostumAlert } from '../../../../Commons'
import { ColumnsTMB, ColumnsTRI, ColumnsUVE, ColumnsISDND, GeneralColumns, InformationClient, SocieteInformation, GEDFiles, Contracts } from '../../../../Share/utils'
import { TechColumns } from '../../../../Share/utils';
const columns = require('../../../../Commons/columns.json');

export const RenewShare = (props) => {
    const [shareSite, setSahreSite] = useState(props.show)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(null)
    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors(null)
        setLoading(true)
        handleExtendShare({ share: shareSite.id_share_site, start: shareSite.start, end: shareSite.end, ...(shareSite.typeChange === "edit" ? { columns: shareSite.columns, files: shareSite.files, contracts: shareSite.contracts } : null) })
            .then(({ ok, data }) => {
                if (ok) {
                    setErrors(1)
                    props.callBack(shareSite.keyIndex, data)
                } else {
                    setErrors(global._trans("common", "errorsInconue"))
                }
            })
            .catch(({ message, errors }) => {
                if (message === "The given data was invalid.") {
                    setErrors(errors)
                } else {
                    setErrors(global._trans("common", "errorsInconue"))
                }
            })
            .finally(e => {
                setLoading(false)
            })
    }
    const handleChangeName = (name, value) => {
        setSahreSite({
            ...shareSite,
            [name]: value
        })
    }
    const handleChangeFiles = (key, checked) => {
        setSahreSite({
            ...shareSite,
            files: { ...shareSite.files, [key]: checked }
        });
    }

    const handleChangeContracts = (key, checked) => {
        console.log({ key, checked })
        setSahreSite({
            ...shareSite,
            contracts: { ...shareSite.contracts, [key]: checked }
        });
    }
    const handleCheckColumn = (col, name, value) => {

        let prevColumn = shareSite.columns
        prevColumn[col][name] = value;

        setSahreSite({
            ...shareSite,
            columns: prevColumn
        })

    }
    const handleCheckSecondLevelColumn = (col, secondLevelCol, name, value) => {

        let prevColumn = shareSite.columns
        prevColumn[col][secondLevelCol][name] = value;

        setSahreSite({
            ...shareSite,
            columns: prevColumn
        })

    }
    const handleMultColumn = (type, name, checked) => {
        let prevColumn = shareSite.columns
        prevColumn[type][name] = checked
        setSahreSite({
            ...shareSite,
            columns: prevColumn
        })
    }
    const handleActiveCat = (e) => {
        const { name, checked } = e.target
        setSahreSite({
            ...shareSite,
            columns: {
                ...shareSite.columns,
                [name]: checked ? {} : null
            }
        })
    }
    const getColumns = () => {
        switch (shareSite.site) {
            case "UVE": return <ColumnsUVE checkedColumns={shareSite.columns} handleChange={handleCheckColumn} />
            case "TRI": return <ColumnsTRI checkedColumns={shareSite.columns} handleChange={handleCheckColumn} />
            case "TMB": return <ColumnsTMB checkedColumns={shareSite.columns} handleChange={handleCheckColumn} />
            case "ISDND": return <ColumnsISDND checkedColumns={shareSite.columns} handleChange={handleCheckColumn} />
        }
    }
    return (
        <Modal size="xl" show={true} onHide={props.onHide} aria-labelledby="example-modal-sizes-title-lg">
            <Spin spinning={loading}>
                <form className="card card-custom" onSubmit={handleSubmit}>
                    <div className="card-header py-3">
                        <div className="card-title align-items-start flex-column">
                            <h3 className="card-label font-weight-bolder text-dark">{global._trans("shares", "renewDates")}</h3>
                            <span className="text-muted font-weight-bold font-size-sm mt-1">{global._trans("shares", "bodyRenew")}</span>
                        </div>
                    </div>
                    <div className="form">
                        <div className="card-body">
                            {errors && <CostumAlert type={errors !== 1 ? 'danger' : 'success'} errors={errors !== 1 ? errors : global._trans("shares", "successMis")} />}
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('shares', 'startDate')}*</Form.Label>
                                    <DatePicker value={moment(shareSite.start, "DD/MM/YYYY")} format="DD/MM/YYYY" onChange={(date, dateString) => handleChangeName("start", dateString)} className="form-control" placeholder={global._trans('shares', 'startDate')} style={{ width: '100%' }} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{global._trans('shares', 'endDate')}*</Form.Label>
                                    <DatePicker disabledDate={(current) => current < moment(shareSite.start, "DD/MM/YYYY").add(1, 'day')} value={moment(shareSite.end, "DD/MM/YYYY")} format="DD/MM/YYYY" onChange={(date, dateString) => handleChangeName("end", dateString)} className="form-control" placeholder={global._trans('shares', 'endDate')} style={{ width: '100%' }} />
                                </Form.Group>
                            </Form.Row>

                            <TechColumns columns={columns}
                                onSelectRow={() => ({})}
                                dataShare={shareSite.type_data_share == 'Site' ? [{ ...shareSite, categorieSite: shareSite.site }] : [shareSite]}
                                typeShare={shareSite.type_data_share}
                                onSecondLevelCheckboxChange={(column, secondLevelCol, key, value) => handleCheckSecondLevelColumn(column, secondLevelCol, key, value)}
                                onCheckboxChange={(column, key, value) => handleCheckColumn(column, key, value)} />

                            <div className='ColumnRow-Partage'>
                                <strong className="d-block my-3">{global._trans('shares', 'GED')} : </strong>
                                <GEDFiles handleChange={handleChangeFiles} filesTypes={props.fileCategories} checkedColumns={shareSite.files} />
                            </div>
                            <br />
                            <hr />
                            <br />
                            <div className='ColumnRow-Partage'>
                                <strong className="d-block my-3">{global._trans('shares', 'Contracts')} : </strong>
                                <Contracts handleChange={handleChangeContracts} columns={columns.contracts} checkedColumns={shareSite.contracts} />
                            </div>
                            <br />
                            <hr />
                            <br />


                            {/* {shareSite.typeChange === "edit" && (shareSite.type_data_share === "Site" ? <>
                                <strong>{global._trans('shares', 'columnSite')} : </strong><hr />
                                <GeneralColumns checkedColumns={shareSite.columns} handleChange={handleCheckColumn} /><br />
                                <strong>{global._trans('shares', 'columnTech')} ({shareSite.site}) : </strong><hr />
                                {getColumns()}
                                <strong className="d-block my-3">{global._trans('shares', 'columnsClient')} : </strong>
                                <InformationClient checkedColumns={shareSite.columns} handleChange={handleCheckColumn} />
                                <strong className="d-block my-3">{global._trans('shares', 'columnsSociete')} : </strong>
                                <SocieteInformation checkedColumns={shareSite.columns} handleChange={handleCheckColumn} />
                                <strong className="d-block my-3">{global._trans('shares', 'GED')} : </strong>
                                <GEDFiles handleChange={handleChangeFiles} filesTypes={props.fileCategories} checkedColumns={shareSite.files} />

                            </> : <>
                                <strong className="d-block py-2">{global._trans('shares', 'columnSite')} : </strong>
                                <GeneralColumns checkedColumns={shareSite.columns.generalInfo} handleChange={({ target: { name, checked } }) => handleMultColumn('generalInfo', name, checked)} />
                                <strong className="d-block py-2">
                                    <Checkbox name="UVE" checked={shareSite.columns.UVE} onChange={handleActiveCat} style={{ lineHeight: '32px' }}>{global._trans('shares', 'columnTechUVE')}</Checkbox> :
                                </strong>
                                {shareSite.columns.UVE && <ColumnsUVE checkedColumns={shareSite.columns.UVE} handleChange={({ target: { name, checked } }) => handleMultColumn('UVE', name, checked)} />}
                                <strong className="d-block py-2">
                                    <Checkbox name="TRI" checked={shareSite.columns.TRI} onChange={handleActiveCat} style={{ lineHeight: '32px' }}>{global._trans('shares', 'columnTechTRI')}</Checkbox> :
                                </strong>
                                {shareSite.columns.TRI && <ColumnsTRI checkedColumns={shareSite.columns.TRI} handleChange={({ target: { name, checked } }) => handleMultColumn('TRI', name, checked)} />}
                                <strong className="d-block py-2">
                                    <Checkbox name="TMB" checked={shareSite.columns.TMB} onChange={handleActiveCat} style={{ lineHeight: '32px' }}>{global._trans('shares', 'columnTechTMB')}</Checkbox> :
                                </strong>
                                {shareSite.columns.TMB && <ColumnsTMB checkedColumns={shareSite.columns.TMB} handleChange={({ target: { name, checked } }) => handleMultColumn('TMB', name, checked)} />}
                                <strong className="d-block py-2">
                                    <Checkbox name="ISDND" checked={shareSite.columns.ISDND} onChange={handleActiveCat} style={{ lineHeight: '32px' }}>{global._trans('shares', 'columnTechISDND')}</Checkbox> :
                                </strong>
                                {shareSite.columns.ISDND && <ColumnsISDND checkedColumns={shareSite.columns.ISDND} handleChange={({ target: { name, checked } }) => handleMultColumn('ISDND', name, checked)} />}
                                <strong className="d-block my-3">{global._trans('shares', 'columnsClient')} : </strong>
                                <InformationClient checkedColumns={shareSite.columns.Client} handleChange={({ target: { name, checked } }) => handleMultColumn('Client', name, checked)} />
                                <strong className="d-block my-3">{global._trans('shares', 'columnsSociete')} : </strong>
                                <SocieteInformation checkedColumns={shareSite.columns.Company} handleChange={({ target: { name, checked } }) => handleMultColumn('Company', name, checked)} />
                                <strong className="d-block my-3">{global._trans('shares', 'GED')} : </strong>
                                <GEDFiles handleChange={handleChangeFiles} filesTypes={props.fileCategories} checkedColumns={shareSite.files} />

                            </>)} */}
                            <div className="d-flex justify-content-end mt-4">
                                <Button variant="default" className="mr-3" type='button' onClick={props.onHide}>{global._trans('common', 'cancelBtn')}</Button>
                                <Button variant="primary" type='submit'>{global._trans('shares', 'saveAndShare')}</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Spin>
        </Modal>
    )
}