import { CrudService } from "../../../services";
import { gestionaireUrls } from './gestionaire.constants'
export function createGestionaire(Gestionaire) {
    return CrudService.Post(gestionaireUrls.CREATE_GESTIONAIRE_URL, Gestionaire);
}
export function fetchGestionaires(queryParams) {
    return CrudService.Get(gestionaireUrls.FETCH_GESTIONAIRES_URL, {}, queryParams);
}
export function updateGestionaire(Gestionaire) {
    return CrudService.Patch(gestionaireUrls.UPDATE_GESTIONAIRE_URL, Gestionaire);
}
export function fetchGestionaire(idGestionaire) {
    return CrudService.Get(gestionaireUrls.FETCH_GESTIONAIRE_URL+idGestionaire, {});
}
export function editGestionaire(idGestionaire) {
    return CrudService.Get(gestionaireUrls.EDIT_GESTIONAIRE_URL+idGestionaire, {});
}
export function deleteGestionaires(Gestionnaires) {
    return CrudService.Delete(gestionaireUrls.DELETE_GESTIONAIRES_URL,Gestionnaires,{});
}
export function sitesGestionaire(idGestionaire,queryParams) {
    return CrudService.Get(gestionaireUrls.SITES_GESTIONAIRE_URL+idGestionaire,{},queryParams);
}
export function deleteSitesGestionaire(sites) {
    return CrudService.Delete(gestionaireUrls.DELETE_GESTIONAIRE_SITES_URL,sites);
}
