export * from "./Auth/auth.reducer"
export * from './Settings/settings.reducer'
export * from './Collectivities/collectivitie.reducer'
export * from './Societes/societe.reducer'
export * from './Contacts/contact.reducer'
export * from './Gestionaires/gestionaire.reducer'
export * from './Sites/sites.reducer'
export * from './MapSites/mapsites.reducer'
export * from './Admins/admin.reducer'
export * from './Contract/contract.reducer'
export * from './User/user.reducer'
export * from './Shares/shares.reducer'
export * from './Common/common.reducer'
export * from './UserSimple/userSimple.reducer'
export * from './Notifications/notifications.reducer'
export * from './GED/contract.reducer'