export const SettingsUrls = {
    CREATE_URL: 'enums/create',
    FETCH_URL: "enums/show",
    DELETE_URL: "enums/delete",
    FETCH_DEPARTEMENT_URL: "departement/enums",
    ADD_DEPARTEMENT_URL:"departement/add",
    DELETE_DEPARTEMENT_URL:"departement/delete",
    FETCH_REGION_URL: "region/enums",
    ADD_REGION_URL:"region/add",
    DELETE_REGION_URL:"region/delete"
}