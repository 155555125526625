import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { SageTable } from '../../Commons'
import { fetchEpics } from "../../../../modules/Collectivities/collectivitie.actions";
class EpicSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            listEpics: [],
            pageSize: 20,
            total: 0,
            cuurentPage: 1,
            loadEpics: true
        }
        this.rowSelected = null
        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.validSelect = this.validSelect.bind(this)
        props.fetchEpics()
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loadEpics) {
            if (nextProps.listEpics && Array.isArray(nextProps.listEpics.data)) {
                this.setState({
                    listEpics: nextProps.listEpics.data,
                    loadEpics: false,
                    cuurentPage: nextProps.listEpics.current_page,
                    total: nextProps.listEpics.total
                })
            }

        }
    }
    handleSelectRow(selectedRowKeys) {
        this.rowSelected = selectedRowKeys
    }
    handleTableChange = (pagination, filters) => {
        if (pagination.current !== this.state.cuurentPage || pagination.pageSize !== this.state.pageSize) {
            this.props.fetchEpics({ pageSize: pagination.pageSize, page: pagination.current })
            this.setState({
                loadEpics: true,
                pageSize: pagination.pageSize
            })
        }
    }
    validSelect() {
        let epicToReturn = null
        if (this.rowSelected && this.rowSelected[0]) {
            epicToReturn = this.state.listEpics.find(obj => obj.id_epic == this.rowSelected[0])
            epicToReturn = {
                id_epic: epicToReturn.id_epic,
                nomEpic: epicToReturn.nomEpic,
                adresse: epicToReturn.adresse
            }
            this.props.onSelect(epicToReturn)
        }
    }
    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} aria-labelledby="example-modal-sizes-title-lg">
                <div className="p-5">
                    <h4 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{global._trans('clientsEpic', 'selectEpicToRatach')}</span>
                    </h4>
                    <SageTable
                        columns={[
                            { title: "ID", dataIndex: "id_epic", fixed: 'left' },
                            { title: global._trans('clientsEpic', 'epicName'), dataIndex: "nomEpic", fixed: 'left' },
                            { title: global._trans('common', 'natureJuridque'), dataIndex: "nature_juridique" },
                            { title: global._trans('common', 'regionSiege'), dataIndex: "region_siege" },

                            { title: global._trans('common', 'compDechetDelgue'), dataIndex: "competence_dechet" },
                            { title: global._trans('common', 'serin'), dataIndex: "serin" },
                            { title: global._trans('common', 'nbPeople'), dataIndex: "nombreHabitant" },
                            { title: global._trans('common', 'address'), dataIndex: "adresse" },
                            { title: global._trans('common', 'departementSiege'), dataIndex: "departement_siege" },
                        ]}
                        style={{ height: 550 }}
                        data={this.state.listEpics}
                        IterableProps={{
                            rowSelection: {
                                onChange: this.handleSelectRow,
                                type: 'radio'
                            },
                            emptyText: global._trans('clientsEpic', 'emptyList'),
                            loading: this.state.loadEpics,
                            rowKey: "id_epic",
                            pagination: { total: this.state.total, pageSize: this.state.pageSize, current: this.state.cuurentPage },
                            onChange: this.handleTableChange
                        }}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="primary" onClick={this.validSelect}>{global._trans('common', 'validSelect')}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
function mapState(state) {
    const { listEpics } = state.collectivitie
    return { listEpics }
}
const connectedClass = connect(mapState, { fetchEpics })(EpicSelector)
export { connectedClass as EpicSelector }