import React, { Component } from 'react';
import { Form, Col } from "react-bootstrap";
import { connect } from 'react-redux'
import { Select, DatePicker } from 'antd';
import moment from 'moment';
class DataTechTRI extends Component {
    render() {
        return (
            <>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'capaciteHoraire')}*</Form.Label>
                        <Form.Control type="number" placeholder={global._trans('sites', 'capaciteHoraire')} value={this.props.stateTech.capaciteHoraire} name="capaciteHoraire" onChange={this.props.handleEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans("sites", "capaciteNominale")}</Form.Label>
                        <Form.Control type="number" placeholder={global._trans("sites", "capaciteNominale")} value={this.props.stateTech.capaciteNominale} name="capaciteNominale" onChange={this.props.handleEvent} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'capaciteReglementaire')}*</Form.Label>
                        <Form.Control type="number" placeholder={global._trans('sites', 'capaciteReglementaire')} value={this.props.stateTech.capaciteReglementaire} name="capaciteReglementaire" onChange={this.props.handleEvent} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'dernierConstructeur')}*</Form.Label>
                        <Form.Control type="text" placeholder={global._trans('sites', 'dernierConstructeur')} value={this.props.stateTech.dernierConstructeur} name="dernierConstructeur" onChange={this.props.handleEvent} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'dateExtension')}*</Form.Label>
                        <DatePicker format="DD/MM/YYYY" value={moment(this.props.stateTech.dateExtension, "DD/MM/YYYY")} onChange={(date, dateString) => this.props.handleName("dateExtension", dateString)} className="form-control" placeholder={global._trans('sites', 'dateExtension')} style={{ width: '100%' }} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{global._trans('sites', 'miseEnService')}</Form.Label>
                        <DatePicker format="DD/MM/YYYY" value={moment(this.props.stateTech.miseEnService, "DD/MM/YYYY")} onChange={(date, dateString) => this.props.handleName("miseEnService", dateString)} className="form-control" placeholder={global._trans('sites', 'miseEnService')} style={{ width: '100%' }} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="d-block">{global._trans('sites', 'extension')}*</Form.Label>
                        <Select
                            showSearch
                            showArrow
                            className="inputSizeSelect"
                            placeholder={global._trans('sites', 'extension')}
                            style={{ width: '100%' }}
                            options={this.props.extension}
                            optionFilterProp="label"
                            defaultValue={this.props.stateTech.extension}
                            onChange={(selectedValue) => this.props.handleName("extension", selectedValue)}
                        />
                    </Form.Group>
                </Form.Row>
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { extension } } = state.enums
    return { extension }
}
const connectedClass = connect(mapState, {})(DataTechTRI)
export { connectedClass as DataTechTRI }