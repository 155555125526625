import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link, Redirect, } from 'react-router-dom'
import { Button, Form, Col } from "react-bootstrap";
import { Select, notification, Spin } from 'antd';
import { CostumAlert, PhonePicker } from '../Commons'
import { createSociete, editSociete, updateSociete, syncSocietiesInseeAPI } from "../../../modules/Societes/societe.actions";
import { ImageCroper, AdressPicker } from '../Commons';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_sage/_helpers';
const sitesStatus = ['VALIDATED', 'NOT_VALIDATED', 'NOT_PUBLISHED'];
const states = [{label: 'Actif', value: 'A'}, {label: 'Fermé', value: 'C'}];
class CreateSocietie extends Component {
    constructor(props) {
        super(props)
        let action = props.match.params.idCreate === "new"
        this.state = {
            groupe: [],
            denomination: '',
            usual_denomination: '',
            adresse: '',
            lat: '',
            lang: '',
            city: '',
            country: '',
            postcode: '',
            serin: '',
            siret: '',
            sinoe: '',
            status: 'NOT_VALIDATED',
            logo: [],
            nature_juridique: null,
            effectifs_new: '',
            effectifs_year: '',
            codeape: null,
            telephoneStandrad: '',
            siteInternet: '',
            loadingCreate: false,
            errors: null,
            showAlert: false,
            loading: !action
        }
        this.isNewItem = action
        if (!action) {
            props.editSociete(props.match.params.idCreate)
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this._suggestionSelect = this._suggestionSelect.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loadingCreate) {
            this.setState({
                loadingCreate: false,
                errors: nextProps.errorsSociete,
                showAlert: true
            })
        }
        if (this.state.loading && (nextProps.societe || nextProps.errorsSociete)) {
            this.isNewItem = !nextProps.societe
            let society = nextProps.societe.groupe ? nextProps.societe : {...nextProps.societe, groupe: []}
            this.setState({
                ...society,
                loading: false
            })
        }
        if (nextProps.societe && nextProps.societe.refresh) {
            this.setState({
                loading: true
            })
            this.props.editSociete(this.props.match.params.idCreate)
        }
    }
    handleChangeEvent(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
        if (name === 'siret' && value.length < 10) {
            this.setState({
                serin: value
            })
        }
    }
    handleChange(name, value) {
        if (name == "groupe" && value && value.length == 1) {
            const group = this.props.groupeList.find(obj => obj.value === value[0]);
            if (group && group.logo && group.logo.length > 0) {
                this.setState({
                    logo: group.logo,
                    groupe: value
                })
                return;
            }
        }
        this.setState({
            [name]: value
        })
    }
    _suggestionSelect(adress) {
        this.setState({
            adresse: adress.address,
            lat: adress.lat,
            lang: adress.lang,
            city: adress.city,
            country: 'France',
            postcode: adress.postcode
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        if (!this.state.loadingCreate) {
            let state = {
                ...this.state,
                logo: this.state.logo[0] ? this.state.logo[0].uid : null
            }
            if (this.isNewItem) {
                this.props.createSociete(state)
            } else {
                this.props.updateSociete(state)
            }
            this.setState({
                loadingCreate: true,
                showAlert: false
            })
        }
    }
    showErrors() {
        if (this.state.showAlert) {
            if (this.state.errors) {
                return <CostumAlert type='danger' errors={this.state.errors} />
            } else {
                notification.success({
                    message: this.isNewItem ? global._trans('companies', 'titleCreated') : global._trans('companies', 'titleUpdated'),
                    description: this.isNewItem ? global._trans('companies', 'bodyCreated') : global._trans('companies', 'bodyUpdated'),
                    placement: 'topRight',
                    style:{backgroundColor: '#EEFFF3', borderRadius: '8px', border: '1px solid #23A047'}

                })
                return <Redirect to="/client/companies" />
            }
        }
    }

    render() {
        return (
            <>
                <div className="card-header border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{this.isNewItem ? global._trans('companies', 'titleCreate') : global._trans('companies', 'titleEdit')}</span>
                    </h3>
                </div>
                <Spin spinning={this.state.loading || this.state.loadingCreate}>
                    <div className="card-body py-4">
                        {this.showErrors()}
                        <div className="form px-5 pb-5">
                            <Form method="post" onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'state')}</Form.Label>
                                        <Select
                                            showSearch
                                            showArrow
                                            className="inputSizeSelect"
                                            placeholder={global._trans("common", "state")}
                                            value={this.state.state}
                                            style={{ width: '100%' }}
                                            options={states}
                                            onSelect={(SelectedValue) => this.handleChange("state", SelectedValue)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('companies', 'groupe')}</Form.Label>
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            showArrow
                                            className="inputSizeSelect"
                                            placeholder={global._trans('companies', 'groupe')}
                                            style={{ width: '100%' }}
                                            optionFilterProp="label"
                                            value={this.state.groupe}
                                            loading={!this.props.groupeList}
                                            options={this.props.groupeList}
                                            onChange={(selectedItem) => this.handleChange('groupe', selectedItem)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('sites', 'denomenation')}*</Form.Label>
                                        <Form.Control type="text" name="denomination" value={this.state.denomination} placeholder={global._trans('sites', 'denomenation')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'usual_denomination')}</Form.Label>
                                        <Form.Control type="text" name="usual_denomination" value={this.state.usual_denomination} placeholder={global._trans('common', 'usual_denomination')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'address')}*</Form.Label>
                                        <AdressPicker value={this.state.adresse} onChange={this._suggestionSelect} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'city')}*</Form.Label>
                                        <Form.Control type='text' placeholder={global._trans('common', 'city')} name='city' value={this.state.city} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'country')}*</Form.Label>
                                        <Form.Control type='text' placeholder={global._trans('common', 'country')} name='country' value={this.state.country} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'postcode')}*</Form.Label>
                                        <Form.Control type='text' placeholder={global._trans('common', 'postcode')} name='postcode' value={this.state.postcode} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('companies', 'siret')}</Form.Label>
                                        <Form.Control type='number' placeholder={global._trans('companies', 'siret')} name='siret' value={this.state.siret} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('companies', 'serin')}*</Form.Label>
                                        <Form.Control type='number' placeholder={global._trans('companies', 'serin')} name='serin' value={this.state.serin} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('clientsEpic', 'sinoe')}*</Form.Label>
                                        <Form.Control type='text' placeholder={global._trans('clientsEpic', 'sinoe')} name='sinoe' value={this.state.sinoe} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'natureJuridque')}*</Form.Label>
                                        <Select
                                            showSearch
                                            showArrow
                                            className="inputSizeSelect"
                                            placeholder={global._trans('common', 'natureJuridque')}
                                            style={{ width: '100%' }}
                                            optionFilterProp="label"
                                            value={this.state.nature_juridique}
                                            loading={!this.props.nature_juridique}
                                            options={this.props.nature_juridique}
                                            onChange={(selectedItem) => this.handleChange('nature_juridique', selectedItem)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('companies', 'codeape')}*</Form.Label>
                                        <Select
                                            showSearch
                                            showArrow
                                            className="inputSizeSelect"
                                            placeholder={global._trans('companies', 'codeape')}
                                            style={{ width: '100%' }}
                                            optionFilterProp="label"
                                            value={this.state.codeape}
                                            loading={!this.props.codeape}
                                            options={this.props.codeape}
                                            onChange={(selectedItem) => this.handleChange('codeape', selectedItem)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'webSite')}</Form.Label>
                                        <Form.Control type="text" name="siteInternet" value={this.state.siteInternet || ""} placeholder={global._trans('common', 'webSite')} onChange={this.handleChangeEvent} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans('common', 'logoCompany')}</Form.Label>
                                        {this.state.groupe.length == 1 && this.props.groupeList.find(obj => obj.value === this.state.groupe[0])?.logo?.length > 0 && <style>{`.ant-upload-list-item-card-actions { display: none; }`}</style>}
                                        <ImageCroper images={this.state.logo} onChange={(images) => this.handleChange('logo', images)} />
                                    </Form.Group>
                                </Form.Row>
                                <div className="separator separator-solid mb-4"></div>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridDenom">
                                        <Form.Label>{global._trans('companies', 'telephoneStandrad')}</Form.Label>
                                        <PhonePicker
                                            value={this.state.telephoneStandrad}
                                            onChange={(value) => this.handleChange("telephoneStandrad", value)} />
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Row>
                                            {/* <Form.Group as={Col} className=" mb-2 ">
                                                <Form.Label>{global._trans('companies', 'nombreSalarie')}</Form.Label>
                                                <Form.Control type="number" name="effectifs" value={this.state.effectifs} placeholder={global._trans('companies', 'nombreSalarie')} onChange={this.handleChangeEvent} />
                                            </Form.Group> */}
                                            <Form.Group as={Col}  className=" mb-2 ">
                                                <Form.Label>{global._trans('common', 'effectifs')}</Form.Label>
                                                <Select
                                                    showSearch
                                                    showArrow
                                                    className="inputSizeSelect"
                                                    placeholder={global._trans('common', 'selectNatureJuridque')}
                                                    style={{ width: '100%' }}
                                                    optionFilterProp="label"
                                                    value={this.state.effectifs_new}
                                                    loading={!this.props.effectifs}
                                                    options={this.props.effectifs}
                                                    onChange={(selectedItem) => this.handleChange('effectifs_new', selectedItem)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} className=" mb-2 ">
                                                <Form.Label>{global._trans('common', 'nbPeopleAnnee')}</Form.Label>
                                                <Form.Control type="number" name="effectifs_year" value={this.state.effectifs_year} placeholder={global._trans('common', 'nbPeopleAnnee')} onChange={this.handleChangeEvent} />
                                            </Form.Group>
                                        </Form.Row>
                                        {this.state.date_enter && <div className="px-2"><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.state.date_enter)}</div>}
                                    </Form.Group>

                                    {/* <Form.Group as={Col} controlId="formGridDenom">
                                        <Form.Label>{global._trans('companies', 'nombreSalarie')}*</Form.Label>
                                        <Form.Control type="number" name="effectifs" value={this.state.effectifs} placeholder={global._trans('companies', 'nombreSalarie')} onChange={this.handleChangeEvent} />
                                        {this.state.date_enter && <div className="p-2"><strong>{global._trans("common", "lastDate")}</strong> : {global._toFormatDate(this.state.date_enter)}</div>}
                                    </Form.Group> */}


                                    <Form.Group as={Col}>
                                        <Form.Label>{global._trans("sites", "status")}*</Form.Label>
                                        <Select
                                            showSearch
                                            showArrow
                                            className="inputSizeSelect"
                                            placeholder={global._trans("sites", "status")}
                                            value={this.state.status}
                                            style={{ width: '100%' }}
                                            options={sitesStatus.map((value) => ({ label: global._trans("sites", `status_${value}`), value }))}
                                            onSelect={(SelectedValue) => this.handleChange("status", SelectedValue)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <div className="d-flex justify-content-end">
                                    <div className="card-toolbar mx-4">
                                        <Link to="/client/companies" className="btn btn-secondary" >{global._trans('common', 'cancelBtn')}</Link>
                                    </div>
                                    {!this.isNewItem &&
                                        <Button variant="primary" className="mr-2" onClick={() => {
                                            this.props.syncSocietiesInseeAPI({ action: 'sync_array', societies: [this.state.siret ? "siret:"+this.state.siret : "siren:"+this.state.serin] })
                                        }}>
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Costum/Compiling.svg"
                                                )} />
                                            {global._trans('common', 'synchronize')}
                                        </Button>
                                    }
                                    <Button variant="primary" type='submit' disabled={this.state.loadingCreate}>{this.isNewItem ? global._trans('companies', 'submitBtn') : global._trans('common', 'saveChanges')}</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Spin>
            </>
        )
    }
}
function mapState(state) {
    const { listEnums: { nature_juridique, codeape, groupeList, effectifs } } = state.enums
    const { errorsSociete, societe } = state.societe
    return { nature_juridique, codeape, societe, groupeList, errorsSociete, effectifs }
}
const connectedClass = connect(mapState, { createSociete, editSociete, updateSociete, syncSocietiesInseeAPI })(CreateSocietie)
export { connectedClass as CreateSocietie }