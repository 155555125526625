import React from 'react'
import { Form } from 'react-bootstrap'
export const FiltersSocieties = (props) => {
    return (
        <>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('companies', 'groupe')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('companies', 'groupe')} defaultValue={props.filters.groupe} name="groupe" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('sites', 'denomenation')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('sites', 'denomenation')} defaultValue={props.filters.denomination} name="denomination" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'serin')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'serin')} defaultValue={props.filters.serin} name="serin" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'natureJuridque')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'natureJuridque')} defaultValue={props.filters.nature_juridique} name="nature_juridique" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('companies', 'codeape')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('companies', 'codeape')} defaultValue={props.filters.codeape} name="codeape" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('common', 'address')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('common', 'address')} defaultValue={props.filters.address} name="address" onChange={props.handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>{global._trans('companies', 'telephoneStandrad')}</Form.Label>
                <Form.Control type="text" placeholder={global._trans('companies', 'telephoneStandrad')} defaultValue={props.filters.telephoneStandrad} name="telephoneStandrad" onChange={props.handleChange} />
            </Form.Group>
        </>
    )
}