import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class TypeDechetAccept extends Component {
    render() {
        return (
            <CommonComponent keyName="typeDechetAccepter" titleSetting={global._trans('settings', 'dechetAccepte')} typeDechetAccepter={this.props.typeDechetAccepter} />
        )
    }
}
function mapState(state) {
    const { listEnums: { typeDechetAccepter } } = state.enums
    return { typeDechetAccepter }
}
const connectedClass = connect(mapState, null)(TypeDechetAccept)
export { connectedClass as TypeDechetAccept }