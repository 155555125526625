import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class ValorisationEnergitique extends Component {
    render() {
        return (
            <CommonComponent keyName="valorisationEnergitique" titleSetting={global._trans('settings', 'valoriEnergitique')} valorisationEnergitique={this.props.valorisationEnergitique} />
        )
    }
}
function mapState(state) {
    const { listEnums: { valorisationEnergitique } } = state.enums
    return { valorisationEnergitique }
}
const connectedClass = connect(mapState, null)(ValorisationEnergitique)
export { connectedClass as ValorisationEnergitique }