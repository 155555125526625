import { CrudService } from "../../../services";
import { contractUrls } from './contract.constants'
export function createContract(Contract) {
    return CrudService.Post(contractUrls.CREATE_CONTRACT_URL, Contract);
}
export function fetchContracts(queryParams) {
    return CrudService.Post(contractUrls.FETCH_CONTRACTS_URL, queryParams);
}
export function fetchContract(idContract) {
    return CrudService.Get(contractUrls.FETCH_CONTRACT_URL + idContract);
}
export function deleteContracts(contracts) {
    return CrudService.Delete(contractUrls.DELETE_CONTRACTS_URL, contracts);
}
export function editContract(idContract) {
    return CrudService.Get(contractUrls.EDIT_CONTRACT_URL + idContract);
}
export function updateContract(contract) {
    return CrudService.Patch(contractUrls.UPDATE_CONTRACT_URL, contract);
}
