import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class CommonComponentWrapper extends Component {
    render() {
        let { keyName, title, enums } = this.props;
        return (
            <CommonComponent keyName={keyName} titleSetting={title} {...{ [keyName]: enums[keyName] }} />
        )
    }
}
function mapState(state) {
    return { enums: state.enums.listEnums }
}
const connectedClass = connect(mapState, null)(CommonComponentWrapper)
export { connectedClass as CommonComponentWrapper }