import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { CreateShare } from './CreateShare'
class SharePage extends Component {
    render() {
        return (
            <div className="card card-custom">
                <Switch>
                    <Route
                        path="/shares/site"
                        component={CreateShare}
                    />
                </Switch>
            </div>
        )
    }
}
export { SharePage }