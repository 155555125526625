import { CrudService } from "../../../services";
import { contactUrls } from './contact.constants'
export function createContact(Conatct) {
    return CrudService.Post(contactUrls.CREATE_CONTACT_URL, Conatct);
}
export function fetchContacts(queryParams) {
    return CrudService.Post(contactUrls.FETCH_CONTACTS_URL, queryParams);
}
export function fetchContact(idContact) {
    return CrudService.Get(contactUrls.FETCH_CONTACT_URL+idContact, {});
}
export function deleteContacts(contacts) {
    return CrudService.Delete(contactUrls.DELETE_CONTACT_URL,contacts,{});
}
export function editContact(idContact) {
    return CrudService.Get(contactUrls.EDIT_CONTACT_URL+idContact, {});
}
export function updateContacts(contact) {
    return CrudService.Patch(contactUrls.UPDATE_CONTACT_URL,contact,{});
}
export function deleteFunctionContact(functionPerson) {
    return CrudService.Delete(contactUrls.DELETE_FUNCTION_CONTACT_URL,functionPerson);
}
export function handleFunctionContacts(functionPerson) {
    return CrudService.Patch(contactUrls.HANDLE_STATUS_FUNCTION_CONTACT_URL,functionPerson,{});
}
