import axios from 'axios';
const URL_ROOT = process.env.REACT_APP_HOST_API
export const CrudService = {
    Put,
    Get,
    Post,
    Patch,
    Delete,
    setAuthHeader
};
axios.interceptors.response.use(function (response) {
  return response.data || {}
},function (error) {
    return Promise.reject((error.response && error.response.data) || {}) ;
})
function setAuthHeader(store) {
    axios.interceptors.request.use(
        config => {
            const {
                auth: { authToken }
            } = store.getState();

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }

            return config;
        },
        err => Promise.reject(err)
    );
}
function Put(url, data, headers) {
    return axios.put(`${URL_ROOT}${url}`, data, {
        headers: headers
    })
}
function Get(url, headers, params) {
    return axios.get(`${URL_ROOT}${url}`, {
        headers: headers,
        params: params
    })
}

function Post(url, data, headers) {
    return axios.post(`${URL_ROOT}${url}`, data, {
        headers: headers
    })
}
function Patch(url, data, headers) {
    return axios.patch(`${URL_ROOT}${url}`, data, {
        headers: headers
    })
}
function Delete(url, data, headers) {
    var config = {
        method: 'delete',
        url: `${URL_ROOT}${url}`,
        headers: headers,
        data: data
    };
    return axios(config)
}
