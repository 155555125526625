import React, { Component } from 'react';
// import { Navbar, FormControl, Button, DropdownButton, Dropdown } from 'react-bootstrap'
import { RegionSearch, DepartementSearch } from '../Commons';
import qs from 'qs'
import { Checkbox, Select, Slider } from 'antd';
import { Form, Col } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { AiOutlineSearch } from 'react-icons/ai';
import { RegionSearchFilter } from './RegionSearchFilter';


const queryString = require('query-string');
export class FilterPickerAdvanced extends Component {
    constructor(props) {
        super(props)
        var searchQuery = props.history.location.search.replace('?', '')
        this.state = {
            showDrawer: false,
            listSide: false,
            currentStyle: 2,
            orderBY: qs.parse(searchQuery).orderBY || 'denomination',
            sitesArray: [],
            // groupsListData: [],
            // typeEquipementData: [],
            // modeGestionData: [],
            // traitementFumeeData: [],
            asCategory: [],
            asModeGestion: ['all'],
            asAmobe: ['all'],
            asGroupeExploitant: ['all'],
            asDepartement: ['all'],
            asRegion: ['all'],
            asNombreDeLignes: ['all'],
            asCapacitereglementaire: [0, 1000000],
            asTechnologieDeTurbine: ['all'],
            asVoieDeTraitementDesFumees: ['all'],
            asTypeDeValorisation: '',
            asChaleurVendueSite: [0, 1000000],
            asChaleurVendueSiteCheck: false,
            asElectriciteVendueSite: [0, 1000000],
            asElectriciteVendueSiteCheck: false,
            asContractAvantApres: 'all',
            asContractDate: null,
            asContractDateEnd: null,
            asContractDateCheck: false,
            search: '',
            ...qs.parse(searchQuery),
        }
        this.orderByList = [
            {
                value: 'categorieSite',
                label: 'Catégorie'
            },
            {
                value: 'modeGestion',
                label: 'M.Gestion'
            },
            {
                value: 'city',
                label: 'Ville'
            },
            {
                value: 'region_siege',
                label: 'Région'
            },
            {
                value: 'departement_siege',
                label: 'Département'
            },
        ]
        this.asUser = this.asUser.bind(this)
        this.handleCallSearch = this.handleCallSearch.bind(this)
        this.handleReset = this.handleReset.bind(this)
    }

    callSearchFun(dt) {
        this.props.handleSearch(dt)
    }

    handleCallSearch(val) {
        if (val) {
            this.setState({
                orderBY: val.target.value
            })
        }
        const dt = { ...this.state }
        delete dt.sitesArray
        delete dt.listSide
        delete dt.currentStyle
        // delete dt.groupsListData
        delete dt.showDrawer
        // delete dt.modeGestionData
        // delete dt.traitementFumeeData
        // delete dt.typeEquipementData
        if (this.state.search) {
            dt.search = this.state.search
        } else {
            delete dt.search
        }
        if (dt.asCapacitereglementaire[0] === 0 && dt.asCapacitereglementaire[1] === 1000000) {
            delete dt.asCapacitereglementaire
        }
        if (!dt.asCategory.length) {
            delete dt.asCategory
            delete dt.asCapacitereglementaire
        } else {
            if (!dt.asCategory.includes('TRI') && !dt.asCategory.includes('ISDND') && !dt.asCategory.includes('UVE')) {
                delete dt.asCapacitereglementaire
            }
        }
        if (!dt.asChaleurVendueSiteCheck) {
            delete dt.asChaleurVendueSiteCheck
        }
        if (dt.asContractAvantApres === 'all') {
            delete dt.asContractAvantApres
            delete dt.asContractDate
            delete dt.asContractDateEnd
            delete dt.asContractDateCheck
        }
        if (dt.asContractAvantApres !== 'entre') {
            delete dt.asContractDateEnd
        }
        if (!dt.asContractDateCheck) {
            delete dt.asContractDateCheck
        }
        if (!dt.asContractDate) {
            delete dt.asContractAvantApres
            delete dt.asContractDate
            delete dt.asContractDateEnd
            delete dt.asContractDateCheck
        }
        if (!dt.asElectriciteVendueSiteCheck) {
            delete dt.asElectriciteVendueSiteCheck
        }
        if (dt.asRegion.includes('all') || !dt.asRegion.length) {
            delete dt.asRegion
        }
        if (dt.asDepartement.includes('all') || !dt.asDepartement.length) {
            delete dt.asDepartement
        }
        if (dt.asGroupeExploitant.includes('all') || !dt.asGroupeExploitant.length) {
            delete dt.asGroupeExploitant
        }
        if (dt.asModeGestion.includes('all') || !dt.asModeGestion.length) {
            delete dt.asModeGestion
        }
        if (dt.asAmobe.includes('all') || !dt.asAmobe.length) {
            delete dt.asAmobe
        }
        if (dt.asNombreDeLignes.includes('all') || !dt.asNombreDeLignes.length) {
            delete dt.asNombreDeLignes
        }
        if (dt.asTechnologieDeTurbine.includes('all') || !dt.asTechnologieDeTurbine.length) {
            delete dt.asTechnologieDeTurbine
        }
        if (dt.asVoieDeTraitementDesFumees.includes('all') || !dt.asVoieDeTraitementDesFumees.length) {
            delete dt.asVoieDeTraitementDesFumees
        }
        // if (dt.asChaleurVendueSite[0] === 0 && dt.asChaleurVendueSite[1] === 1000) {
        //     delete dt.asChaleurVendueSite
        // }
        // if (dt.asElectriciteVendueSite[0] === 0 && dt.asElectriciteVendueSite[1] === 1000) {
        //     delete dt.asElectriciteVendueSite
        // }
        if (!dt.asTypeDeValorisation) {
            delete dt.asTypeDeValorisation

            delete dt.asChaleurVendueSite
            delete dt.asChaleurVendueSiteCheck

            delete dt.asElectriciteVendueSite
            delete dt.asElectriciteVendueSiteCheck
        } else {
            if (dt.asTypeDeValorisation === 'electric') {
                delete dt.asChaleurVendueSite
                delete dt.asChaleurVendueSiteCheck
            }
            if (dt.asTypeDeValorisation === 'hydrogene') {
                delete dt.asElectriciteVendueSite
                delete dt.asElectriciteVendueSiteCheck
            }
            if (dt.asTypeDeValorisation === 'thermique') {
                delete dt.asElectriciteVendueSite
                delete dt.asElectriciteVendueSiteCheck
            }
            // if (dt.asTypeDeValorisation === 'thermiqueANDelectric') {
            //     // delete dt.asElectriciteVendueSite
            //     // delete dt.asElectriciteVendueSiteCheck
            // }
        }
        if (val) {
            dt.orderBY = val.target.value
        }
        if (dt.orderBY === 'denomination') {
            delete dt.orderBY
        }
        this.props.history.push({ search: qs.stringify(dt) })
        this.callSearchFun(dt)
    }

    handleReset(e) {
        this.setState({
            orderBY: 'denomination',
            search: '',
            asCategory: [],
            asModeGestion: ['all'],
            asAmobe: ['all'],
            asGroupeExploitant: ['all'],
            asDepartement: ['all'],
            asRegion: ['all'],
            asNombreDeLignes: ['all'],
            asTechnologieDeTurbine: ['all'],
            asVoieDeTraitementDesFumees: ['all'],
            asTypeDeValorisation: '',
            asChaleurVendueSite: [0, 1000000],
            asChaleurVendueSiteCheck: false,
            asElectriciteVendueSite: [0, 1000000],
            asElectriciteVendueSiteCheck: false,
            asCapacitereglementaire: [0, 1000000],
            asContractAvantApres: 'all',
            asContractDate: null,
            asContractDateCheck: false,
        })

        this.props.history.push({ search: '' })
        this.callSearchFun({})
    }

    asUser() {
        return (
            <div className="mt-5" style={{
                position: 'fixed', top: '70px', 'right': '20px',
                borderRadius: '6px',
                overflowY: 'auto', height: 'calc(100vh - 140px)', borderBottom: '0px solid white', width: '500px', backgroundColor: '#202531'
            }}>
                <div className="pb-0 pl-0 pt-0 pr-0 position-relative pt-8 px-2" style={{ backgroundColor: '#202531' }}>
                    <div className="d-flex justify-content-start align-items-center" style={{ width: '95%', margin: '0 auto' }}>
                        <div className="d-flex justify-content-start align-items-center mr-4" style={{ flex: 1, backgroundColor: '#F5F8FA', height: '44px', borderRadius: '0.475rem' }}>
                            <AiOutlineSearch size={20} className='mx-3' />
                            <input type="text" onChange={(e) => this.setState({ search: e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.keyCode === 13) {
                                        this.handleCallSearch()
                                    }
                                    if (e.key === '"Escape"' || e.keyCode === 27) {
                                        this.handleReset()
                                    }
                                }}
                                className="form-control form-control-solid border border-2 shadow-xs pl-3 inputGeneralSearch" name="search"
                                value={this.state.search} placeholder='Rechercher' id='' />
                            <button type="button"
                                onClick={() => {
                                    this.handleReset()
                                }}
                                className="btnDelete">
                                <AiOutlineCloseCircle size={25} color='#202531' />
                            </button>


                        </div>
                        <button type="button"
                            onClick={() => this.handleCallSearch()}
                            className="btn btn-outline-primary py-2 fs-6">Rechercher</button>
                    </div>
                    <p className='my-0 px-4 mt-4' style={{ color: '#F4F7FF', padding: '', fontSize: '16px', fontWeight: '300' }}>{this.props.data.listSitesMap.length} résultats</p>
                    <hr className='my-5 mx-4 bg-white ' />
                    {/* <div className='d-flex justify-content-between align-items-center mx-5'>
                        <div className='d-flex justify-content-end align-items-center' style={{ width: '200px' }}>
                            <p className='text-white m-0 mr-2' style={{ fontSize: '14px', fontWeight: '500' }}>
                                Sort by:
                            </p>
                            <select onChange={(val) => {
                                this.handleCallSearch(val)
                            }} name={'orderBY'} defaultValue={this.state.orderBY} className="orderBySelect">
                                <option value="denomination">Dénomination</option>
                                {this.orderByList.map((el) => {
                                    return <option value={el.value} key={el.value}>{el.label}</option>
                                })}
                            </select>
                        </div>
                    </div> */}
                </div>
                <div className="px-3 mt-5" style={{ borderBottom: '0px solid white' }}>
                    <div className="row row-sage py-8">
                        <div className="col-md-12 d-flex justify-content-start align-items-center gap-10">
                            <div className={`d-flex justify-content-center align-items-center flex-column gap-4 px-7 py-2 rounded
                            `}
                                style={{
                                    backgroundColor: this.state.asCategory.includes('UVE') ? '#f6ff001f' : 'transparent',
                                    border: this.state.asCategory.includes('UVE') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (!this.state.asCategory.includes('UVE')) {
                                        this.setState(state => {
                                            const asCategory = [...state.asCategory, 'UVE']
                                            return {
                                                asCategory,
                                            }
                                        })
                                    } else {
                                        this.setState(state => {
                                            const asCategory = state.asCategory.filter((el) => el !== 'UVE')
                                            return {
                                                asCategory,
                                            }
                                        })

                                    }
                                }}>
                                <div className='markerSearch markerUve'></div>
                                <h5 className='text-white m-0'>UVE</h5>
                            </div>
                            <div className={`d-flex justify-content-center align-items-center flex-column gap-4 px-7 py-2 rounded
                            ${this.state.asCategory === 'TRI' && 'border-warning'}
                            `}
                                style={{
                                    backgroundColor: this.state.asCategory.includes('TRI') ? '#f6ff001f' : 'transparent',
                                    border: this.state.asCategory.includes('TRI') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (!this.state.asCategory.includes('TRI')) {
                                        this.setState(state => {
                                            const asCategory = [...state.asCategory, 'TRI']
                                            return {
                                                asCategory,
                                            }
                                        })
                                    } else {
                                        this.setState(state => {
                                            const asCategory = state.asCategory.filter((el) => el !== 'TRI')
                                            return {
                                                asCategory,
                                            }
                                        })

                                    }
                                }}>
                                <div className='markerSearch markerTri' ></div>
                                <h5 className='text-white m-0'>TRI</h5>
                            </div>
                            <div className={`d-flex justify-content-center align-items-center flex-column gap-4 px-7 py-2 rounded
                            ${this.state.asCategory === 'TMB' && 'border-warning'}
                            `}
                                style={{
                                    backgroundColor: this.state.asCategory.includes('TMB') ? '#f6ff001f' : 'transparent',
                                    border: this.state.asCategory.includes('TMB') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (!this.state.asCategory.includes('TMB')) {
                                        this.setState(state => {
                                            const asCategory = [...state.asCategory, 'TMB']
                                            return {
                                                asCategory,
                                            }
                                        })
                                    } else {
                                        this.setState(state => {
                                            const asCategory = state.asCategory.filter((el) => el !== 'TMB')
                                            return {
                                                asCategory,
                                            }
                                        })

                                    }
                                }}>
                                <div className='markerSearch markerTmb' ></div>
                                <h5 className='text-white m-0'>TMB</h5>
                            </div>
                            <div className={`d-flex justify-content-center align-items-center flex-column gap-4 px-5 py-2 rounded
                            ${this.state.asCategory === 'ISDND' && 'border-warning'}
                            `}
                                style={{
                                    backgroundColor: this.state.asCategory.includes('ISDND') ? '#f6ff001f' : 'transparent',
                                    border: this.state.asCategory.includes('ISDND') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (!this.state.asCategory.includes('ISDND')) {
                                        this.setState(state => {
                                            const asCategory = [...state.asCategory, 'ISDND']
                                            return {
                                                asCategory,
                                            }
                                        })
                                    } else {
                                        this.setState(state => {
                                            const asCategory = state.asCategory.filter((el) => el !== 'ISDND')
                                            return {
                                                asCategory,
                                            }
                                        })

                                    }
                                }}>                               <div className='markerSearch markerIsdnd' ></div>
                                <h5 className='text-white m-0'>ISDND</h5>
                            </div>
                        </div>
                    </div>
                    {this.state.asCategory.length ?
                        <>
                            <div className='row-sage'>
                                <div className="row g-8 mb-3">
                                    <div className="col-md-12 select-sage">
                                        <label className="fs-6 form-label text-white">Mode de gestion</label>
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            allowClear
                                            placeholder='Mode de gestion'
                                            style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                            optionFilterProp="label"
                                            size="small"
                                            filterOption={(input, Option) =>
                                                Option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                            value={this.state.asModeGestion}
                                            onChange={(selectedValue) => {
                                                if (selectedValue[selectedValue.length - 1] === 'all') {
                                                    this.setState({ asModeGestion: ['all'] })
                                                } else {
                                                    this.setState({ asModeGestion: selectedValue.filter((el) => el !== 'all') })
                                                }
                                            }}
                                        >
                                            <Select.Option value='all' disabled={false}>Tout</Select.Option>
                                            {this.props.data.modeGestionData.map((el, ind) => (
                                                <Select.Option key={ind} value={el.label} disabled={false}>{el.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="row g-8 mb-3">
                                    <div className="col-md-12 select-sage">
                                        <label className="fs-6 form-label text-white">Groupe exploitant</label>
                                        <Select
                                            mode="multiple"
                                            filterOption={(input, Option) =>
                                                Option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                            showSearch
                                            size="small"
                                            allowClear
                                            placeholder='Groupe exploitant'
                                            style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                            optionFilterProp="label"
                                            value={this.state.asGroupeExploitant}
                                            onChange={(selectedValue) => {
                                                if (selectedValue[selectedValue.length - 1] === 'all') {
                                                    this.setState({ asGroupeExploitant: ['all'] })
                                                } else {
                                                    this.setState({ asGroupeExploitant: selectedValue.filter((el) => el !== 'all') })
                                                }
                                            }}
                                        >
                                            <Select.Option value='all' disabled={false}>Tout</Select.Option>
                                            {this.props.data.groupsListData.map((el, ind) => (
                                                <Select.Option key={ind} value={el.value} disabled={false}>{el.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="row g-8 mb-3">
                                    <div className="col-md-12 select-sage">
                                        <RegionSearchFilter placeholder='Région' onChange={(selectedValue) => {
                                            if (selectedValue[selectedValue.length - 1] === 'all') {
                                                this.setState({ asRegion: ['all'] })
                                            } else {
                                                this.setState({ asRegion: selectedValue.filter((el) => el !== 'all') })
                                            }
                                        }} value={this.state.asRegion} type='reg' />
                                    </div>
                                </div>
                                <div className="row g-8 mb-5">
                                    <div className="col-md-12 select-sage">
                                        <RegionSearchFilter placeholder='Département' onChange={(selectedValue) => {
                                            if (selectedValue[selectedValue.length - 1] === 'all') {
                                                this.setState({ asDepartement: ['all'] })
                                            } else {
                                                this.setState({ asDepartement: selectedValue.filter((el) => el !== 'all') })
                                            }
                                        }} value={this.state.asDepartement} type='dep' />
                                    </div>
                                </div>
                                {(this.state.asCategory.includes("ISDND") || this.state.asCategory.includes("TRI") || this.state.asCategory.includes("UVE")) && (
                                    <div className="row g-8 ">
                                        <div className="col-md-12 select-sage">
                                            <label className="fs-6 form-label text-white">Capacité règlementaire</label>
                                            <div className='d-flex justify-content-between align-items-center gap-6 mb-0 mx-6'>
                                                <input type="number" onChange={(e) => {
                                                    this.setState({ asCapacitereglementaire: [e.target.value, this.state.asCapacitereglementaire[1]] })

                                                }} className="form-control form-control-solid border border-2 shadow-xs pl-3 inputGeneralSearchOK"
                                                    min={0}
                                                    step={10000}
                                                    max={this.state.asCapacitereglementaire[1]}
                                                    name="asCapacitereglementaire[0]" value={this.state.asCapacitereglementaire[0]} id='' />
                                                <input
                                                    type="number"
                                                    min={this.state.asCapacitereglementaire[0]}
                                                    max={1000000}
                                                    step={10000}
                                                    onChange={(e) => {
                                                        this.setState({ asCapacitereglementaire: [this.state.asCapacitereglementaire[0], e.target.value] })

                                                    }} className="form-control form-control-solid border border-2 shadow-xs pl-3 inputGeneralSearchOK" name="asCapacitereglementaire[1]" value={this.state.asCapacitereglementaire[1]} id='' />
                                            </div>
                                            <div className='d-flex justify-content-center align-items-center w-100 mb-2'>
                                                <Slider
                                                    min={0}
                                                    max={1000000}
                                                    style={{ width: '90%' }}
                                                    range={{
                                                        draggableTrack: true,
                                                    }}
                                                    onChange={(value) => {
                                                        this.setState({ asCapacitereglementaire: value })

                                                    }}
                                                    value={this.state.asCapacitereglementaire}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='row-sage'>
                                <div className="row g-8 mb-3">
                                    <div className="col-md-12 select-sage">
                                        <label className="fs-6 form-label text-white">Assistant Maîtrise d'Ouvrage</label>
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            allowClear
                                            placeholder="Assistant Maîtrise d'Ouvrage"
                                            style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                            optionFilterProp="label"
                                            size="small"
                                            filterOption={(input, Option) =>
                                                Option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                            value={this.state.asAmobe}
                                            onChange={(selectedValue) => {
                                                if (selectedValue[selectedValue.length - 1] === 'all') {
                                                    this.setState({ asAmobe: ['all'] })
                                                } else {
                                                    this.setState({ asAmobe: selectedValue.filter((el) => el !== 'all') })
                                                }
                                            }}
                                        >
                                            <Select.Option value='all' disabled={false}>Tout</Select.Option>
                                            {this.props.data.amobeData.map((el, ind) => (
                                                <Select.Option key={ind} value={el.label} disabled={false}>{el.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="row-sage">
                                <div className="row select-sage m-0 mb-3">
                                    <label className="fs-6 form-label text-white">Date de fin de contrat</label>
                                    <Select
                                        // mode="multiple"
                                        showSearch
                                        allowClear
                                        placeholder={'avant/après'}
                                        style={{ width: '100%', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                        optionFilterProp="label"
                                        size="small"
                                        filterOption={(input, Option) =>
                                            Option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        value={this.state.asContractAvantApres}
                                        onChange={(selectedValue) => {
                                            this.setState({ asContractAvantApres: selectedValue })
                                        }}
                                    >
                                        <Select.Option value='all' disabled={false}>Choisir avant/après</Select.Option>
                                        <Select.Option value='avant' disabled={false}>Avant</Select.Option>
                                        <Select.Option value='entre' disabled={false}>Entre</Select.Option>
                                        <Select.Option value='apres' disabled={false}>Après</Select.Option>
                                    </Select>
                                </div>
                                <div className="row g-4 mb-3">
                                    <div className="col-md-12 select-sage m-0">
                                        <div className="row" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                            <div className={`${this.state.asContractAvantApres === 'entre' ? 'col-md-12' : 'col-md-12'} select-sage`}>
                                                <Form.Group as={Col} className="mb-3">
                                                    <label className="fs-6 form-label text-white">
                                                        {this.state.asContractAvantApres !== 'avant' && this.state.asContractAvantApres !== 'apres' && "Date"}
                                                    </label>
                                                    <Form.Control type="date" name="asContractDate" className='sage-date'
                                                        onChange={(selectedValue) => {
                                                            this.setState({ asContractDate: selectedValue.target.value })
                                                        }}
                                                        value={this.state.asContractDate ? this.state.asContractDate : ''} placeholder={'choisir une date'} />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">

                                            {this.state.asContractAvantApres === 'entre' && (
                                                <div className="col-md-12 select-sage">
                                                    <Form.Group as={Col} className="mb-3">
                                                        <label className="fs-6 form-label text-white">Et</label>
                                                        <Form.Control type="date" name="asContractDate" className='sage-date'
                                                            onChange={(selectedValue) => {
                                                                this.setState({ asContractDateEnd: selectedValue.target.value })
                                                            }}
                                                            value={this.state.asContractDateEnd ? this.state.asContractDateEnd : ''} placeholder={'choisir une date'} />
                                                    </Form.Group>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Checkbox
                                            style={{ color: 'white', userSelect: 'none' }}
                                            checked={this.state.asContractDateCheck} onChange={(e) => {
                                                this.setState({ asContractDateCheck: e.target.checked })

                                            }}>Inclure les sites sans date de fin contrat</Checkbox>
                                    </div>
                                </div>
                            </div>

                            {this.state.asCategory.includes("UVE") && (
                                <div className="mb-6 row-sage-uve">
                                    <h3 style={{ color: '#56befb', textAlign: 'center', marginBottom: '15px' }}>Filtres pour Les sites UVE</h3>
                                    <div className="row row-sage-uve-inside">
                                        <label className="fs-5 mb-4 form-label text-white">Nombre de lignes</label>
                                        <div className="col-md-12 d-flex justify-content-center align-items-center gap-8">
                                            <div className={`d-flex justify-content-center align-items-center flex-column  rounded-circle 
                            `}
                                                style={{
                                                    backgroundColor: this.state.asNombreDeLignes.includes('all') ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asNombreDeLignes.includes('all') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                    width: '60px',
                                                    height: '60px',
                                                }}
                                                onClick={() => {
                                                    if (!this.state.asNombreDeLignes.includes('all')) {
                                                        this.setState({
                                                            asNombreDeLignes: ['all']
                                                        })
                                                    } else {
                                                        this.setState({
                                                            asNombreDeLignes: []
                                                        })
                                                    }
                                                }}>
                                                <h5 className='text-white m-0'>Tout</h5>
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center flex-column  rounded-circle 
                            `}
                                                style={{
                                                    backgroundColor: this.state.asNombreDeLignes.includes('1') ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asNombreDeLignes.includes('1') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                    width: '50px',
                                                    height: '50px',
                                                }}
                                                // onClick={() => { this.setState({ asNombreDeLignes: '1' }) }}>
                                                onClick={() => {
                                                    if (!this.state.asNombreDeLignes.includes('1')) {
                                                        if (this.state.asNombreDeLignes.includes('all')) {
                                                            this.setState(state => {
                                                                const asNombreDeLignesw = state.asNombreDeLignes.filter((el) => el !== 'all')
                                                                const asNombreDeLignes = [...asNombreDeLignesw, '1']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        } else {
                                                            this.setState(state => {
                                                                const asNombreDeLignes = [...state.asNombreDeLignes, '1']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        this.setState(state => {
                                                            const asNombreDeLignes = state.asNombreDeLignes.filter((el) => el !== '1')
                                                            return {
                                                                asNombreDeLignes,
                                                            }
                                                        })

                                                    }
                                                }}>
                                                <h3 className='text-white m-0'>1</h3>
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center flex-column  rounded-circle 
                            `}
                                                style={{
                                                    backgroundColor: this.state.asNombreDeLignes.includes('2') ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asNombreDeLignes.includes('2') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                    width: '50px',
                                                    height: '50px',
                                                }}
                                                // onClick={() => { this.setState({ asNombreDeLignes: '2' }) }}>
                                                onClick={() => {
                                                    if (!this.state.asNombreDeLignes.includes('2')) {
                                                        if (this.state.asNombreDeLignes.includes('all')) {
                                                            this.setState(state => {
                                                                const asNombreDeLignesw = state.asNombreDeLignes.filter((el) => el !== 'all')
                                                                const asNombreDeLignes = [...asNombreDeLignesw, '2']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        } else {
                                                            this.setState(state => {
                                                                const asNombreDeLignes = [...state.asNombreDeLignes, '2']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        this.setState(state => {
                                                            const asNombreDeLignes = state.asNombreDeLignes.filter((el) => el !== '2')
                                                            return {
                                                                asNombreDeLignes,
                                                            }
                                                        })

                                                    }
                                                }}>
                                                <h3 className='text-white m-0'>2</h3>
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center flex-column  rounded-circle 
                            `}
                                                style={{
                                                    backgroundColor: this.state.asNombreDeLignes.includes('3') ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asNombreDeLignes.includes('3') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                    width: '50px',
                                                    height: '50px',
                                                }}
                                                // onClick={() => { this.setState({ asNombreDeLignes: '3' }) }}>
                                                onClick={() => {
                                                    if (!this.state.asNombreDeLignes.includes('3')) {
                                                        if (this.state.asNombreDeLignes.includes('all')) {
                                                            this.setState(state => {
                                                                const asNombreDeLignesw = state.asNombreDeLignes.filter((el) => el !== 'all')
                                                                const asNombreDeLignes = [...asNombreDeLignesw, '3']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        } else {
                                                            this.setState(state => {
                                                                const asNombreDeLignes = [...state.asNombreDeLignes, '3']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        this.setState(state => {
                                                            const asNombreDeLignes = state.asNombreDeLignes.filter((el) => el !== '3')
                                                            return {
                                                                asNombreDeLignes,
                                                            }
                                                        })

                                                    }
                                                }}>
                                                <h3 className='text-white m-0'>3</h3>
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center flex-column  rounded-circle 
                            `}
                                                style={{
                                                    backgroundColor: this.state.asNombreDeLignes.includes('4') ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asNombreDeLignes.includes('4') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                    width: '50px',
                                                    height: '50px',
                                                }}
                                                // onClick={() => { this.setState({ asNombreDeLignes: '4' }) }}>
                                                onClick={() => {
                                                    if (!this.state.asNombreDeLignes.includes('4')) {
                                                        if (this.state.asNombreDeLignes.includes('all')) {
                                                            this.setState(state => {
                                                                const asNombreDeLignesw = state.asNombreDeLignes.filter((el) => el !== 'all')
                                                                const asNombreDeLignes = [...asNombreDeLignesw, '4']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        } else {
                                                            this.setState(state => {
                                                                const asNombreDeLignes = [...state.asNombreDeLignes, '4']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        this.setState(state => {
                                                            const asNombreDeLignes = state.asNombreDeLignes.filter((el) => el !== '4')
                                                            return {
                                                                asNombreDeLignes,
                                                            }
                                                        })

                                                    }
                                                }}>
                                                <h3 className='text-white m-0'>4</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-6 row-sage-uve-inside">
                                        <div className="col-md-12 select-sage mb-4 p-0">
                                            <label className="fs-6 form-label text-white">Technologie de turbine</label>
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                filterOption={(input, Option) =>
                                                    Option.children.toLowerCase().includes(input.toLowerCase())
                                                }
                                                allowClear
                                                placeholder='Technologie de turbine'
                                                style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                                optionFilterProp="label"
                                                size="small"
                                                value={this.state.asTechnologieDeTurbine}
                                                onChange={(selectedValue) => {
                                                    if (selectedValue[selectedValue.length - 1] === 'all') {
                                                        this.setState({ asTechnologieDeTurbine: ['all'] })
                                                    } else {
                                                        this.setState({ asTechnologieDeTurbine: selectedValue.filter((el) => el !== 'all') })
                                                    }
                                                }}
                                            >
                                                <Select.Option value='all' disabled={false}>Tout</Select.Option>
                                                {this.props.data.typeEquipementData.map((el, ind) => (
                                                    <Select.Option key={ind} value={el.value} disabled={false}>{el.label}</Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className="col-md-12 select-sage p-0">
                                            <label className="fs-6 form-label text-white">Voie de traitement des fumées</label>
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                allowClear
                                                filterOption={(input, Option) =>
                                                    Option.children.toLowerCase().includes(input.toLowerCase())
                                                }
                                                placeholder={'Voie de traitement des fumées'}
                                                style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                                optionFilterProp="label"
                                                size="small"
                                                value={this.state.asVoieDeTraitementDesFumees}
                                                onChange={(selectedValue) => {
                                                    if (selectedValue[selectedValue.length - 1] === 'all') {
                                                        this.setState({ asVoieDeTraitementDesFumees: ['all'] })
                                                    } else {
                                                        this.setState({ asVoieDeTraitementDesFumees: selectedValue.filter((el) => el !== 'all') })
                                                    }
                                                }}
                                            >
                                                <Select.Option value='all' disabled={false}>Tout</Select.Option>
                                                {this.props.data.traitementFumeeData.map((el, ind) => (
                                                    <Select.Option key={ind} value={el.value} disabled={false}>{el.label}</Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="row row-sage-uve-inside">
                                        <label className="fs-6 mb-4 form-label text-white">Type de valorisation</label>
                                        <div className="col-md-12 d-flex justify-content-center align-items-center gap-5">
                                            <div className={`d-flex justify-content-center align-items-center p-4 rounded`}
                                                style={{
                                                    backgroundColor: this.state.asTypeDeValorisation === 'hydrogene' ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asTypeDeValorisation === 'hydrogene' ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    if (this.state.asTypeDeValorisation === 'hydrogene') {
                                                        this.setState({ asTypeDeValorisation: '' })
                                                    } else {
                                                        this.setState({ asTypeDeValorisation: 'hydrogene' })
                                                    }
                                                }
                                                }>
                                                <img src='/media/images/2023imgs/hydro.png' alt="a picture" width='35' />
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center p-4 rounded`}
                                                style={{
                                                    backgroundColor: this.state.asTypeDeValorisation === 'electric' ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asTypeDeValorisation === 'electric' ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    if (this.state.asTypeDeValorisation === 'electric') {
                                                        this.setState({ asTypeDeValorisation: '' })
                                                    } else {
                                                        this.setState({ asTypeDeValorisation: 'electric' })
                                                    }
                                                }
                                                }>
                                                <img src='/media/images/2023imgs/elec.png' alt="a picture" width='35' />
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center p-4 rounded`}
                                                style={{
                                                    backgroundColor: this.state.asTypeDeValorisation === 'thermique' ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asTypeDeValorisation === 'thermique' ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    if (this.state.asTypeDeValorisation === 'thermique') {
                                                        this.setState({ asTypeDeValorisation: '' })
                                                    } else {
                                                        this.setState({ asTypeDeValorisation: 'thermique' })
                                                    }
                                                }
                                                }>
                                                <img src='/media/images/2023imgs/thermique.png' alt="a picture" width='35' />
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center p-4 gap-2 rounded`}
                                                style={{
                                                    backgroundColor: this.state.asTypeDeValorisation === 'thermiqueANDelectric' ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asTypeDeValorisation === 'thermiqueANDelectric' ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    if (this.state.asTypeDeValorisation === 'thermiqueANDelectric') {
                                                        this.setState({ asTypeDeValorisation: '' })
                                                    } else {
                                                        this.setState({ asTypeDeValorisation: 'thermiqueANDelectric' })
                                                    }
                                                }
                                                }>
                                                <img src='/media/images/2023imgs/elec.png' alt="a picture" width='35' />
                                                <img src='/media/images/2023imgs/thermique.png' alt="a picture" width='35' />
                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.asTypeDeValorisation !== 'electric' && this.state.asTypeDeValorisation !== '') && (
                                        <div className="row g-8 mb-3 row-sage-uve-inside">
                                            <div className="col-md-12 select-sage">
                                                <label className="fs-6 form-label text-white">Chaleur vendue (MWh/an)</label>
                                                <div className='d-flex justify-content-center align-items-center gap-6 mb-2'>:
                                                    <p className='text-white m-0' style={{ fontWeight: '600', fontSize: '15px' }}>{this.state.asChaleurVendueSite ? this.state.asChaleurVendueSite[0] : ''}</p>
                                                    <Slider
                                                        min={0}
                                                        max={1000000}
                                                        style={{ width: '90%' }}
                                                        range={{
                                                            draggableTrack: true,
                                                        }}
                                                        onChange={(value) => {
                                                            this.setState({ asChaleurVendueSite: value })

                                                        }}
                                                        value={this.state.asChaleurVendueSite}
                                                    />
                                                    <p className='text-white m-0' style={{ fontWeight: '600', fontSize: '15px' }}>{this.state.asChaleurVendueSite ? this.state.asChaleurVendueSite[1] : ''}</p>
                                                </div>
                                                <Checkbox
                                                    style={{ color: 'white', userSelect: 'none' }}
                                                    checked={this.state.asChaleurVendueSiteCheck} onChange={(e) => {
                                                        this.setState({ asChaleurVendueSiteCheck: e.target.checked })

                                                    }}>Inclure sites avec donnée non disponible</Checkbox>
                                            </div>
                                        </div>
                                    )}
                                    {(this.state.asTypeDeValorisation === 'electric' || this.state.asTypeDeValorisation === 'thermiqueANDelectric') && (
                                        <div className="row g-8 row-sage-uve-inside">
                                            <div className="col-md-12 select-sage">
                                                <label className="fs-6 form-label text-white">Electricité vendue site (MWh/an)</label>
                                                <div className='d-flex justify-content-center align-items-center gap-6 mb-2'>
                                                    <p className='text-white m-0' style={{ fontWeight: '600', fontSize: '15px' }}>{this.state.asElectriciteVendueSite ? this.state.asElectriciteVendueSite[0] : ''}</p>
                                                    <Slider
                                                        min={0}
                                                        max={1000000}
                                                        style={{ width: '90%' }}
                                                        range={{
                                                            draggableTrack: true,
                                                        }}
                                                        onChange={(value) => {
                                                            this.setState({ asElectriciteVendueSite: value })

                                                        }}
                                                        value={this.state.asElectriciteVendueSite}
                                                    />
                                                    <p className='text-white m-0' style={{ fontWeight: '600', fontSize: '15px' }}>{this.state.asElectriciteVendueSite ? this.state.asElectriciteVendueSite[1] : ''}</p>
                                                </div>
                                                <Checkbox
                                                    style={{ color: 'white', userSelect: 'none' }}
                                                    checked={this.state.asElectriciteVendueSiteCheck} onChange={(e) => {
                                                        this.setState({ asElectriciteVendueSiteCheck: e.target.checked })

                                                    }}>Inclure sites avec donnée non disponible</Checkbox>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                        : null}
                    <div className="d-flex justify-content-center align-items-center row-sage py-5" style={{ position: 'absoluste', bottom: 0, left: 0, right: 0 }}>
                        <button type="button" onClick={this.handleReset} className="btn py-2 fw-bold btn-outline-danger mr-4 fs-6">Réinitialiser</button>
                        <button type="button" onClick={() => this.handleCallSearch()} className="btn btn-outline-primary py-2 fs-6">Rechercher</button>
                    </div>
                </div>
            </div >
        )
    }

    render() {
        return (
            this.asUser()
        )
    }
}