import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { CreateSocietie } from './CreateSocietie'
import { ListSocietie } from './ListSocietie'
import { ViewSocietie } from './ViewSocietie'
class SocietiePage extends Component {
    render() {
        return (
            <div className="card card-custom">
                <Switch>
                    <Route
                        path="/client/companies/view/:idCompany"
                        component={ViewSocietie}
                    />
                    <Route
                        path="/client/companies/:idCreate"
                        component={CreateSocietie}
                    />
                    <Route
                        path="/client/companies"
                        component={ListSocietie}
                    />
                </Switch>
            </div>
        )
    }
}
export { SocietiePage }