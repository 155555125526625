import React, { Component } from 'react'
import { connect } from "react-redux";
import { CommonComponent } from '../utils/CommonComponent'
class Amobe extends Component {
    render() {
        return (
            <CommonComponent keyName="amobe" titleSetting={global._trans('clientsSyndicat', 'amobe')} amobe={this.props.amobe} />
        )
    }
}
function mapState(state) {
    const { listEnums: { amobe } } = state.enums
    return { amobe }
}
const connectedClass = connect(mapState, null)(Amobe)
export { connectedClass as Amobe }