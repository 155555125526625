import { CrudService } from "../../../services";
import { shareUrls } from './shares.constants'
export function shareSite(Share) {
    return CrudService.Post(shareUrls.SHARE_SITE_URL, Share);
}
export function fetchShares(queryParams) {
    return CrudService.Get(shareUrls.FETCH_SHARES_URL, {}, queryParams);
}
export function handleStatus(Share) {
    return CrudService.Patch(shareUrls.STOP_SHARE_URL,Share);
}
export function handleDelete(Shares) {
    return CrudService.Delete(shareUrls.DELETE_SHARE_URL,Shares);
}
export function handleExtend(Shares) {
    return CrudService.Patch(shareUrls.EXTEND_SHARE_URL,Shares);
}
