import React, { Component } from 'react'
import { connect } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { SageTable, HeaderTab } from '../../Commons'
import { fetchSitesGet } from '../../../../modules/Sites/sites.actions'
import { fetchDepartements, fetchRegions } from '../../../../modules/Common/common.actions'
import { debounce } from 'lodash';
class DataSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRow: [[], [], []],
            searchValue: ["", "", ""],
            category: null,
            listsData: [[], [], []],
            loading: true,
            loadingReg: false,
            currentTab: 0
        }
        this.SageTable = null;
        this.columes = [
            [
                { title: global._trans('sites', 'denomenation'), dataIndex: "denomination" },
                { title: global._trans('common', 'address'), dataIndex: "adresse" },
                { title: global._trans('sites', 'modeGestion'), dataIndex: "modeGestion" },
                { title: global._trans('sites', 'categorieSite'), dataIndex: "categorieSite" }
            ],
            [
                { title: global._trans('common', 'codeDep'), dataIndex: "value" },
                { title: global._trans('common', 'nomDepartement'), dataIndex: "label" },
                { title: global._trans('shares', 'numberSites'), dataIndex: "sites_count" }
            ],
            [
                { title: global._trans('common', 'codeReg'), dataIndex: "value" },
                { title: global._trans('common', 'nomRegion'), dataIndex: "label" },
                { title: global._trans('shares', 'numberSites'), dataIndex: "sites_count" }
            ]
        ]
        this.keyValue = ['id_site', 'value', 'value']
        this.shareType = [{ typeShare: 'Site', dataIndex: 'id_site' }, { typeShare: 'Departement', dataIndex: 'value' }, { typeShare: 'Region', dataIndex: 'value' }]

        props.fetchSitesGet({ pageSize: 15, all: true, status: ['NOT_VALIDATED', 'VALIDATED'] })
        this.fetchDeps('')
        this.fetchReg('')

        this.handleSelectRow = this.handleSelectRow.bind(this)
        this.validSelect = this.validSelect.bind(this)
        this.handleSearch = debounce(this.handleSearch.bind(this), 2000)
        this.handleSwitchTabs = this.handleSwitchTabs.bind(this)
        this.setSageTable = this.setSageTable.bind(this)
        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.handleCategory = this.handleCategory.bind(this);
    }
    setSageTable(elem) {
        this.SageTable = elem;
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading && nextProps.listSites) {
            let prevData = this.state.listsData
            prevData[0] = nextProps.listSites
            this.setState({
                loading: false,
                listsData: prevData
            })
        }
    }

    handleSwitchTabs(keyItem) {
        if (this.props.blocked && this.props.blocked.indexOf(keyItem) !== -1) {
            return
        }
        if (this.SageTable) {
            this.SageTable.setState({ selectedRow: [] });
        }
        this.setState({
            currentTab: keyItem,
            selectedRow: [[], [], []],
        }, () => this.handleSearch(this.state.currentTab, this.state.category, this.state.searchValue[this.state.currentTab]))
    }

    handleSelectRow(selectedRowKeys) {
        let prevRow = this.state.selectedRow
        prevRow[this.state.currentTab] = selectedRowKeys
        this.setState({
            selectedRow: prevRow
        })
    }

    handleCategory = (e) => {
        this.setState({
            category: e.target.value,
        }, () => this.handleSearch(this.state.currentTab, this.state.category, this.state.searchValue[this.state.currentTab]))

    }
    handleSearchInput(e) {
        let newValues = [...this.state.searchValue];
        newValues.splice(this.state.currentTab, 1, e.target.value)
        this.setState({
            searchValue: newValues,
        }, () => this.handleSearch(this.state.currentTab, this.state.category, this.state.searchValue[this.state.currentTab]));
    }


    handleSearch(tab, category, searchq) {
        switch (tab) {
            case 0:
                this.setState({
                    loading: true
                })
                this.props.fetchSitesGet({ pageSize: 15, address: searchq, denomination: searchq, categorieSite: category, all: true, typeFilter: "inter" });
                break;
            case 1:
                this.setState({
                    loadingReg: true
                })
                this.fetchDeps(searchq, category);
                break;
            case 2:
                this.setState({
                    loadingReg: true
                })
                this.fetchReg(searchq, category)
                break;
            default:
                return;
        }
    }

    // handleSearch(e) {
    //     let value = e.target.value
    //     let prevSearch = this.state.searchValue
    //     prevSearch[this.state.currentTab] = value
    //     switch (this.state.currentTab) {
    //         case 0:
    //             this.props.fetchSites({ pageSize: 15, address: value, denomination: value, categorieSite: this.state.category, all: true })
    //             this.setState({
    //                 searchValue: prevSearch,
    //                 loading: true
    //             })
    //             break;
    //         case 1:
    //             this.setState({
    //                 searchValue: prevSearch,
    //                 category: this.state.category,
    //                 loadingReg: true
    //             })
    //             this.fetchDeps(value)
    //             break;
    //         case 2:
    //             this.setState({
    //                 searchValue: prevSearch,
    //                 category: this.state.category,
    //                 loadingReg: true
    //             })
    //             this.fetchReg(value)
    //             break;
    //     }
    // }
    fetchDeps(value, category) {
        fetchDepartements(value, { hasCount: true, category, all: true })
            .then(({ message, list }) => {
                if (message === "async") {
                    let prevData = this.state.listsData
                    prevData[1] = list
                    this.setState({
                        listsData: prevData,
                    })
                }
            })
            .finally(e => {
                this.setState({
                    loadingReg: false
                })
            })
    }
    fetchReg(value, category) {
        fetchRegions(value, { hasCount: true, category, all: true })
            .then(({ message, list }) => {
                if (message === "async") {
                    let prevData = this.state.listsData
                    prevData[2] = list
                    this.setState({
                        listsData: prevData,
                    })
                }
            })
            .finally(e => {
                this.setState({
                    loadingReg: false
                })
            })
    }
    validSelect() {
        let elems = this.state.selectedRow[this.state.currentTab].map(itemElem => {
            let elem = this.state.listsData[this.state.currentTab].find(obj => obj[this.shareType[this.state.currentTab].dataIndex] == itemElem)
            if (elem) elem = { ...elem, ...this.shareType[this.state.currentTab], columns: { UVE: null, TRI: null, TMB: null, ISDND: null, generalInfo: {}, Client: {}, Company: {} } }
            return elem
        })
        this.props.validSelection(elems)
    }
    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} aria-labelledby="example-modal-sizes-title-lg">
                <HeaderTab style={{ paddingBlock: 10 }} items={[global._trans("common", "sites"), global._trans("common", "departementSiege"), global._trans("common", "regionSiege")]} switchItem={this.handleSwitchTabs} active={this.state.currentTab} />
                <div className="px-5 pb-5 pt-2">
                    <div className='d-flex align-items-center'>
                        <Form.Control type="text" name="searchvalue" value={this.state.searchValue[this.state.currentTab]} placeholder={global._trans('common', 'searchData')} onChange={this.handleSearchInput} />
                        <Form.Label className='text-nowrap mb-0 mx-2'>{global._trans("sites", "categorieSite")}</Form.Label>
                        <Form.Control as="select" name="category" onChange={this.handleCategory} value={this.state.category}>
                            <option value="all">Tout</option>
                            <option value="UVE">UVE</option>
                            <option value="TRI">TRI</option>
                            <option value="TMB">TMB</option>
                            <option value="ISDND">ISDND</option>
                        </Form.Control>
                    </div>

                    <SageTable
                        ref={this.setSageTable}
                        columns={this.columes[this.state.currentTab]}
                        data={this.state.listsData[this.state.currentTab]}
                        style={{ height: 560 }}
                        IterableProps={{
                            rowSelection: {
                                selectedRowKeys: this.state.selectedRow[this.state.currentTab],
                                onChange: this.handleSelectRow,
                                type: this.props.typeSelect || 'radio'
                            },
                            emptyText: global._trans('common', 'emptyData'),
                            loading: this.state.loading || this.state.loadingReg,
                            rowKey: this.keyValue[this.state.currentTab],
                            pagination: false
                        }}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="primary" onClick={this.validSelect}>{global._trans('common', 'validSelect')}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
function mapState(state) {
    const { listSites } = state.sites
    return { listSites }
}
const connectedClass = connect(mapState, { fetchSitesGet })(DataSelector)
export { connectedClass as DataSelector }